import React, { useRef, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { StringToInt } from 'utils';


interface Props {
  input: any;
  className?: string;
  placeholder?: string;
  fnCallback: Function;
  tabindex: string;
  meta: { touched: string; error: string };
  txtError?: string;
}

function priceCheckMethod(e: any) {
  const re = /[0-9a-zA-Z.]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
}

const RenderInputAdvanceTradePrice: React.FunctionComponent<Props> = (
  props,
) => {
  const [unit] = useState<number>(100);

  const wrapperRef = useRef<any>();
  const {
    input,
    className,
    placeholder,
    fnCallback,
    tabindex,
    meta: { touched, error },
    txtError,
  } = props;

  function _handleMinus() {
    const { value } = input;

    const _newPrice = Math.floor((StringToInt(value) - unit) / unit) * unit;

    fnCallback(_newPrice < 0 ? 0 : _newPrice);
  }

  function _handlePlus() {
    const { value } = input;

    const _newPrice = Math.floor((StringToInt(value) + unit) / unit) * unit;

    fnCallback(_newPrice < 0 ? 0 : _newPrice);
  }

  return (
    <div className="flex flex-col w-full vol-advance" ref={wrapperRef}>
      <div
        className="w-full grid grid-cols-[20px_auto_20px] form-gr-trade"
        style={{
          borderColor: `${(touched && error) || txtError ? '#FF0000' : '#343142'
            }`,
        }}
      >
        <div className="btn-minus" onClick={_handleMinus}>
          <FaMinus />
        </div>

        <input
          {...input}
          style={{ width: 'inherit' }}
          onKeyPress={(e) => priceCheckMethod(e)}
          type="text"
          className={className}
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          tabIndex={tabindex}
          onFocus={(e) => {
            e.currentTarget.select();
          }}
        />
        <div className="btn-plus" onClick={_handlePlus}>
          <FaPlus />
        </div>
      </div>
      {((touched && error) || txtError) && (
        <div
          style={{
            color: '#FF0000',
            margin: '0',
            fontSize: '0.75rem',
          }}
        >
          {error || txtError}
        </div>
      )}
    </div>
  );
};

export default RenderInputAdvanceTradePrice;
