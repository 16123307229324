import { handleApiErrors } from 'lib/api-error';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { Config } from 'interface';
import { AppState } from 'reducers';
import { checkInvalidSession } from 'utils';
import {
  accBenListError,
  accBenListSuccess,
  accBenUpdError,
  accBenUpdSuccess,
  accCashApprError,
  accCashApprSuccess,
  accInfoError,
  accInfoSuccess,
  branchInfoError,
  branchInfoSuccess
} from './actions';
import * as actions from './actionType';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

async function postRequestApi(data: Object, appUrl: string) {
  const url = `${appUrl}/TraditionalService`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* accountInfoRequestFlow(action: actions.AccInfoRequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accInfoSuccess(_res.data[0]));
  } catch (error: any) {
    yield put(accInfoError(error));
  }
}

function* accountCashApprRequestFlow(
  action: actions.AccCashApprRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accCashApprSuccess(Math.random()));
  } catch (error: any) {
    yield put(accCashApprError(error));
  }
}

function* accountBenUpdRequestFlow(
  action: actions.AccBenUpdRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenUpdSuccess(Math.random()));
  } catch (error: any) {
    yield put(accBenUpdError(error));
  }
}

function* accountBenListRequestFlow(
  action: actions.AccBenListRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(accBenListSuccess(_res.data));
  } catch (error: any) {
    yield put(accBenListError(error));
  }
}

function* branchInfoRequestFlow(
  action: actions.BranchInfoRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(branchInfoSuccess(_res.data?.length ? _res.data[0] : null));
  } catch (error: any) {
    yield put(branchInfoError(error));
  }
}

function* custWatcher() {
  yield all([
    takeLatest(actions.ACC_INFO_REQUESTING, accountInfoRequestFlow),
    takeLatest(actions.ACC_CASH_APPR_REQUESTING, accountCashApprRequestFlow),
    takeLatest(actions.ACC_BEN_UPD_REQUESTING, accountBenUpdRequestFlow),
    takeLatest(actions.ACC_BEN_LIST_REQUESTING, accountBenListRequestFlow),
    takeLatest(actions.BRANCH_INFO_REQUESTING, branchInfoRequestFlow),
  ]);
}

export default custWatcher;
