import { makeGetClientSetting } from 'lib/selector';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { useConfig } from 'shared/configContext';

ReactModal.setAppElement('#root');

const styleLg = {
  content: {
    inset: '0',
    padding: '0',
    border: '0',
    backgroundColor: 'var(--color-bg-trade)',
  },
  overlay: {
    backgroundColor: 'transparent',
  },
};

const styleXs = {
  content: {
    inset: '0',
    padding: '0',
    border: '0',
    overflow: 'inherit',
    backgroundColor: 'var(--color-bg-trade)',
  },
};

interface Props {
  showModal: boolean;
  handleCloseModal: any;
  symbol: string;
  settings?: any;
}

function StockDetail(props: Props): JSX.Element {
  const { showModal, handleCloseModal, symbol, settings } = props;

  const [lang, setLang] = React.useState<string>('');
  const [theme, setTheme] = React.useState<string>('');

  const { config } = useConfig();

  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    const lang = settings?.lang || 'vi';
    const theme = settings?.theme || 'dark';
    setLang(lang);
    setTheme(theme);

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      style={dimensions.width >= 820 ? styleLg : styleXs}
    >
      <div className="absolute right-2 top-2 z-10">
        <FaTimes onClick={handleCloseModal} color="var(--color-text-3)" />
      </div>

      <iframe
        className={
          'm-0 w-full h-full ' + (dimensions.width < 820 ? 'mt-5' : '')
        }
        title="chart"
        src={`${config?.HomeUrl}/dn-360?symbol=${symbol || 'AAA'}&lang=${lang || 'vi'
          }&theme=${theme || 'dark'}`}
        scrolling="no"
      ></iframe>
    </ReactModal>
  );
}

const makeMapStateToProps = () => {
  const getClientSetting = makeGetClientSetting();
  const mapStateToProps = (state: AppState) => {
    return {
      settings: getClientSetting(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(React.memo(StockDetail));
