import * as actions from './actionType';

export interface RegAccountState {
  regCheckCust: any;
  regCheckOtp: any;
  regAccount: any;
  regCustTemp: any;

  resCheckCust: any;
  resChangePass: any;
}

const initialState = {
  regCheckCust: null,
  regCheckOtp: null,
  regAccount: null,
  regCustTemp: null,

  resCheckCust: null,
  resChangePass: null,
};

export default function regAccReducer(
  state: RegAccountState = initialState,
  action: actions.RegAccountAction,
): RegAccountState {
  switch (action.type) {
    case actions.REG_CHECK_CUST_REQUESTING:
    case actions.REG_CHECK_CUST_ERROR:
      return {
        ...state,
        regCheckCust: null,
      };

    case actions.REG_CHECK_CUST_SUCCESS:
      return {
        ...state,
        regCheckCust: action.resData,
      };

    case actions.REG_CHECK_OTP_REQUESTING:
    case actions.REG_CHECK_OTP_ERROR:
      return {
        ...state,
        regCheckOtp: null,
      };

    case actions.REG_CHECK_OTP_SUCCESS:
      return {
        ...state,
        regCheckOtp: action.resData,
      };

    case actions.REG_ACCOUNT_REQUESTING:
    case actions.REG_ACCOUNT_ERROR:
      return {
        ...state,
        regAccount: null,
      };

    case actions.REG_ACCOUNT_SUCCESS:
      return {
        ...state,
        regAccount: action.resData,
      };

    case actions.REG_CUST_TEMP:
      return {
        ...state,
        regCustTemp: action.data,
      };

    case actions.RES_CHECK_CUST_REQUESTING:
    case actions.RES_CHECK_CUST_ERROR:
      return {
        ...state,
        resCheckCust: null,
      };

    case actions.RES_CHECK_CUST_SUCCESS:
      return {
        ...state,
        resCheckCust: action.resData,
      };

    case actions.RES_CHANGE_PASS_REQUESTING:
    case actions.RES_CHANGE_PASS_ERROR:
      return {
        ...state,
        resChangePass: null,
      };

    case actions.RES_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        resChangePass: action.resData,
      };

    default:
      return state;
  }
}
