import * as actions from './actionType';

export interface ClientState {
  token: any;
  toast: any;
  config: any;
  setting: any;
  typeNav: string;
  reqLogin: boolean;
  dataSearch: any;
  showNav: boolean;
  footActive: string;
  headActive: string;

  reqChangePass: boolean;
  reqChangePin: boolean;
  reqLostSession: boolean;
  reqTrading: boolean;
  objTrading: any;
  symbolActive: any;
  symbolDel: any;
  symbolAdd: any;
  symbolScroll: any;
  pin: any;
  tempPin: any;
  ordCond: boolean;
  dblPri: any;
  configRequesting: boolean;
  configSuccessful: boolean;
  configErrors: any;

  category: any;
  categoryRequesting: boolean;
  categorySuccessful: boolean;
  categoryErrors: any;

  accPortfolio: any;
  accPortfolioRequesting: boolean;
  updPortfolio: any;
  delPortfolio: any;

  listAccount: any;
  defAccount: any;
  allBank: any;
  bankBranch: any;
  allProvince: any;

  checkPin: any;
  confirmOrder: any;
}

const initialState = {
  token: null,
  toast: null,
  config: null,
  setting: null,
  typeNav: 'minimize',
  reqLogin: false,
  dataSearch: null,
  showNav: false,
  footActive: '',
  headActive: '',
  reqChangePass: false,
  reqChangePin: false,
  reqLostSession: false,
  reqTrading: false,
  objTrading: null,
  symbolActive: null,
  symbolDel: null,
  symbolAdd: null,
  symbolScroll: null,
  pin: null,
  tempPin: null,
  ordCond: false,
  dblPri: null,
  configRequesting: false,
  configSuccessful: false,
  configErrors: [],

  category: null,
  categoryRequesting: false,
  categorySuccessful: false,
  categoryErrors: [],

  accPortfolio: null,
  accPortfolioRequesting: false,
  updPortfolio: null,
  delPortfolio: null,

  listAccount: [],
  defAccount: null,
  allBank: null,
  bankBranch: null,
  allProvince: null,

  checkPin: null,
  confirmOrder: null,
};

export default function clientReducer(
  state: ClientState = initialState,
  action: actions.ClientAction | any,
): ClientState {
  switch (action.type) {
    case 'INVALID_SESSION':
      return {
        ...state,
        reqLogin: true,
        token: null,
      };
    case 'INVALID_SESSION_CLEAR':
      return {
        ...state,
        reqLogin: false,
      };

    case actions.CLIENT_UNSET:
      return {
        ...state,
        token: null,
      };

    case 'REQUEST_PRIVATE_FALSE':
    case 'API_ERR':
      return {
        ...state,
        toast: {
          msg: action.msg,
          title: 'Thông báo',
          type: 'error',
        },
      };

    case 'CLEAR_TOAST':
      return {
        ...state,
        toast: null,
      };

    case actions.CLIENT_SET:
      return {
        ...state,
        token: action.data,
      };

    case actions.CLIENT_TOAST_SET:
      return {
        ...state,
        toast: action.data,
      };

    case actions.CLIENT_TOAST_CLEAR:
      return {
        ...state,
        toast: null,
      };

    case actions.CLIENT_CONFIG_SET:
      return {
        ...state,
        config: action.data,
      };

    case actions.CLIENT_TYPENAV_SET:
      return {
        ...state,
        typeNav: action.data,
      };

    case actions.DATA_SEARCH_SET:
      return {
        ...state,
        dataSearch: action.data,
      };

    case actions.SHOW_NAV_SET:
      return {
        ...state,
        showNav: action.data,
      };

    case actions.CLIENT_SETTING_SET:
      return {
        ...state,
        setting: action.data,
      };

    case actions.FOOTER_ACTIVE_SET:
      return {
        ...state,
        footActive: action.data,
      };

    case actions.HEADER_ACTIVE_SET:
      return {
        ...state,
        headActive: action.data,
      };

    case actions.SHOW_TOAST:
      return {
        ...state,
        toast: action.resData,
      };

    case actions.SYMBOL_DEL:
      return {
        ...state,
        symbolDel: action.symbol,
      };

    case actions.SYMBOL_ADD:
      return {
        ...state,
        symbolAdd: action.symbol,
      };

    case actions.SYMBOL_SCROLL:
      return {
        ...state,
        symbolScroll: action.symbol,
      };

    case actions.CATEGORY_SET:
      return {
        ...state,
        category: action.category,
      };

    case actions.SETTING_SET:
      return {
        ...state,
        setting: action.setting,
      };

    case actions.LOGIN_SET:
      return {
        ...state,
        reqLogin: true,
      };

    case actions.LOGIN_UNSET:
      return {
        ...state,
        reqLogin: false,
      };

    case actions.CHANGE_PASS_SET:
      return {
        ...state,
        reqChangePass: action.data,
      };

    case actions.CHANGE_PIN_SET:
      return {
        ...state,
        reqChangePin: action.data,
      };

    case actions.OBJ_TRADING_SET:
      return {
        ...state,
        objTrading: action.data,
      };

    case actions.OBJ_TRADING_UNSET:
      return {
        ...state,
        objTrading: null,
      };

    case actions.TYPE_INDEX_SET:
      return {
        ...state,
        setting: { ...state.setting, typeIndex: action.data },
      };

    case actions.TYPE_PRICE_SET:
      return {
        ...state,
        setting: { ...state.setting, priceType: action.data },
      };

    case actions.INDEX_HIDE_SET:
      return {
        ...state,
        setting: { ...state.setting, indHid: action.data },
      };

    case actions.DBL_PRICE_SET:
      return {
        ...state,
        dblPri: action.data,
      };

    case actions.SYMBOL_ACTIVE_SET:
      return {
        ...state,
        symbolActive: action.data,
      };

    case actions.CONFIG_REQUESTING:
      return {
        ...state,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [],
      };

    case actions.CONFIG_REQUEST_SUCCESS:
      return {
        ...state,
        config: action.config,
        configRequesting: false,
        configSuccessful: true,
        configErrors: [],
      };

    case actions.CONFIG_REQUEST_ERROR:
      return {
        ...state,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.CATEGORY_REQUESTING:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [],
      };

    case actions.CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        category: action.category,
        categoryRequesting: false,
        categorySuccessful: true,
        categoryErrors: [],
      };

    case actions.CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.GET_BANK_ONLINE_REQUESTING:
    case actions.GET_BANK_ONLINE_REQUEST_ERROR:
      return {
        ...state,
        allBank: [],
      };

    case actions.GET_BANK_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        allBank: action.resData,
      };

    case actions.GET_BANK_BRANCH_REQUESTING:
    case actions.GET_BANK_BRANCH_REQUEST_ERROR:
      return {
        ...state,
        bankBranch: [],
      };

    case actions.GET_BANK_BRANCH_REQUEST_SUCCESS:
      return {
        ...state,
        bankBranch: action.resData,
      };

    case actions.GET_PROVINCE_REQUESTING:
    case actions.GET_PROVINCE_REQUEST_ERROR:
      return {
        ...state,
        allProvince: [],
      };

    case actions.GET_PROVINCE_REQUEST_SUCCESS:
      return {
        ...state,
        allProvince: action.resData,
      };

    case actions.LIST_ACCOUNT_REQUESTING:
    case actions.LIST_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        listAccount: [],
      };

    case actions.LIST_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        listAccount: action.resData,
      };

    case actions.ACC_PORTFOLIO_REQUESTING:
      return {
        ...state,
        // accPortfolio: [],
        accPortfolioRequesting: true,
      };

    case actions.ACC_PORTFOLIO_SUCCESS:
      return {
        ...state,
        accPortfolio: action.resData,
        accPortfolioRequesting: false,
      };

    case actions.ACC_PORTFOLIO_ERROR:
      return {
        ...state,
        accPortfolio: [],
        accPortfolioRequesting: false,
      };

    case actions.UPD_PORTFOLIO_REQUESTING:
    case actions.UPD_PORTFOLIO_ERROR:
      return {
        ...state,
        updPortfolio: null,
      };

    case actions.UPD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        updPortfolio: action.resData,
      };

    case actions.DEL_PORTFOLIO_REQUESTING:
    case actions.DEL_PORTFOLIO_ERROR:
      return {
        ...state,
        delPortfolio: null,
      };

    case actions.DEL_PORTFOLIO_SUCCESS:
      return {
        ...state,
        delPortfolio: action.resData,
      };

    case actions.CHECK_PIN_REQUESTING:
    case actions.CHECK_PIN_REQUEST_ERROR:
      return {
        ...state,
        checkPin: null,
      };

    case actions.CHECK_PIN_REQUEST_SUCCESS:
      return {
        ...state,
        checkPin: action.resData,
      };

    case actions.DEF_ACCOUNT_SET:
      return {
        ...state,
        defAccount: action.resData,
      };

    case 'PIN_SET':
      return {
        ...state,
        pin: action.data,
      };

    case 'TEMP_PIN_SET':
      return {
        ...state,
        tempPin: action.data,
      };
    case 'CONFIRM_ORDER':
      return {
        ...state,
        confirmOrder: action.data,
      };
    case 'REQ_TRADING':
      return {
        ...state,
        reqTrading: action.data,
      };

    default:
      return state;
  }
}
