import _ from 'lodash';
import React from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Check from 'assets/img/icon/check.png';
//#region styled

const styleLg = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: 'auto',
    left: 'calc( 50% - 275px )',
    height: 'auto',
    width: '550px',
    padding: '0',
    borderWidth: '0',
    borderColor: '#f3f3f3',
    overflow: 'inherit',
    borderRadius: '12px',
  },
};

const styleXs = {
  content: {
    inset: '12px',
    padding: '0',
    borderWidth: '0',
    borderColor: '#f3f3f3',
    overflow: 'inherit',
  },
};

//#endregion

interface Props {
  onClose: Function;
  data: any;
  accountCode: any;
}

function RegisterSuccessModal(props: Props): JSX.Element {
  const { t } = useTranslation();
  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const { onClose, data, accountCode } = props;

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      ariaHideApp={false}
      onRequestClose={() => onClose()}
      //   shouldCloseOnOverlayClick={true}
      overlayClassName="overlay"
      style={dimensions.width >= 820 ? styleLg : styleXs}
    >
      <div
        className="w-full rounded-xl h-full bg-white p-8 flex flex-col items-center justify-center text-skin-text1"
        style={
          dimensions.width >= 820 ? { maxHeight: 'calc(100vh - 120px)' } : {}
        }
      >
        <div className="w-32 h-32 rounded-full bg-skin-active flex items-center justify-center">
          <img src={Check} alt="" />
        </div>
        <b className="text-2xl pl-3 mt-4">Bạn đã hoàn tất yêu cầu</b>
        <span className="my-4 text-base text-center md:text-left">
          Cảm ơn bạn {data?.userName} đã tham gia VST
        </span>
        <b className="text-base text-center">
          Bạn có thể đăng nhập và trải nghiệm chứng khoán ngay sau khi tài khoản
          được duyệt bằng email đã đăng ký:
        </b>
        <b className="text-lg text-skin-text6 mb-6 leading-10">
          {data?.userEmail}
        </b>
        <button
          onClick={() => onClose()}
          className="w-full bg-skin-active text-skin-white text-base h-14 flex items-center justify-center rounded-md"
        >
          Quay lại trang đăng nhập
        </button>
      </div>
    </ReactModal>
  );
}

export default RegisterSuccessModal;
