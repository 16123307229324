/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef } from 'react';
import * as router from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';

import * as _ from 'lodash';

import { ToastContainer, toast as notify } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  makeGetConfig,
  makeGetToast,
  makeGetToken,
  makeGetTypeNav,
} from 'lib/selector';
import NavLeft from 'shared/navLeft';
import {
  accountPortfolioRequest,
  clientTokenUnset,
  defaultAccountSet,
  listAccountRequest,
} from 'containers/client/actions';
import { unsetRegSymbol } from 'containers/socket/actions';
import Loading from 'shared/loading';
import DefaultFooter from './DefaultFooter';
import { useTranslation } from 'react-i18next';
import { storages } from 'lib/storages';
import CashTransfer from 'containers/cash/cash-transfer';
import { allStockRequest } from '../banggia/actions';
import { WebSocketContext } from '../socket/webSocket';
import { AlgoSignalContext } from 'containers/socket/signalAlgo';
// import { checkDefaultAuthorization, checkDefaultRight } from "lib/check-auth";

// const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

const Trading = React.lazy(() => import('../trading'));
const OrderBook = React.lazy(() => import('../trading/order-book'));
const SaoKeTien = React.lazy(() => import('../summary/sao-ke/tien'));
const SaoKeCK = React.lazy(() => import('../summary/sao-ke/chung-khoan'));
const SaoKeLai = React.lazy(() => import('../summary/sao-ke/lai-lo'));
const Right = React.lazy(() => import('../right'));

const DmChungKhoanPage = React.lazy(() => import('../cash/chung-khoan'));
const UserPage = React.lazy(() => import('../user'));

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <router.Route
      {...rest}
      render={(props) => {
        // checkDefaultAuthorization(rest);
        return <Component {...props} store={rest.dispatch.store} />;
      }}
    />
  );
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function DefaultCash(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = router.useLocation();
  const navigate = router.useHistory();
  const [timeToLive] = React.useState(120);
  const [showLossSessionModal, setShowLossSessionModal] = React.useState(false);
  const ws = React.useContext(WebSocketContext);
  const as = React.useContext(AlgoSignalContext);

  const { token, toast, reqLogin, typeNav, regSym } = props;
  const prevToast = usePrevious(toast);
  const preToken = usePrevious(token);
  const preReqLogin = usePrevious(reqLogin);
  const prePathName = usePrevious(pathname);

  let logoutTimeout;

  useEffect(() => {
    ws.init();
    as.init();
    dispatch(allStockRequest());
    const _token = storages.loadState('token');
    console.log('token', _token);
    if (!_token) {
      navigate.push('/cash/bang-gia/vn30');
      return;
    }

    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      storages.saveState('ttl', new Date().getTime());
    });
  }, []);

  useEffect(() => {
    if (pathname && !_.isEqual(pathname, prePathName)) {
      if (pathname.indexOf('/cash/bang-gia') < 0 && regSym) {
        // leave data
        const payload = {
          action: 'leave',
          data: regSym,
        };
        //log("leave socket data", JSON.stringify(payload));
        ws.sendMessage(payload);
        dispatch(unsetRegSymbol());
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!token && !_.isEqual(token, preToken)) {
      clearTimeoutFunc();
      if (preToken) {
        if (pathname.indexOf('/cash/bang-gia') > -1) {
          if (!showLossSessionModal) {
            setShowLossSessionModal(true);
          }
        } else navigate.push('/cash/bang-gia/vn30');
      }
    }

    if (token && !_.isEqual(token, preToken)) {
      const ttl = storages.loadState('ttl') || new Date().getTime(),
        now = new Date().getTime();
      const diffMinute = (now - ttl) / (1000 * 60);
      // console.log(diffMinute);
      if (diffMinute > timeToLive) {
        _actionTimeToLive();
        return;
      }

      dispatch({ type: 'INVALID_SESSION_CLEAR' });
      dispatch(defaultAccountSet(token.defaultAcc));
      // load list account
      getListAccount();
      const events = [
        // 'load',
        // 'mousemove',
        // 'mousedown',
        'click',
        'scroll',
        'keypress',
      ];
      for (var i in events) {
        window.addEventListener(events[i], resetTimeout);
      }

      setTimeOut();
    }
  }, [token]);

  useEffect(() => {
    if (reqLogin && !_.isEqual(reqLogin, preReqLogin)) {
      confirmAlert({
        title: t('notice.yc-ht'),
        message: t('notice.relogin'),
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              Logout();
            },
          },
        ],
        onClickOutside: () => {
          Logout();
        },
      });

      // reset
      dispatch({ type: 'INVALID_SESSION_CLEAR' });
    }
  }, [reqLogin]);

  useEffect(() => {
    if (toast && toast.msg && !_.isEqual(toast, prevToast)) {
      if (toast.type) {
        notify[toast.type](toast.msg?.message || toast.msg);
      } else notify.info(toast.msg?.message || toast.msg);
      dispatch({ type: 'CLEAR_TOAST' });
    }
  }, [toast]);

  const Logout = () => {
    dispatch(clientTokenUnset());
    storages.removeState('token');
    storages.removeState('ttl');

    setTimeout(() => {
      navigate.push('/login');
    }, 100);
  };

  const resetTimeout = () => {
    // console.log('resetTimeout');
    clearTimeoutFunc();
    setTimeOut();
  };

  const clearTimeoutFunc = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const setTimeOut = () => {
    // console.log('timeToLive', timeToLive);
    logoutTimeout = setTimeout(_actionTimeToLive, timeToLive * 60 * 1000);
  };

  const _actionTimeToLive = () => {
    dispatch({ type: 'INVALID_SESSION' });

    dispatch(clientTokenUnset());
    storages.removeState('token');
    storages.removeState('ttl');
  };

  const getListAccount = () => {
    const params = {
      group: 'B',
      user: token.user,
      session: token.sid,
      data: {
        type: 'cursor',
        cmd: 'ListAccount',
      },
    };

    // request list account
    dispatch(listAccountRequest(params));
  };

  return (
    <div className="app overflow-y-hidden h-screen bg-skin-fill text-skin-base">
      <Suspense fallback={<Loading />}>
        <DefaultHeader token={token} />
      </Suspense>
      <div className="app-body">
        <NavLeft typeNav={typeNav} />
        <main
          className={
            'main w-full h-[calc(100vh-72px)] ' +
            (typeNav === 'full'
              ? 'md:w-[calc(100vw-18rem)]'
              : 'md:w-[calc(100vw-5rem)]')
          }
        >
          <div className="p-0 h-[calc(100vh-106px)]">
            <Suspense fallback={<Loading />}>
              <router.Switch>
                <PrivateRoute
                  path="/cash/trading"
                  component={Trading}
                  dispatch={props}
                  store={props}
                />
                <PrivateRoute
                  path="/cash/so-lenh/:typeId?"
                  component={OrderBook}
                  dispatch={props}
                  store={props}
                />
                <PrivateRoute
                  path="/cash/cash-transaction"
                  component={SaoKeTien}
                  dispatch={props}
                  store={props}
                />
                <PrivateRoute
                  path="/cash/lai-lo-da-thuc-hien"
                  component={SaoKeLai}
                  dispatch={props}
                  store={props}
                />
                <PrivateRoute
                  path="/cash/right-info/:typeId?"
                  component={Right}
                  dispatch={props}
                  store={props}
                />
                <PrivateRoute
                  path="/cash/bank-transfer"
                  component={SaoKeCK}
                  dispatch={props}
                  store={props}
                />

                <PrivateRoute
                  path="/cash/nop-rut-tien"
                  component={CashTransfer}
                  dispatch={props}
                />
                <PrivateRoute
                  path="/cash/danh-muc-ck"
                  component={DmChungKhoanPage}
                  dispatch={props}
                />
                <PrivateRoute
                  path="/cash/quan-tri-nguoi-dung/:typeId?"
                  component={UserPage}
                  dispatch={props}
                />
                <router.Redirect
                  from="/cash/so-lenh"
                  to="/cash/so-lenh/inday"
                />
                <router.Redirect
                  from="/cash/right-info"
                  to="/cash/right-info/register"
                />
              </router.Switch>
            </Suspense>
          </div>
          <Suspense fallback={<Loading />}>
            <DefaultFooter />
          </Suspense>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getConfig = makeGetConfig();
  const getToast = makeGetToast();
  const getTypeNav = makeGetTypeNav();

  const mapStateToProps = (state) => {
    // console.log(state.client)
    return {
      token: getToken(state),
      config: getConfig(state),
      toast: getToast(state),
      typeNav: getTypeNav(state),
      regSym: state.socket.regSym,
      reqLogin: state.client.reqLogin,
      showNav: state.client.showNav,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(DefaultCash);
