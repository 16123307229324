import { handleApiErrors } from 'lib/api-error'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as actions from './actionType'

import {
  advStockListDataError,
  advStockListDataSuccess,
  advStockSingleError,
  advStockSingleSuccess,
  allOrdCondError,
  allOrdCondSuccess,
  allOrdRequestError,
  allOrdRequestSuccess,
  allStockRequestError,
  allStockRequestSuccess,
  branchCodeRequestError,
  branchCodeRequestSuccess,
  cwListRequestError,
  cwListRequestSuccess,
  dmNganhRequestError,
  dmNganhRequestSuccess,
  getStockByIdRequestError,
  getStockByIdRequestSuccess,
  indexRequestError,
  indexRequestSuccess,
  indSumRequestError,
  indSumRequestSuccess,
  list30RequestError,
  list30RequestSuccess,
  listEtfRequestError,
  listEtfRequestSuccess,
  listMessageRequestError,
  listMessageRequestSuccess,
  listNganhRequestError,
  listNganhRequestSuccess,
  loleHSXSnapshotRequestError,
  loleHSXSnapshotRequestSuccess,
  loleSnapshotRequestError,
  loleSnapshotRequestSuccess,
  marginListRequestError,
  marginListRequestSuccess,
  ndataSnapshotRequestError,
  ndataSnapshotRequestSuccess,
  noicapRequestError,
  noicapRequestSuccess,
  psIndexRequestError,
  psIndexRequestSuccess,
  psListRequestError,
  psListRequestSuccess,
  psSnapshotRequestError,
  psSnapshotRequestSuccess,
  ptListRequestError,
  ptListRequestSuccess,
  secInfoAdvError,
  secInfoAdvSuccess,
  snapshotPartRequestError,
  snapshotPartRequestSuccess,
  snapshotRequestError,
  snapshotRequestSuccess,
  snapshotUnderlyingCWError,
  snapshotUnderlyingCWSuccess,
  stockInfoRequestError,
  stockInfoRequestSuccess,
  topInterestRequestError,
  topInterestRequestSuccess,
  tradeHisPsRequestError,
  tradeHisPsRequestSuccess,
  vcbsFilterListError,
  vcbsFilterListSuccess,
  vcbsFilterSnapshotError,
  vcbsFilterSnapshotSuccess,
} from './actions'

import { checkInvalidSession, getMsgByErrorCode } from 'utils'

import { processMapData } from 'utils/processMapData'

import axios from 'axios'
import { Config } from 'interface'
import { map, sortBy } from 'lodash'
import { AppState } from 'reducers'

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.timeout = 3000

const infoUrl = `${process.env.REACT_APP_INFO_URL}`
const priceUrl = `${process.env.REACT_APP_PRICE_URL}`

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error
    })
}

function handleTextRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.text())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error
    })
}

function allStockRequestApi(appUrl: string) {
  const url = `${appUrl}/getlistallstock`
  const request = fetch(url)

  return handleRequest(request)
}

function marginListRequestApi() {
  const url = `${priceUrl}/getMarginList`
  const request = fetch(url)

  return handleRequest(request)
}

function ptListRequestApi() {
  const url = `${infoUrl}/getPTList`
  const request = fetch(url)

  return handleRequest(request)
}

function psListRequestApi() {
  const url = `${priceUrl}/pslistdata`
  const request = fetch(url)

  return handleRequest(request)
}

function cwListRequestApi() {
  const url = `${infoUrl}/listCW.pt`
  const request = fetch(url)

  return handleRequest(request)
}

function list30RequestApi(data: any) {
  const url = `${priceUrl}/adv_getliststockbyid/${data}`
  const request = fetch(url)

  return handleTextRequest(request)
}

function listNganhRequestApi(data: any, algoUrl: string) {
  const request = fetch(algoUrl + '/pbapi/api/sectors/' + data)
  return handleRequest(request)
}

function listEtfRequestApi(data: any) {
  const url = `${infoUrl}/listETF.pt?market=${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function listMessageRequestApi() {
  return null
}

function branchCodeRequestApi(appUrl: string) {
  const url = `${appUrl}/getInmemList/BRANCH_CODE`
  const request = fetch(url)

  return handleRequest(request)
}

function noicapRequestApi(appUrl: string) {
  const url = `${appUrl}/getInmemList/NOI_CAP`
  const request = fetch(url)

  return handleRequest(request)
}

function indSumRequestApi() {
  const url = `${priceUrl}/getSummary`
  const request = fetch(url)

  return handleRequest(request)
}

function snapshotRequestApi(data: any) {
  const url = `${priceUrl}/getliststockdata/${data.toUpperCase()}`
  const request = fetch(url)

  return handleRequest(request)
}

function loleSnapshotRequestApi(data: any) {
  const url = `${priceUrl}/getliststockdataoddlot/${data.toUpperCase()}`
  const request = fetch(url)

  return handleRequest(request)
}

function loleHSXSnapshotRequestApi() {
  const url = `${priceUrl}/getOddLotData/HSX`
  const request = fetch(url)

  return handleRequest(request)
}

function psSnapshotRequestApi(data: any) {
  const url = `${priceUrl}/getpsalldatalsnapshot/${data.toUpperCase()}`
  const request = fetch(url)

  return handleRequest(request)
}

function stockByIdRequestApi(data: any) {
  const url = `${priceUrl}/getliststockById/${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function indexRequestApi(data: any) {
  const url = `${priceUrl}/getlistindexdetail/${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function ndataRequestApi(data: any) {
  const url = `https://bgapidatafeed.vps.com.vn/getps10pricesnapshot/${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function tradeHisPsRequestApi(data: any) {
  const url = `https://bddatafeed.vps.com.vn/getpschartintraday/${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function dmNganhRequestApi() {
  const url = `http://banggia.tvs.vn:8083/webservices.vnd?msgType=loadCategories`
  const request = fetch(url)

  return handleRequest(request)
}

function topInterestRequestApi() {
  const url = `${infoUrl}/topStockInterested?count=10`
  const request = fetch(url)

  return handleRequest(request)
}

function advStockListDataRequestApi(data: string) {
  const url = `${priceUrl}/adv_getliststockdata/${data}`
  const request = fetch(url)

  return handleRequest(request)
}

function advSecInfoRequestApi(algoUrl: string) {
  const url = `${algoUrl}/pbapi/api/secInfoPriceboard`
  const request = fetch(url)

  return handleRequest(request)
}

async function vcbsFilteristDataRequestApi(data: any, algoUrl: string) {
  const url = `${algoUrl}/pbapi/api/getSystemFilters`
  try {
    const response = axios.post(url, JSON.stringify(data))
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

async function vcbsFilterListSnapshotRequestApi(data: any, algoUrl: string) {
  const url = `${algoUrl}/pbapi/api/filter?userid=000052`

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-token': '56FC17B760F8B5EBDEB3FB0FC306103D',
      },
    })
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

async function allOrderRequestApi(data: any, appUrl: string) {
  const url = `${appUrl}/TraditionalService`
  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

function* allStockRequestFlow(): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const dataList: any = yield call(allStockRequestApi, config.AuthUrl)

    yield put(allStockRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(allStockRequestError(error))
  }
}

function* marginListRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(marginListRequestApi)

    yield put(marginListRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(marginListRequestError(error))
  }
}

function* snapshotRequestFlow(action: actions.SnapshotRequestAction): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    // log(config)
    const { data } = action
    const dataList: any = yield call(snapshotRequestApi, data)
    let csData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item)
      csData.push(symbol)
    })

    yield put(snapshotRequestSuccess(csData))
  } catch (error) {
    yield put(snapshotRequestError(error))
  }
}

function* snapshotUnderlyingRequestFlow(
  action: actions.SnapshotUnderlyingCWRequestAction
): any {
  try {
    const { data } = action
    const dataList: any = yield call(snapshotRequestApi, data)
    let csData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item)
      csData.push(symbol)
    })

    yield put(snapshotUnderlyingCWSuccess(csData))
  } catch (error) {
    yield put(snapshotUnderlyingCWError(error))
  }
}

function* psSnapshotRequestFlow(action: actions.PsSnapshotRequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(psSnapshotRequestApi, data)
    let psData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataPS(item)
      psData.push(symbol)
    })

    yield put(psSnapshotRequestSuccess(psData))
  } catch (error) {
    yield put(psSnapshotRequestError(error))
  }
}

function* psIndexRequestFlow(action: actions.PsIndexRequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(psSnapshotRequestApi, data)
    let psData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataPS(item)
      psData.push(symbol)
    })

    yield put(psIndexRequestSuccess(psData))
  } catch (error) {
    yield put(psIndexRequestError(error))
  }
}

function* snapshotPartRequestFlow(
  action: actions.SnapshotPartRequestAction
): any {
  try {
    const { data } = action
    // log(data)
    const dataList: any = yield call(snapshotRequestApi, data)
    let csData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item)
      csData.push(symbol)
    })

    yield put(snapshotPartRequestSuccess(csData))
  } catch (error) {
    yield put(snapshotPartRequestError(error))
  }
}

function* loleSnapshotRequestFlow(
  action: actions.LoleSnapshotRequestAction
): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    // log(data)
    const { data } = action
    const dataList: any = yield call(loleSnapshotRequestApi, data)

    yield put(loleSnapshotRequestSuccess(dataList))
  } catch (error) {
    yield put(loleSnapshotRequestError(error))
  }
}

function* loleHSXSnapshotRequestFlow(): any {
  try {
    const dataList: any = yield call(loleHSXSnapshotRequestApi)

    let arrData: any = []
    dataList.forEach((element: any) => {
      arrData.push(element.data)
    })

    yield put(loleHSXSnapshotRequestSuccess(sortBy(arrData, 'sym')))
  } catch (error) {
    yield put(loleHSXSnapshotRequestError(error))
  }
}

function* stockInfoRequestFlow(action: actions.StockInfoRequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(snapshotRequestApi, data)

    let csData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item)
      csData.push(symbol)
    })

    yield put(stockInfoRequestSuccess(csData))
  } catch (error) {
    yield put(stockInfoRequestError(error))
  }
}

function* allOrderRequestFlow(action: actions.AllOrdRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const resData: any = yield call(allOrderRequestApi, data, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        })
      }
      throw Error(resData.rs)
    }

    yield put(allOrdRequestSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(allOrdRequestError(error))
  }
}

function* allOrdCondRequestFlow(action: actions.AllOrdCondRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const resData: any = yield call(allOrderRequestApi, data, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        })
      }
      throw Error(resData.rs)
    }

    yield put(allOrdCondSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(allOrdCondError(error))
  }
}

function* ptListRequestFlow(): any {
  try {
    const dataList: any = yield call(ptListRequestApi)

    yield put(ptListRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(ptListRequestError(error))
  }
}

function* psListRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(psListRequestApi)

    yield put(psListRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(psListRequestError(error))
  }
}

function* cwListRequestFlow(): any {
  try {
    const dataList: any = yield call(cwListRequestApi)

    yield put(cwListRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(cwListRequestError(error))
  }
}

function* list30RequestFlow(action: actions.List30RequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(list30RequestApi, data)
    // console.log(data, dataList)
    localStorage.setItem(data, JSON.stringify({"list": dataList}))
    yield put(list30RequestSuccess({"list": dataList}))
  } catch (error) {
    // log(error)
    yield put(list30RequestError(error))
  }
}

function* listNganhRequestFlow(action: actions.ListNganhRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const dataList: any = yield call(listNganhRequestApi, data, config.SmartAPIUrl)
    // log(data, dataList)
    const _list = map(dataList.data, 'securityCode')
    yield put(listNganhRequestSuccess(_list.join(',')))
  } catch (error) {
    // log(error)
    yield put(listNganhRequestError(error))
  }
}

function* listEtfRequestFlow(action: actions.ListEtfRequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(listEtfRequestApi, data)

    yield put(listEtfRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(listEtfRequestError(error))
  }
}

function* listMessageRequestFlow(): any {
  try {
    const dataList: any = yield call(listMessageRequestApi)

    yield put(listMessageRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(listMessageRequestError(error))
  }
}

function* branchCodeRequestFlow(): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const dataList: any = yield call(branchCodeRequestApi, config.AuthUrl)

    yield put(branchCodeRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(branchCodeRequestError(error))
  }
}

function* noicapRequestFlow(): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const dataList: any = yield call(noicapRequestApi, config.AuthUrl)

    yield put(noicapRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(noicapRequestError(error))
  }
}

function* indSumRequestFlow(): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const dataList: any = yield call(indSumRequestApi)

    yield put(indSumRequestSuccess(dataList))
  } catch (error) {
    // log(error)
    yield put(indSumRequestError(error))
  }
}

function* stockByIdRequestFlow(action: actions.GetStockByIdRequestAction): any {
  try {
    const { data } = action
    const dataList: any = yield call(stockByIdRequestApi, data)

    let csData: any = []
    dataList.forEach((item: any) => {
      let symbol = processMapData._processMapDataCS(item)
      csData.push(symbol)
    })

    yield put(getStockByIdRequestSuccess(csData))
  } catch (error) {
    yield put(getStockByIdRequestError(error))
  }
}

function* indexRequestFlow(action: actions.IndexRequestAction): any {
  try {
    // const config = yield select(getConfig)
    // if(!priceUrl){
    //   priceUrl = config.config.bs
    // }
    const { data } = action
    const indexs: any = yield call(indexRequestApi, data)
    let indexList: any = []
    indexs &&
      !!indexs.length &&
      indexs.forEach((element: any) => {
        indexList.push(processMapData._processMapDataIndex(element))
      })
    yield put(indexRequestSuccess(indexList))
  } catch (error) {
    yield put(indexRequestError(error))
  }
}

function* ndataSnapshotRequestFlow(
  action: actions.NdataSnapshotRequestAction
): any {
  try {
    // grab the data from our action
    const { data } = action

    const ndata: any = yield call(ndataRequestApi, data)
    yield put(ndataSnapshotRequestSuccess(ndata))
  } catch (error) {
    yield put(ndataSnapshotRequestError(error))
  }
}

function* tradeHisPsRequestFlow(action: actions.TradeHisPsRequestAction): any {
  try {
    // grab the data from our action
    const { data } = action

    const ndata: any = yield call(tradeHisPsRequestApi, data)
    yield put(tradeHisPsRequestSuccess(ndata))
  } catch (error) {
    yield put(tradeHisPsRequestError(error))
  }
}

function* dmNganhRequestFlow(): any {
  try {
    const dataList: any = yield call(dmNganhRequestApi)
    yield put(dmNganhRequestSuccess(dataList))
  } catch (error) {
    yield put(dmNganhRequestError(error))
  }
}

function* topInterestRequestFlow(): any {
  try {
    const dataList: any = yield call(topInterestRequestApi)
    // log(dataList);
    yield put(topInterestRequestSuccess(dataList.data))
  } catch (error) {
    yield put(topInterestRequestError(error))
  }
}

function* advStockSingleFlow(action: actions.AdvStockSingleRequestAction): any {
  try {
    const { data } = action
    const res: any = yield call(advStockListDataRequestApi, data)
    yield put(advStockSingleSuccess(res))
  } catch (error) {
    yield put(advStockSingleError(error))
  }
}

function* advStockListDataFlow(
  action: actions.AdvStockListDataRequestAction
): any {
  try {
    const { data } = action
    const dataList: any = yield call(advStockListDataRequestApi, data)
    // log(dataList);
    yield put(advStockListDataSuccess(dataList))
  } catch (error) {
    yield put(advStockListDataError(error))
  }
}
function* secInfoAdvFlow(): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const res: any = yield call(advSecInfoRequestApi, config.SmartAPIUrl)
    yield put(secInfoAdvSuccess(res.data))
  } catch (error) {
    yield put(secInfoAdvError(error))
  }
}

function* vcbsFilterListDataFlow(): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const res: any = yield call(vcbsFilteristDataRequestApi, { lang: 'vi' }, config.SmartAPIUrl)
    console.log(res.data)
    yield put(vcbsFilterListSuccess(res.data))
  } catch (error) {
    yield put(vcbsFilterListError(error))
  }
}

function* vcbsFilterSnapshotDataFlow(
  action: actions.VcbsFilterSnapshotRequestAction
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const res: any = yield call(vcbsFilterListSnapshotRequestApi, action.data,config.SmartAPIUrl)
    console.log(res.data?.items)
    if (!res.data?.items || !res.data?.items?.length) {
      yield put(vcbsFilterSnapshotSuccess(''))
    } else
      yield put(
        vcbsFilterSnapshotSuccess(
          map(res.data.items, 'SECURITY_CODE').sort().join(',')
        )
      )
  } catch (error) {
    yield put(vcbsFilterSnapshotError(error))
  }
}

function* priceBoardWatcher() {
  yield all([
    takeLatest(actions.ALL_STOCK_REQUESTING, allStockRequestFlow),
    takeLatest(actions.SNAPSHOT_REQUESTING, snapshotRequestFlow),
    takeLatest(
      actions.SNAPSHOT_UNDERLYING_REQUESTING,
      snapshotUnderlyingRequestFlow
    ),
    takeEvery(actions.SNAPSHOT_PART_REQUESTING, snapshotPartRequestFlow),
    takeLatest(actions.STOCK_INFO_REQUESTING, stockInfoRequestFlow),
    takeLatest(actions.ALL_ORD_REQUESTING, allOrderRequestFlow),
    takeLatest(actions.ALL_ORD_COND_REQUESTING, allOrdCondRequestFlow),
    takeLatest(actions.PT_LIST_REQUESTING, ptListRequestFlow),
    takeLatest(actions.PS_LIST_REQUESTING, psListRequestFlow),
    takeLatest(actions.PS_SNAPSHOT_REQUESTING, psSnapshotRequestFlow),
    takeLatest(actions.PS_INDEX_REQUESTING, psIndexRequestFlow),
    takeLatest(actions.CW_LIST_REQUESTING, cwListRequestFlow),
    takeLatest(actions.LIST_30_REQUESTING, list30RequestFlow),
    takeLatest(actions.LIST_NGANH_REQUESTING, listNganhRequestFlow),
    takeLatest(actions.LIST_ETF_REQUESTING, listEtfRequestFlow),
    takeLatest(actions.LIST_MESSAGE_REQUESTING, listMessageRequestFlow),
    takeLatest(actions.BRANCH_CODE_REQUESTING, branchCodeRequestFlow),
    takeLatest(actions.NOI_CAP_REQUESTING, noicapRequestFlow),
    takeLatest(actions.SUMMARY_REQUESTING, indSumRequestFlow),
    takeLatest(actions.GET_STOCK_BY_ID_REQUESTING, stockByIdRequestFlow),
    takeLatest(actions.INDEX_REQUESTING, indexRequestFlow),
    takeEvery(actions.NDATA_SNAPSHOT_REQUESTING, ndataSnapshotRequestFlow),
    takeLatest(actions.TRADE_HIS_PS_REQUESTING, tradeHisPsRequestFlow),
    takeLatest(actions.DM_NGANH_REQUESTING, dmNganhRequestFlow),
    takeLatest(actions.LOLE_SNAPSHOT_REQUESTING, loleSnapshotRequestFlow),
    takeLatest(
      actions.LOLE_HSX_SNAPSHOT_REQUESTING,
      loleHSXSnapshotRequestFlow
    ),
    takeLatest(actions.MARGIN_LIST_REQUESTING, marginListRequestFlow),
    takeLatest(actions.TOP_INTEREST_REQUESTING, topInterestRequestFlow),
    takeLatest(actions.ADV_STOCK_LIST_DATA_REQUESTING, advStockListDataFlow),
    takeLatest(actions.ADV_STOCK_SINGLE_REQUESTING, advStockSingleFlow),
    takeLatest(actions.ADV_SEC_INFO_REQUESTING, secInfoAdvFlow),
    takeLatest(actions.VCBS_FILTER_LIST_REQUESTING, vcbsFilterListDataFlow),
    takeLatest(
      actions.VCBS_FILTER_SNAPSHOT_REQUESTING,
      vcbsFilterSnapshotDataFlow
    ),
  ])
}

export default priceBoardWatcher
