import styled from 'styled-components'

export const Breadcrumb = styled.div`
  display: flex;
  background: #f5f5f5;
  width: 100%;
  border 1px solid #f0eeea;
  border-width: 0 0 1px 0;
  justify-content: space-between;
  padding: 5px 10px;

  div.bread-crumb {
    height: 100%;
    padding-left: 0;
    display: flex;

    a {
      border-left: none;
      display: flex;
      align-items: center;
      border-radius: 8px;
      font-size: 13px;
      color: #6f6f6f;

      &.active {
        color: #007bff;
      }
    }
  }

  div.bread-crumb-report{
    // display: flex;
    font-size: 13px;

    .flex-item{
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        border-style: dashed;
        border-color: rgb(232, 234, 235);
        border-image: initial;
        border-width: 0px 1px 0px 0px;

        img{
            padding-right: 5px;
        }

        &:hover{
            color: #000;
        }
    }
  }
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #ffffff;
  width: 100%;

  div.cash-info {
    background: rgb(247, 247, 247);
    border-radius: 12px;
    box-shadow: none;

    .cash-info-left {
      .cash-item:not(:last-of-type) {
        border-style: dashed;
        border-color: rgb(232, 234, 235);
        border-image: initial;
        border-width: 0px 1px 0px 0px;
      }
    }
    .cash-info-right {
      .cash-item:not(:last-of-type) {
        border-style: dashed;
        border-color: rgb(232, 234, 235);
        border-image: initial;
        border-width: 0px 1px 0px 0px;
      }
    }

    .cash-item {
      text-align: left;
      padding: 0 15px 5px 15px;
      color: #6f6f6f;

      span:last-of-type {
        font-weight: 600;
        font-size: 16px;
        color: rgb(26, 32, 44);
      }

      svg {
        margin-left: 5px;
        font-size: 14px;
        color: #747474;
      }
    }
  }

  div.bg-border {
    width: 100%;
    height: 1px;
    margin: 5px 0 0 0;
    background: #f0eeea;
  }

  .header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: rgb(26, 32, 44);
    align-items: center;
    // padding-top: 5px;

    button {
      height: 25px;
    }
  }
`

//#region button
const ButtonDef = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  text-transform: uppercase;

  &:active,
  :focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: var(--color-button-accent-hover);
    border-color: var(--color-button-accent-hover);
  }
`

export const BtnClose = styled(ButtonDef)`
  background: #ffffff;
  border: 1px solid var(--color-vendor-active);
  min-width: 75px;
  color: var(--color-vendor-active);

  &.active,
  &:hover {
    background: var(--color-button-accent);
    color: #fff;
  }
`

export const BtnPlus = styled(ButtonDef)`
  background: #fffbeb;
  border: 1px solid var(--color-button-accent);
  height: auto;

  &.active {
    background: var(--color-button-accent);
    color: #fff;
  }
`

export const BtnSubmit = styled(ButtonDef)`
  background: linear-gradient(
    180deg,
    var(--color-vendor-from) -10%,
    var(--color-vendor-to) 105%
  );
  border: 1px solid var(--color-vendor-active);
  min-width: 100px;
  color: var(--color-text-white);

  &.danger {
    background: #ec1818;
    border-color: #ec1818;
  }
  &.success {
    background: var(--color-button-success);
    border-color: var(--color-button-success);
  }
  &.warning {
    background: rgb(245 158 11);
    border-color: rgb(245 158 11);
  }
  &.primary {
    background: #ec5e0f;
    border-color: #ec5e0f;
  }
  &.info {
    background: #243c5f;
    border-color: #243c5f;
  }

  &.danger:disabled {
    background: #ffa2ab;
  }
  &.success:disabled {
    background: #7ad28e;
  }
  &.warning:disabled {
    background: rgb(253 230 138);
  }

  &.disabled {
    background: var(--color-text-muted) !important;
    border-color: var(--color-text-muted) !important;
  }
  &:disabled {
    background: var(--color-text-muted) !important;
    border-color: var(--color-text-muted) !important;
  }
`

export const BtnFileSample = styled.a`
  background: #28a745;
  border-color: #28a745;
  color: #fff;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;

  &:active,
  :focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #00293c;
  }
`
//#endregion

export const CreateInfo = styled.div`
  background: #f5f5f5;

  border-top: 1px solid #f9f9f9;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #3790fb;
`

export const TableContent = styled.div`s
  overflow: hidden;
`

export const DirectoryList = styled.ul`
  margin-top: 6px;

  ul {
    margin-left: 10px;
    padding-left: 20px;
    border-left: 1px dashed #ddd;
  }

  li {
    list-style: none;
    font-size: 16px;
    font-weight: normal;
    padding: 5px 0;

    &.hasFile {
      border-bottom: 1px dotted #dee2e6;
    }

    .open {
      display: block;
    }

    .close {
      display: none;
    }

    &.show .open {
      display: none;
    }

    &.show .close {
      display: block;
    }
  }

  .folder {
    color: #212529;
    font-weight: bold;

    .d-flex {
      position: relative;
    }

    .hasFolder svg {
      fill: #ffffff;

      path {
        stroke: #4f4e4e;
      }
    }

    ul {
      display: none;
    }

    &.show ul {
      display: block;
      margin-top: 5px;

      .folder ul {
        display: none;
      }

      .folder.show ul {
        display: block;
        margin-top: 5px;
      }
    }
  }

  .folder li {
    padding: 5px 0 5px 5px;

    &.hasFile {
      font-size: 13px;

      .txt-left svg {
        height: 20px;
        fill: #ffffff;

        path {
          stroke: #4f4e4e;
        }
      }
    }
  }
`

export const HasBottmHr = styled.div`
  height: 0px;
  border-bottom: 1px dotted #dee2e6;
  width: calc(100% - 25px);
  position: absolute;
  bottom: -5px;
  right: 0;
`

export const BtnGroupStyle = styled.div`
  display: flex;
  height: 25px;

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1px 5px;
    background: var(--color-natural-3);
    border: 1px solid var(--color-border-2);

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    &.active {
      background-color: var(--color-vendor-active);
      border-color: var(--color-vendor-active);
      color: var(--color-nav);
    }
  }
`

export const TabsType1Style = styled.div`
  display: flex;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1px 0px;
    margin: 0 10px 0 0;
    color: var(--color-text-2);
    font-weight: 500;
    font-size: 13px;

    &.active {
      color: var(--color-text-1);
      border-color: var(--color-vendor-active);
    }
  }
`

export const TableStyle = styled.table`
  width: 100%;
  font-size: 13px;
  line-height: 18px;

  td {
    padding: 8px;
  }
  tr.header td {
    background: var(--color-natural-3);
    font-weight: 600;
  }
  tr.has-bottom:not(.header) td {
    border-bottom: 1px solid var(--color-border-2);
  }
`
