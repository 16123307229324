/** chi tiết khách hàng */
export const ACC_INFO_REQUESTING = 'acc/INFO_REQUESTING';
export interface AccInfoRequestingAction {
  type: typeof ACC_INFO_REQUESTING;
  data: any;
}

export const ACC_INFO_SUCCESS = 'acc/INFO_SUCCESS';
export interface AccInfoSuccessAction {
  type: typeof ACC_INFO_SUCCESS;
  resData: any;
}

export const ACC_INFO_ERROR = 'acc/INFO_ERROR';
export interface AccInfoErrorAction {
  type: typeof ACC_INFO_ERROR;
  error: any;
}

/** duyệt nghiệp vụ */
export const ACC_CASH_APPR_REQUESTING = 'acc/CASH_APPR_REQUESTING';
export interface AccCashApprRequestingAction {
  type: typeof ACC_CASH_APPR_REQUESTING;
  data: any;
}

export const ACC_CASH_APPR_SUCCESS = 'acc/CASH_APPR_SUCCESS';
export interface AccCashApprSuccessAction {
  type: typeof ACC_CASH_APPR_SUCCESS;
  resData: any;
}

export const ACC_CASH_APPR_ERROR = 'acc/CASH_APPR_ERROR';
export interface AccCashApprErrorAction {
  type: typeof ACC_CASH_APPR_ERROR;
  error: any;
}

/** tài khoản thụ hưởng */
export const ACC_BEN_UPD_REQUESTING = 'acc/BEN_UPD_REQUESTING';
export interface AccBenUpdRequestingAction {
  type: typeof ACC_BEN_UPD_REQUESTING;
  data: any;
}

export const ACC_BEN_UPD_SUCCESS = 'acc/BEN_UPD_SUCCESS';
export interface AccBenUpdSuccessAction {
  type: typeof ACC_BEN_UPD_SUCCESS;
  resData: any;
}

export const ACC_BEN_UPD_ERROR = 'acc/BEN_UPD_ERROR';
export interface AccBenUpdErrorAction {
  type: typeof ACC_BEN_UPD_ERROR;
  error: any;
}

/** danh sách tài khoản thụ hưởng */
export const ACC_BEN_LIST = 'acc/BEN_LIST';
export interface AccBenListAction {
  type: typeof ACC_BEN_LIST;
  data: any;
}
export const ACC_BEN_LIST_REQUESTING = 'acc/BEN_LIST_REQUESTING';
export interface AccBenListRequestingAction {
  type: typeof ACC_BEN_LIST_REQUESTING;
  data: any;
}

export const ACC_BEN_LIST_SUCCESS = 'acc/BEN_LIST_SUCCESS';
export interface AccBenListSuccessAction {
  type: typeof ACC_BEN_LIST_SUCCESS;
  resData: any;
}

export const ACC_BEN_LIST_ERROR = 'acc/BEN_LIST_ERROR';
export interface AccBenListErrorAction {
  type: typeof ACC_BEN_LIST_ERROR;
  error: any;
}

/** branch info */
export const BRANCH_INFO_REQUESTING = 'branch/INFO_REQUESTING';
export interface BranchInfoRequestingAction {
  type: typeof BRANCH_INFO_REQUESTING;
  data: any;
}

export const BRANCH_INFO_SUCCESS = 'branch/INFO_SUCCESS';
export interface BranchInfoSuccessAction {
  type: typeof BRANCH_INFO_SUCCESS;
  resData: any;
}

export const BRANCH_INFO_ERROR = 'branch/INFO_ERROR';
export interface BranchInfoErrorAction {
  type: typeof BRANCH_INFO_ERROR;
  error: any;
}

export type AccountInfoAction =
  | AccInfoRequestingAction
  | AccInfoSuccessAction
  | AccInfoErrorAction
  | AccCashApprRequestingAction
  | AccCashApprSuccessAction
  | AccCashApprErrorAction
  | AccBenUpdRequestingAction
  | AccBenUpdSuccessAction
  | AccBenUpdErrorAction
  | AccBenListAction
  | AccBenListRequestingAction
  | AccBenListSuccessAction
  | AccBenListErrorAction
  | BranchInfoRequestingAction
  | BranchInfoSuccessAction
  | BranchInfoErrorAction;
