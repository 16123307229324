import { INormalSelect } from 'interface';
import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getSettingParams = (state: AppState) => state.setting.setParams;
const getSettingParamDetail = (state: AppState) => state.setting.setParamDetail;
const getSettingParamUpd = (state: AppState) => state.setting.setParamUpd;
const getSettingParamDel = (state: AppState) => state.setting.setParamDel;

const getSettingFuncs = (state: AppState) => state.setting.setFuncs;
const getSettingFuncDetail = (state: AppState) => state.setting.setFuncDetail;
const getSettingFuncUpd = (state: AppState) => state.setting.setFuncUpd;
const getSettingFuncDel = (state: AppState) => state.setting.setFuncDel;
const getListPerSelect = (state: AppState) => {
  const { setFuncs } = state.setting;

  if (!setFuncs || !setFuncs.length) return [];

  let _arr: INormalSelect[] = [];

  setFuncs.forEach((element: any) => {
    _arr.push({
      value: element.C_CODE,
      label: element.C_MENU_NAME,
    });
  });

  return _arr;
};

const getSettingUserGrs = (state: AppState) => state.setting.setUserGrs;
const getSettingUserGrDetail = (state: AppState) =>
  state.setting.setUserGrDetail;
const getSettingUserGrUpd = (state: AppState) => state.setting.setUserGrUpd;
const getSettingUserGrDel = (state: AppState) => state.setting.setUserGrDel;
const getSettingUserGrFunc = (state: AppState) => state.setting.setUserGrFunc;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetSettingParams = () =>
  createSelector(getSettingParams, (setParams) => setParams);
export const makeGetSettingParamDetail = () =>
  createSelector(getSettingParamDetail, (setParamDetail) => setParamDetail);
export const makeGetSettingParamUpd = () =>
  createSelector(getSettingParamUpd, (setParamUpd) => setParamUpd);
export const makeGetSettingParamDel = () =>
  createSelector(getSettingParamDel, (setParamDel) => setParamDel);

export const makeGetSettingFuncs = () =>
  createSelector(getSettingFuncs, (setFuncs) => setFuncs);
export const makeGetSettingFuncDetail = () =>
  createSelector(getSettingFuncDetail, (setFuncDetail) => setFuncDetail);
export const makeGetSettingFuncUpd = () =>
  createSelector(getSettingFuncUpd, (setFuncUpd) => setFuncUpd);
export const makeGetSettingFuncDel = () =>
  createSelector(getSettingFuncDel, (setFuncDel) => setFuncDel);
export const makeGetListPerSelect = () =>
  createSelector(getListPerSelect, (listPerSelect) => listPerSelect);

export const makeGetSettingUserGrs = () =>
  createSelector(getSettingUserGrs, (setUserGrs) => setUserGrs);
export const makeGetSettingUserGrDetail = () =>
  createSelector(getSettingUserGrDetail, (setUserGrDetail) => setUserGrDetail);
export const makeGetSettingUserGrUpd = () =>
  createSelector(getSettingUserGrUpd, (setUserGrUpd) => setUserGrUpd);
export const makeGetSettingUserGrDel = () =>
  createSelector(getSettingUserGrDel, (setUserGrDel) => setUserGrDel);
export const makeGetSettingUserGrFunc = () =>
  createSelector(getSettingUserGrFunc, (setUserGrFunc) => setUserGrFunc);
