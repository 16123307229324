import _ from 'lodash';
import React from 'react';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  reset,
} from 'redux-form';
import { AppState } from 'reducers';
import { connect } from 'react-redux';
import { BtnSubmit } from 'utils/styledUtils';
import { useDispatch } from 'react-redux';
import { IUser } from 'interface';
import { loginSuccess } from 'containers/login/actions';
import { maxLength, minLength, required, validateEmail, validatePhone } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import RenderLoginInput from 'helper/input/renderLoginInput';
import * as actionTypes from "containers/register/actionType";

import RenderOtp from 'helper/input/renderOTP';
import BgLogin from 'assets/img/bg/bg_register_small.png';
import { resChangePassRequest, resChangePassSuccess, resCheckCustRequest, resCheckCustSuccess } from 'containers/register/actions';
import { makeGetResChangePass, makeGetResCheckCust } from 'lib/selector';
import { clientToastSet } from 'containers/client/actions';
import ResetPassSuccessModal from 'components/modal/resetPassSuccess';

interface Props {
  email?: string;
  clearChangePass: Function;
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  errorStep3?: Error | string | null;
  resCheckCust?: Number | null;
  resChangePass?: Number | null;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const validate = (values: any) => {
  const errors: any = {};
  const { password, rePassword } = values;
  if (rePassword !== password) {
    errors.rePassword = "Nhập lại mật khẩu không trùng mật khẩu mới";
  }

  return errors;
};

const maxLength32 = maxLength(32)
const minLength6 = minLength(6)

const FormForgotPass: React.FunctionComponent<
  InjectedFormProps<IUser> & Props
> = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, isLoading, errorMsg, clearChangePass, email, resCheckCust,
    resChangePass, errorStep3 } = props;
  const { t } = useTranslation();
  const [step, setStep] = React.useState<number>(1);
  const [isShowSuccess, setIsShowSuccess] = React.useState<boolean>(false);

  const preResCheckCust = usePrevious(resCheckCust);
  const preResChangePass = usePrevious(resChangePass);
  const preErrStep3 = usePrevious(errorStep3);

  React.useEffect(() => {
    return () => {
      dispatch(resCheckCustSuccess(null));
      dispatch(resChangePassSuccess(null));
      dispatch({ type: "res/CHANGE_PASS_CLEAR" });
      dispatch({ type: "res/CHECK_CUST_CLEAR" });
      dispatch({ type: "CLEAR_TOAST" });
    };
  }, []);

  React.useEffect(() => {
    if (errorStep3 && !_.isEqual(errorStep3, preErrStep3)) {
      // check otp thành công => gửi mỏ tài khoản
      _handleToast(errorStep3.toString(), "error");
      setStep(1);
      dispatch(reset("formForgotPass"));
      dispatch(resChangePassSuccess(null));
    }
  }, [errorStep3]);

  React.useEffect(() => {
    if (resCheckCust && !_.isEqual(resCheckCust, preResCheckCust)) {
      dispatch({ type: "res/CHANGE_PASS_CLEAR" });
      dispatch({ type: "res/CHECK_CUST_CLEAR" });
      // check otp thành công => gửi mỏ tài khoản
      setStep(2);
      dispatch(resCheckCustSuccess(null));
    }
  }, [resCheckCust]);

  React.useEffect(() => {
    if (resChangePass && !_.isEqual(resChangePass, preResChangePass)) {
      // check otp thành công => gửi mỏ tài khoản
      setIsShowSuccess(true);
      dispatch(resChangePassSuccess(null));
    }
  }, [resChangePass]);

  function submit(user: IUser) {
    if (step === 1) {
      const param = {
        user: "back",
        group: "LIST",
        cmd: "CHECK_CUST_EMAIL",
        param: { MOBILE: user.username || "", EMAIL: user.email?.toLowerCase() },
      };

      dispatch(resCheckCustRequest(param));
    };
    if (step === 2) {
      const paramChange = {
        user: "back",
        group: "LIST",
        cmd: "RESET_PASS",
        param: { otp: user.otp, email: user.email?.toLowerCase(), new_pass: user.password },
      };

      dispatch(resChangePassRequest(paramChange));
    }
    // dispatch(clientTokenUnset());
    // dispatch(loginRequest(user.username, user.password));
  }

  function handleClose() {
    setIsShowSuccess(false);
    clearChangePass();
  }

  const _handleToast = (msg: string, type = "info") => {
    // console.log(msg)
    const toastMsg = {
      id: Math.random(),
      msg,
      type,
      title: t("notice.thong-bao"),
    };
    dispatch(clientToastSet(toastMsg));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className="px-10 py-7 rounded-[10px] m-auto w-full md:w-[480px] flex flex-col justify-center"
        style={{
          border: 'none',
          background: 'transparent',
          backgroundImage: `url(${BgLogin})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <label className="text-2xl text-white uppercase font-semibold">
          {step === 1 && 'Quên mật khẩu?'}
          {step === 2 && 'Xác nhận mã OTP'}
          {step === 3 && 'Tạo mật khẩu mới'}
        </label>
        <label className="text-base text-skin-vendor self-start py-2">
          {step === 1 &&
            'Vui lòng nhập thông tin đã đăng ký để lấy lại mật khẩu.'}
          {step === 2 &&
            `Vui lòng nhập mã OTP được gửi đến địa chỉ email: ${email}`}
        </label>

        <div className="auth-messages">
          {!isLoading && errorMsg ? (
            <div className="fz-13">{errorMsg.toString()}</div>
          ) : null}
          {isLoading ? <div className=" fz-13">Logging in...</div> : null}
        </div>

        {step === 1 && (
          <>
            <label className="mt-2 w-full">
              <span className="text-base text-skin-vendor pb-2">{'Email'}<small className="text-red-600 font-normal">(*)</small></span>
              <Field
                name="email"
                type="email"
                placeholder={'Email'}
                autoComplete="current-password"
                component={RenderLoginInput}
                validate={[required, validateEmail]}
              />
            </label>
            <label className="w-full mt-3">
              <span className="text-base text-skin-vendor pb-2">
                {"Số điện thoại"}
              </span>
              <Field
                name="username"
                type="text"
                autoComplete="off"
                placeholder='Số điện thoại'
                component={RenderLoginInput}
                validate={[validatePhone, maxLength32]}
              />
            </label>
          </>
        )}
        {step === 2 && (
          <>
            <div className="w-full mt-3">
              <Field name="otp" component={RenderOtp} validate={required} />
            </div>

            {/* <div className="w-full flex items-center justify-start mt-6 text-base">
              <label className="text-skin-base mb-0">
                Bạn không nhận được mã OTP.
              </label>
              <a
                onClick={() => clearChangePass()}
                className="cursor-pointer text-skin-active pl-2"
              >
                Gửi lại mã
              </a>
            </div> */}
            <label className="text-2xl md:text-[28px] text-skin-vendor self-start text-family-semibold mt-5">
              {/* {step === 1 && "Quên mật khẩu?"} */}
              {/* {step === 2 && "Xác nhận mã OTP"} */}
              Tạo mật khẩu mới
            </label>
            <label className="w-full mt-4">
              <span className="text-base text-skin-vendor pb-2">
                Mật khẩu mới
              </span>
              <Field
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Nhập mật khẩu"
                component={RenderLoginInput}
                validate={[required, minLength6, maxLength32]}
              />
            </label>

            <label className="mt-4 w-full">
              <span className="text-base text-skin-vendor pb-2">
                {"Xác nhận mật khẩu mới"}
              </span>
              <Field
                name="rePassword"
                type="password"
                placeholder={"Nhập mật khẩu"}
                autoComplete="current-password"
                component={RenderLoginInput}
                validate={[required, minLength6, maxLength32]}
              />
            </label>
          </>
        )}

        {step === 3 && (
          <>
            <label className="w-full mt-3">
              <span className="text-base text-skin-vendor pb-2">
                Mật khẩu mới
              </span>
              <Field
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Nhập mật khẩu"
                component={RenderLoginInput}
                validate={required}
              />
            </label>

            <label className="mt-4 w-full">
              <span className="text-base text-skin-vendor pb-2">
                {'Xác nhận mật khẩu mới'}
              </span>
              <Field
                name="rePassword"
                type="password"
                placeholder={'Nhập mật khẩu'}
                autoComplete="current-password"
                component={RenderLoginInput}
                validate={required}
              />
            </label>
          </>
        )}
        <div className="flex justify-center mt-2 ">
          <BtnSubmit
            type="submit"
            className="mx-8 w-full mt-5 !text-white"
            disabled={isLoading}
          >
            {t('button.continue')}
          </BtnSubmit>
        </div>

        <div className="w-full flex items-center justify-center mt-6 text-base">
          <label className="text-skin-base mb-0">Bạn đã có tài khoản?</label>
          <a
            onClick={() => clearChangePass()}
            className="cursor-pointer text-skin-active pl-2"
          >
            Đăng nhập
          </a>
        </div>
      </form>
      {isShowSuccess && (
        <ResetPassSuccessModal
          onClose={() => handleClose()}
          data={email}
        />
      )}
    </>
  );
};

const _FormForgotPass = reduxForm<IUser, Props>({
  form: 'formForgotPass',
  validate,
})(FormForgotPass as any);

const selector = formValueSelector('formForgotPass');

const makeMapStateToProps = () => {
  const getResCheckCust = makeGetResCheckCust();
  const getResChangePass = makeGetResChangePass();

  const mapStateToProps = (state: AppState) => {
    const { username, email, otp } = selector(
      state,
      'username',
      'email',
      'otp',
    );

    return {
      resCheckCust: getResCheckCust(state),
      resChangePass: getResChangePass(state),

      isLoading:
        state.isLoading[actionTypes.RES_CHECK_CUST] ||
        state.isLoading[actionTypes.RES_CHANGE_PASS],
      errorMsg:
        state.error[actionTypes.RES_CHECK_CUST] ||
        state.error[actionTypes.RES_CHANGE_PASS],
      errorStep3: state.error[actionTypes.RES_CHANGE_PASS],

      username,
      email,
      otp,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormForgotPass);
