/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IUserRegister } from 'interface';
import {
  maxLength,
  minLength,
  required,
  validateEmail,
  validatePhone,
} from 'utils/validation';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import {
  regCheckCustRequest,
  regCheckCustSuccess,
  regCustTempSet,
} from 'containers/register/actions';
import RenderLoginInput from 'helper/input/renderLoginInput';
import RenderRegisCheckbox from 'helper/input/renderRegisCheckbox';
import BgLogin from 'assets/img/bg/bg_register_small.png';
import _ from 'lodash';
import { TFunction } from 'i18next';
import { BtnSubmit } from 'utils/styledUtils';
import { AppState } from 'reducers';
import * as actionTypes from 'containers/register/actionType';
import { makeGetRegCheckCust, makeGetRegCustTemp } from 'lib/selector';

interface Props {
  setStep: Function;
  returnLogin: Function;
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  checkCust?: number | null;
  t: TFunction;
}

const minLength6 = minLength(6);
const maxLength128 = maxLength(128);
const maxLength32 = maxLength(32);

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const FormRegisStep1: React.FunctionComponent<
  InjectedFormProps<IUserRegister> & Props
> = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    isLoading,
    errorMsg,
    setStep,
    checkCust,
    returnLogin,
    t,
  } = props;
  const [dataTemp, setDataTemp] = React.useState<any>(null);
  const preCheckCust = usePrevious(checkCust);

  React.useEffect(() => {
    return () => {
      dispatch(regCheckCustSuccess(null));
      dispatch({ type: 'res/CHECK_CUST_CLEAR' });
    };
  }, []);

  React.useEffect(() => {
    if (checkCust && !_.isEqual(checkCust, preCheckCust)) {
      dispatch(regCustTempSet(dataTemp));
      setStep(2);
    }
  }, [checkCust]);

  function submit(user: IUserRegister) {
    const _temp = {
      userName: user.formUserName,
      userEmail: user.formEmail?.toLowerCase(),
      userMobile: user.formMobile,
      userRefCode: user.formReferralCode,
      userPass: user.formPassword,
      mktId: user.formReferralCode,
    };
    setDataTemp(_temp);

    const _data = {
      user: 'back',
      group: 'LIST',
      cmd: 'CHECK_OPENACC',
      param: {
        C_MOBILE: user.formMobile,
        C_EMAIL: user.formEmail?.toLowerCase(),
        C_SALE_ID: user.formReferralCode || '',
      },
    };
    dispatch(regCheckCustRequest(_data));
    // setStep(2);
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="px-10 py-7 rounded-[10px] m-auto w-full md:w-[480px] flex flex-col justify-center"
      style={{
        border: 'none',
        background: 'transparent',
        backgroundImage: `url(${BgLogin})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <label className="text-2xl text-white uppercase font-semibold">
        Đăng ký
      </label>

      <div className="auth-messages text-xs">
        {!isLoading && errorMsg ? (
          <div className="fz-13">{errorMsg.toString()}</div>
        ) : null}
        {isLoading ? <div className=" fz-13">Logging in...</div> : null}
      </div>
      <label className="w-full">
        <div className="text-sm font-medium text-white pb-2 mt-4">
          Họ và tên
          <small className="text-red-600 font-normal">(*)</small>
        </div>
        <Field
          name="formUserName"
          type="text"
          autoComplete="off"
          placeholder="Nhập họ tên"
          component={RenderLoginInput}
          validate={[required, minLength6, maxLength128]}
        />
        <div className="text-sm font-medium text-white pb-2 mt-4">
          Email
          <small className="text-red-600 font-normal">(*)</small>
        </div>
        <Field
          name="formEmail"
          type="email"
          autoComplete="off"
          placeholder="Nhập email"
          component={RenderLoginInput}
          validate={[required, validateEmail]}
        />
        <div className="text-sm font-medium text-white pb-2 mt-4">
          Số điện thoại
        </div>
        <Field
          name="formMobile"
          type="email"
          autoComplete="off"
          placeholder="Nhập sđt"
          component={RenderLoginInput}
          validate={[validatePhone, maxLength32]}
        />
        <div className="text-sm font-medium text-white pb-2 mt-4">
          {t('login.txt-pass')}{' '}
          <small className="text-red-600 font-normal">(*)</small>
        </div>
        <Field
          name="formPassword"
          type="password"
          autoComplete="off"
          placeholder={t('login.txt-placeholder-pass')}
          component={RenderLoginInput}
          validate={[required, minLength6, maxLength32]}
        />
        <div className="text-sm font-medium text-white pb-2 mt-4">
          Mã giới thiệu
        </div>
        <Field
          name="formReferralCode"
          type="text"
          autoComplete="off"
          placeholder={`Nhập mã giới thiệu`}
          component={RenderLoginInput}
          validate={[maxLength32]}
        />
      </label>
      <Field
        name="formAgreeFlg"
        component={RenderRegisCheckbox}
        type="checkbox"
        validate={required}
      />
      <div className="flex justify-center mt-2 ">
        <BtnSubmit
          type="submit"
          disabled={isLoading}
          className="mx-8 w-full mt-5 !text-white"
        >
          Đăng ký
        </BtnSubmit>
      </div>
      <div className="flex justify-center mt-4 text-white text-sm font-normal leading-5 tracking-[0.01em]">
        <span>Đã có tài khoản?</span>
        <a
          className="ml-2 underline cursor-pointer"
          onClick={() => returnLogin()}
        >
          Đăng nhập
        </a>
      </div>
    </form>
  );
};

const _FormRegisStep1 = reduxForm<IUserRegister, Props>({
  form: 'formRegisStep1',
  enableReinitialize: true,
})(FormRegisStep1 as any);

const selector = formValueSelector('formRegisStep1');

const makeMapStateToProps = () => {
  const getRegCheckCust = makeGetRegCheckCust();
  const getRegCustTemp = makeGetRegCustTemp();

  const mapStateToProps = (state: AppState) => {
    const {
      formUserName,
      formMobile,
      formEmail,
      formCardId,
      formShareCode,
      formReferralCode,
      formAgreeFlg,
    } = selector(
      state,
      'formUserName',
      'formMobile',
      'formEmail',
      'formCardId',
      'formShareCode',
      'formReferralCode',
      'formAgreeFlg',
    );

    return {
      checkCust: getRegCheckCust(state),
      custTemp: getRegCustTemp(state),
      isLoading: state.isLoading[actionTypes.REG_CHECK_CUST],
      errorMsg: state.error[actionTypes.REG_CHECK_CUST],

      formUserName,
      formMobile,
      formEmail,
      formCardId,
      formShareCode,
      formReferralCode,
      formAgreeFlg,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormRegisStep1);
