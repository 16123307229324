import { values } from 'lodash';
import { INormalSelect } from '../interface';

export default class BaseConfig {
  private _baseUrl: string | undefined;
  private _socketUrl: string | undefined;

  public get baseUrl() {
    return this._baseUrl || '';
  }

  public set baseUrl(theUrl: string) {
    this._baseUrl = theUrl;
  }

  public get socketUrl() {
    return this._socketUrl || '';
  }

  public set socketUrl(theUrl: string) {
    this._socketUrl = theUrl;
  }

  public getFullConfig(): any {
    return {
      baseUrl: this._baseUrl,
      socketUrl: this._socketUrl,
    };
  }
}

// tháng
export const AllMonth: Array<INormalSelect> = [
  { label: 'Tháng 1', labelCode: 'txt-thang_1', value: '1' },
  { label: 'Tháng 2', labelCode: 'txt-thang_2', value: '2' },
  { label: 'Tháng 3', labelCode: 'txt-thang_3', value: '3' },
  { label: 'Tháng 4', labelCode: 'txt-thang_4', value: '4' },
  { label: 'Tháng 5', labelCode: 'txt-thang_5', value: '5' },
  { label: 'Tháng 6', labelCode: 'txt-thang_6', value: '6' },
  { label: 'Tháng 7', labelCode: 'txt-thang_7', value: '7' },
  { label: 'Tháng 8', labelCode: 'txt-thang_8', value: '8' },
  { label: 'Tháng 9', labelCode: 'txt-thang_9', value: '9' },
  { label: 'Tháng 10', labelCode: 'txt-thang_10', value: '10' },
  { label: 'Tháng 11', labelCode: 'txt-thang_11', value: '11' },
  { label: 'Tháng 12', labelCode: 'txt-thang_12', value: '12' },
];

// trạng thái cash transaction
export const AllCashTransType: Array<INormalSelect> = [
  { label: 'Thu', value: 'CC_INCOME' },
  { label: 'Chi', value: 'CC_EXPENSE' },
  { label: 'Vay thêm', value: 'CC_ADD_MORE' },
  { label: 'Tiền phí', value: 'CASH_FEE' },
  { label: 'Tiền thêm khi Đóng/chuộc đồ', value: 'CC_CLOSE' },
  { label: 'Tiền thanh lý', value: 'CC_LIQUID' },
  { label: 'Tiền trả định kỳ - Trả góp', value: 'CASH_PAY' },
  { label: 'Tiền giải ngân khi tạo HĐ', value: 'CC_CONTRACT' },
];

// trạng thái tìm kiếm chung
export const AllNormalStatus: Array<INormalSelect> = [
  { label: 'Chưa duyệt', labelCode: 'stt.unapproved', value: 'CHUA_DUYET' },
  { label: 'Đã duyệt', labelCode: 'stt.approved', value: 'DA_DUYET' },
];

// loại khách hàng
export const AllCustType: Array<INormalSelect> = [
  { label: 'Cá nhân', labelCode: 'cust_type_ca_nhan', value: 'CA_NHAN' },
  { label: 'Tổ chức', labelCode: 'cust_type_to_chuc', value: 'TO_CHUC' },
];

// loại quốc tịch
export const AllNationalType: Array<INormalSelect> = [
  { label: 'Trong nước', value: 'TRONG_NUOC' },
  { label: 'Nước ngoài', value: 'NUOC_NGOAI' },
];
// loại quốc tịch
export const AllBusinessCode: Array<INormalSelect> = [
  { label: 'Thu nợ', labelCode: 'txt-thu-no', value: 'RECEIVE' },
  { label: 'Giải ngân', labelCode: 'txt-giai-ngan', value: 'DELIVER' },
];

// Đăng nhập theo
export const arrLoginMode: Array<INormalSelect> = [
  { value: 'DOMAIN', label: 'Domain' },
  { value: 'SYSTEM', label: 'Ngoài domain' },
];

//Quyền duyệt tiền về
export const arrApproveOnMoney: Array<INormalSelect> = [
  { value: 'AM_KY_QUY', label: 'Âm ký quỹ' },
  { value: 'AM_TIEN_MAT', label: 'Âm tiền mặt' },
];

export const _indexList: Array<string> = [
  'VNXALL',
  'VNX50',
  'VNUTI',
  'VNSML',
  'VNSI',
  'VNREAL',
  'VNMID',
  'VNMAT',
  'VNIT',
  'VNINDEX',
  'VNIND',
  'VNHEAL',
  'VNFINSELECT',
  'VNFINLEAD',
  'VNFIN',
  'VNENE',
  'VNDIAMOND',
  'VNCONS',
  'VNCOND',
  'VNALL',
  'VN30INDEX',
  'VN30',
  'VN100',
  'VN30F1Q',
  'VN30F2Q',
  'VN30F1M',
  'VN30F2M',
  'HNX',
  'HNXINDEX',
  'HNX30',
  'HNX30INDEX',
  'UPCOM',
  'UPCOMINDEX',
];

//select loai lenh
export const ArrLoaiLenh: INormalSelect[] = [
  { value: 'ALL', label: 'Tất cả', labelCode: 'trading.order-book.all' },
  { value: 'B', label: 'Lệnh mua', labelCode: 'trading.order-book.order-buy' },
  {
    value: 'S',
    label: 'Lệnh bán',
    labelCode: 'trading.order-book.order-sell',
  },
];

//select trang thai sổ lệnh
export const ArrStatusOrderBook: INormalSelect[] = [
  { value: 'ALL', label: 'Tất cả', labelCode: 'trading.order-book.all' },
  { value: '1', label: 'Chờ khớp', labelCode: 'trading.order-book.confirm' },
  {
    value: '2',
    label: 'Đã khớp',
    labelCode: 'trading.order-book.matched',
  },
  {
    value: '3',
    label: 'Đã hủy',
    labelCode: 'trading.order-book.canceled',
  },
  {
    value: '4',
    label: 'Khớp 1 phần',
    labelCode: 'trading.order-book.part-matched',
  },
];

//select trang thai ls lệnh
export const ArrStatusHis: INormalSelect[] = [
  { value: '', label: 'Tất cả', labelCode: 'orderbook.form.all' },
  { value: 'M', label: 'Đã khớp', labelCode: 'orderbook.form.matched' },
  {
    value: 'X',
    label: 'Đã hủy',
    labelCode: 'orderbook.form.canceled',
  },
];

export const mapIndexList: any = {
  VNXALL: 'VNXALL',
  VNX50: 'VNX50',
  VNUTI: 'VNUTI',
  VNSML: 'VNSML',
  VNSI: 'VNSI',
  VNREAL: 'VNREAL',
  VNMID: 'VNMID',
  VNMAT: 'VNMAT',
  VNIT: 'VNIT',
  VNINDEX: 'VNIndex',
  VNIND: 'VNIND',
  VNHEAL: 'VNHEAL',
  VNFINSELECT: 'VNFINSELECT',
  VNFINLEAD: 'VNFINLEAD',
  VNFIN: 'VNFIN',
  VNENE: 'VNENE',
  VNDIAMOND: 'VNDIAMOND',
  VNCONS: 'VNCONS',
  VNCOND: 'VNCOND',
  VNALL: 'VNALL',
  VN30: 'VN30',
  VN100: 'VN100',
  VN30F1Q: 'VN30F1Q',
  VN30F2Q: 'VN30F2Q',
  VN30F1M: 'VN30F1M',
  VN30F2M: 'VN30F2M',
  HNX: 'HNXIndex',
  HNXINDEX: 'HNXIndex',
  HOSE: 'VNIndex',
  HNX30: 'HNX30Index',
  VN30INDEX: 'VN30',
  HNX30INDEX: 'HNX30',
  UPCOM: 'HNXUpcomIndex',
  UPCOMINDEX: 'HNXUpcomIndex',
};

export const mapIndustryList = {
  '0001': 'Dầu khí',
  '1300': 'Hóa chất',
  '1700': 'Tài nguyên cơ bản',
  '2300': 'Xây dựng và vật liệu',
  '2700': 'Hàng & Dịch vụ Công nghiệp',
  '3300': 'Ô tô và phụ tùng',
  '3500': 'Thực phẩm và đồ uống',
  '3700': 'Hàng cá nhân & Gia dụng',
  '4530': 'Thiết bị và Dịch vụ Y tế',
  '4570': 'Dược phẩm',
  '5300': 'Bán lẻ',
  '5500': 'Truyền thông',
  '5700': 'Du lịch và Giải trí',
  '6000': 'Viễn thông',
  '7530': 'Điện',
  '7570': 'Nước & Khí đốt',
  '8300': 'Ngân hàng',
  '8500': 'Bảo hiểm',
  '8600': 'Bất động sản',
  '8700': 'Dịch vụ Tài chính',
  '9000': 'Công nghệ thông tin',
};

export const mapTypeFilter = {
  CO_TUC: 'Cổ tức',
  DINH_GIA: 'Định giá',
  GIA_KHOI_LUONG: 'Giá và khối lượng',
  HIEU_QUA_HOAT_DONG: 'Hiệu quả hoạt động',
  KHA_NANG_SINH_LOI: 'Khả năng sinh lời',
  KHA_NANG_THANH_TOAN_DAI_HAN: 'Khả năng thanh toán dài hạn',
  KHA_NANG_THANH_TOAN_NGAN_HAN: 'Khả năng thanh toán ngắn hạn',
  KHOI_NGOAI: 'Khối ngoại',
  QUY_MO: 'Quy mô',
  SUC_KHOE_TAI_CHINH: 'Sức khỏe tài chính',
  TANG_TRUONG: 'Tăng trưởng',
  TIN_HIEU_KY_THUAT: 'Tín hiệu kỹ thuật',
};
export const ChildTypeFilter = [
  { group: 'CO_TUC', type: 'DVD_PAID_RATE', label: 'Tỷ lệ chi trả cổ tức (%)' },
  { group: 'CO_TUC', type: 'DVD_PER_P', label: 'Tỷ suất cổ tức (%)' },
  { group: 'CO_TUC', type: 'DVD_1Y', label: 'Cổ tức 4 quý (VND)' },
  { group: 'CO_TUC', type: 'EPS', label: 'EPS 4 quý (VND)' },

  { group: 'DINH_GIA', type: 'PE', label: 'PE (lần)' },
  { group: 'DINH_GIA', type: 'PB', label: 'PB (lần)' },
  { group: 'DINH_GIA', type: 'PS', label: 'PS (lần)' },
  { group: 'DINH_GIA', type: 'EV_PER_EBIT', label: 'EV/EBIT (lần)' },
  { group: 'DINH_GIA', type: 'EV_PER_EBITDA', label: 'EV/EBITDA (lần)' },
  { group: 'DINH_GIA', type: 'P_PER_FCF', label: 'P/FCF (lần)' },

  { group: 'GIA_KHOI_LUONG', type: 'TOTAL_VOL', label: 'KLGD hiện tại (CP)' },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VOL_5D',
    label: 'TB KLGD 5 phiên (CP)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VOL_10D',
    label: 'TB KLGD 10 phiên (CP)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VOL_20D',
    label: 'TB KLGD 20 phiên (CP)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VOL_30D',
    label: 'TB KLGD 30 phiên (CP)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VOL_60D',
    label: 'TB KLGD 60 phiên (CP)',
  },
  { group: 'GIA_KHOI_LUONG', type: 'AVG_VOL_YTD', label: 'TB KLGD YTD (CP)' },
  { group: 'GIA_KHOI_LUONG', type: 'AVG_VOL_1Y', label: 'TB KLGD 1 năm (CP)' },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'TOTAL_VAL',
    label: 'GTGD hiện tại (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_5D',
    label: 'TB GTGD 5 phiên (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_10D',
    label: 'TB GTGD 10 phiên (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_20D',
    label: 'TB GTGD 20 phiên (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_30D',
    label: 'TB GTGD 30 phiên (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_60D',
    label: 'TB GTGD 60 phiên (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_YTD',
    label: 'TB GTGD YTD (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'AVG_VAL_1Y',
    label: 'TB GTGD 1 năm (Triệu VND)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VOL_PER_5D',
    label: 'KLGD so với KLGD bình quân 5 phiên (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VOL_PER_10D',
    label: 'KLGD so với KLGD bình quân 10 phiên (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VOL_PER_20D',
    label: 'KLGD so với KLGD bình quân 20 phiên (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VOL_10D_VS_3M',
    label: 'KLGD bình quân 10 ngày so với KLGD bình quân 3 tháng (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VAL_PER_5D',
    label: 'GTGD so với GTGD bình quân 5 phiên (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VAL_PER_10D',
    label: 'GTGD so với GTGD bình quân 10 phiên (lần)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'VAL_PER_20D',
    label: 'GTGD so với GTGD bình quân 20 phiên (lần)',
  },
  { group: 'GIA_KHOI_LUONG', type: 'P_PER_20D', label: 'Giá so với MA 20 (%)' },
  { group: 'GIA_KHOI_LUONG', type: 'P_PER_50D', label: 'Giá so với MA 50 (%)' },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_PER_130D',
    label: 'Giá so với MA 130 (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_PER_200D',
    label: 'Giá so với MA 200 (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_CHANGE_1W',
    label: 'Thay đổi giá 1 tuần (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_CHANGE_1M',
    label: 'Thay đổi giá 1 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_CHANGE_3M',
    label: 'Thay đổi giá 3 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_CHANGE_6M',
    label: 'Thay đổi giá 6 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_CHANGE_1Y',
    label: 'Thay đổi giá 1 năm (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_PER_MAX_52W',
    label: 'Giá so với giá cao 52 tuần (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'P_PER_MIN_52W',
    label: 'Giá so với giá thấp 52 tuần (%)',
  },
  { group: 'GIA_KHOI_LUONG', type: 'BETA30D', label: 'Beta 30 ngày' },
  { group: 'GIA_KHOI_LUONG', type: 'BETA60D', label: 'Beta 60 ngày' },
  { group: 'GIA_KHOI_LUONG', type: 'BETA90D', label: 'Beta 90 ngày' },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'RS1W',
    label: 'Sức mạnh tương đương 1 tuần (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'RS1M',
    label: 'Sức mạnh tương đương 1 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'RS3M',
    label: 'Sức mạnh tương đương 3 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'RS6M',
    label: 'Sức mạnh tương đương 6 tháng (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'RS1Y',
    label: 'Sức mạnh tương đương 1 năm (%)',
  },
  {
    group: 'GIA_KHOI_LUONG',
    type: 'STD_P_1Y',
    label: 'Độ lệch chuẩn theo ngày, 1 năm (%)',
  },

  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'INVENTORY_TURN',
    label: 'Hệ số quay vòng hàng tồn kho (lần)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'INVENTORY_DAYS',
    label: 'Số ngày hàng tồn kho trong kỳ (ngày)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'REC_TURN',
    label: 'Hệ số quay vòng khoản phải thu (lần)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'REC_DAYS',
    label: 'Số ngày phải thu trong kỳ (ngày)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'PAY_TURN',
    label: 'Hệ số quay vòng khoản phải trả (lần)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'PAY_DAYS',
    label: 'Số ngày phải trả trong kỳ (ngày',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'CAPITAL_TURN',
    label: 'Hệ số quay vòng vốn lưu động (lần)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'FIXED_ASSETS_TURN',
    label: 'Hệ số quay vòng tài sản cố định (lần)',
  },
  {
    group: 'HIEU_QUA_HOAT_DONG',
    type: 'ASSETS_TURN',
    label: 'Hệ số quay vòng tổng tài sản (lần)',
  },

  { group: 'KHA_NANG_SINH_LOI', type: 'ROA', label: 'ROA (%)' },
  { group: 'KHA_NANG_SINH_LOI', type: 'ROE', label: 'ROE (%)' },
  {
    group: 'KHA_NANG_SINH_LOI',
    type: 'GR_MARGIN',
    label: 'Biên lợi nhuận gộp (%)',
  },
  {
    group: 'KHA_NANG_SINH_LOI',
    type: 'OPERATING_MARGIN',
    label: 'Biên lợi nhuận hoạt động (%)',
  },
  {
    group: 'KHA_NANG_SINH_LOI',
    type: 'BF_TAX_MARGIN',
    label: 'Biên lợi nhuận trước thuế (%)',
  },
  {
    group: 'KHA_NANG_SINH_LOI',
    type: 'NET_MARGIN',
    label: 'Biên lợi nhuận sau thuế (%)',
  },
  { group: 'KHA_NANG_SINH_LOI', type: 'ROIC', label: 'ROIC (%)' },
  { group: 'KHA_NANG_SINH_LOI', type: 'ROCE', label: 'ROCE (%)' },

  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'DEBT_PER_ASSETS',
    label: 'Chỉ số nợ trên tổng tài sản (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'DEBT_PER_CAPITAL',
    label: 'Chỉ số nợ trên tổng nguồn vốn (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'DEBT_PER_EQUITY',
    label: 'Chỉ số nợ trên vốn chủ sở hữu (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'LEVERAGE',
    label: 'Chỉ số đòn bẩy tài chính (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'INT_COVERAGE',
    label: 'Tỉ lệ đảm bảo chi phí lãi vay (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'CASH_PER_ASSETS',
    label: 'Chỉ số tiền mặt trên tổng tài sản (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'FCF_PER_LT_DEBT',
    label: 'Chỉ số FCF trên nợ dài hạn (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'NET_DEBT_PER_EQUITY',
    label: 'Chỉ số nợ ròng trên vốn chủ sở hữu (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'NET_DEBT_PER_TANGIBLE_EQUITY',
    label: 'Chỉ số nợ ròng trên vốn chủ sở hữu hữu hình (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'LT_DEBT_PER_ASSETS',
    label: 'Chỉ số nợ dài hạn trên tổng tài sản (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'TANGIBLE_ASSETS_PER_EQUITY',
    label: 'Chỉ số tài sản hữu hình trên vốn chủ sở hữu (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'NET_DEBT_PER_MC',
    label: 'Chỉ số nợ ròng trên vốn hóa (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_DAI_HAN',
    type: 'NET_DEBT_PER_TANGIBLE_ASSETS',
    label: 'Chỉ số nợ ròng trên tài sản hữu hình (lần)',
  },

  {
    group: 'KHA_NANG_THANH_TOAN_NGAN_HAN',
    type: 'CURR_RATIO',
    label: 'Chỉ số thanh toán ngắn hạn (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_NGAN_HAN',
    type: 'QUICK_RATIO',
    label: 'Chỉ số thanh toán nhanh (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_NGAN_HAN',
    type: 'CASH_RATIO',
    label: 'Chỉ số thanh toán tiền mặt (lần)',
  },
  {
    group: 'KHA_NANG_THANH_TOAN_NGAN_HAN',
    type: 'CASH_TURN',
    label: 'Vòng quay tiền mặt (lần)',
  },

  {
    group: 'KHOI_NGOAI',
    type: 'F_BUY_VALUE',
    label: 'Giá trị mua khối ngoại (triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_SELL_VALUE',
    label: 'Giá trị bán khối ngoại (triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_NET_BUY_VALUE',
    label: 'Giá trị mua ròng khối ngoại (triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_BUY_VAL_PER_5D',
    label: 'GT mua khối ngoại so với TB 5 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_BUY_VAL_PER_10D',
    label: 'GT mua khối ngoại so với TB 10 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_BUY_VAL_PER_20D',
    label: 'GT mua khối ngoại so với TB 20 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_SELL_VAL_PER_5D',
    label: 'GT bán khối ngoại so với TB 5 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_SELL_VAL_PER_10D',
    label: 'GT bán khối ngoại so với TB 10 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_SELL_VAL_PER_20D',
    label: 'GT bán khối ngoại so với TB 20 phiên trước (lần)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_NET_BUY_VOLUME',
    label: 'KL mua ròng khối ngoại (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_5D',
    label: 'KL mua ròng khối ngoại TB 5 phiên (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_10D',
    label: 'KL mua ròng khối ngoại TB 10 phiên (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_20D',
    label: 'KL mua ròng khối ngoại TB 20 phiên (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_30D',
    label: 'KL mua ròng khối ngoại TB 30 phiên (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_60D',
    label: 'KL mua ròng khối ngoại TB 60 phiên (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_YTD',
    label: 'KL mua ròng khối ngoại TB YTD (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_VOL_1Y',
    label: 'KL mua ròng khối ngoại TB 1 năm (CP)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'F_NET_BUY_VALUE',
    label: 'GT mua ròng khối ngoại (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_5D',
    label: 'GT mua ròng khối ngoại TB 5 phiên (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_10D',
    label: 'GT mua ròng khối ngoại TB 10 phiên (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_20D',
    label: 'GT mua ròng khối ngoại TB 20 phiên (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_30D',
    label: 'GT mua ròng khối ngoại TB 30 phiên (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_60D',
    label: 'GT mua ròng khối ngoại TB 60 phiên (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_YTD',
    label: 'GT mua ròng khối ngoại TB YTD (Triệu VND)',
  },
  {
    group: 'KHOI_NGOAI',
    type: 'AVG_F_NET_BUY_1Y',
    label: 'GT mua ròng khối ngoại TB 1 năm (Triệu VND)',
  },

  { group: 'QUY_MO', type: 'MC', label: 'Vốn hóa (tỷ VND)' },
  { group: 'QUY_MO', type: 'EV', label: 'EV (tỷ VND)' },

  { group: 'SUC_KHOE_TAI_CHINH', type: 'M_SCORE', label: 'M-Score' },
  { group: 'SUC_KHOE_TAI_CHINH', type: 'F_SCORE', label: 'F-Score' },
  { group: 'SUC_KHOE_TAI_CHINH', type: 'Z_SCORE', label: 'Z-Score' },
  { group: 'SUC_KHOE_TAI_CHINH', type: 'C_SCORE', label: 'C-Score' },
  {
    group: 'SUC_KHOE_TAI_CHINH',
    type: 'MAGIC_SCORE',
    label: 'Magic Formula Score',
  },

  {
    group: 'TANG_TRUONG',
    type: 'NET_INC_GROWTH',
    label: 'Tăng trưởng LN ròng 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'EPS_GROWTH',
    label: 'Tăng trưởng EPS 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'REV_GROWTH_1Y',
    label: 'Tăng trưởng doanh thu 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'DVD_GROWTH',
    label: 'Tăng trưởng DPS 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'OPERATING_INC_GROWTH',
    label:
      'Tăng trưởng LN từ hoạt động kinh doanh 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'GR_PROFIT_GROWTH',
    label: 'Tăng trưởng LN gộp 4 quý gần nhất so với 4 quý trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'NET_INC_GROWTH1',
    label: 'Tăng trưởng LN ròng quý gần nhất so với quý cùng kỳ năm trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'REV_GROWTH1',
    label:
      'Tăng trưởng doanh thu quý gần nhất so với quý cùng kỳ năm trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'OPERATING_INC_GROWTH1',
    label:
      'Tăng trưởng LN từ HĐKD quý gần nhất so với quý cùng kỳ năm trước (%)',
  },
  {
    group: 'TANG_TRUONG',
    type: 'GR_PROFIT_GROWTH1',
    label: 'Tăng trưởng LN gộp quý gần nhất so với quý cùng kỳ năm trước (%)',
  },

  { group: 'TIN_HIEU_KY_THUAT', type: 'RSI_14', label: 'RSI (14)' },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'STOCHASTIC_14_3_3',
    label: 'STOCH (14)',
  },
  { group: 'TIN_HIEU_KY_THUAT', type: 'ADI_14', label: 'ADX (14)' },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'STOCHASTIC_RSI_14',
    label: 'STOCHRSI (14)',
  },
  { group: 'TIN_HIEU_KY_THUAT', type: 'ATR_14', label: 'ATR (14)' },
  { group: 'TIN_HIEU_KY_THUAT', type: 'WILLIAMR_14', label: 'William %R (14)' },
  { group: 'TIN_HIEU_KY_THUAT', type: 'MACD_12_26_9', label: 'MACD (12,26,9)' },
  { group: 'TIN_HIEU_KY_THUAT', type: 'MFI_14', label: 'MFI (14)' },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'BBAND_20_2',
    label: 'Bollinger Band %B (20,2)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'MA_ENVELOPES_20_25',
    label: 'Moving Average Envelopes (20,2.5) %E',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'P_PER_SMA10',
    label: 'Giá so với SMA (10) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'P_PER_EMA10',
    label: 'Giá so với EMA (10) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'P_PER_DEMA10',
    label: 'Giá so với DEMA (10) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'P_PER_WMA10',
    label: 'Giá so với WMA (10) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'SMA5_PER_SMA20',
    label: 'SMA (5) so với SMA (20) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'EMA5_PER_EMA20',
    label: 'EMA (5) so với EMA (20) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'DEMA5_PER_DEMA20',
    label: 'DEMA (5) so với DEMA (20) (%)',
  },
  {
    group: 'TIN_HIEU_KY_THUAT',
    type: 'WMA5_PER_WMA20',
    label: 'WMA (5) so với WMA (20) (%)',
  },
];

export const mapFormulaID = {
  '1133': 'ADX(14)',
  '1134': 'Bollinger Band(20,2)',
  '1135': 'CCI(14)',
  '1137': 'Giá so với đường EMA(20)',
  '1138': 'Giá so với đường EMA(50)',
  '1139': 'Giá so với đường SMA(20)',
  '1140': 'Giá so với đường SMA(50)',
  '1141': 'Giá so với đỉnh đáy 3 tháng',
  '1142': 'Giá so với đỉnh đáy 6 tháng',
  '1143': 'MACD(12,26,9)',
  '1144': 'MFI(14)',
  '1145': 'RSI(14)',
  '1146': 'StochRSI(14)',
  '1147': 'Stochastic(9,6,6)',
  '1148': 'William %R(14)',
  '1149': 'Đường EMA(20) so với đường EMA(50)',
  '1150': 'Đường EMA(5) so với đường EMA(20)',
  '1151': 'Đường SMA(20) so với đường SMA(50)',
  '1152': 'Đường SMA(5) so với đường SMA(20)',
};

export const arrMCStock = [
  { value: '500', label: 'Tối thiểu 500 tỷ VND' },
  { value: '1000', label: 'Tối thiểu 1000 tỷ VND' },
  { value: '2000', label: 'Tối thiểu 2000 tỷ VND' },
  { value: '3000', label: 'Tối thiểu 3000 tỷ VND' },
  { value: '5000', label: 'Tối thiểu 5000 tỷ VND' },
];

export const arrAvgVolStock = [
  { value: '10000', label: 'Tối thiểu 10000 CP' },
  { value: '50000', label: 'Tối thiểu 50000 CP' },
  { value: '100000', label: 'Tối thiểu 100000 CP' },
  { value: '500000', label: 'Tối thiểu 500000 CP' },
  { value: '1000000', label: 'Tối thiểu 1000000 CP' },
];
