/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { BtnSubmit } from 'utils/styledUtils';
import { useDispatch } from 'react-redux';

import { IUserRegister } from 'interface';
import * as actionTypes from 'containers/register/actionType';
import { makeGetRegCheckOtp, makeGetRegCustTemp } from 'lib/selector';
import { AppState } from 'reducers';
import {
  regAccountRequest,
  regAccountSuccess,
  regCheckOtpRequest,
  regCheckOtpSuccess,
} from 'containers/register/actions';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { formatMinutes } from 'utils';
import { required } from 'utils/validation';
import { TFunction } from 'i18next';
import BgLogin from 'assets/img/bg/bg_register_small.png';
import RenderLoginInput from 'helper/input/renderLoginInput';

interface Props {
  setStep: Function;
  returnLogin: Function;
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  checkOtp?: number | null;
  custTemp?: any;
  t: TFunction;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const FormRegisStep2: React.FunctionComponent<
  InjectedFormProps<IUserRegister> & Props
> = (props) => {
  const dispatch = useDispatch();
  const { isLoading, errorMsg, setStep, handleSubmit, checkOtp, custTemp, t } =
    props;
  const [countdown, setCountdown] = React.useState<number>(299);

  const preCheckOtp = usePrevious(checkOtp);

  React.useEffect(() => {
    return () => {
      dispatch(regCheckOtpSuccess(null));
      dispatch(regAccountSuccess(null));
      dispatch({ type: 'res/CHECK_CUST_CLEAR' });
      dispatch({ type: 'res/REG_ACCOUNT_CLEAR' });
      dispatch({ type: 'res/REG_CHECK_OTP_CLEAR' });
      dispatch({ type: 'CLEAR_TOAST' });
    };
  }, []);

  React.useEffect(() => {
    if (!countdown) {
      // if (refSubmit.current) refSubmit.current?.click();
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  React.useEffect(() => {
    if (checkOtp && !_.isEqual(checkOtp, preCheckOtp)) {
      // check otp thành công => gửi mỏ tài khoản
      const _data = {
        user: 'back',
        group: 'LIST',
        cmd: 'OPEN_VIRTUAL_ACCOUNT',
        param: {
          CUSTOMER_NAME: custTemp.userName,
          CUSTOMER_EMAIL: custTemp?.userEmail,
          CUSTOMER_MOBILE: custTemp?.userMobile || '',
          CUSTOMER_PASS: custTemp?.userPass,
          MARKETING_ID: custTemp?.mktId || '',
        },
      };

      dispatch(regAccountRequest(_data));
    }
  }, [checkOtp]);

  function nextStep() {
    setStep(1);
  }

  function submit(user: IUserRegister) {
    const _data = {
      user: 'back',
      group: 'LIST',
      cmd: 'CHECK_OTP',
      param: { otp: user.formOtp, email: custTemp?.userEmail },
    };
    dispatch(regCheckOtpRequest(_data));
    // setStep(2);
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="px-10 py-7 rounded-[10px] m-auto w-full md:w-[480px] flex flex-col justify-center"
      style={{
        border: 'none',
        background: 'transparent',
        backgroundImage: `url(${BgLogin})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <label className="text-2xl text-skin-white uppercase font-semibold">
        Xác nhận OTP
      </label>
      <label className="text-base text-skin-vendor self-start pb-3 pt-1">
        Mã OTP đã được gửi đến email {custTemp?.userEmail} của quý khách. Hiệu
        lực OTP
      </label>

      <a className="text-skin-active text-family-bold text-xl pt-2 pb-5">
        {formatMinutes(countdown)}
      </a>
      <div className="auth-messages">
        {!isLoading && errorMsg ? (
          <div className="fz-13">{errorMsg.toString()}</div>
        ) : null}
        {isLoading ? <div className=" fz-13">Requesting in...</div> : null}
      </div>
      <div className="w-full p-8 border-x-2 border-y-2 border-dashed rounded-md cl-border-var flex flex-col">
        <label className="self-start text-skin-vendor text-base">
          Chỉ cần <span className=" text-skin-vendor">1 phút</span> để thực hiện
        </label>
        <label className="w-full mt-4">
          <span className="text-base text-skin-vendor">Mã OTP *</span>
          <Field
            name="formOtp"
            type="otp"
            placeholder="Nhập mã OTP"
            component={RenderLoginInput}
            validate={[required]}
          />
        </label>
      </div>

      <div className="w-full grid grid-cols-2 gap-5 mt-10">
        <BtnSubmit
          type="button"
          onClick={nextStep}
          className="!text-base !rounded-md py-2"
        >
          Quay lại
        </BtnSubmit>
        <BtnSubmit
          type="submit"
          className="primary !text-base !rounded-md py-2"
        >
          Xác nhận
        </BtnSubmit>
      </div>
    </form>
  );
};

const _FormRegisStep2 = reduxForm<IUserRegister, Props>({
  form: 'formRegisStep2',
  enableReinitialize: true,
})(FormRegisStep2 as any);

const selector = formValueSelector('formRegisStep2');

const makeMapStateToProps = () => {
  const getRegCheckOtp = makeGetRegCheckOtp();
  const getRegCustTemp = makeGetRegCustTemp();

  const mapStateToProps = (state: AppState) => {
    const { formUserName, formOtp } = selector(
      state,
      'formUserName',
      'formOtp',
    );

    return {
      checkOtp: getRegCheckOtp(state),
      custTemp: getRegCustTemp(state),
      isLoading:
        state.isLoading[actionTypes.REG_CHECK_OTP] ||
        state.isLoading[actionTypes.REG_ACCOUNT],
      errorMsg:
        state.error[actionTypes.REG_CHECK_OTP] ||
        state.error[actionTypes.REG_ACCOUNT],

      formUserName,
      formOtp,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormRegisStep2);
