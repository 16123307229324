import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import StockDetailModal from 'components/modal/stockDetail';
import PanelTrade from 'components/panel/trade';
import PanelHeaderActiveM from 'components/panel/trade/tradingMobileModal';
import { setSymbolActive } from 'containers/client/actions';
import { makeGetToken } from 'lib/selector';
import * as _ from 'lodash';
import { useLocation } from 'react-router';
import { AppState } from 'reducers';

interface Props {
  footerActive: string;
  headActive?: string;
  symbolActive: any;
  token?: any;
  dblPri?: any;
  reqTrading?: boolean;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const DefaultFooter: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { symbolActive, token, dblPri, reqTrading, headActive } = props;
  const preSymbolActive = usePrevious(symbolActive);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [toggleTrade, setToggleTrade] = React.useState<boolean>(false);
  const preToken = usePrevious(token);
  const preDblPri = usePrevious(dblPri);
  const preReqTrading = usePrevious(reqTrading);

  const wrapperRef = React.useRef<any>();

  // React.useEffect(() => {
  //   window.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     window.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  React.useEffect(() => {
    if (token && !_.isEqual(token, preToken)) {
      setToggleTrade(false);
    }
  }, [token]);

  React.useEffect(() => {
    if (symbolActive && !_.isEqual(symbolActive, preSymbolActive)) {
      setShowModal(true);
    }
  }, [symbolActive]);

  React.useEffect(() => {
    if (
      (dblPri && !_.isEqual(dblPri, preDblPri)) ||
      (reqTrading && !_.isEqual(reqTrading, preReqTrading))
    ) {
      setToggleTrade(true);
      dispatch({ type: 'REQ_TRADING', data: false });
    }
  }, [dblPri, reqTrading]);

  // const handleClickOutside = (event: any) => {
  //   if (
  //     wrapperRef &&
  //     wrapperRef.current &&
  //     !wrapperRef.current.contains(event.target)
  //   ) {
  //     setToggleTrade(false);
  //   }
  // };

  function _handleCloseModal() {
    setShowModal(false);
    dispatch(setSymbolActive(''));
  }

  function _handleClosePanelTrade() {
    setToggleTrade(false);
  }

  console.log(symbolActive);

  return (
    <>
      {/* <footer className="px-4 py-[10px] text-[11px] text-right text-skin-footer">
        Cung cấp bởi ALGO v1.0
      </footer> */}
      {showModal && (
        <StockDetailModal
          showModal={showModal}
          handleCloseModal={_handleCloseModal}
          symbol={symbolActive}
        />
      )}
      {toggleTrade && token && (
        <div ref={wrapperRef}>
          <PanelTrade onClose={_handleClosePanelTrade} />
        </div>
      )}

      {headActive && <PanelHeaderActiveM />}
    </>
  );
};

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state: AppState) => {
    return {
      token: getToken(state),
      symbolActive: state.client.symbolActive,
      headActive: state.client.headActive,
      dblPri: state.client.dblPri,
      reqTrading: state.client.reqTrading,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(DefaultFooter);
