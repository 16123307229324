import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getRegCheckCust = (state: AppState) => state.regis.regCheckCust;
const getRegCheckOtp = (state: AppState) => state.regis.regCheckOtp;
const getRegAccount = (state: AppState) => state.regis.regAccount;
const getRegCustTemp = (state: AppState) => state.regis.regCustTemp;

const getResCheckCust = (state: AppState) => state.regis.resCheckCust;
const getResChangePass = (state: AppState) => state.regis.resChangePass;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetRegCheckCust = () =>
  createSelector(getRegCheckCust, (accInfo) => accInfo);
export const makeGetRegCheckOtp = () =>
  createSelector(getRegCheckOtp, (accInfo) => accInfo);
export const makeGetRegAccount = () =>
  createSelector(getRegAccount, (accInfo) => accInfo);
export const makeGetRegCustTemp = () =>
  createSelector(getRegCustTemp, (custTemp) => custTemp);
export const makeGetResCheckCust = () =>
  createSelector(getResCheckCust, (resCheckCust) => resCheckCust);
export const makeGetResChangePass = () =>
  createSelector(getResChangePass, (resChangePass) => resChangePass);
