/** dữ liệu tạm đăng ký khách hàng mới */
export const REG_CUST_TEMP = 'reg/CUST_TEMP';
export interface RegCustTempAction {
  type: typeof REG_CUST_TEMP;
  data: any;
}

/** check verify email */
export const REG_CHECK_CUST = 'reg/CHECK_CUST';
export interface RegCheckCustAction {
  type: typeof REG_CHECK_CUST;
}

export const REG_CHECK_CUST_REQUESTING = 'reg/CHECK_CUST_REQUESTING';
export interface RegCheckCustRequestingAction {
  type: typeof REG_CHECK_CUST_REQUESTING;
  data: any;
}

export const REG_CHECK_CUST_SUCCESS = 'reg/CHECK_CUST_SUCCESS';
export interface RegCheckCustSuccessAction {
  type: typeof REG_CHECK_CUST_SUCCESS;
  resData: any;
}

export const REG_CHECK_CUST_ERROR = 'reg/CHECK_CUST_ERROR';
export interface RegCheckCustErrorAction {
  type: typeof REG_CHECK_CUST_ERROR;
  error: any;
}

/** verify otp */
export const REG_CHECK_OTP = 'reg/CHECK_OTP';
export interface RegCheckOtpAction {
  type: typeof REG_CHECK_OTP;
}

export const REG_CHECK_OTP_REQUESTING = 'reg/CHECK_OTP_REQUESTING';
export interface RegCheckOtpRequestingAction {
  type: typeof REG_CHECK_OTP_REQUESTING;
  data: any;
}

export const REG_CHECK_OTP_SUCCESS = 'reg/CHECK_OTP_SUCCESS';
export interface RegCheckOtpSuccessAction {
  type: typeof REG_CHECK_OTP_SUCCESS;
  resData: any;
}

export const REG_CHECK_OTP_ERROR = 'reg/CHECK_OTP_ERROR';
export interface RegCheckOtpErrorAction {
  type: typeof REG_CHECK_OTP_ERROR;
  error: any;
}

/** open account */
export const REG_ACCOUNT = 'reg/ACCOUNT';

export const REG_ACCOUNT_REQUESTING = 'reg/ACCOUNT_REQUESTING';
export interface RegAccountRequestingAction {
  type: typeof REG_ACCOUNT_REQUESTING;
  data: any;
}

export const REG_ACCOUNT_SUCCESS = 'reg/ACCOUNT_SUCCESS';
export interface RegAccountSuccessAction {
  type: typeof REG_ACCOUNT_SUCCESS;
  resData: any;
}

export const REG_ACCOUNT_ERROR = 'reg/ACCOUNT_ERROR';
export interface RegAccountErrorAction {
  type: typeof REG_ACCOUNT_ERROR;
  error: any;
}

/** reset pass verify email */
export const RES_CHECK_CUST = 'res/CHECK_CUST';
export interface ResCheckCustAction {
  type: typeof RES_CHECK_CUST;
}

export const RES_CHECK_CUST_REQUESTING = 'res/CHECK_CUST_REQUESTING';
export interface ResCheckCustRequestingAction {
  type: typeof RES_CHECK_CUST_REQUESTING;
  data: any;
}

export const RES_CHECK_CUST_SUCCESS = 'res/CHECK_CUST_SUCCESS';
export interface ResCheckCustSuccessAction {
  type: typeof RES_CHECK_CUST_SUCCESS;
  resData: any;
}

export const RES_CHECK_CUST_ERROR = 'res/CHECK_CUST_ERROR';
export interface ResCheckCustErrorAction {
  type: typeof RES_CHECK_CUST_ERROR;
  error: any;
}

/** reset pass verify email */
export const RES_CHANGE_PASS = 'res/CHANGE_PASS';
export interface ResChangePassAction {
  type: typeof RES_CHECK_CUST;
}

export const RES_CHANGE_PASS_REQUESTING = 'res/CHANGE_PASS_REQUESTING';
export interface ResChangePassRequestingAction {
  type: typeof RES_CHANGE_PASS_REQUESTING;
  data: any;
}

export const RES_CHANGE_PASS_SUCCESS = 'res/CHANGE_PASS_SUCCESS';
export interface ResChangePassSuccessAction {
  type: typeof RES_CHANGE_PASS_SUCCESS;
  resData: any;
}

export const RES_CHANGE_PASS_ERROR = 'res/CHANGE_PASS_ERROR';
export interface ResChangePassErrorAction {
  type: typeof RES_CHANGE_PASS_ERROR;
  error: any;
}

export type RegAccountAction =
  | RegCustTempAction
  | RegCheckCustAction
  | RegCheckCustRequestingAction
  | RegCheckCustSuccessAction
  | RegCheckCustErrorAction
  | RegCheckOtpAction
  | RegCheckOtpRequestingAction
  | RegCheckOtpSuccessAction
  | RegCheckOtpErrorAction
  | RegAccountRequestingAction
  | RegAccountSuccessAction
  | RegAccountErrorAction
  | ResCheckCustAction
  | ResCheckCustRequestingAction
  | ResCheckCustSuccessAction
  | ResCheckCustErrorAction
  | ResChangePassAction
  | ResChangePassRequestingAction
  | ResChangePassSuccessAction
  | ResChangePassErrorAction;
