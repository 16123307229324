import * as actions from './actionType';

export function allStockRequest(): actions.AllStockRequestAction {
  return {
    type: actions.ALL_STOCK_REQUESTING,
  };
}

export function allStockRequestSuccess(
  allStock: any,
): actions.AllStockRequestSuccessAction {
  return {
    type: actions.ALL_STOCK_REQUEST_SUCCESS,
    allStock,
  };
}

export function allStockRequestError(
  error: any,
): actions.AllStockRequestErrorAction {
  return {
    type: actions.ALL_STOCK_REQUEST_ERROR,
    error,
  };
}

export function marginListRequest(): actions.MarginListRequestAction {
  return {
    type: actions.MARGIN_LIST_REQUESTING,
  };
}

export function marginListRequestSuccess(
  marginList: any,
): actions.MarginListRequestSuccessAction {
  return {
    type: actions.MARGIN_LIST_REQUEST_SUCCESS,
    marginList,
  };
}

export function marginListRequestError(
  error: any,
): actions.MarginListRequestErrorAction {
  return {
    type: actions.MARGIN_LIST_REQUEST_ERROR,
    error,
  };
}

export function listMessageRequest(): actions.ListMessageRequestAction {
  return {
    type: actions.LIST_MESSAGE_REQUESTING,
  };
}

export function listMessageRequestSuccess(
  listMessage: any,
): actions.ListMessageRequestSuccessAction {
  return {
    type: actions.LIST_MESSAGE_REQUEST_SUCCESS,
    listMessage,
  };
}

export function listMessageRequestError(
  error: any,
): actions.ListMessageRequestErrorAction {
  return {
    type: actions.LIST_MESSAGE_REQUEST_ERROR,
    error,
  };
}

export function snapshotRequest(data: any): actions.SnapshotRequestAction {
  return {
    type: actions.SNAPSHOT_REQUESTING,
    data,
  };
}

export function snapshotRequestSuccess(
  dataList: any,
): actions.SnapshotRequestSuccessAction {
  return {
    type: actions.SNAPSHOT_REQUEST_SUCCESS,
    dataList,
  };
}

export function snapshotRequestError(
  error: any,
): actions.SnapshotRequestErrorAction {
  return {
    type: actions.SNAPSHOT_REQUEST_ERROR,
    error,
  };
}

export function snapshotUnderlyingCWRequest(
  data: any,
): actions.SnapshotUnderlyingCWRequestAction {
  return {
    type: actions.SNAPSHOT_UNDERLYING_REQUESTING,
    data,
  };
}

export function snapshotUnderlyingCWSuccess(
  dataList: any,
): actions.SnapshotUnderlyingCWSuccessAction {
  return {
    type: actions.SNAPSHOT_UNDERLYING_REQUEST_SUCCESS,
    dataList,
  };
}

export function snapshotUnderlyingCWError(
  error: any,
): actions.SnapshotUnderlyingCWErrorAction {
  return {
    type: actions.SNAPSHOT_UNDERLYING_REQUEST_ERROR,
    error,
  };
}

export function psSnapshotRequest(data: any): actions.PsSnapshotRequestAction {
  return {
    type: actions.PS_SNAPSHOT_REQUESTING,
    data,
  };
}

export function psSnapshotRequestSuccess(
  dataList: any,
): actions.PsSnapshotRequestSuccessAction {
  return {
    type: actions.PS_SNAPSHOT_REQUEST_SUCCESS,
    dataList,
  };
}

export function psSnapshotRequestError(
  error: any,
): actions.PsSnapshotRequestErrorAction {
  return {
    type: actions.PS_SNAPSHOT_REQUEST_ERROR,
    error,
  };
}

export function psIndexRequest(data: any): actions.PsIndexRequestAction {
  return {
    type: actions.PS_INDEX_REQUESTING,
    data,
  };
}

export function psIndexRequestSuccess(
  dataList: any,
): actions.PsIndexRequestSuccessAction {
  return {
    type: actions.PS_INDEX_REQUEST_SUCCESS,
    dataList,
  };
}

export function psIndexRequestError(
  error: any,
): actions.PsIndexRequestErrorAction {
  return {
    type: actions.PS_INDEX_REQUEST_ERROR,
    error,
  };
}

export function loleSnapshotRequest(
  data: any,
): actions.LoleSnapshotRequestAction {
  return {
    type: actions.LOLE_SNAPSHOT_REQUESTING,
    data,
  };
}

export function loleSnapshotRequestSuccess(
  dataList: any,
): actions.LoleSnapshotRequestSuccessAction {
  return {
    type: actions.LOLE_SNAPSHOT_REQUEST_SUCCESS,
    dataList,
  };
}

export function loleSnapshotRequestError(
  error: any,
): actions.LoleSnapshotRequestErrorAction {
  return {
    type: actions.LOLE_SNAPSHOT_REQUEST_ERROR,
    error,
  };
}

export function loleHSXSnapshotRequest(
  data: any,
): actions.LoleHSXSnapshotRequestAction {
  return {
    type: actions.LOLE_HSX_SNAPSHOT_REQUESTING,
    data,
  };
}

export function loleHSXSnapshotRequestSuccess(
  dataList: any,
): actions.LoleHSXSnapshotRequestSuccessAction {
  return {
    type: actions.LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS,
    dataList,
  };
}

export function loleHSXSnapshotRequestError(
  error: any,
): actions.LoleHSXSnapshotRequestErrorAction {
  return {
    type: actions.LOLE_HSX_SNAPSHOT_REQUEST_ERROR,
    error,
  };
}

export function clearPartRequest(): actions.ClearPartRequestAction {
  return {
    type: actions.CLEAR_PART_REQUEST,
  };
}

export function clearSnapshotRequest(): actions.ClearSnapshotRequestAction {
  return {
    type: actions.CLEAR_SNAPSHOT_REQUEST,
  };
}

export function snapshotPartRequest(
  data: any,
): actions.SnapshotPartRequestAction {
  return {
    type: actions.SNAPSHOT_PART_REQUESTING,
    data,
  };
}

export function snapshotPartRequestSuccess(
  dataList: any,
): actions.SnapshotPartRequestSuccessAction {
  return {
    type: actions.SNAPSHOT_PART_REQUEST_SUCCESS,
    dataList,
  };
}

export function snapshotPartRequestError(
  error: any,
): actions.SnapshotPartRequestErrorAction {
  return {
    type: actions.SNAPSHOT_PART_REQUEST_ERROR,
    error,
  };
}

export function stockInfoRequest(data: any): actions.StockInfoRequestAction {
  return {
    type: actions.STOCK_INFO_REQUESTING,
    data,
  };
}

export function stockInfoRequestSuccess(
  stockInfo: any,
): actions.StockInfoRequestSuccessAction {
  return {
    type: actions.STOCK_INFO_REQUEST_SUCCESS,
    stockInfo,
  };
}

export function stockInfoRequestError(
  error: any,
): actions.StockInfoRequestErrorAction {
  return {
    type: actions.STOCK_INFO_REQUEST_ERROR,
    error,
  };
}

export function allOrdRequest(data: any): actions.AllOrdRequestAction {
  return {
    type: actions.ALL_ORD_REQUESTING,
    data,
  };
}

export function allOrdRequestSuccess(
  allOrder: any,
): actions.AllOrdRequestSuccessAction {
  return {
    type: actions.ALL_ORD_REQUEST_SUCCESS,
    allOrder,
  };
}

export function allOrdRequestError(
  error: any,
): actions.AllOrdRequestErrorAction {
  return {
    type: actions.ALL_ORD_REQUEST_ERROR,
    error,
  };
}

export function clearOrdList(): actions.ClearOrdListAction {
  return {
    type: actions.CLEAR_ORD_LIST,
  };
}

export function ptListRequest(): actions.PtListRequestAction {
  return {
    type: actions.PT_LIST_REQUESTING,
  };
}

export function ptListRequestSuccess(
  ptList: any,
): actions.PtListRequestSuccessAction {
  return {
    type: actions.PT_LIST_REQUEST_SUCCESS,
    ptList,
  };
}

export function ptListRequestError(
  error: any,
): actions.PtListRequestErrorAction {
  return {
    type: actions.PT_LIST_REQUEST_ERROR,
    error,
  };
}

export function psListRequest(): actions.PsListRequestAction {
  return {
    type: actions.PS_LIST_REQUESTING,
  };
}

export function psListRequestSuccess(
  psList: any,
): actions.PsListRequestSuccessAction {
  return {
    type: actions.PS_LIST_REQUEST_SUCCESS,
    psList,
  };
}

export function psListRequestError(
  error: any,
): actions.PsListRequestErrorAction {
  return {
    type: actions.PS_LIST_REQUEST_ERROR,
    error,
  };
}

export function list30Request(data: any): actions.List30RequestAction {
  return {
    type: actions.LIST_30_REQUESTING,
    data,
  };
}

export function list30RequestSuccess(
  list30: any,
): actions.List30RequestSuccessAction {
  return {
    type: actions.LIST_30_REQUEST_SUCCESS,
    list30,
  };
}

export function list30RequestError(
  error: any,
): actions.List30RequestErrorAction {
  return {
    type: actions.LIST_30_REQUEST_ERROR,
    error,
  };
}

export function listEtfRequest(data: any): actions.ListEtfRequestAction {
  return {
    type: actions.LIST_ETF_REQUESTING,
    data,
  };
}

export function listEtfRequestSuccess(
  listEtf: any,
): actions.ListEtfRequestSuccessAction {
  return {
    type: actions.LIST_ETF_REQUEST_SUCCESS,
    listEtf,
  };
}

export function listEtfRequestError(
  error: any,
): actions.ListEtfRequestErrorAction {
  return {
    type: actions.LIST_ETF_REQUEST_ERROR,
    error,
  };
}

export function cwListRequest(): actions.CwListRequestAction {
  return {
    type: actions.CW_LIST_REQUESTING,
  };
}

export function cwListRequestSuccess(
  cwList: any,
): actions.CwListRequestSuccessAction {
  return {
    type: actions.CW_LIST_REQUEST_SUCCESS,
    cwList,
  };
}

export function cwListRequestError(
  error: any,
): actions.CwListRequestErrorAction {
  return {
    type: actions.CW_LIST_REQUEST_ERROR,
    error,
  };
}

export function listNganhRequest(data: any): actions.ListNganhRequestAction {
  return {
    type: actions.LIST_NGANH_REQUESTING,
    data,
  };
}

export function listNganhRequestSuccess(
  listNganh: any,
): actions.ListNganhRequestSuccessAction {
  return {
    type: actions.LIST_NGANH_REQUEST_SUCCESS,
    listNganh,
  };
}

export function listNganhRequestError(
  error: any,
): actions.ListNganhRequestErrorAction {
  return {
    type: actions.LIST_NGANH_REQUEST_ERROR,
    error,
  };
}

export function branchCodeRequest(): actions.BranchCodeRequestAction {
  return {
    type: actions.BRANCH_CODE_REQUESTING,
  };
}

export function branchCodeRequestSuccess(
  branchList: any,
): actions.BranchCodeRequestSuccessAction {
  return {
    type: actions.BRANCH_CODE_REQUEST_SUCCESS,
    branchList,
  };
}

export function branchCodeRequestError(
  error: any,
): actions.BranchCodeRequestErrorAction {
  return {
    type: actions.BRANCH_CODE_REQUEST_ERROR,
    error,
  };
}

export function noicapRequest(): actions.NoicapRequestAction {
  return {
    type: actions.NOI_CAP_REQUESTING,
  };
}

export function noicapRequestSuccess(
  noicap: any,
): actions.NoicapRequestSuccessAction {
  return {
    type: actions.NOI_CAP_REQUEST_SUCCESS,
    noicap,
  };
}

export function noicapRequestError(
  error: any,
): actions.NoicapRequestErrorAction {
  return {
    type: actions.NOI_CAP_REQUEST_ERROR,
    error,
  };
}

export function indSumRequest(): actions.IndSumRequestAction {
  return {
    type: actions.SUMMARY_REQUESTING,
  };
}

export function indSumRequestSuccess(
  indSum: any,
): actions.IndSumRequestSuccessAction {
  return {
    type: actions.SUMMARY_REQUEST_SUCCESS,
    indSum,
  };
}

export function indSumRequestError(
  error: any,
): actions.IndSumRequestErrorAction {
  return {
    type: actions.SUMMARY_REQUEST_ERROR,
    error,
  };
}

export function getStockByIdRequest(
  data: any,
): actions.GetStockByIdRequestAction {
  return {
    type: actions.GET_STOCK_BY_ID_REQUESTING,
    data,
  };
}

export function getStockByIdRequestSuccess(
  dataList: any,
): actions.GetStockByIdRequestSuccessAction {
  return {
    type: actions.GET_STOCK_BY_ID_REQUEST_SUCCESS,
    dataList,
  };
}

export function getStockByIdRequestError(
  error: any,
): actions.GetStockByIdRequestErrorAction {
  return {
    type: actions.GET_STOCK_BY_ID_REQUEST_ERROR,
    error,
  };
}

export function indexRequest(data: any): actions.IndexRequestAction {
  return {
    type: actions.INDEX_REQUESTING,
    data,
  };
}

export function indexRequestSuccess(
  indexList: any,
): actions.IndexRequestSuccessAction {
  return {
    type: actions.INDEX_REQUEST_SUCCESS,
    indexList,
  };
}

export function indexRequestError(error: any): actions.IndexRequestErrorAction {
  return {
    type: actions.INDEX_REQUEST_ERROR,
    error,
  };
}

export function ndataSnapshotRequest(
  data: any,
): actions.NdataSnapshotRequestAction {
  return {
    type: actions.NDATA_SNAPSHOT_REQUESTING,
    data,
  };
}

export function ndataSnapshotRequestSuccess(
  ndata: any,
): actions.NdataSnapshotRequestSuccessAction {
  return {
    type: actions.NDATA_SNAPSHOT_REQUEST_SUCCESS,
    ndata,
  };
}

export function ndataSnapshotRequestError(
  error: any,
): actions.NdataSnapshotRequestErrorAction {
  return {
    type: actions.NDATA_SNAPSHOT_REQUEST_ERROR,
    error,
  };
}

export function tradeHisPsRequest(data: any): actions.TradeHisPsRequestAction {
  return {
    type: actions.TRADE_HIS_PS_REQUESTING,
    data,
  };
}

export function tradeHisPsRequestSuccess(
  dataList: any,
): actions.TradeHisPsRequestSuccessAction {
  return {
    type: actions.TRADE_HIS_PS_REQUEST_SUCCESS,
    dataList,
  };
}

export function tradeHisPsRequestError(
  error: any,
): actions.TradeHisPsRequestErrorAction {
  return {
    type: actions.TRADE_HIS_PS_REQUEST_ERROR,
    error,
  };
}

export function tradeHisPsAddRow(data: any): actions.TradeHisPsAddRowAction {
  return {
    type: actions.TRADE_HIS_PS_Add_ROW,
    data,
  };
}

export function sortTypeTbl(_type: any): actions.SortTypeTblAction {
  return {
    type: actions.SORT_TYPE,
    _type,
  };
}

export function dmNganhRequest(): actions.DmNganhRequestAction {
  return {
    type: actions.DM_NGANH_REQUESTING,
  };
}

export function dmNganhRequestSuccess(
  dataList: any,
): actions.DmNganhRequestSuccessAction {
  return {
    type: actions.DM_NGANH_REQUEST_SUCCESS,
    dataList,
  };
}

export function dmNganhRequestError(
  error: any,
): actions.DmNganhRequestErrorAction {
  return {
    type: actions.DM_NGANH_REQUEST_ERROR,
    error,
  };
}

export function topInterestRequest(): actions.TopInterestRequestAction {
  return {
    type: actions.TOP_INTEREST_REQUESTING,
  };
}

export function topInterestRequestSuccess(
  dataList: any,
): actions.TopInterestRequestSuccessAction {
  return {
    type: actions.TOP_INTEREST_REQUEST_SUCCESS,
    dataList,
  };
}

export function topInterestRequestError(
  error: any,
): actions.TopInterestRequestErrorAction {
  return {
    type: actions.TOP_INTEREST_REQUEST_ERROR,
    error,
  };
}

export function allOrdCondRequest(data: any): actions.AllOrdCondRequestAction {
  return {
    type: actions.ALL_ORD_COND_REQUESTING,
    data,
  };
}

export function allOrdCondSuccess(
  resData: any,
): actions.AllOrdCondSuccessAction {
  return {
    type: actions.ALL_ORD_COND_REQUEST_SUCCESS,
    resData,
  };
}

export function allOrdCondError(error: any): actions.AllOrdCondErrorAction {
  return {
    type: actions.ALL_ORD_COND_REQUEST_ERROR,
    error,
  };
}

export function stockByIdClear(): actions.StockByIdClearAction {
  return {
    type: actions.STOCK_BY_ID_CLEAR,
  };
}

export function advStockListDataClear(): actions.AdvStockListDataClearAction {
  return {
    type: actions.ADV_STOCK_LIST_DATA_CLEAR,
  };
}

export function stockByIdVer2Success(
  data: any,
): actions.StockByIdVer2SuccessAction {
  return {
    type: actions.STOCK_BY_ID_VER2_SUCCESS,
    data,
  };
}

export function advStockListDataRequest(
  data: any,
): actions.AdvStockListDataRequestAction {
  return {
    type: actions.ADV_STOCK_LIST_DATA_REQUESTING,
    data,
  };
}

export function advStockListDataSuccess(
  data: any,
): actions.AdvStockListDataSuccessAction {
  return {
    type: actions.ADV_STOCK_LIST_DATA_SUCCESS,
    data,
  };
}

export function advStockListDataError(
  error: any,
): actions.AdvStockListDataErrorAction {
  return {
    type: actions.ADV_STOCK_LIST_DATA_ERROR,
    error,
  };
}

export function advStockSingleRequest(
  data: any,
): actions.AdvStockSingleRequestAction {
  return {
    type: actions.ADV_STOCK_SINGLE_REQUESTING,
    data,
  };
}

export function advStockSingleSuccess(
  data: any,
): actions.AdvStockSingleSuccessAction {
  return {
    type: actions.ADV_STOCK_SINGLE_SUCCESS,
    data,
  };
}

export function advStockSingleError(
  error: any,
): actions.AdvStockSingleErrorAction {
  return {
    type: actions.ADV_STOCK_SINGLE_ERROR,
    error,
  };
}

export function advStockDataByIdRequest(
  data: any,
): actions.AdvStockDataByIdRequestAction {
  return {
    type: actions.ADV_STOCK_DATA_BYID_REQUESTING,
    data,
  };
}

export function advStockDataByIdSuccess(
  data: any,
): actions.AdvStockDataByIdSuccessAction {
  return {
    type: actions.ADV_STOCK_DATA_BYID_SUCCESS,
    data,
  };
}

export function advStockDataByIdError(
  error: any,
): actions.AdvStockDataByIdErrorAction {
  return {
    type: actions.ADV_STOCK_DATA_BYID_ERROR,
    error,
  };
}

export function vcbsFilterListRequest(): actions.VcbsFilterListRequestAction {
  return {
    type: actions.VCBS_FILTER_LIST_REQUESTING,
  };
}

export function vcbsFilterListSuccess(
  data: any,
): actions.VcbsFilterListSuccessAction {
  return {
    type: actions.VCBS_FILTER_LIST_SUCCESS,
    data,
  };
}

export function vcbsFilterListError(
  error: any,
): actions.VcbsFilterListErrorAction {
  return {
    type: actions.VCBS_FILTER_LIST_ERROR,
    error,
  };
}

export function vcbsFilterSnapshotRequest(
  data: any,
): actions.VcbsFilterSnapshotRequestAction {
  return {
    type: actions.VCBS_FILTER_SNAPSHOT_REQUESTING,
    data,
  };
}

export function vcbsFilterSnapshotSuccess(
  data: any,
): actions.VcbsFilterSnapshotSuccessAction {
  return {
    type: actions.VCBS_FILTER_SNAPSHOT_SUCCESS,
    data,
  };
}

export function vcbsFilterSnapshotError(
  error: any,
): actions.VcbsFilterSnapshotErrorAction {
  return {
    type: actions.VCBS_FILTER_SNAPSHOT_ERROR,
    error,
  };
}

/** lấy thông tin mã chứng khoán cho bảng giá nâng cao */
export function secInfoAdvRequest(): actions.AdvSecInfoRequestAction {
  return {
    type: actions.ADV_SEC_INFO_REQUESTING,
  };
}

export function secInfoAdvSuccess(data: any): actions.AdvSecInfoSuccessAction {
  return {
    type: actions.ADV_SEC_INFO_SUCCESS,
    data,
  };
}

export function secInfoAdvError(error: any): actions.AdvSecInfoErrorAction {
  return {
    type: actions.ADV_SEC_INFO_ERROR,
    error,
  };
}
