import * as actions from './actionType';

export interface AccInfoState {
  accInfo: any;
  accCashAppr: any;
  accBenUpd: any;
  accBenList: any;
  branchInfo: any;
}

const initialState = {
  accInfo: null,
  accCashAppr: null,
  accBenUpd: null,
  accBenList: null,
  branchInfo: null,
};

export default function mgBankReducer(
  state: AccInfoState = initialState,
  action: actions.AccountInfoAction,
): AccInfoState {
  switch (action.type) {
    // case actions.PAWN_CASH_REQUESTING:
    case actions.ACC_INFO_REQUESTING:
      return {
        ...state,
        accInfo: null,
      };

    case actions.ACC_INFO_SUCCESS:
      return {
        ...state,
        accInfo: action.resData,
      };

    case actions.ACC_CASH_APPR_REQUESTING:
    case actions.ACC_CASH_APPR_ERROR:
      return {
        ...state,
        accCashAppr: null,
      };

    case actions.ACC_CASH_APPR_SUCCESS:
      return {
        ...state,
        accCashAppr: action.resData,
      };

    case actions.ACC_BEN_UPD_REQUESTING:
    case actions.ACC_BEN_UPD_ERROR:
      return {
        ...state,
        accBenUpd: null,
      };

    case actions.ACC_BEN_UPD_SUCCESS:
      return {
        ...state,
        accBenUpd: action.resData,
      };

    case actions.ACC_BEN_LIST_ERROR:
      return {
        ...state,
        accBenList: null,
      };

    case actions.ACC_BEN_LIST_SUCCESS:
      return {
        ...state,
        accBenList: action.resData,
      };

    case actions.BRANCH_INFO_ERROR:
      return {
        ...state,
        branchInfo: null,
      };

    case actions.BRANCH_INFO_SUCCESS:
      return {
        ...state,
        branchInfo: action.resData,
      };

    default:
      return state;
  }
}
