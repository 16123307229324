import { handleApiErrors } from 'lib/api-error';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { Config } from 'interface';
import { AppState } from 'reducers';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import {
  loanShareRequestError,
  loanShareRequestSuccess,
  oddShareHisRequestError,
  oddShareHisRequestSuccess,
  oddShareIndayRequestError,
  oddShareIndayRequestSuccess,
  oddShareRequestError,
  oddShareRequestSuccess,
  orderHistoryRequestError,
  orderHistoryRequestSuccess,
  symbolIndayRequestError,
  symbolIndayRequestSuccess,
  tdCancelOrderError,
  tdCancelOrderSuccess,
  tdNewOrderError,
  tdNewOrderSuccess,
  tdOrderListError,
  tdOrderListSuccess,
  updOddShareRequestError,
  updOddShareRequestSuccess,
} from './actions';
import * as actions from './actionType';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

async function postRequestApi(data: Object, appUrl: string) {
  const url = `${appUrl}`;

  try {
    const response = axios.post(url, JSON.stringify(data));
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* tdNewOrderRequestFlow(
  action: actions.TdNewOrderRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.code < 1) {
      yield put({ type: 'API_ERR', msg: _res.re });
      throw Error(_res.re);
    }
    yield put(tdNewOrderSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(tdNewOrderError(error));
    }
  }
}

function* tdCancelOrderRequestFlow(
  action: actions.TdCancelOrderRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.code < 1) {
      yield put({ type: 'API_ERR', msg: _res.re });
      throw Error(_res.re);
    }
    yield put(tdCancelOrderSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(tdCancelOrderError(error));
    }
  }
}

function* tdOrderListRequestFlow(
  action: actions.TdOrderListRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.code < 1) {
      yield put({ type: 'API_ERR', msg: _res.re });
      throw Error(_res.re);
    }
    yield put(tdOrderListSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(tdOrderListError(error));
    }
  }
}

function* symbolIndayRequestFlow(
  action: actions.SymbolIndayRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(symbolIndayRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(symbolIndayRequestError(error));
  }
}

function* orderHistoryRequestFlow(
  action: actions.OrderHistoryRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(orderHistoryRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(orderHistoryRequestError(error));
  }
}

function* loanShareRequestFlow(action: actions.LoanShareRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(loanShareRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(loanShareRequestError(error));
  }
}

function* oddShareRequestFlow(action: actions.OddShareRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(oddShareRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(oddShareRequestError(error));
  }
}

function* oddShareIndayRequestFlow(
  action: actions.OddShareIndayRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(oddShareIndayRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(oddShareIndayRequestError(error));
  }
}

function* updOddShareRequestFlow(
  action: actions.UpdOddShareRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(updOddShareRequestSuccess(Math.random()));
  } catch (error) {
    // log(error)
    yield put(updOddShareRequestError(error));
  }
}

function* oddShareHisRequestFlow(
  action: actions.OddShareHisRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(postRequestApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(oddShareHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(oddShareHisRequestError(error));
  }
}

function* tradingWatcher() {
  yield all([
    takeLatest(actions.TD_NEW_ORDER_REQUESTING, tdNewOrderRequestFlow),
    takeLatest(actions.TD_CANCEL_ORDER_REQUESTING, tdCancelOrderRequestFlow),
    takeLatest(actions.TD_ORDER_LIST_REQUESTING, tdOrderListRequestFlow),

    takeLatest(actions.SYMBOL_INDAY_REQUESTING, symbolIndayRequestFlow),
    takeLatest(actions.ORDER_HISTORY_REQUESTING, orderHistoryRequestFlow),
    takeLatest(actions.LOAN_SHARE_REQUESTING, loanShareRequestFlow),

    takeLatest(actions.ODD_SHARE_REQUESTING, oddShareRequestFlow),
    takeLatest(actions.ODD_SHARE_INDAY_REQUESTING, oddShareIndayRequestFlow),
    takeLatest(actions.UPD_ODD_SHARE_REQUESTING, updOddShareRequestFlow),
    takeLatest(actions.ODD_SHARE_HIS_REQUESTING, oddShareHisRequestFlow),
  ]);
}

export default tradingWatcher;
