import { AppState } from 'reducers';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

const getTradingNewOrder = (state: AppState) => state.trading.tdNewOrder;
const getTradingCancelOrder = (state: AppState) => state.trading.tdCancelOrder;
const getTradingOrderList = (state: AppState) => state.trading.tdOrderList;

const getSymbolList = (state: AppState) => state.trading.symbolInday;
const getOrderHistory = (state: AppState) => state.trading.orderHis;
const getLoanShare = (state: AppState) => state.trading.loanShare;

const getOddShare = (state: AppState) => {
  const { oddShare } = state.trading;

  if (!oddShare || !oddShare.length) return [];

  return _.filter(oddShare, (o) => o.C_SHARE_ODD_LOT > 0);
};
const getOddShareInday = (state: AppState) => state.trading.oddShareInday;
const getOddShareHistory = (state: AppState) => state.trading.oddShareHis;
const getUpdateOddShare = (state: AppState) => state.trading.updOddShare;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetTradingNewOrder = () =>
  createSelector(getTradingNewOrder, (tdNewOrder) => tdNewOrder);
export const makeGetTradingCancelOrder = () =>
  createSelector(getTradingCancelOrder, (tdCancelOrder) => tdCancelOrder);
export const makeGetTradingOrderList = () =>
  createSelector(getTradingOrderList, (tdOrderList) => tdOrderList);
export const makeGetSymbolList = () =>
  createSelector([getSymbolList], (symbolInday) => symbolInday);

export const makeGetOrderHistory = () =>
  createSelector([getOrderHistory], (orderHis) => orderHis);

export const makeGetLoanShare = () =>
  createSelector([getLoanShare], (loanShare) => loanShare);

export const makeGetOddShare = () =>
  createSelector([getOddShare], (oddShare) => oddShare);

export const makeGetOddShareInday = () =>
  createSelector([getOddShareInday], (oddShareInday) => oddShareInday);

export const makeGetOddShareHistory = () =>
  createSelector([getOddShareHistory], (oddShareHis) => oddShareHis);

export const makeGetUpdateOddShare = () =>
  createSelector([getUpdateOddShare], (updOddShare) => updOddShare);
