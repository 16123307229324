import * as actions from './actionType';

export interface RightState {
  rightUnExec: any;
  updUnExec: any;
  listRightBuy: any;
  rightBuyHis: any;
  rightAllStt: any;
}

const initialState = {
  rightUnExec: null,
  updUnExec: null,
  listRightBuy: null,
  rightBuyHis: null,
  rightAllStt: null,
};

export default function rightReducer(
  state: RightState = initialState,
  action: actions.RightAction,
): RightState {
  switch (action.type) {
    case actions.RIGHT_UN_EXEC_REQUESTING:
    case actions.RIGHT_UN_EXEC_REQUEST_ERROR:
      return {
        ...state,
        rightUnExec: null,
      };
    case actions.RIGHT_UN_EXEC_REQUEST_SUCCESS:
      return {
        ...state,
        rightUnExec: action.rightUnExec,
      };

    case actions.UPDATE_UN_EXEC_REQUESTING:
    case actions.UPDATE_UN_EXEC_REQUEST_ERROR:
      return {
        ...state,
        updUnExec: null,
      };
    case actions.UPDATE_UN_EXEC_REQUEST_SUCCESS:
      return {
        ...state,
        updUnExec: action.updUnExec,
      };

    case actions.RIGHT_BUY_STT_REQUESTING:
    case actions.RIGHT_BUY_STT_REQUEST_ERROR:
      return {
        ...state,
        listRightBuy: null,
      };
    case actions.RIGHT_BUY_STT_REQUEST_SUCCESS:
      return {
        ...state,
        listRightBuy: action.listRightBuy,
      };

    case actions.RIGHT_BUY_HIS_REQUESTING:
    case actions.RIGHT_BUY_HIS_REQUEST_ERROR:
      return {
        ...state,
        rightBuyHis: null,
      };
    case actions.RIGHT_BUY_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        rightBuyHis: action.rightBuyHis,
      };

    case actions.RIGHT_ALL_STATUS_REQUESTING:
    case actions.RIGHT_ALL_STATUS_ERROR:
      return {
        ...state,
        rightAllStt: null,
      };
    case actions.RIGHT_ALL_STATUS_SUCCESS:
      return {
        ...state,
        rightAllStt: action.resData,
      };

    default:
      return state;
  }
}
