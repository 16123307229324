export const START_CHANNEL = 'START_CHANNEL';
export interface StartChannelAction {
  type: typeof START_CHANNEL;
}

export const SOCKET_READY = 'socket/READY';
export interface SocketReadyAction {
  type: typeof SOCKET_READY;
}

export const SERVER_ON = 'socket/SERVER_ON';
export interface ServerOnAction {
  type: typeof SERVER_ON;
}

export const SERVER_OFF = 'socket/SERVER_OFF';
export interface ServerOffAction {
  type: typeof SERVER_OFF;
}

export const STOP_CHANNEL = 'socket/STOP_CHANNEL';
export interface StopChannelAction {
  type: typeof STOP_CHANNEL;
}

export const SET_REG_SYMBOL = 'socket/REG_SYMBOL';
export interface SetRegSymbolAction {
  type: typeof SET_REG_SYMBOL;
}

export const UNSET_REG_SYMBOL = 'socket/UN_REG_SYMBOL';
export interface UnRegSymbolAction {
  type: typeof UNSET_REG_SYMBOL;
}

export const REGISTER_SOCKET_DATA = 'socket/REG_DATA';
export interface RegisterSocketDataAction {
  type: typeof REGISTER_SOCKET_DATA;
}

export const LEAVE_SOCKET_DATA = 'socket/LEAVE_DATA';
export interface LeaveSocketDataAction {
  type: typeof LEAVE_SOCKET_DATA;
}

export const RE_REGISTER = 'socket/RE_REGISTER';
export interface ReRegisterAction {
  type: typeof RE_REGISTER;
}

export const RECEIVE_1101 = 'socket/RECEIVE_1101';
export interface Receive1101Action {
  type: typeof RECEIVE_1101;
}

export const RECEIVE_3220 = 'socket/RECEIVE_3220';
export interface Receive3220Action {
  type: typeof RECEIVE_3220;
}

export const RECEIVE_3210 = 'socket/RECEIVE_3210';
export interface Receive3210Action {
  type: typeof RECEIVE_3210;
}

export type SocketAction =
  | StartChannelAction
  | SocketReadyAction
  | ServerOnAction
  | ServerOffAction
  | StopChannelAction
  | SetRegSymbolAction
  | UnRegSymbolAction
  | RegisterSocketDataAction
  | LeaveSocketDataAction
  | ReRegisterAction
  | Receive1101Action
  | Receive3220Action
  | Receive3210Action;
