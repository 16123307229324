/* eslint-disable react-hooks/exhaustive-deps */
//#region import
import {
  cancelOrderClear,
  newOrderClear,
  newOrderRequest,
  newOrderRequestSuccess,
  sStockInfoRequest,
  sStockInfoRequestSuccess,
  stockBalanceRequest,
  stockBalanceRequestSuccess,
} from 'containers/privData/actions';
import RenderSelectDate from 'helper/input/renderDatePicker';
import {
  makeGetAllStock,
  makeGetDefaultAccount,
  makeGetNewOrder,
  makeGetSStockInfo,
  makeGetStockBalance,
  makeGetSymbolBySymbolDetail,
  makeGetToken,
} from 'lib/selector';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  untouch,
} from 'redux-form';
import { required } from 'utils/validation';

import { IoClose } from 'react-icons/io5';

import {
  defaultAccountSet,
  setDblPrice,
  setToast,
} from 'containers/client/actions';
import * as _ from 'lodash';
import {
  _convertTradeTp,
  checkSum,
  formatDate,
  getMsgByErrorCode,
  getRandom,
  getRandomGroup,
  numberFormat,
  StringToDouble,
  StringToInt,
} from 'utils';

import FormConfirmOrder from 'components/modal/order/confirmOrder';
import RenderInputAdvanceTradePrice from 'helper/input/renderNewPriceAdvance';
import RenderInputAdvanceTradeVol from 'helper/input/renderNewVolumnAdvance';
import SymbolAdvanceOrder from 'helper/input/symbolAdvanceOrder';
import {
  makeGetAccountPortfolio,
  makeGetCancelOrder,
  makeGetEditOrder,
  makeGetIndexList,
  makeGetUpdatePortfolio,
} from 'lib/selector';

import { IAdvancedOrderForm } from 'interface';
import {
  IAccPortfolio,
  IAllStock,
  IIndexList,
  IStockBalance,
  IStockDetailOrder,
  IStockInfo,
} from 'interface/response';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppState } from 'reducers';
import { useConfig } from 'shared/configContext';
import { BtnSubmit } from 'utils/styledUtils';
import AdvanceBuocGia from './layout/panelBuocGia';
import CustomTypeOrder from './layout/typeOrder';
//#endregion

function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  token?: any;
  onClose?: any;
  defAccount?: string;
  hasSymbolValue?: string;

  typeTrade?: string;
  orderAccount?: string;
  orderSymbol?: string;
  orderVolume?: string;
  orderPrice?: string;
  orderBuyVolume?: string;
  orderBuyPrice?: string;
  orderSellVolume?: string;
  orderSellPrice?: string;
  orderSide?: string;
  stockDetail?: IStockDetailOrder | undefined;
  updPortfolio?: any;
  indexList?: IIndexList[];
  stockBalance?: IStockBalance | undefined;
  symbolInfo?: IStockInfo;
  dblPri?: any;
  newOrder?: any;
  editOrder?: any;
  cancelOrder?: any;
  // listAccount?: IListAcc[];
  accPortfolio?: IAccPortfolio[];
  allStock?: IAllStock[];
}

const FormAdvancedOrder: React.FunctionComponent<
  InjectedFormProps<IAdvancedOrderForm> & Props
> = (props) => {
  const abortController = new AbortController();
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [txtPriceErr, setTxtPriceErr] = useState<string>('');
  const [txtVolErr, setTxtVolErr] = useState<string>('');
  const [txtSellPriceErr, setTxtSellPriceErr] = useState<string>('');
  const [txtSellVolErr, setTxtSellVolErr] = useState<string>('');
  const [isChangePrice, setIsChangePrice] = useState<boolean>(false);
  const [isChangeVol, setIsChangeVol] = useState<boolean>(false);
  const [isChangeSellPrice, setIsChangeSellPrice] = useState<boolean>(false);
  const [isChangeSellVol, setIsChangeSellVol] = useState<boolean>(false);
  const [indexs, setIndexs] = useState<IIndexList[]>([]);

  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [stockName, setStockName] = useState<IAllStock | null>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { config } = useConfig();

  const {
    token,
    onClose,
    defAccount,
    typeTrade,
    orderAccount,
    orderSymbol,
    orderVolume,
    orderPrice,
    orderBuyVolume,
    orderBuyPrice,
    orderSellVolume,
    orderSellPrice,
    stockDetail,
    newOrder,
    editOrder,
    cancelOrder,
    dblPri,

    indexList,
    handleSubmit,
    hasSymbolValue,
    stockBalance,
    // listAccount,
    allStock,
  } = props;

  const preNewOrder = usePrevious(newOrder);
  const preEditOrder = usePrevious(editOrder);
  const preCancelOrder = usePrevious(cancelOrder);
  const preOrderSymbol = usePrevious(orderSymbol);
  const preOrderBuyPrice = usePrevious(orderBuyPrice);
  const preDblPri = usePrevious(dblPri);
  const preOrderAccount = usePrevious(orderAccount);
  const preDefAccount = usePrevious(defAccount);
  const preStockDetail = usePrevious(stockDetail);
  const prevIndexList = usePrevious(indexList);
  const prevTypeTrade = usePrevious(typeTrade);

  const formRef = useRef<any>();

  //#region effect
  useEffect(() => {
    if (!dblPri) {
      dispatch(change('formtradeNewOrder', 'orderSymbol', 'ACB'));
    }
    // lệnh thường
    dispatch(change('formtradeNewOrder', 'typeTrade', '1'));
    dispatch(change('formtradeNewOrder', 'orderSide', 'B'));
    dispatch(change('formtradeNewOrder', 'orderVolume', '100'));
    dispatch(change('formtradeNewOrder', 'orderPrice', ''));

    // lệnh điều kiện
    dispatch(change('formtradeNewOrder', 'orderBuyVolume', '100'));
    dispatch(change('formtradeNewOrder', 'orderBuyPrice', ''));
    dispatch(change('formtradeNewOrder', 'orderBuyCond', 'SOL'));
    dispatch(change('formtradeNewOrder', 'orderSellVolume', '100'));
    dispatch(change('formtradeNewOrder', 'orderSellPrice', ''));
    dispatch(change('formtradeNewOrder', 'orderSellCond', 'SOU'));
    dispatch(
      change(
        'formtradeNewOrder',
        'orderExpireDate',
        formatDate(new Date(), '/', 'dmy'),
      ),
    );

    if (defAccount) {
      dispatch(change('formtradeNewOrder', 'orderAccount', defAccount));
    }

    return () => {
      // clean
      dispatch(sStockInfoRequestSuccess(null));
      dispatch(stockBalanceRequestSuccess(null));
    };
  }, []);

  // useEffect(() => {
  //   if (typeTrade && !_.isEqual(typeTrade, prevTypeTrade)) {
  //     clearData();
  //   }
  // }, [typeTrade]);

  useEffect(() => {
    if (indexList && !_.isEqual(indexList, prevIndexList)) {
      setIndexs(indexList);
    }
  }, [indexList]);

  useEffect(() => {
    if (orderSymbol && stockDetail && !_.isEqual(stockDetail, preStockDetail)) {
      console.log(dblPri, stockDetail)
      dispatch(
        change(
          'formtradeNewOrder',
          'orderBuyPrice',
          stockDetail?.lastPrice + '',
        ),
      );
      dispatch(
        change(
          'formtradeNewOrder',
          'orderSellPrice',
          stockDetail?.lastPrice + '',
        ),
      );
      if (!dblPri || !dblPri?.price) {
        dispatch(
          change('formtradeNewOrder', 'orderPrice', stockDetail?.lastPrice + ''),
        );
      } else {
        dispatch(change('formtradeNewOrder', 'orderPrice', dblPri.price + ''));
      }

      dispatch(setDblPrice(null));

      const _stock = _.find(
        allStock,
        (o: IAllStock) => o.stock_code === stockDetail.sym,
      );
      _stock && setStockName(_stock);
    }
  }, [stockDetail]);

  useEffect(() => {
    if (dblPri && !_.isEqual(dblPri, preDblPri)) {
      console.log(dblPri);
      dispatch(change('formtradeNewOrder', 'orderSymbol', dblPri.symbol));
      dispatch(change('formtradeNewOrder', 'orderPrice', dblPri.price + ''));
      dispatch(
        change(
          'formtradeNewOrder',
          'orderSide',
          dblPri.side === 'BUY' ? 'B' : 'S',
        ),
      );

      // dispatch(setDblPrice(null));
    }
  }, [dblPri]);

  useEffect(() => {
    if (defAccount && !_.isEqual(defAccount, preDefAccount)) {
      if (!_.isEqual(defAccount, orderAccount)) {
        dispatch(change('formtradeNewOrder', 'orderAccount', defAccount));
      }
    }
  }, [defAccount]);

  useEffect(() => {
    if (orderAccount && !_.isEqual(orderAccount, preOrderAccount)) {
      dispatch(defaultAccountSet(orderAccount));
    }
    if (
      (orderAccount && !_.isEqual(orderAccount, preOrderAccount)) ||
      !_.isEqual(orderBuyPrice, preOrderBuyPrice) ||
      (!_.isEqual(orderSymbol, preOrderSymbol) &&
        (!orderSymbol ||
          orderSymbol?.length === 3 ||
          orderSymbol?.length === 8))
    ) {
      dispatch(defaultAccountSet(orderAccount));
      getStockBalance();
    }
  }, [orderAccount, orderBuyPrice, orderSymbol]);

  useEffect(() => {
    if (
      newOrder &&
      newOrder.newOrderSuccess &&
      !_.isEqual(newOrder, preNewOrder)
    ) {
      _handleCloseModal();
      dispatch(newOrderClear());

      clearData();

      _handleToast(t('trading.order.success-order'), 'success');

      // dispatch(sStockInfoRequestSuccess(null));

      getStockBalance();
    }
  }, [newOrder]);

  useEffect(() => {
    if (
      (cancelOrder &&
        cancelOrder.cancelOrderSuccess &&
        !_.isEqual(cancelOrder, preCancelOrder)) ||
      (editOrder &&
        editOrder.editOrderSuccess &&
        !_.isEqual(editOrder, preEditOrder))
    ) {
      dispatch(cancelOrderClear());
      getStockBalance();
    }
  }, [cancelOrder, editOrder]);

  useEffect(() => {
    if (
      !orderSymbol &&
      preOrderSymbol &&
      !_.isEqual(orderSymbol, preOrderSymbol)
    ) {
      console.log('Xóa mã');
      dispatch(sStockInfoRequestSuccess(null));
      dispatch(stockBalanceRequestSuccess(null));
      dispatch(change('formtradeNewOrder', 'orderPrice', ''));
      dispatch(change('formtradeNewOrder', 'orderBuyPrice', ''));
      dispatch(change('formtradeNewOrder', 'orderSellPrice', ''));
      // if (regSym) leaveSymbol(regSym);
    }
    if (
      orderSymbol &&
      !_.isEqual(orderSymbol, preOrderSymbol) &&
      orderSymbol.length < 3
    ) {
      console.log('clear symbol detail');
      // clear symbol detail
      dispatch(sStockInfoRequestSuccess(null));
      dispatch(stockBalanceRequestSuccess(null));

      clearData();

      // if (regSym) leaveSymbol(regSym);
    }
    if (
      orderSymbol &&
      !_.isEqual(orderSymbol, preOrderSymbol) &&
      (orderSymbol.trim().length === 8 || orderSymbol.trim().length === 3)
    ) {
      // get Purchase power
      getStockBalance();
      getSymbolInfo();
    }
  }, [orderSymbol]);

  useEffect(() => {
    setTxtPriceErr('');
    if (!orderBuyPrice) setIsChangePrice(false);
    else setIsChangePrice(true);
  }, [orderBuyPrice]);

  useEffect(() => {
    setTxtVolErr('');
    if (!orderBuyVolume) setIsChangeVol(false);
    else setIsChangeVol(true);
  }, [orderBuyVolume]);

  useEffect(() => {
    setTxtSellPriceErr('');
    if (!orderBuyPrice) setIsChangeSellPrice(false);
    else setIsChangeSellPrice(true);
  }, [orderSellPrice]);

  useEffect(() => {
    setTxtSellVolErr('');
    if (!orderBuyVolume) setIsChangeSellVol(false);
    else setIsChangeSellVol(true);
  }, [orderSellVolume]);

  function clearData() {
    dispatch(change('formtradeNewOrder', 'orderPrice', ''));
    dispatch(change('formtradeNewOrder', 'orderVolume', '100'));

    dispatch(change('formtradeNewOrder', 'orderBuyPrice', ''));
    dispatch(change('formtradeNewOrder', 'orderBuyVolume', '100'));
    dispatch(change('formtradeNewOrder', 'orderSellPrice', ''));
    dispatch(change('formtradeNewOrder', 'orderSellVolume', '100'));
    dispatch(
      change(
        'formtradeNewOrder',
        'orderExpireDate',
        formatDate(new Date(), '/', 'dmy'),
      ),
    );

    dispatch(untouch('formtradeNewOrder', 'orderPrice'));
    dispatch(untouch('formtradeNewOrder', 'orderVolume'));
    dispatch(untouch('formtradeNewOrder', 'orderBuyPrice'));
    dispatch(untouch('formtradeNewOrder', 'orderBuyVolume'));
    dispatch(untouch('formtradeNewOrder', 'orderSellPrice'));
    dispatch(untouch('formtradeNewOrder', 'orderSellVolume'));

    setIsChangeVol(false);
    setIsChangePrice(false);
    setIsChangeSellVol(false);
    setIsChangeSellPrice(false);
  }

  //#endregion

  function getStockBalance() {
    if (!orderSymbol || orderSymbol?.length < 3 || !orderAccount) return;
    const cmdPortFolio = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.sCashBalance',
        p1: orderAccount,
        p2: orderSymbol ? orderSymbol.toUpperCase() : '',
        p3: (orderBuyPrice ?? '') + '',
        p4: 'B',
      },
    };

    dispatch(stockBalanceRequest(cmdPortFolio));
  }

  function getSymbolInfo() {
    if (!orderSymbol || !orderAccount) return;

    const cmdPortFolio = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.sStockInfo',
        p1: orderAccount,
        p2: orderSymbol.toUpperCase(),
      },
    };

    dispatch(sStockInfoRequest(cmdPortFolio));
  }

  const _handleChangeBuyPrice = (price: number | string) => {
    setTxtPriceErr('');
    dispatch(change('formtradeNewOrder', 'orderBuyPrice', price + ''));

    // tinh lai suc mua
    getStockBalance();
  };

  const _handleChangeBuyVol = (vol: number) => {
    setTxtVolErr('');
    dispatch(change('formtradeNewOrder', 'orderBuyVolume', vol));
  };

  const _handleChangeSellPrice = (price: number | string) => {
    setTxtSellPriceErr('');
    dispatch(change('formtradeNewOrder', 'orderSellPrice', price + ''));
  };

  const _handleChangeSellVol = (vol: number) => {
    setTxtSellVolErr('');
    dispatch(change('formtradeNewOrder', 'orderSellVolume', vol));
  };

  const _handleChangePrice = (price: number | string) => {
    console.log(price);
    setTxtPriceErr('');
    dispatch(change('formtradeNewOrder', 'orderPrice', price + ''));

    // tinh lai suc mua
    getStockBalance();
  };

  const _handleChangeVol = (vol: number) => {
    setTxtVolErr('');
    dispatch(change('formtradeNewOrder', 'orderVolume', vol));
  };

  //#region validate
  function _handleCheckPrice() {
    setTxtPriceErr('');
    if (!orderBuyPrice) {
      return;
    }

    const _c = Math.round((stockDetail?.c || 0) * 1000);
    const _f = Math.round((stockDetail?.f || 0) * 1000);

    const orderTradeTp = _convertTradeTp(orderBuyPrice);
    // valid
    if (orderTradeTp === '00') {
      setTxtPriceErr(t('trading.order.price-not-match'));
      return;
    } else if (
      stockDetail?.mc === 'HO' &&
      ['05', '06', '07', '08'].indexOf(orderTradeTp) > -1
    ) {
      // hsx khong duoc chon gia MTL/MOK/MAK/PLO
      setTxtPriceErr(t('trading.order.hsx-price'));
      return;
    } else if (stockDetail?.mc === 'HA' && orderTradeTp === '04') {
      // hnx khong duoc chon gia MP
      setTxtPriceErr(t('trading.order.hnx-price'));
      return;
    } else if (stockDetail?.mc === 'UP' && orderTradeTp !== '01') {
      // upcom khong duoc chon gia thị trường
      setTxtPriceErr(t('trading.order.upcom-price'));
      return;
    }

    let _validPrice = true;

    if (orderTradeTp === '01') {
      // nhap gia double
      const vPrice = StringToDouble(orderBuyPrice);
      if (Math.round(vPrice * 1000) > _c) {
        setTxtPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }
      if (Math.round(vPrice * 1000) < _f) {
        setTxtPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }

      if (stockDetail?.mc === 'HO') {
        // hsx
        _validPrice = _validPriceHose(vPrice);
      } else {
        // mc = 02: hnx, mc = 03: upcom
        _validPrice = _validPriceHnx(vPrice);
      }
    }

    if (_validPrice) {
      // tinh lai suc mua
      getStockBalance();
    }
  }

  function _handleCheckSellPrice() {
    setTxtSellPriceErr('');
    if (!orderSellPrice) {
      return;
    }

    const _c = Math.round((stockDetail?.c || 0) * 1000);
    const _f = Math.round((stockDetail?.f || 0) * 1000);

    const orderTradeTp = _convertTradeTp(orderSellPrice);
    // valid
    if (orderTradeTp === '00') {
      setTxtSellPriceErr(t('trading.order.price-not-match'));
      return;
    } else if (
      stockDetail?.mc === 'HO' &&
      ['05', '06', '07', '08'].indexOf(orderTradeTp) > -1
    ) {
      // hsx khong duoc chon gia MTL/MOK/MAK/PLO
      setTxtSellPriceErr(t('trading.order.hsx-price'));
      return;
    } else if (stockDetail?.mc === 'HA' && orderTradeTp === '04') {
      // hnx khong duoc chon gia MP
      setTxtSellPriceErr(t('trading.order.hnx-price'));
      return;
    } else if (stockDetail?.mc === 'UP' && orderTradeTp !== '01') {
      // upcom khong duoc chon gia thị trường
      setTxtSellPriceErr(t('trading.order.upcom-price'));
      return;
    }

    if (orderTradeTp === '01') {
      // nhap gia double
      const vPrice = StringToDouble(orderSellPrice);
      if (Math.round(vPrice * 1000) > _c) {
        setTxtSellPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }
      if (Math.round(vPrice * 1000) < _f) {
        setTxtSellPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }

      if (stockDetail?.mc === 'HO') {
        // hsx
        _validPriceHose(vPrice, 'S');
      } else {
        // mc = 02: hnx, mc = 03: upcom
        _validPriceHnx(vPrice, 'S');
      }
    }
  }

  function _handleCheckVol() {
    setTxtVolErr('');
    if (!orderBuyVolume) {
      return;
    }

    const vVolume = StringToInt(orderBuyVolume);
    if (stockDetail?.mc === 'HO') {
      _validVolHose(vVolume);
    } else {
      _validVolHnx(vVolume);

      const orderTradeTp = _convertTradeTp(orderBuyPrice || '');
      if (orderBuyPrice && orderTradeTp !== '01') {
        setTxtPriceErr('Lệnh lô lẻ chỉ đặt giá LO');
        return;
      }
    }
  }

  function _handleCheckSellVol() {
    setTxtSellVolErr('');
    if (!orderSellVolume) {
      return;
    }

    const vVolume = StringToInt(orderSellVolume);
    if (stockDetail?.mc === 'HO') {
      _validVolHose(vVolume, 'S');
    } else {
      _validVolHnx(vVolume, 'S');

      const orderTradeTp = _convertTradeTp(orderSellPrice || '');
      if (orderSellPrice && orderTradeTp !== '01') {
        setTxtSellPriceErr('Lệnh lô lẻ chỉ đặt giá LO');
        return;
      }
    }
  }

  const _validPriceHose = (price: number, side = 'B') => {
    let step = 10;
    if (price < 10) step = 10;
    else if (price >= 10 && price < 50) step = 50;
    else step = 100;
    if (Math.round((price * 1000) % step) % 10 !== 0) {
      if (side === 'B') setTxtPriceErr(t('trading.order.price-not-match'));
      else setTxtSellPriceErr(t('trading.order.price-not-match'));

      return false;
    }
    return true;
  };

  const _validPriceHnx = (price: number, side = 'B') => {
    let step = 100;
    // log(price * 1000, (price * 1000) % step)
    if (Math.round((price * 1000) % step) % 10 !== 0) {
      if (side === 'B') setTxtPriceErr(t('trading.order.price-not-match'));
      else setTxtSellPriceErr(t('trading.order.price-not-match'));
      return false;
    }
    return true;
  };

  const _validVolHose = (vol: number, side = 'B') => {
    let step = 100;
    if (vol < 1 || vol > 500000) {
      if (side === 'B') setTxtVolErr(t('trading.order.vol-not-match'));
      else setTxtSellVolErr(t('trading.order.vol-not-match'));
      return false;
    }
    if (vol >= 100 && vol % step !== 0) {
      if (side === 'B') setTxtVolErr(t('trading.order.vol-not-match'));
      else setTxtSellVolErr(t('trading.order.vol-not-match'));
      return false;
    }

    return true;
  };

  const _validVolHnx = (vol: number, side = 'B') => {
    let step = 100;
    if (vol === 0) {
      if (side === 'B') setTxtVolErr(t('trading.order.vol-not-match'));
      else setTxtSellVolErr(t('trading.order.vol-not-match'));
      return false;
    }
    if (vol >= 100 && vol % step !== 0) {
      if (side === 'B') setTxtVolErr(t('trading.order.vol-not-match'));
      else setTxtSellVolErr(t('trading.order.vol-not-match'));
      return false;
    }

    return true;
  };

  //#endregion

  function submitForm(values: IAdvancedOrderForm) {
    // log(values);
    if (!stockDetail) {
      _handleToast(t('trading.order.symbol-not-match'), 'warning');
      return;
    }

    if (values.typeTrade === '1') {
      // lệnh thường
      processNormalOrder(values);
    } else {
      // lệnh điều kiện
      processCondOrder(values);
    }
  }

  function processNormalOrder(values: IAdvancedOrderForm) {
    const { orderSymbol, orderVolume, orderPrice, orderSide } = values;

    const _c = Math.round((stockDetail?.c || 0) * 1000);
    const _f = Math.round((stockDetail?.f || 0) * 1000);

    const orderTradeTp = _convertTradeTp(orderPrice);
    // valid
    if (orderTradeTp === '00') {
      setTxtPriceErr(t('trading.order.price-not-match'));
      return;
    } else if (
      stockDetail?.mc === 'HO' &&
      ['05', '06', '07', '08'].indexOf(orderTradeTp) > -1
    ) {
      // hsx khong duoc chon gia MTL/MOK/MAK/PLO
      setTxtPriceErr(t('trading.order.hsx-price'));
      return;
    } else if (stockDetail?.mc === 'HA' && orderTradeTp === '04') {
      // hnx khong duoc chon gia MP
      setTxtPriceErr(t('trading.order.hnx-price'));
      return;
    } else if (stockDetail?.mc === 'UP' && orderTradeTp !== '01') {
      // upcom khong duoc chon gia thị trường
      setTxtPriceErr(t('trading.order.upcom-price'));
      return;
    }

    let _validPrice = true,
      _validVol = true;

    if (orderTradeTp === '01') {
      // nhap gia double
      const vPrice = StringToDouble(orderPrice);
      if (Math.round(vPrice * 1000) > _c) {
        setTxtPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }
      if (Math.round(vPrice * 1000) < _f) {
        setTxtPriceErr('Giá nằm ngoài biên độ Trần, Sàn');
        return;
      }

      if (stockDetail?.mc === 'HO') {
        // hsx
        _validPrice = _validPriceHose(vPrice);
      } else {
        // mc = 02: hnx, mc = 03: upcom
        _validPrice = _validPriceHnx(vPrice);
      }
    }

    const vVolume = StringToInt(orderVolume);
    if (stockDetail?.mc === 'HO') {
      _validVol = _validVolHose(vVolume);
    } else {
      _validVol = _validVolHnx(vVolume);
      if (vVolume < 100 && _validVol) {
        if (orderTradeTp !== '01') {
          setTxtPriceErr('Lệnh lô lẻ chỉ đặt giá LO');
          return;
        }
      }
    }

    if (_validPrice && _validVol) {
      const pData: any = {
        type: 'string',
        cmd: 'Web.newOrder',
        account: orderAccount,
        side: orderSide,
        symbol: orderSymbol.toUpperCase(),
        volume: vVolume,
        price: orderPrice,
        advance: '',
        refId: token.user + '.H.' + getRandom(),
        orderType: '1',
        pin: '',
      };

      const params = {
        group: 'O',
        user: token.user,
        session: token.sid,
        checksum: checkSum(token.sid, pData),
        data: pData,
      };

      setOrder(params);
      setShowModal(true);
    }
  }

  function processCondOrder(values: IAdvancedOrderForm) {
    const {
      orderSymbol,
      orderBuyVolume,
      orderBuyPrice,
      orderBuyCond,
      orderSellPrice,
      orderSellVolume,
      orderSellCond,
      orderExpireDate,
    } = values;
    let _validBuy = false,
      _validSell = false;
    const _group = getRandomGroup();

    let _orders = [];

    if (orderBuyPrice && orderBuyVolume) {
      // validate order cond buy
      _validBuy = validateBySide(orderBuyPrice, orderBuyVolume, 'B');
      if (_validBuy) {
        const vVolume = StringToInt(orderBuyVolume);
        const _orderType =
          'ADV=' +
          orderExpireDate +
          orderBuyCond +
          '=' +
          orderBuyPrice +
          '=' +
          _group;

        const pData: any = {
          type: 'string',
          cmd: 'Web.newOrder',
          account: orderAccount,
          side: 'B',
          symbol: orderSymbol.toUpperCase(),
          volume: vVolume,
          price: orderBuyPrice,
          advance: '',
          refId: token.user + '.H.' + getRandom(),
          orderType: _orderType,
          pin: '',
        };

        const params = {
          group: 'O',
          user: token.user,
          session: token.sid,
          checksum: checkSum(token.sid, pData),
          data: pData,
        };

        _orders.push(params);
      }
    }

    if (orderSellPrice && orderSellVolume) {
      // validate order cond sell
      _validSell = validateBySide(orderSellPrice, orderSellVolume, 'S');
      if (_validSell) {
        const vVolume = StringToInt(orderSellVolume);
        const _orderType =
          'ADV=' +
          orderExpireDate +
          orderSellCond +
          '=' +
          orderSellPrice +
          '=' +
          _group;

        const pData: any = {
          type: 'string',
          cmd: 'Web.newOrder',
          account: orderAccount,
          side: 'S',
          symbol: orderSymbol.toUpperCase(),
          volume: vVolume,
          price: orderSellPrice,
          advance: '',
          refId: token.user + '.H.' + getRandom(),
          orderType: _orderType,
          pin: '',
        };

        const params = {
          group: 'O',
          user: token.user,
          session: token.sid,
          checksum: checkSum(token.sid, pData),
          data: pData,
        };

        _orders.push(params);
      }
    }

    if (!!_orders.length) {
      setOrder(_orders);
      setShowModal(true);
    }
  }

  function validateBySide(
    orderPrice: string,
    orderVolume: string,
    side: string,
  ): boolean {
    const orderTradeTp = _convertTradeTp(orderPrice);
    // valid
    if (orderTradeTp !== '01') {
      if (side === 'B') setTxtPriceErr(t('trading.order.price-not-match'));
      else setTxtSellPriceErr(t('trading.order.price-not-match'));
      return false;
    }

    let _validPrice = true,
      _validVol = true;

    if (orderTradeTp === '01') {
      // nhap gia double
      const vPrice = StringToDouble(orderPrice);
      if (stockDetail?.mc === 'HO') {
        // hsx
        _validPrice = _validPriceHose(vPrice, side);
      } else {
        // mc = 02: hnx, mc = 03: upcom
        _validPrice = _validPriceHnx(vPrice, side);
      }
    }

    const vVolume = StringToInt(orderVolume);
    if (stockDetail?.mc === 'HO') {
      _validVol = _validVolHose(vVolume, side);
    } else {
      _validVol = _validVolHnx(vVolume, side);
      if (vVolume < 100 && _validVol) {
        if (orderTradeTp !== '01') {
          if (side === 'B') setTxtPriceErr('Lệnh lô lẻ chỉ đặt giá LO');
          else setTxtSellPriceErr('Lệnh lô lẻ chỉ đặt giá LO');
          return false;
        }
      }
    }

    return _validVol && _validPrice;
  }

  function _handleCloseModal() {
    setShowModal(false);
    setOrder(null);
  }

  function asyncIterator(_array: any[]) {
    return {
      next: function () {
        if (_array.length) {
          return {
            value: _array.shift(),
            done: false,
          };
        } else {
          return {
            done: true,
          };
        }
      },
    };
  }

  async function importItems(_ite: {
    next: () =>
      | { value: any; done: boolean }
      | { done: boolean; value?: undefined };
  }) {
    const _iterator = _ite.next();
    if (_iterator.done) {
      dispatch(newOrderRequestSuccess(Math.random()));
    } else {
      acceptImport(_iterator.value).then((response) => {
        if (response.rc < 1) {
          _handleToast(
            'Lỗi đặt lệnh: ' + getMsgByErrorCode(response.rc),
            'error',
          );
        } else {
          importItems(_ite);
        }
      });
    }
  }

  function acceptImport(data: any) {
    const url = `${config?.AuthUrl || ''}/TraditionalService`;

    const request = fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      signal: abortController.signal,
    });
    return request
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => {
        throw error;
      });
  }

  function _handleSubmitOrder(resData: any) {
    if (typeTrade === '2') {
      // console.log('resdata', resData);
      // lệnh điều kiện
      var _ite = asyncIterator(resData);
      importItems(_ite);
    } else {
      // lệnh thường
      dispatch(newOrderRequest(resData));
    }
    // dispatch(newOrderRequest(resData));
  }

  function _handleKeydownSym(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const _tag = document.getElementsByName('orderBuyPrice');
      if (_tag && !!_tag.length) {
        // log(_tag[0])
        _tag[0].focus();
      }
    }
  }

  function _handleToast(msg: string, type = 'info') {
    // log(msg)
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      type,
    };
    dispatch(setToast(toastMsg));
  }

  return (
    <>
      {
        isMobile && <form
          ref={formRef}
          onSubmit={handleSubmit(submitForm)}
          className="form-trade h-[calc(100vh-120px)] sm:h-full flex flex-col overflow-auto sm:overflow-hidden select-none"
        >
          <div className="flex flex-row flex-nowrap items-center w-full">
            <Field
              name="orderSymbol"
              component={SymbolAdvanceOrder}
              tabindex={'1'}
              placeholder={t('trading.order.search-stock')}
              onKeyDown={_handleKeydownSym}
              dataSuggest={allStock}
              validate={[required]}
            />
            <IoClose
              className="hidden sm:block ml-auto"
              size={24}
              onClick={() => onClose()}
            />
          </div>
          <div className="whitespace-nowrap text-skin-white min-h-5 my-4 grid grid-cols-[auto_50px]">
            <div className="whitespace-break-spaces">
              {stockDetail && orderSymbol ? (
                <>
                  <span className="text-xl font-medium">
                    {stockName?.stock_code}
                  </span>
                  <span className="text-[13px] ml-2">
                    {stockName?.name_short}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-[auto_120px_85px] md:grid-cols-[auto_145px_120px] h-10 w-full">
            <div className="grid grid-cols-[auto_50px]">
              <span
                className={
                  'text-[35px] md:text-[40px] leading-10 font-medium ' +
                  stockDetail?.matchCL
                }
              >
                {hasSymbolValue &&
                  stockDetail &&
                  numberFormat(stockDetail?.lastPrice, 2)}
              </span>
              <div
                className={'grid text-[10px] md:text-xs ' + stockDetail?.matchCL}
              >
                <span className="leading-4">
                  {stockDetail?.change ? stockDetail?.change.trim() : ''}
                </span>
                <span className="leading-4">{stockDetail?.changePc.trim()}%</span>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="grid text-[10px] md:text-xs uppercase text-center">
                <span className="text-skin-placeholder">Trần</span>
                <span className="text-skin-ceil">
                  {hasSymbolValue &&
                    stockDetail &&
                    numberFormat(stockDetail?.c, 2)}
                </span>
              </div>
              <div className="grid text-[10px] md:text-xs uppercase text-center">
                <span className="text-skin-placeholder">Sàn</span>
                <span className="text-skin-floor">
                  {hasSymbolValue &&
                    stockDetail &&
                    numberFormat(stockDetail?.f, 2)}
                </span>
              </div>
              <div className="grid text-xs uppercase text-center">
                <span className="text-skin-placeholder">TC</span>
                <span className="text-skin-ref">
                  {hasSymbolValue &&
                    stockDetail &&
                    numberFormat(stockDetail?.r, 2)}
                </span>
              </div>
            </div>
            <div className="grid text-[10px] md:text-xs uppercase text-right">
              <span className="text-skin-placeholder">Khối lượng</span>
              <span className="text-skin-base">
                {hasSymbolValue && numberFormat(stockDetail?.lot || 0, 0, '0')}
              </span>
            </div>
          </div>

          {hasSymbolValue && stockDetail?.status_info && (
            <small className="mb-0 text-xs d ">{stockDetail?.status_info}</small>
          )}

          <div className="mt-4 w-full">
            <AdvanceBuocGia symbolInfo={hasSymbolValue ? stockDetail : null} />
          </div>

          <ul className="border-skin-bd3 p-4 border rounded mt-4">
            <li className="flex justify-between">
              <span className="text-xs text-skin-text3">
                {t('trading.order.pp')}
              </span>
              <span className="text-xs text-skin-text3 ">
                {stockBalance &&
                  (stockBalance?.accType === 'M'
                    ? numberFormat(stockBalance?.pp, 0, '0')
                    : numberFormat(stockBalance?.cashAvaiable, 0, '0'))}
              </span>
            </li>
            <li className="flex justify-between mt-2">
              <span className="text-xs text-skin-text3">
                {t('trading.order.margin-rate')}
              </span>
              <span className="text-xs text-skin-text3">
                {stockBalance?.accType === 'M'
                  ? numberFormat(stockBalance?.im_ck, 0, '0')
                  : '100'}
                %
              </span>
            </li>
            <li className="flex justify-between mt-2">
              <span className="text-xs text-skin-text3">
                {t('trading.order.buy-sell-max')}
              </span>
              <span className="text-xs ">
                <span className="i">
                  {stockBalance &&
                    numberFormat(stockBalance?.volumeAvaiable, 0, '0')}
                </span>{' '}
                /{' '}
                <span className="d">
                  {stockBalance && numberFormat(stockBalance?.balance, 0, '0')}
                </span>
              </span>
            </li>
            <li className="flex justify-between mt-2">
              <span className="text-xs text-skin-text3">
                {t('trading.order.total-2')}
              </span>
              <div>
                {typeTrade === '2' && (
                  <>
                    <span className="text-xs text-skin-text3">
                      {numberFormat(
                        StringToInt(orderBuyVolume) *
                        StringToDouble(orderBuyPrice) *
                        1000,
                        0,
                        '0',
                      )}
                    </span>
                    /
                    <span className="text-xs text-skin-text3">
                      {numberFormat(
                        StringToInt(orderSellVolume) *
                        StringToDouble(orderSellPrice) *
                        1000,
                        0,
                        '0',
                      )}
                    </span>
                  </>
                )}
                {typeTrade === '1' && (
                  <span className="text-xs text-skin-text3">
                    {numberFormat(
                      StringToInt(orderVolume) *
                      StringToDouble(orderPrice) *
                      1000,
                      0,
                      '0',
                    )}
                  </span>
                )}
              </div>
            </li>
          </ul>

          <div className="w-full grid grid-cols-2 gap-2 my-4 date-full">
            <span className="text-xs self-center">Loại lệnh</span>
            <Field
              name="typeTrade"
              className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] py-0 pr-0 text-center"
              component={'select'}
            >
              <option value={'1'}>Lệnh thường</option>
              <option value={'2'}>Lệnh điều kiện</option>
            </Field>
          </div>
          {typeTrade === '1' && (
            <>
              <Field
                name="orderSide"
                component={CustomTypeOrder}
                validate={required}
                tabindex={'2'}
              />
              <ul className="mb-auto">
                <li className="grid grid-cols-[120px_auto] w-full">
                  <span className="text-xs self-center text-skin-text3">
                    {t('trading.order.price')}
                  </span>
                  <Field
                    name="orderPrice"
                    className="form-control input-order text-center uppercase text-[13px]"
                    placeholder={t('trading.order.price')}
                    record={hasSymbolValue ? stockDetail : undefined}
                    component={RenderInputAdvanceTradePrice}
                    typeTrade={typeTrade}
                    fnCallback={_handleChangePrice}
                    validate={
                      (isChangePrice || orderPrice) && typeTrade === '1'
                        ? required
                        : null
                    }
                    onBlur={_handleCheckPrice}
                    txtError={txtPriceErr}
                    tabindex={'2'}
                    indexList={indexs}
                  />
                </li>
                <li className="grid grid-cols-[120px_auto] mt-3">
                  <span className="text-xs self-center text-skin-text3">
                    {t('trading.order.vol')}
                  </span>
                  <Field
                    name="orderVolume"
                    className="form-control text-center input-order text-[13px]"
                    placeholder={t('trading.order.vol')}
                    component={RenderInputAdvanceTradeVol}
                    fnCallback={_handleChangeVol}
                    validate={
                      (isChangeVol || orderVolume) && typeTrade === '1'
                        ? required
                        : null
                    }
                    onBlur={_handleCheckVol}
                    txtError={txtVolErr}
                    tabindex={'3'}
                  />
                </li>
              </ul>
            </>
          )}
          {typeTrade === '2' && (
            <>
              <div className="w-full grid sm:grid-cols-2 gap-3">
                <div className="border-skin-bd3 p-2 rounded border">
                  <ul className="mb-auto">
                    <li className="text-center text-sm">Bên mua</li>
                    <li className="grid grid-cols-[50px_auto] mt-1">
                      <span className="text-xs self-center text-skin-text3">
                        KL
                      </span>
                      <Field
                        name="orderBuyVolume"
                        className="form-control text-center input-order text-[13px]"
                        placeholder="KL"
                        component={RenderInputAdvanceTradeVol}
                        fnCallback={_handleChangeBuyVol}
                        validate={
                          orderBuyPrice && typeTrade === '2' ? required : null
                        }
                        onBlur={_handleCheckVol}
                        txtError={txtVolErr}
                        tabindex={'3'}
                      />
                    </li>
                    <li className="grid grid-cols-[50px_auto] w-full mt-1">
                      <span className="text-xs self-center text-skin-text3">
                        {t('trading.order.price')}
                      </span>
                      <div className="grid grid-cols-[28px_auto] gap-1 items-center">
                        <Field
                          name="orderBuyCond"
                          className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] py-0 pr-0 text-center"
                          component={'select'}
                        >
                          <option value={'SOU'}>&ge;</option>
                          <option value={'SOL'}>&le;</option>
                        </Field>
                        <Field
                          name="orderBuyPrice"
                          className="form-control input-order text-center uppercase text-[13px]"
                          placeholder={t('trading.order.price')}
                          record={hasSymbolValue ? stockDetail : undefined}
                          component={RenderInputAdvanceTradePrice}
                          fnCallback={_handleChangeBuyPrice}
                          validate={
                            orderBuyVolume && typeTrade === '2' ? required : null
                          }
                          onBlur={_handleCheckPrice}
                          txtError={txtPriceErr}
                          tabindex={'2'}
                          indexList={indexs}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="border-skin-bd3 p-2 rounded border">
                  <ul className="mb-auto">
                    <li className="text-center text-sm">Bên bán</li>
                    <li className="grid grid-cols-[50px_auto] mt-1">
                      <span className="text-xs self-center text-skin-text3">
                        KL
                      </span>
                      <Field
                        name="orderSellVolume"
                        className="form-control text-center input-order text-[13px]"
                        placeholder={'KL'}
                        component={RenderInputAdvanceTradeVol}
                        fnCallback={_handleChangeSellVol}
                        validate={
                          orderSellPrice && typeTrade === '2' ? required : null
                        }
                        onBlur={_handleCheckSellVol}
                        txtError={txtSellVolErr}
                        tabindex={'3'}
                      />
                    </li>
                    <li className="grid grid-cols-[50px_auto] w-full mt-1">
                      <span className="text-xs self-center text-skin-text3">
                        {t('trading.order.price')}
                      </span>
                      <div className="grid grid-cols-[28px_auto] gap-1 items-center">
                        <Field
                          name="orderSellCond"
                          className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] py-0 pr-0 text-center"
                          component={'select'}
                        >
                          <option value={'SOU'}>&ge;</option>
                          <option value={'SOL'}>&le;</option>
                        </Field>
                        <Field
                          name="orderSellPrice"
                          className="form-control input-order text-center uppercase text-[13px]"
                          placeholder={t('trading.order.price')}
                          record={hasSymbolValue ? stockDetail : undefined}
                          component={RenderInputAdvanceTradePrice}
                          fnCallback={_handleChangeSellPrice}
                          validate={
                            orderSellVolume && typeTrade === '2' ? required : null
                          }
                          onBlur={_handleCheckSellPrice}
                          txtError={txtSellPriceErr}
                          tabindex={'2'}
                          indexList={indexs}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-2 my-4 date-full">
                <span className="text-xs self-center">Ngày đến hạn</span>
                <Field
                  className="w-full"
                  name="orderExpireDate"
                  component={RenderSelectDate}
                  minDate={new Date()}
                  validate={typeTrade === '2' ? required : null}
                />
              </div>
            </>
          )}

          <BtnSubmit
            type="submit"
            className="!h-8 !text-sm font-medium !border-0 mt-4 p-2"
            disabled={!orderSymbol}
          >
            Xác nhận
          </BtnSubmit>
        </form>
      }
      {
        !isMobile && <PerfectScrollbar className=' h-[calc(100vh-120px)]'>
          <form
            ref={formRef}
            onSubmit={handleSubmit(submitForm)}
            className="form-trade flex flex-col select-none"
          >
            <div className="flex flex-row flex-nowrap items-center w-full">
              <Field
                name="orderSymbol"
                component={SymbolAdvanceOrder}
                tabindex={'1'}
                placeholder={t('trading.order.search-stock')}
                onKeyDown={_handleKeydownSym}
                dataSuggest={allStock}
                validate={[required]}
              />
              <IoClose
                className="hidden sm:block ml-auto"
                size={24}
                onClick={() => onClose()}
              />
            </div>
            <div className="whitespace-nowrap text-skin-white min-h-5 my-4 grid grid-cols-[auto_50px]">
              <div className="whitespace-break-spaces">
                {stockDetail && orderSymbol ? (
                  <>
                    <span className="text-xl font-medium">
                      {stockName?.stock_code}
                    </span>
                    <span className="text-[13px] ml-2">
                      {stockName?.name_short}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </div>
              <div></div>
            </div>
            <div className="grid grid-cols-[auto_120px_85px] md:grid-cols-[auto_145px_120px] h-10 w-full">
              <div className="grid grid-cols-[auto_50px]">
                <span
                  className={
                    'text-[35px] md:text-[40px] leading-10 font-medium ' +
                    stockDetail?.matchCL
                  }
                >
                  {hasSymbolValue &&
                    stockDetail &&
                    numberFormat(stockDetail?.lastPrice, 2)}
                </span>
                <div
                  className={'grid text-[10px] md:text-xs ' + stockDetail?.matchCL}
                >
                  <span className="leading-4">
                    {stockDetail?.change ? stockDetail?.change.trim() : ''}
                  </span>
                  <span className="leading-4">{stockDetail?.changePc.trim()}%</span>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="grid text-[10px] md:text-xs uppercase text-center">
                  <span className="text-skin-placeholder">{t('table.ceil')}</span>
                  <span className="text-skin-ceil">
                    {hasSymbolValue &&
                      stockDetail &&
                      numberFormat(stockDetail?.c, 2)}
                  </span>
                </div>
                <div className="grid text-[10px] md:text-xs uppercase text-center">
                  <span className="text-skin-placeholder">{t('table.floor')}</span>
                  <span className="text-skin-floor">
                    {hasSymbolValue &&
                      stockDetail &&
                      numberFormat(stockDetail?.f, 2)}
                  </span>
                </div>
                <div className="grid text-xs uppercase text-center">
                  <span className="text-skin-placeholder">{t('table.ref')}</span>
                  <span className="text-skin-ref">
                    {hasSymbolValue &&
                      stockDetail &&
                      numberFormat(stockDetail?.r, 2)}
                  </span>
                </div>
              </div>
              <div className="grid text-[10px] md:text-xs uppercase text-right">
                <span className="text-skin-placeholder">{t('trading.txt-type-vol')}</span>
                <span className="text-skin-base">
                  {hasSymbolValue && numberFormat(stockDetail?.lot || 0, 0, '0')}
                </span>
              </div>
            </div>

            {hasSymbolValue && stockDetail?.status_info && (
              <small className="mb-0 text-xs d ">{stockDetail?.status_info}</small>
            )}

            <div className="mt-4 w-full">
              <AdvanceBuocGia symbolInfo={hasSymbolValue ? stockDetail : null} />
            </div>

            <ul className="border-skin-bd3 p-4 border rounded mt-4">
              <li className="flex justify-between">
                <span className="text-xs text-skin-text3">
                  {t('trading.order.pp')}
                </span>
                <span className="text-xs text-skin-text3 ">
                  {stockBalance &&
                    (stockBalance?.accType === 'M'
                      ? numberFormat(stockBalance?.pp, 0, '0')
                      : numberFormat(stockBalance?.cashAvaiable, 0, '0'))}
                </span>
              </li>
              <li className="flex justify-between mt-2">
                <span className="text-xs text-skin-text3">
                  {t('trading.order.margin-rate')}
                </span>
                <span className="text-xs text-skin-text3">
                  {stockBalance?.accType === 'M'
                    ? numberFormat(stockBalance?.im_ck, 0, '0')
                    : '100'}
                  %
                </span>
              </li>
              <li className="flex justify-between mt-2">
                <span className="text-xs text-skin-text3">
                  {t('trading.order.buy-sell-max')}
                </span>
                <span className="text-xs ">
                  <span className="i">
                    {stockBalance &&
                      numberFormat(stockBalance?.volumeAvaiable, 0, '0')}
                  </span>{' '}
                  /{' '}
                  <span className="d">
                    {stockBalance && numberFormat(stockBalance?.balance, 0, '0')}
                  </span>
                </span>
              </li>
              <li className="flex justify-between mt-2">
                <span className="text-xs text-skin-text3">
                  {t('trading.order.total-2')}
                </span>
                <div>
                  {typeTrade === '2' && (
                    <>
                      <span className="text-xs text-skin-text3">
                        {numberFormat(
                          StringToInt(orderBuyVolume) *
                          StringToDouble(orderBuyPrice) *
                          1000,
                          0,
                          '0',
                        )}
                      </span>
                      /
                      <span className="text-xs text-skin-text3">
                        {numberFormat(
                          StringToInt(orderSellVolume) *
                          StringToDouble(orderSellPrice) *
                          1000,
                          0,
                          '0',
                        )}
                      </span>
                    </>
                  )}
                  {typeTrade === '1' && (
                    <span className="text-xs text-skin-text3">
                      {numberFormat(
                        StringToInt(orderVolume) *
                        StringToDouble(orderPrice) *
                        1000,
                        0,
                        '0',
                      )}
                    </span>
                  )}
                </div>
              </li>
            </ul>

            <div className="w-full grid grid-cols-2 gap-2 my-4 date-full">
              <span className="text-xs self-center">{t('trading.order.order-type')}</span>
              <Field
                name="typeTrade"
                className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] py-0 pr-0 text-center"
                component={'select'}
              >
                <option value={'1'}>{t('trading.txt-type-normal')}</option>
                <option value={'2'}>{t('trading.txt-type-condition')}</option>
              </Field>
            </div>
            {typeTrade === '1' && (
              <>
                <Field
                  name="orderSide"
                  component={CustomTypeOrder}
                  validate={required}
                  tabindex={'2'}
                />
                <ul className="mb-auto">
                  <li className="grid grid-cols-[120px_auto] w-full">
                    <span className="text-xs self-center text-skin-text3">
                      {t('trading.order.price')}
                    </span>
                    <Field
                      name="orderPrice"
                      className="form-control input-order text-center uppercase text-[13px]"
                      placeholder={t('trading.order.price')}
                      record={hasSymbolValue ? stockDetail : undefined}
                      component={RenderInputAdvanceTradePrice}
                      typeTrade={typeTrade}
                      fnCallback={_handleChangePrice}
                      validate={
                        (isChangePrice || orderPrice) && typeTrade === '1'
                          ? required
                          : null
                      }
                      onBlur={_handleCheckPrice}
                      txtError={txtPriceErr}
                      tabindex={'2'}
                      indexList={indexs}
                    />
                  </li>
                  <li className="grid grid-cols-[120px_auto] mt-3">
                    <span className="text-xs self-center text-skin-text3">
                      {t('trading.order.vol')}
                    </span>
                    <Field
                      name="orderVolume"
                      className="form-control text-center input-order text-[13px]"
                      placeholder={t('trading.order.vol')}
                      component={RenderInputAdvanceTradeVol}
                      fnCallback={_handleChangeVol}
                      validate={
                        (isChangeVol || orderVolume) && typeTrade === '1'
                          ? required
                          : null
                      }
                      onBlur={_handleCheckVol}
                      txtError={txtVolErr}
                      tabindex={'3'}
                    />
                  </li>
                </ul>
              </>
            )}
            {typeTrade === '2' && (
              <>
                <div className="w-full grid sm:grid-cols-2 gap-3">
                  <div className="border-skin-bd3 p-2 rounded border">
                    <ul className="mb-auto">
                      <li className="text-center text-sm">{t('trading.txt-benmua')}</li>
                      <li className="grid grid-cols-[50px_auto] mt-1">
                        <span className="text-xs self-center text-skin-text3">
                          {t('trading.history.vol')}
                        </span>
                        <div className='w-36'>
                          <Field
                            name="orderBuyVolume"
                            className="form-control text-center input-order text-[13px]"
                            placeholder={t('trading.history.vol')}
                            component={RenderInputAdvanceTradeVol}
                            fnCallback={_handleChangeBuyVol}
                            validate={
                              orderBuyPrice && typeTrade === '2' ? required : null
                            }
                            onBlur={_handleCheckVol}
                            txtError={txtVolErr}
                            tabindex={'3'}
                          />
                        </div>
                      </li>
                      <li className="grid grid-cols-[50px_auto] w-full mt-1">
                        <span className="text-xs self-center text-skin-text3">
                          {t('trading.order.price')}
                        </span>
                        <div className="grid grid-cols-[28px_auto] gap-1 items-center w-36">
                          <Field
                            name="orderBuyCond"
                            className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] p-0 text-center"
                            component={'select'}
                          >
                            <option value={'SOU'}>&ge;</option>
                            <option value={'SOL'}>&le;</option>
                          </Field>
                          <div className='w-28'>
                            <Field
                              name="orderBuyPrice"
                              className="form-control input-order text-center uppercase text-[13px]"
                              placeholder={t('trading.order.price')}
                              record={hasSymbolValue ? stockDetail : undefined}
                              component={RenderInputAdvanceTradePrice}
                              fnCallback={_handleChangeBuyPrice}
                              validate={
                                orderBuyVolume && typeTrade === '2' ? required : null
                              }
                              onBlur={_handleCheckPrice}
                              txtError={txtPriceErr}
                              tabindex={'2'}
                              indexList={indexs}
                            />
                          </div>

                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="border-skin-bd3 p-2 rounded border">
                    <ul className="mb-auto">
                      <li className="text-center text-sm">{t('trading.txt-benban')}</li>
                      <li className="grid grid-cols-[50px_auto] mt-1">
                        <span className="text-xs self-center text-skin-text3">
                          {t('trading.history.vol')}
                        </span>
                        <div className='w-36'>
                          <Field
                            name="orderSellVolume"
                            className="form-control text-center input-order text-[13px]"
                            placeholder={t('trading.history.vol')}
                            component={RenderInputAdvanceTradeVol}
                            fnCallback={_handleChangeSellVol}
                            validate={
                              orderSellPrice && typeTrade === '2' ? required : null
                            }
                            onBlur={_handleCheckSellVol}
                            txtError={txtSellVolErr}
                            tabindex={'3'}
                          />
                        </div>

                      </li>
                      <li className="grid grid-cols-[50px_auto] w-full mt-1">
                        <span className="text-xs self-center text-skin-text3">
                          {t('trading.order.price')}
                        </span>
                        <div className="grid grid-cols-[28px_auto] gap-1 items-center">
                          <Field
                            name="orderSellCond"
                            className="form-control input-order form-gr-trade uppercase text-[13px] h-[26px] p-0 text-center"
                            component={'select'}
                          >
                            <option value={'SOU'}>&ge;</option>
                            <option value={'SOL'}>&le;</option>
                          </Field>
                          <div className='w-28'>
                            <Field
                              name="orderSellPrice"
                              className="form-control input-order text-center uppercase text-[13px]"
                              placeholder={t('trading.order.price')}
                              record={hasSymbolValue ? stockDetail : undefined}
                              component={RenderInputAdvanceTradePrice}
                              fnCallback={_handleChangeSellPrice}
                              validate={
                                orderSellVolume && typeTrade === '2' ? required : null
                              }
                              onBlur={_handleCheckSellPrice}
                              txtError={txtSellPriceErr}
                              tabindex={'2'}
                              indexList={indexs}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-full grid grid-cols-2 gap-2 my-4 date-full">
                  <span className="text-xs self-center">{t('trading.txt-expired-date')}</span>
                  <Field
                    className="w-full"
                    name="orderExpireDate"
                    component={RenderSelectDate}
                    minDate={new Date()}
                    validate={typeTrade === '2' ? required : null}
                  />
                </div>
              </>
            )}

            <BtnSubmit
              type="submit"
              className="!h-8 !text-sm font-medium !border-0 mt-4 p-2"
              disabled={!orderSymbol}
            >
              {t('trading.order.xac-nhan')}
            </BtnSubmit>
          </form>
        </PerfectScrollbar>
      }

      {showModal && (
        <FormConfirmOrder
          order={order}
          handleCloseModal={_handleCloseModal}
          handleSubmitOrder={_handleSubmitOrder}
        />
      )}
    </>
  );
};

const FormTradeNew = reduxForm<IAdvancedOrderForm, Props>({
  form: 'formtradeNewOrder',
  enableReinitialize: true,
})(FormAdvancedOrder as any);

const selector = formValueSelector('formtradeNewOrder');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getIndexList = makeGetIndexList();
  const getDefaultAccount = makeGetDefaultAccount();
  const getStockDetail = makeGetSStockInfo();
  const getStockBalance = makeGetStockBalance();
  // const getListAccount = makeGetListAccount();

  const getAccountPortfolio = makeGetAccountPortfolio();
  const getUpdatePortfolio = makeGetUpdatePortfolio();

  const getSymbolBySymbolDetail = makeGetSymbolBySymbolDetail();

  const getNewOrder = makeGetNewOrder();
  const getCancelOrder = makeGetCancelOrder();
  const getEditOrder = makeGetEditOrder();
  const getAllStock = makeGetAllStock();

  const mapStateToProps = (state: AppState) => {
    const token = getToken(state);
    const defAccount = getDefaultAccount(state);

    const {
      orderSymbol,
      orderAccount,
      orderPrice,
      orderVolume,
      orderBuyVolume,
      orderBuyPrice,
      orderSellPrice,
      orderSellVolume,
      orderSide,
      typeTrade,
      orderExpireDate,
      saveOrder,
    } = selector(
      state,
      'orderSymbol',
      'orderAccount',
      'orderPrice',
      'orderVolume',
      'orderBuyVolume',
      'orderBuyPrice',
      'orderSellPrice',
      'orderSellVolume',
      'orderSide',
      'typeTrade',
      'orderExpireDate',
      'saveOrder',
    );

    return {
      token,
      defAccount,
      indexList: getIndexList(state),
      // listAccount: getListAccount(state),
      stockBalance: getStockBalance(state),
      stockDetail: getStockDetail(state),
      symbolInfo: getSymbolBySymbolDetail(state),
      accPortfolio: getAccountPortfolio(state),
      updPortfolio: getUpdatePortfolio(state),
      allStock: getAllStock(state),

      newOrder: getNewOrder(state),
      cancelOrder: getCancelOrder(state),
      editOrder: getEditOrder(state),

      dblPri: state.client.dblPri,

      orderSymbol,
      orderAccount,
      orderPrice,
      orderVolume,
      orderBuyVolume,
      orderBuyPrice,
      orderSellPrice,
      orderSellVolume,
      orderSide,
      typeTrade,
      orderExpireDate,
      saveOrder,

      hasSymbolValue: selector(state, 'orderSymbol') && orderSymbol.length > 2,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(FormTradeNew);
