import { createSelector } from 'reselect';

import * as _ from 'lodash';
import { AppState } from 'reducers';

const getMarginList = (state: AppState) =>
  state.priceBoard.marginList?.data || [];
const indexList = (state: AppState) => state.priceBoard.indexList;
const indexVN30 = (state: AppState) => {
  const { indexList } = state.priceBoard;
  if (!indexList || !indexList.length) return null;

  return _.find(indexList, (o) => o.mc === '11');
};
const getPart = (state: AppState) => {
  if (!state.priceBoard.partId) return null;
  return state.priceBoard.partId;
};

const getAllStock = (state: AppState) => state.priceBoard.allStock;
const getSnapShot = (state: AppState) => state.priceBoard.snapshot;
const getSnapShotCW = (state: AppState) => state.priceBoard.snapshotCW;
const getPsSnapShot = (state: AppState) => state.priceBoard.psSnapshot;
const getPsIndex = (state: AppState) => state.priceBoard.psIndex;
const getAllOrder = (state: AppState) => state.priceBoard.allOrder;
const getAllOrderCond = (state: AppState) => state.priceBoard.allOrdCond;
const getCWList = (state: AppState) => state.priceBoard.cwList;
const getPsList = (state: AppState) => state.priceBoard.psList;
const getVcbsFilterSnapshot = (state: AppState) =>
  state.priceBoard.vcbsFilterSnapshot;
export const getVcbsFilterList = (state: AppState) =>
  state.priceBoard.vcbsFilterList;
const getLoleSnapshot = (state: AppState) => state.priceBoard.lole;
const getNdataSnapshot = (state: AppState) => state.priceBoard.ndataSnapshot;
const getAdvStockData = (state: AppState) => state.priceBoard.advStockData;
const getTradeHisPs = (state: AppState) => {
  const { tradeHisPs } = state.priceBoard;
  if (_.isArray(tradeHisPs)) {
    const _stockTrade = _.sortBy(tradeHisPs, ['time']);
    return _stockTrade.reverse();
  }
  return [];
};
const getListNganh = (state: AppState) => state.priceBoard.listNganh;
const getList30 = (state: AppState) => state.priceBoard.list30;
const getListEtf = (state: AppState) => state.priceBoard.listEtf;
const getIndexSummary = (state: AppState) => state.priceBoard.indSum;
const getTopInterest = (state: AppState) => state.priceBoard.topInterest;
// const getNoiCap = (state: AppState, props: any) => {
//   const { profile } = state.customer;
//   const { noicap } = state.priceBoard;
//   // log(profile, noicap)
//   if (!profile) return null;

//   if (!noicap) return profile.custIdIssuLoca;

//   const _noicap = _.find(noicap, (o) => o.orderno === profile.custIdIssuLoca);
//   return _noicap ? _noicap.name : profile.custIdIssuLoca;
// };
const getListBank = (state: AppState) => {
  const { branchList } = state.priceBoard;
  if (!branchList) return null;

  const _allBankName = _.groupBy(branchList, 'org_abbr_nm');
  const _result = Object.keys(_allBankName).map((key) => ({
    bank: key,
    branch: _allBankName[key],
  }));
  // log('_allBankName', _result)
  return _result;
};

const getTotalGD = (state: AppState) => {
  const { indexList } = state.priceBoard;
  return _.sumBy(
    indexList,
    (o: any) => o.value * (['10', '02'].includes(o.mc) ? 1 : 0),
  );
};

const getPutThroughByGroup = (state: AppState, props: any) => {
  const grName = props.ptGr;
  const grCode =
    grName === 'HSX'
      ? '10'
      : grName === 'HNX'
        ? '02'
        : grName === 'UPCOM'
          ? '03'
          : '10';
  const ptList = _.filter(
    state.priceBoard.ptList,
    (o) => o.marketID === grCode,
  );
  return _.sortBy(ptList, 'time').reverse();
};

const getSymbol = (state: AppState, props: any) => {
  const sym = props.record.sym;
  const symbol = _.find(
    state.priceBoard.allStock,
    (item) => item.stock_code === sym,
  );
  return symbol;
};

const getSymbolBySymbolDetail = (state: AppState) => {
  if (!state.privData?.sStockInfo?.sym) return null;

  return _.find(
    state.priceBoard.allStock,
    (item) => item.stock_code === state.privData?.sStockInfo?.sym,
  );
};

const getSymbolByStock = (state: AppState, props: any) => {
  return _.find(
    state.priceBoard.allStock,
    (item) => item.stock_code === props.symbol,
  );
};

/**************************************** MAKE GET *************************************/

export const makeGetTotalGD = () =>
  createSelector([getTotalGD], (totalGd) => totalGd);

export const makeGetIndexList = () =>
  createSelector([indexList], (list) => list);

export const makeGetIndexVN30 = () =>
  createSelector([indexVN30], (list) => list);

export const makeGetAllStock = () =>
  createSelector([getAllStock], (allStock) => allStock);

export const makeGetCWList = () =>
  createSelector([getCWList], (cwList) => cwList);

export const makeGetPsList = () =>
  createSelector([getPsList], (psList) => psList);

export const makeGetLoleSnapshot = () =>
  createSelector([getLoleSnapshot], (lole) => lole);

export const makeGetNdataSnapshot = () =>
  createSelector([getNdataSnapshot], (ndataSnapshot) => ndataSnapshot);

export const makeGetTradeHisPs = () =>
  createSelector([getTradeHisPs], (tradeHisPs) => tradeHisPs);

export const makeGetList30 = () =>
  createSelector([getList30], (list30) => list30);

export const makeGetListNganh = () =>
  createSelector([getListNganh], (listNganh) => listNganh);

export const makeGetListEtf = () =>
  createSelector([getListEtf], (listEtf) => listEtf);

export const makeGetIndexSummary = () =>
  createSelector([getIndexSummary], (indSum) => indSum);

export const makeGetTopInterest = () =>
  createSelector([getTopInterest], (topInterest) => topInterest);

export const makeGetListBankName = () =>
  createSelector([getListBank], (bankName) => bankName);

// export const makeGetNoiCap = () =>
//   createSelector([getNoiCap], (noicap) => noicap);

export const makeGetPart = () => createSelector([getPart], (part) => part);

export const makeGetSnapShot = () =>
  createSelector([getSnapShot], (snapshot) => snapshot);

export const makeGetSnapShotCW = () =>
  createSelector([getSnapShotCW], (snapshotCW) => snapshotCW);

export const makeGetPsSnapShot = () =>
  createSelector([getPsSnapShot], (psSnapshot) => psSnapshot);

export const makeGetPsIndex = () =>
  createSelector([getPsIndex], (psIndex) => psIndex);

export const makeGetAllOrder = () =>
  createSelector([getAllOrder], (allOrder) => allOrder);

export const makeGetAllOrderCond = () =>
  createSelector([getAllOrderCond], (allOrder) => allOrder);

export const makeGetMarginList = () =>
  createSelector([getMarginList], (marginList) => marginList);

export const makeGetSymbolByStock = () =>
  createSelector([getSymbolByStock], (symbol) => symbol);

export const makeGetSymbolState = () =>
  createSelector([getSymbol], (symbol) => symbol);

export const makeGetSymbolBySymbolDetail = () =>
  createSelector([getSymbolBySymbolDetail], (symbol) => symbol);

export const makeGetPutThroughByGroup = () =>
  createSelector([getPutThroughByGroup], (ptList) => ptList);

export const makeGetAdvStockData = () =>
  createSelector([getAdvStockData], (ptList) => ptList);

export const makeGetVcbsFilterSnapshot = () =>
  createSelector(
    [getVcbsFilterSnapshot],
    (vcbsFilterSnapshot) => vcbsFilterSnapshot,
  );

export const makeGetVcbsFilterList = () =>
  createSelector([getVcbsFilterList], (vcbsFilterList) => vcbsFilterList);
