import { ParamModel } from 'interface';
import * as actions from './actionType';

/** danh sách nguồn */
export function accInfoRequest(data: any): actions.AccInfoRequestingAction {
  return {
    type: actions.ACC_INFO_REQUESTING,
    data,
  };
}

export function accInfoSuccess(resData: any): actions.AccInfoSuccessAction {
  return {
    type: actions.ACC_INFO_SUCCESS,
    resData,
  };
}

export function accInfoError(
  error: Error | string,
): actions.AccInfoErrorAction {
  return {
    type: actions.ACC_INFO_ERROR,
    error,
  };
}

/** duyệt nghiệp vụ tiển */
export function accCashApprRequest(
  data: any,
): actions.AccCashApprRequestingAction {
  return {
    type: actions.ACC_CASH_APPR_REQUESTING,
    data,
  };
}

export function accCashApprSuccess(
  resData: any,
): actions.AccCashApprSuccessAction {
  return {
    type: actions.ACC_CASH_APPR_SUCCESS,
    resData,
  };
}

export function accCashApprError(
  error: Error | string,
): actions.AccCashApprErrorAction {
  return {
    type: actions.ACC_CASH_APPR_ERROR,
    error,
  };
}

/** thêm mới tài khoản thụ hưởng */
export function accBenUpdRequest(data: any): actions.AccBenUpdRequestingAction {
  return {
    type: actions.ACC_BEN_UPD_REQUESTING,
    data,
  };
}

export function accBenUpdSuccess(resData: any): actions.AccBenUpdSuccessAction {
  return {
    type: actions.ACC_BEN_UPD_SUCCESS,
    resData,
  };
}

export function accBenUpdError(
  error: Error | string,
): actions.AccBenUpdErrorAction {
  return {
    type: actions.ACC_BEN_UPD_ERROR,
    error,
  };
}

/** danh sách tài khoản thụ hưởng */
export function accBenListRequest(
  data: any,
): actions.AccBenListRequestingAction {
  return {
    type: actions.ACC_BEN_LIST_REQUESTING,
    data,
  };
}

export function accBenListSuccess(
  resData: any,
): actions.AccBenListSuccessAction {
  return {
    type: actions.ACC_BEN_LIST_SUCCESS,
    resData,
  };
}

export function accBenListError(
  error: Error | string,
): actions.AccBenListErrorAction {
  return {
    type: actions.ACC_BEN_LIST_ERROR,
    error,
  };
}

/** thông tin cài đặt của chi nhánh */
export function branchInfoRequest(
  data: any,
): actions.BranchInfoRequestingAction {
  return {
    type: actions.BRANCH_INFO_REQUESTING,
    data,
  };
}

export function branchInfoSuccess(
  resData: any,
): actions.BranchInfoSuccessAction {
  return {
    type: actions.BRANCH_INFO_SUCCESS,
    resData,
  };
}

export function branchInfoError(
  error: Error | string,
): actions.BranchInfoErrorAction {
  return {
    type: actions.BRANCH_INFO_ERROR,
    error,
  };
}
