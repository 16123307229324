import axios from 'axios';
import { Config } from 'interface';
import { handleApiErrors } from 'lib/api-error';
import { AppState } from 'reducers';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import {
  accountPortfolioError,
  accountPortfolioSuccess,
  categoryRequestError,
  categoryRequestSuccess,
  checkPinRequestError,
  checkPinRequestSuccess,
  configRequestError,
  configRequestSuccess,
  delPortfolioError,
  delPortfolioSuccess,
  getBankBranchRequestError,
  getBankBranchRequestSuccess,
  getBankOnlineRequestError,
  getBankOnlineRequestSuccess,
  getOtpRequestError,
  getOtpRequestSuccess,
  getProvinceRequestError,
  getProvinceRequestSuccess,
  listAccountRequestError,
  listAccountRequestSuccess,
  updatePortfolioError,
  updatePortfolioSuccess,
} from './actions';
import * as actions from './actionType';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function configRequestApi() {
  const url = `http://banggia.tvs.vn/server.cfg`;
  // const url = process.env.NODE_ENV !== 'production' ? `http://banggia.tvs.vn/server.cfg` : `/server.cfg`;
  const request = axios.get(url);

  return handleRequest(request);
}

function categoryRequestApi(data: any, apiUrl: string) {
  const url = `${apiUrl}/CoreServlet.pt`;
  const request = axios.post(url, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });

  return handleRequest(request);
}

async function postRequestApi(data: any, apiUrl: string) {
  const url = `${apiUrl}/TraditionalService`;
  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* configRequestFlow(): any {
  try {
    const data = yield call(configRequestApi);
    // log('configRequestFlow', data)
    yield put(configRequestSuccess(data));
  } catch (error) {
    // console.log('configRequestFlow', error)
    yield put(configRequestError(error));
  }
}

function* categoryRequestFlow(action: actions.CategoryRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const category = yield call(categoryRequestApi, action.data, config.AuthUrl);
    // log(category)
    yield put(categoryRequestSuccess(category.data));
  } catch (error) {
    yield put(categoryRequestError(error));
  }
}

function* listAccountRequestFlow(
  action: actions.ListAccountRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const accounts = yield call(postRequestApi, action.data, config.AuthUrl);
    // log(category)
    const { data } = accounts;
    console.log(data);
    yield put(listAccountRequestSuccess(data));
  } catch (error) {
    console.log('listAccountRequestFlow', error);
    yield put(listAccountRequestError(error));
  }
}

function* getOtpRequestFlow(action: actions.GetOtpRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      // else {
      //   yield put({
      //     type: 'REQUEST_PRIVATE_FALSE',
      //     msg: resData.rs,
      //   });
      // }
      throw Error(resData.rs);
    }
    yield put(getOtpRequestSuccess(Math.random()));
  } catch (error) {
    yield put(getOtpRequestError(error));
  }
}

function* checkPinRequestFlow(action: actions.CheckPinRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(checkPinRequestSuccess(Math.random()));
  } catch (error) {
    yield put(checkPinRequestError(error));
  }
}

function* getBankOnlineRequestFlow(
  action: actions.GetBankOnlineRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getBankOnlineRequestSuccess(resData.data));
  } catch (error) {
    yield put(getBankOnlineRequestError(error));
  }
}

function* getBankBranchRequestFlow(
  action: actions.GetBankBranchRequestSuccessAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.resData, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getBankBranchRequestSuccess(resData.data));
  } catch (error) {
    yield put(getBankBranchRequestError(error));
  }
}

function* getProvinceRequestFlow(
  action: actions.GetProvinceRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(getProvinceRequestSuccess(resData.data));
  } catch (error) {
    yield put(getProvinceRequestError(error));
  }
}

function* accountPortfolioRequestFlow(
  action: actions.AccountPortfolioRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(accountPortfolioSuccess(resData.data[0].data1));
  } catch (error) {
    yield put(accountPortfolioError(error));
  }
}

function* updatePortfolioRequestFlow(
  action: actions.UpdatePortfolioRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(updatePortfolioSuccess(Math.random()));
  } catch (error) {
    yield put(updatePortfolioError(error));
  }
}

function* delPortfolioRequestFlow(
  action: actions.DelPortfolioRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(postRequestApi, action.data, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }
    yield put(delPortfolioSuccess(Math.random()));
  } catch (error) {
    yield put(delPortfolioError(error));
  }
}

function* clientWatcher() {
  yield all([
    takeLatest(actions.CONFIG_REQUESTING, configRequestFlow),
    takeLatest(actions.CATEGORY_REQUESTING, categoryRequestFlow),
    takeLatest(actions.LIST_ACCOUNT_REQUESTING, listAccountRequestFlow),
    takeLatest(actions.GET_OTP_REQUESTING, getOtpRequestFlow),
    takeLatest(actions.CHECK_PIN_REQUESTING, checkPinRequestFlow),
    takeLatest(actions.GET_BANK_ONLINE_REQUESTING, getBankOnlineRequestFlow),
    takeLatest(actions.GET_BANK_BRANCH_REQUESTING, getBankBranchRequestFlow),
    takeLatest(actions.GET_PROVINCE_REQUESTING, getProvinceRequestFlow),
    takeLatest(actions.ACC_PORTFOLIO_REQUESTING, accountPortfolioRequestFlow),
    takeLatest(actions.UPD_PORTFOLIO_REQUESTING, updatePortfolioRequestFlow),
    takeLatest(actions.DEL_PORTFOLIO_REQUESTING, delPortfolioRequestFlow),
  ]);
}

export default clientWatcher;
