// src/contexts/ConfigContext.tsx
import { Config } from 'interface';
import React, { createContext, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setUrlConfig } from './configSlice';

interface ConfigContextType {
  config: Config | null;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);
const appUrl = `${process.env.REACT_APP_API_URL}`;

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = React.useState<Config | null>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch(`${appUrl}/pbapi/api/envConfigs`);
        const data: Config = await response.json();
        dispatch(setUrlConfig(data));
        setConfig(data);
      } catch (error) {
        console.error('Error fetching configuration:', error);
        const appUrl = `${process.env.REACT_APP_DETAIL_URL}`;
        const smartUrl = `${process.env.REACT_APP_ALGO_API_URL}`;
        const authUrl = `${process.env.REACT_APP_API_URL}`;
        const configData: Config = {
          HomeUrl: appUrl,
          SmartAPIUrl: smartUrl,
          AuthUrl: authUrl,
        };
        dispatch(setUrlConfig(configData));
        setConfig(configData);
      }
    };

    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): ConfigContextType => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

export default ConfigContext;
