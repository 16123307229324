/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef } from 'react'
import * as router from 'react-router-dom'

import { connect, useDispatch } from 'react-redux'

import * as _ from 'lodash'

import LoginModal from 'components/modal/login'
import {
  accInfoRequest,
  branchInfoRequest,
} from 'containers/account-info/actions'
import {
  clientConfigSet,
  clientSettingSet,
  clientTokenSet,
  clientTokenUnset,
  defaultAccountSet,
  listAccountRequest,
  unsetLogin,
} from 'containers/client/actions'
import { unsetRegSymbol } from 'containers/socket/actions'
import { AlgoSignalContext } from 'containers/socket/signalAlgo'
import i18n from 'i18n/config'
import { checkDefaultAuthorization } from 'lib/check-auth'
import {
  makeGetAccountInfo,
  makeGetClientSetting,
  makeGetConfig,
  makeGetToast,
  makeGetToken,
  makeGetTypeNav,
} from 'lib/selector'
import { getCategory, storages } from 'lib/storages'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast as notify } from 'react-toastify'
import Loading from 'shared/loading'
import NavLeft from 'shared/navLeft'
import Notfound from 'shared/notFound'
import Tooltiped from 'shared/tooltiped'
import { renderParams } from 'utils'
import {
  allStockRequest,
  vcbsFilterListRequest
} from '../banggia/actions'
import { WebSocketContext } from '../socket/webSocket'
import DefaultFooter from './DefaultFooter'

const DefaultHeader = React.lazy(() => import('./DefaultHeader'))

const Dashboard = React.lazy(() => import('../dashboard'))
const Banggia = React.lazy(() => import('../banggia'))
const EffectPage = React.lazy(() => import('../effect'))
const AccountInfo = React.lazy(() => import('../account-info'))

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <router.Route
      {...rest}
      render={(props) => {
        checkDefaultAuthorization(rest)
        return <Component {...props} store={rest.dispatch.store} />
      }}
    />
  )
}

function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

function DefaultLayout(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = router.useLocation()
  const navigate = router.useHistory()
  const [timeToLive] = React.useState(120)
  const [showLossSessionModal, setShowLossSessionModal] = React.useState(false)
  const [showLoginModal, setShowLoginModal] = React.useState(false)

  const ws = React.useContext(WebSocketContext)
  const as = React.useContext(AlgoSignalContext)

  const {
    token,
    toast,
    reqLogin,
    typeNav,
    regSym,
    mode,
    adv,
    lang,
    theme,
    userStr,
    tokenStr,
    config,
    settings,
    accInfo,
  } = props
  const prevToast = usePrevious(toast)
  const preToken = usePrevious(token)
  const preReqLogin = usePrevious(reqLogin)
  const prePathName = usePrevious(pathname)
  const preMode = usePrevious(mode)
  const preAdv = usePrevious(adv)
  const preAccInfo = usePrevious(accInfo)

  let logoutTimeout

  useEffect(() => {
    ws.init()
    getCategory(props)

    i18n.changeLanguage(lang || 'vi')
    if (theme === 'dark') {
      document.querySelector('html')?.classList.remove('light-mode')
      document.querySelector('html')?.classList.remove('oled-mode')
      document.querySelector('html')?.classList.add('dark-mode')
    } else if (theme === 'light') {
      document.querySelector('html')?.classList.remove('dark-mode')
      document.querySelector('html')?.classList.remove('oled-mode')
      document.querySelector('html')?.classList.add('light-mode')
    } else {
      document.querySelector('html')?.classList.remove('dark-mode')
      document.querySelector('html')?.classList.remove('light-mode')
      document.querySelector('html')?.classList.add('oled-mode')
    }

    dispatch(clientSettingSet({ ...settings, lang, theme }))

    dispatch(allStockRequest())
    // danh sách thông số kỹ thuật các mã chứng khoán
    // dispatch(secInfoAdvRequest())
    // danh sách bộ lọc VCBS
    dispatch(vcbsFilterListRequest())

    const _token = storages.loadState('token')
    let tokenFrame
    if (userStr !== '') {
      tokenFrame = {
        name: userStr,
        user: userStr,
        defaultAcc: userStr + '6',
        sid: tokenStr,
      }
      // mới thì save lại
      storages.saveState('ttl', new Date().getTime())
      storages.saveState('token', JSON.stringify(tokenFrame))
      dispatch(clientTokenSet(tokenFrame))
    }

    console.log('token', _token)
    if (!_token && !tokenFrame) {
      if (mode === 'FRAME') navigate.push('/price/bang-gia/vn30')
      else navigate.push('/login')
    }

    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      storages.saveState('ttl', new Date().getTime())
    })
  }, [])

  useEffect(() => {
    if (pathname && !_.isEqual(pathname, prePathName)) {
      if (pathname.indexOf('/price/bang-gia') < 0 && regSym) {
        // leave data
        const payload = {
          action: 'leave',
          data: regSym,
        }
        //log("leave socket data", JSON.stringify(payload));
        ws.sendMessage(payload)
        dispatch(unsetRegSymbol())
      }
    }
  }, [pathname])

  useEffect(() => {
    console.log(token, preToken)
    if (
      !token &&
      token !== undefined &&
      preToken &&
      !_.isEqual(token, preToken)
    ) {
      clearTimeoutFunc()
      console.log('token', token, preToken)
      if (preToken) {
        if (pathname.indexOf('/price/bang-gia') > -1) {
          if (!showLossSessionModal) {
            setShowLossSessionModal(true)
          }
        } else {
          if (mode === 'FRAME') navigate.push('/price/bang-gia/vn30')
          else navigate.push('/login')
        }
      }
    }

    if (token && !_.isEqual(token, preToken)) {
      const ttl = storages.loadState('ttl') || new Date().getTime(),
        now = new Date().getTime()
      const diffMinute = (now - ttl) / (1000 * 60)
      // console.log(diffMinute);
      if (diffMinute > timeToLive && mode !== 'FRAME') {
        _actionTimeToLive()
        return
      }

      dispatch({ type: 'INVALID_SESSION_CLEAR' })
      dispatch(defaultAccountSet(token.defaultAcc))
      // load list account
      getListAccount()
      // get account info
      getAccountInfo()

      const events = [
        // 'load',
        // 'mousemove',
        // 'mousedown',
        'click',
        'scroll',
        'keypress',
      ]
      for (var i in events) {
        window.addEventListener(events[i], resetTimeout)
      }

      setTimeOut()
    }
  }, [token])

  useEffect(() => {
    if (reqLogin && !_.isEqual(reqLogin, preReqLogin)) {
      confirmAlert({
        title: t('notice.yc-ht'),
        message: t('notice.relogin'),
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              Logout()
            },
          },
        ],
        onClickOutside: () => {
          Logout()
        },
      })

      // reset
      dispatch({ type: 'INVALID_SESSION_CLEAR' })
    }
  }, [reqLogin])

  useEffect(() => {
    if ((mode && !_.isEqual(mode, preMode)) || (
      adv && !_.isEqual(adv, preAdv)
    )) {
      dispatch(clientConfigSet({ ...config, mode, adv }))
    }
  }, [mode, adv])

  useEffect(() => {
    if (accInfo && !_.isEqual(accInfo, preAccInfo)) {
      getVendorSetting()
    }
  }, [accInfo])

  useEffect(() => {
    if (toast && toast.msg && !_.isEqual(toast, prevToast)) {
      if (toast.type) {
        notify[toast.type](toast.msg?.message || toast.msg)
      } else notify.info(toast.msg?.message || toast.msg)
      dispatch({ type: 'CLEAR_TOAST' })
    }
  }, [toast])

  function getAccountInfo() {
    const _data = {
      cmd: 'GetAccountInfo',
      type: 'cursor',
      p1: token?.user,
    }
    const _param = renderParams(token, _data, 'B')
    dispatch(accInfoRequest(_param))
  }

  function getVendorSetting() {
    const _data = {
      cmd: 'GetBranchInfo',
      type: 'cursor',
      p1: accInfo?.C_BRANCH_CODE,
    }
    const _param = renderParams(token, _data, 'B')
    dispatch(branchInfoRequest(_param))
  }

  const Logout = () => {
    dispatch(clientTokenUnset())
    dispatch(unsetLogin())
    storages.removeState('token')
    storages.removeState('ttl')

    // axios.get('https://uat-vst.algoplatform.vn/logout')

    setTimeout(() => {
      // navigate.push('/login');
      setShowLoginModal(true)
    }, 100)
  }

  const resetTimeout = () => {
    // console.log('resetTimeout');
    clearTimeoutFunc()
    setTimeOut()
  }

  const clearTimeoutFunc = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  const setTimeOut = () => {
    // console.log('timeToLive', timeToLive);
    logoutTimeout = setTimeout(_actionTimeToLive, timeToLive * 60 * 1000)
  }

  const _actionTimeToLive = () => {
    dispatch({ type: 'INVALID_SESSION' })

    dispatch(clientTokenUnset())
    storages.removeState('token')
    storages.removeState('ttl')
  }

  const getListAccount = () => {
    const params = {
      group: 'B',
      user: token.user,
      session: token.sid,
      data: {
        type: 'cursor',
        cmd: 'ListAccount',
      },
    }

    // request list account
    dispatch(listAccountRequest(params))
  }

  function handleClose() {
    setShowLoginModal(false)
  }

  if (mode === 'FRAME')
    return (
      <div className="app overflow-hidden h-screen bg-skin-body text-skin-base w-screen relative">
        <main className={'main w-full h-full p-3'}>
          <Suspense fallback={<Loading />}>
            <router.Switch>
              {/* <PrivateRoute
                path={['/price/dashboard']}
                component={Dashboard}
                dispatch={props}
              /> */}
              <PrivateRoute
                path={'/price/bang-gia/:categoryId?'}
                component={Banggia}
                dispatch={props}
              />
              <PrivateRoute
                path={'/dashboard'}
                component={Dashboard}
                dispatch={props}
              />
              <PrivateRoute
                path={['/account/:categoryId?', '/setting/:categoryId?']}
                component={Notfound}
                dispatch={props}
              />

              <router.Redirect from="/price" to="/price/bang-gia/vn30" />
              <router.Redirect
                from="/price/bang-gia"
                to="/price/bang-gia/vn30"
              />
              <router.Redirect from="/bang-gia" to="/price/bang-gia/vn30" />
            </router.Switch>
          </Suspense>
          <Suspense fallback={<Loading />}>
            <DefaultFooter />
          </Suspense>
        </main>
        <Tooltiped id="global" />
      </div>
    )

  return (
    <div className="app overflow-hidden h-screen bg-skin-body text-skin-base w-screen">
      <Suspense fallback={<Loading />}>
        <DefaultHeader token={token} />
      </Suspense>
      <div className="app-body h-[calc(100vh-94px)] md:h-[calc(100vh-48px)] relative ">
        <NavLeft typeNav={typeNav} />
        <main className={'main w-full md:w-[calc(100vw-72px)]'}>
          <div className="px-3 h-[calc(100vh-60px)]">
            <Suspense fallback={<Loading />}>
              <router.Switch>
                <PrivateRoute
                  path={['/price/dashboard']}
                  component={Dashboard}
                  dispatch={props}
                />
                <PrivateRoute
                  path={'/price/bang-gia/:categoryId?'}
                  component={Banggia}
                  dispatch={props}
                />
                <PrivateRoute
                  path={'/dashboard/:categoryId?'}
                  component={Dashboard}
                  dispatch={props}
                />
                <PrivateRoute
                  path={'/account/:categoryId?'}
                  component={EffectPage}
                  dispatch={props}
                />
                <PrivateRoute
                  path={['/setting/:categoryId?']}
                  component={AccountInfo}
                  dispatch={props}
                />

                <router.Redirect from="/price" to="/price/bang-gia/vn30" />
                <router.Redirect
                  from="/price/bang-gia"
                  to="/price/bang-gia/vn30"
                />
                <router.Redirect from="/bang-gia" to="/price/bang-gia/vn30" />
              </router.Switch>
            </Suspense>
          </div>
          <Suspense fallback={<Loading />}>
            <DefaultFooter />
          </Suspense>
        </main>
      </div>
      <ToastContainer />
      <Tooltiped id="global" />
      {showLoginModal && <LoginModal onClose={() => handleClose()} />}
    </div>
  )
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken()
  const getConfig = makeGetConfig()
  const getClientSetting = makeGetClientSetting()
  const getToast = makeGetToast()
  const getTypeNav = makeGetTypeNav()
  const getAccountInfo = makeGetAccountInfo()

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      config: getConfig(state),
      accInfo: getAccountInfo(state),
      settings: getClientSetting(state),
      toast: getToast(state),
      typeNav: getTypeNav(state),
      regSym: state.socket.regSym,
      reqLogin: state.client.reqLogin,
      showNav: state.client.showNav,
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps)(DefaultLayout)
