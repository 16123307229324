import i18n from 'i18next';

export const required = (value: any) =>
  value ? undefined : i18n.t('valid.required');

export const lengthRequired = (length: number) => (value: any) =>
  value && value.length === length
    ? undefined
    : i18n.t('valid.lengthRequired', { length: length });

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max
    ? i18n.t('valid.maxLength', { max: max })
    : undefined;

export const minLength = (min: number) => (value: any) =>
  value && value.length < min
    ? i18n.t('valid.minLength', { min: min })
    : undefined;

export const number = (value: any) =>
  value && isNaN(Number(value)) ? i18n.t('valid.number') : undefined;

export const validatePhone = (value: any) => {
  if (!value) return;

  let vnf_regex = /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/;
  const _value = value.replace(/ /g, '');
  if (!_value || (vnf_regex.test(_value) && _value.indexOf('_') < 0))
    return undefined;

  return i18n.t('valid.validatePhone');
};

export const validateBirth = (value: any) => {
  if (!value) return;

  let vnf_regex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  // console.log(vnf_regex.test(value))
  if (!value || (vnf_regex.test(value) && value.indexOf('_') < 0))
    return undefined;

  return i18n.t('valid.validateBirth');
};

export const validateEmail = (value: any) => {
  if (!value) return;
  var re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (!value || re.test(value)) return undefined;

  return i18n.t('valid.validateEmail');
};

export const validateNumber = (min: number, max: number) => (value: any) =>
  Number(value) < min
    ? i18n.t('valid.validateNumber', { max: max, min: min })
    : Number(value) > max
      ? i18n.t('valid.validateNumber', { max: max, min: min })
      : undefined;

export const validateNumberExact = (value: any) =>
  Number(value) <= 0 ? i18n.t('valid.validateNumberExact') : undefined;

export const noWhiteSpace = (value: any) => {
  if (!value) return;

  var reWhiteSpace = new RegExp('\\s+');
  if (!reWhiteSpace.test(value)) return undefined;

  return i18n.t('valid.noWhiteSpace');
};
