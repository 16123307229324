import { createSelector } from 'reselect';

import * as _ from 'lodash';
import { AppState } from 'reducers';

const getStockDetail = (state: AppState) => state.stockDetail.stockDetail;
const getStockTrade = (state: AppState) => {
  const { stockTrade } = state.stockDetail;
  if (_.isArray(stockTrade)) {
    const _stockTrade = _.sortBy(stockTrade, ['time']);
    return _stockTrade.reverse();
  }
  return [];
};
const getStockCst = (state: AppState) => state.stockDetail.cst;
const getStockKQKD = (state: AppState) => state.stockDetail.kqkd;
const getStockCDKT = (state: AppState) => state.stockDetail.cdkt;
const getStockHis = (state: AppState) => state.stockDetail.stockHis;
const getStockNews = (state: AppState) => state.stockDetail.stockNews;
const getNewsDetail = (state: AppState) => state.stockDetail.newsDetail;
const getStockEvents = (state: AppState) => state.stockDetail.stockEvents;
const getStockManage = (state: AppState) => state.stockDetail.stockManage;
const getCompanyInfo = (state: AppState) => state.stockDetail.companyInfo;
const getAssociate = (state: AppState) => state.stockDetail.associate;
const getTransferData = (state: AppState) => state.stockDetail.transferData;
const getCollectionPrice = (state: AppState) =>
  state.stockDetail.collectionPrice;
const getTopMatch = (state: AppState) => state.stockDetail.topMatch;

/************************************** MAKE GET ***************************************/

export const makeGetTopMatch = () =>
  createSelector([getTopMatch], (topMatch) => topMatch);

export const makeGetCollectionPrice = () =>
  createSelector([getCollectionPrice], (collectionPrice) => collectionPrice);

export const makeGetStockDetail = () =>
  createSelector([getStockDetail], (stockDetail) => stockDetail);

export const makeGetStockTrade = () =>
  createSelector([getStockTrade], (stockTrade) => stockTrade);

export const makeGetStockCST = () =>
  createSelector([getStockCst], (cst) => cst);

export const makeGetStockKQKD = () =>
  createSelector([getStockKQKD], (kqkd) => kqkd);

export const makeGetStockCDKT = () =>
  createSelector([getStockCDKT], (cdkt) => cdkt);

export const makeGetStockHis = () =>
  createSelector([getStockHis], (stockHis) => stockHis);

export const makeGetStockNews = () =>
  createSelector([getStockNews], (stockNews) => stockNews);

export const makeGetNewsDetail = () =>
  createSelector([getNewsDetail], (newsDetail) => newsDetail);

export const makeGetStockEvents = () =>
  createSelector([getStockEvents], (stockEvents) => stockEvents);

export const makeGetStockManage = () =>
  createSelector([getStockManage], (stockManage) => stockManage);

export const makeGetCompanyInfo = () =>
  createSelector([getCompanyInfo], (companyInfo) => companyInfo);

export const makeGetAssociate = () =>
  createSelector([getAssociate], (associate) => associate);

export const makeGetTransferData = () =>
  createSelector([getTransferData], (transferData) => transferData);
