import * as actions from './actionType';

export interface StockDetailState {
  stockDetail: any;
  stockDetailRequesting: boolean;
  stockDetailSuccessful: boolean;
  stockDetailErrors: any;

  stockTrade: any;
  stockTradeRequesting: boolean;
  stockTradeSuccessful: boolean;
  stockTradeErrors: any;

  cst: any;
  cstRequesting: boolean;
  cstSuccessful: boolean;
  cstErrors: any;

  kqkd: any;
  kqkdRequesting: boolean;
  kqkdSuccessful: boolean;
  kqkdErrors: any;

  cdkt: any;
  cdktRequesting: boolean;
  cdktSuccessful: boolean;
  cdktErrors: any;

  stockHis: null;
  stockHisRequesting: boolean;
  stockHisSuccessful: boolean;
  stockHisErrors: any;

  stockNews: any;
  stockNewsRequesting: boolean;
  stockNewsSuccessful: boolean;
  stockNewsErrors: any;

  newsDetail: any;
  newsDetailRequesting: boolean;
  newsDetailSuccessful: boolean;
  newsDetailErrors: any;

  stockEvents: any;
  stockEventsRequesting: boolean;
  stockEventsSuccessful: boolean;
  stockEventsErrors: any;

  stockManage: any;
  stockManageRequesting: boolean;
  stockManageSuccessful: boolean;
  stockManageErrors: any;

  companyInfo: any;
  companyInfoRequesting: boolean;
  companyInfoSuccessful: boolean;
  companyInfoErrors: any;

  associate: any;
  transferData: any;

  collectionPrice: any;
  topMatch: any;
}

const initialState = {
  stockDetail: {},
  stockDetailRequesting: false,
  stockDetailSuccessful: false,
  stockDetailErrors: [],

  stockTrade: {},
  stockTradeRequesting: false,
  stockTradeSuccessful: false,
  stockTradeErrors: [],

  cst: {},
  cstRequesting: false,
  cstSuccessful: false,
  cstErrors: [],

  kqkd: {},
  kqkdRequesting: false,
  kqkdSuccessful: false,
  kqkdErrors: [],

  cdkt: {},
  cdktRequesting: false,
  cdktSuccessful: false,
  cdktErrors: [],

  stockHis: null,
  stockHisRequesting: false,
  stockHisSuccessful: false,
  stockHisErrors: [],

  stockNews: {},
  stockNewsRequesting: false,
  stockNewsSuccessful: false,
  stockNewsErrors: [],

  newsDetail: {},
  newsDetailRequesting: false,
  newsDetailSuccessful: false,
  newsDetailErrors: [],

  stockEvents: {},
  stockEventsRequesting: false,
  stockEventsSuccessful: false,
  stockEventsErrors: [],

  stockManage: {},
  stockManageRequesting: false,
  stockManageSuccessful: false,
  stockManageErrors: [],

  companyInfo: {},
  companyInfoRequesting: false,
  companyInfoSuccessful: false,
  companyInfoErrors: [],

  associate: [],
  transferData: [],

  collectionPrice: [],
  topMatch: [],
};

export default function stockDetailReducer(
  state: StockDetailState = initialState,
  action: actions.StockDetailAction,
): StockDetailState {
  switch (action.type) {
    case actions.STOCK_DETAIL_REQUESTING:
      return {
        ...state,
        stockDetailRequesting: true,
        stockDetailSuccessful: false,
        stockDetailErrors: [],
      };

    case actions.STOCK_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        stockDetail: action.resData,
        stockDetailRequesting: false,
        stockDetailSuccessful: true,
        stockDetailErrors: [],
      };

    case actions.STOCK_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        stockDetailRequesting: false,
        stockDetailSuccessful: false,
        stockDetailErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.STOCK_TRADE_REQUESTING:
      return {
        ...state,
        stockTradeRequesting: true,
        stockTradeSuccessful: false,
        stockTradeErrors: [],
      };

    case actions.STOCK_TRADE_REQUEST_SUCCESS:
      return {
        ...state,
        stockTrade: action.resData,
        stockTradeRequesting: false,
        stockTradeSuccessful: true,
        stockTradeErrors: [],
      };

    case actions.STOCK_TRADE_REQUEST_ERROR:
      return {
        ...state,
        stockTradeRequesting: false,
        stockTradeSuccessful: false,
        stockTradeErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.CST_REQUESTING:
      return {
        ...state,
        cstRequesting: true,
        cstSuccessful: false,
        cstErrors: [],
      };

    case actions.CST_REQUEST_SUCCESS:
      return {
        ...state,
        cst: action.resData,
        cstRequesting: false,
        cstSuccessful: true,
        cstErrors: [],
      };

    case actions.CST_REQUEST_ERROR:
      return {
        ...state,
        cstRequesting: false,
        cstSuccessful: false,
        cstErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.KQKD_REQUESTING:
      return {
        ...state,
        kqkdRequesting: true,
        kqkdSuccessful: false,
        kqkdErrors: [],
      };

    case actions.KQKD_REQUEST_SUCCESS:
      return {
        ...state,
        kqkd: action.resData,
        kqkdRequesting: false,
        kqkdSuccessful: true,
        kqkdErrors: [],
      };

    case actions.KQKD_REQUEST_ERROR:
      return {
        ...state,
        kqkdRequesting: false,
        kqkdSuccessful: false,
        kqkdErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.CDKT_REQUESTING:
      return {
        ...state,
        cdktRequesting: true,
        cdktSuccessful: false,
        cdktErrors: [],
      };

    case actions.CDKT_REQUEST_SUCCESS:
      return {
        ...state,
        cdkt: action.resData,
        cdktRequesting: false,
        cdktSuccessful: true,
        cdktErrors: [],
      };

    case actions.CDKT_REQUEST_ERROR:
      return {
        ...state,
        cdktRequesting: false,
        cdktSuccessful: false,
        cdktErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.STOCK_HIS_REQUESTING:
      return {
        ...state,
        stockHis: null,
        stockHisRequesting: true,
        stockHisSuccessful: false,
        stockHisErrors: [],
      };

    case actions.STOCK_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        stockHis: action.resData,
        stockHisRequesting: false,
        stockHisSuccessful: true,
        stockHisErrors: [],
      };

    case actions.STOCK_HIS_REQUEST_ERROR:
      return {
        ...state,
        stockHis: null,
        stockHisRequesting: false,
        stockHisSuccessful: false,
        stockHisErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.STOCK_NEWS_REQUESTING:
      return {
        ...state,
        stockNewsRequesting: true,
        stockNewsSuccessful: false,
        stockNewsErrors: [],
      };

    case actions.STOCK_NEWS_REQUEST_SUCCESS:
      return {
        ...state,
        stockNews: action.resData,
        stockNewsRequesting: false,
        stockNewsSuccessful: true,
        stockNewsErrors: [],
      };

    case actions.STOCK_NEWS_REQUEST_ERROR:
      return {
        ...state,
        stockNewsRequesting: false,
        stockNewsSuccessful: false,
        stockNewsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.NEWS_DETAIL_REQUESTING:
      return {
        ...state,
        newsDetailRequesting: true,
        newsDetailSuccessful: false,
        newsDetailErrors: [],
      };

    case actions.NEWS_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        newsDetail: action.resData,
        newsDetailRequesting: false,
        newsDetailSuccessful: true,
        newsDetailErrors: [],
      };

    case actions.NEWS_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        newsDetailRequesting: false,
        newsDetailSuccessful: false,
        newsDetailErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.STOCK_EVENTS_REQUESTING:
      return {
        ...state,
        stockEventsRequesting: true,
        stockEventsSuccessful: false,
        stockEventsErrors: [],
      };

    case actions.STOCK_EVENTS_REQUEST_SUCCESS:
      return {
        ...state,
        stockEvents: action.resData,
        stockEventsRequesting: false,
        stockEventsSuccessful: true,
        stockEventsErrors: [],
      };

    case actions.STOCK_EVENTS_REQUEST_ERROR:
      return {
        ...state,
        stockEventsRequesting: false,
        stockEventsSuccessful: false,
        stockEventsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.STOCK_MANAGEMENT_REQUESTING:
      return {
        ...state,
        stockManageRequesting: true,
        stockManageSuccessful: false,
        stockManageErrors: [],
      };

    case actions.STOCK_MANAGEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        stockManage: action.resData,
        stockManageRequesting: false,
        stockManageSuccessful: true,
        stockManageErrors: [],
      };

    case actions.STOCK_MANAGEMENT_REQUEST_ERROR:
      return {
        ...state,
        stockManageRequesting: false,
        stockManageSuccessful: false,
        stockManageErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.COMPANY_INFO_REQUESTING:
      return {
        ...state,
        companyInfoRequesting: true,
        companyInfoSuccessful: false,
        companyInfoErrors: [],
      };

    case actions.COMPANY_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        companyInfo: action.resData,
        companyInfoRequesting: false,
        companyInfoSuccessful: true,
        companyInfoErrors: [],
      };

    case actions.COMPANY_INFO_REQUEST_ERROR:
      return {
        ...state,
        companyInfoRequesting: false,
        companyInfoSuccessful: false,
        companyInfoErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.ASSOCIATE_REQUESTING:
    case actions.ASSOCIATE_REQUEST_ERROR:
      return {
        ...state,
        associate: [],
      };
    case actions.ASSOCIATE_REQUEST_SUCCESS:
      return {
        ...state,
        associate: action.resData,
      };

    case actions.TRANSFER_DATA_REQUESTING:
    case actions.TRANSFER_DATA_REQUEST_ERROR:
      return {
        ...state,
        transferData: [],
      };
    case actions.TRANSFER_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        transferData: action.resData,
      };

    case actions.COLLECTION_PRICE_REQUESTING:
    case actions.COLLECTION_PRICE_REQUEST_ERROR:
      return {
        ...state,
        collectionPrice: [],
      };
    case actions.COLLECTION_PRICE_REQUEST_SUCCESS:
      return {
        ...state,
        collectionPrice: action.resData,
      };

    case actions.TOP_MATCH_REQUESTING:
    case actions.TOP_MATCH_REQUEST_ERROR:
      return {
        ...state,
        topMatch: [],
      };
    case actions.TOP_MATCH_REQUEST_SUCCESS:
      return {
        ...state,
        topMatch: action.resData,
      };

    case actions.CLEAR_CACHE_STOCK_DETAIL:
      return {
        ...state,
        stockDetailRequesting: false,
        stockDetailSuccessful: false,
        stockDetailErrors: [],

        stockTrade: {},
        stockTradeRequesting: false,
        stockTradeSuccessful: false,
        stockTradeErrors: [],

        cst: {},
        cstRequesting: false,
        cstSuccessful: false,
        cstErrors: [],

        kqkd: {},
        kqkdRequesting: false,
        kqkdSuccessful: false,
        kqkdErrors: [],

        cdkt: {},
        cdktRequesting: false,
        cdktSuccessful: false,
        cdktErrors: [],

        stockHis: null,
        stockHisRequesting: false,
        stockHisSuccessful: false,
        stockHisErrors: [],

        stockNews: {},
        stockNewsRequesting: false,
        stockNewsSuccessful: false,
        stockNewsErrors: [],

        newsDetail: {},
        newsDetailRequesting: false,
        newsDetailSuccessful: false,
        newsDetailErrors: [],

        stockEvents: {},
        stockEventsRequesting: false,
        stockEventsSuccessful: false,
        stockEventsErrors: [],

        stockManage: {},
        stockManageRequesting: false,
        stockManageSuccessful: false,
        stockManageErrors: [],

        companyInfo: {},
        companyInfoRequesting: false,
        companyInfoSuccessful: false,
        companyInfoErrors: [],

        associate: [],
        transferData: [],
      };

    default:
      return state;
  }
}
