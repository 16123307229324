/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { INormalSelect } from 'interface';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconPolygon } from 'assets/img/icon/icPolygon.svg';
import { ReactComponent as IconChecked } from 'assets/img/icon/icChecked.svg';
import _ from 'lodash';

interface Props {
  input: any;
  placeholderText?: string;
  disabled: boolean | undefined;
  opts: Array<INormalSelect> | undefined;
  required: boolean | undefined;
  meta: any;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const RenderNormalSelect: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const showRef = React.useRef<HTMLDivElement>(null);

  const [txtSearch, setTxtSearch] = React.useState<string>();
  const [showSelect, setShowSelect] = React.useState<boolean>(false);

  const {
    placeholderText,
    input,
    disabled,
    opts,
    required = false,
    meta: { touched, error },
  } = props;

  const preValue = usePrevious(input?.value);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showRef &&
        showRef.current &&
        !showRef.current?.contains(event.target)
      ) {
        setShowSelect(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    // See note below
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (!_.isEqual(input?.value, preValue)) {
      if (input?.value) {
        const _optSelected = _.find(
          opts,
          (o: INormalSelect) => o.value === input?.value,
        );
        if (_optSelected) {
          setTxtSearch(t(_optSelected.labelCode || _optSelected.label));
        }
      } else setTxtSearch(placeholderText || t('txt-chon'));
    }
  }, [input?.value]);

  function handleSelect(val: string) {
    setShowSelect(false);
    input?.onChange(val);
  }

  return (
    <div
      {...input}
      className="h-[30px] rounded-sm relative bg-transparent disabled:bg-skin-natural-2 border-[0.5px]"
      style={{
        borderColor: `${touched && error ? '#FF0000' : 'var(--color-border-3)'}`,
      }}
      disabled={disabled}
      ref={showRef}
    >
      <div
        className={
          'w-full h-full cursor-pointer flex items-center text-xs pl-2 pr-7 ' +
          (!input?.value ? 'text-skin-placeholder' : 'text-skin-base')
        }
        onClick={() => setShowSelect(!showSelect)}
      >
        {txtSearch || placeholderText || ''}
      </div>
      <ul
        className={
          'absolute z-10 bg-skin-natural-2 w-full left-0 top-[calc(100%+2px)] p-2 select-list-trading ' +
          (showSelect ? '' : 'hidden')
        }
      >
        {!required && (
          <li onClick={() => handleSelect('')}>-- {t('txt-chon')} --</li>
        )}
        {opts &&
          !!opts.length &&
          opts.map((item, index) => (
            <li
              key={index}
              onClick={() => handleSelect(item.value)}
              className={
                'relative ' + (input?.value === item.value ? 'selected' : '')
              }
            >
              {item.labelCode ? t(item.labelCode) : item.label}

              {input?.value === item.value && (
                <a className="absolute right-2 top-1/2 -translate-y-1/2 ">
                  <IconChecked className="w-3 h-auto" />
                </a>
              )}
            </li>
          ))}
      </ul>
      <a className="absolute right-2 top-1/2 -translate-y-1/2">
        <IconPolygon className="w-3 p-[1px] h-auto text-skin-placeholder" />
      </a>
    </div>
  );
};

export default RenderNormalSelect;
