import * as actions from './actionType';

export interface SummaryState {
  cashList: any;
  cashListRequesting: boolean;
  cashListErrors: any;
  shareEarn: any;

  cashCanAdv: any;
  cashCanAdvRequesting: boolean;
  cashCanAdvErrors: any;

  feeAdvWithdraw: any;
  advStt: any;
  advSttHis: any;
  listShareBalance: any;
  listShareStt: any;
  shareTransferHis: any;

  cashAccountInfo: any;
  beneficiaryAccount: any;

  listCashStt: any;
  cashTransferHis: any;
}

const initialState = {
  cashList: [],
  cashListRequesting: false,
  cashListErrors: [],
  shareEarn: null,
  cashCanAdv: [],
  cashCanAdvRequesting: false,
  cashCanAdvErrors: [],

  feeAdvWithdraw: null,
  advStt: null,
  advSttHis: null,
  listShareBalance: null,
  listShareStt: null,
  shareTransferHis: null,

  cashAccountInfo: null,
  beneficiaryAccount: null,

  listCashStt: null,
  cashTransferHis: null,
};

export default function summaryReducer(
  state: SummaryState = initialState,
  action: actions.SummaryAction,
): SummaryState {
  switch (action.type) {
    case actions.SUMMARY_CASH_CLEAR:
      return {
        ...state,
        cashList: [],
        cashListRequesting: false,
        cashListErrors: [],
      };
    case actions.SUMMARY_CASH_REQUESTING:
      return {
        ...state,
        cashList: [],
        cashListRequesting: true,
        cashListErrors: [],
      };

    case actions.SUMMARY_CASH_REQUEST_SUCCESS:
      return {
        ...state,
        cashList: action.cashList,
        cashListRequesting: false,
        cashListErrors: [],
      };

    case actions.SUMMARY_CASH_REQUEST_ERROR:
      return {
        ...state,
        cashList: [],
        cashListRequesting: false,
        cashListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.SUMMARY_SHARE_EARN_REQUESTING:
    case actions.SUMMARY_SHARE_EARN_REQUEST_ERROR:
      return {
        ...state,
        shareEarn: null,
      };
    case actions.SUMMARY_SHARE_EARN_REQUEST_SUCCESS:
      return {
        ...state,
        shareEarn: action.resData,
      };

    case actions.CASH_CAN_ADV_REQUESTING:
      return {
        ...state,
        cashCanAdv: [],
        cashCanAdvRequesting: true,
        cashCanAdvErrors: [],
      };

    case actions.CASH_CAN_ADV_REQUEST_SUCCESS:
      return {
        ...state,
        cashCanAdv: action.cashCanAdv,
        cashCanAdvRequesting: false,
        cashCanAdvErrors: [],
      };

    case actions.CASH_CAN_ADV_REQUEST_ERROR:
      return {
        ...state,
        cashCanAdv: [],
        cashCanAdvRequesting: false,
        cashCanAdvErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.FEE_ADV_WITHDRAW_REQUESTING:
    case actions.FEE_ADV_WITHDRAW_REQUEST_ERROR:
      return {
        ...state,
        feeAdvWithdraw: null,
      };
    case actions.FEE_ADV_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        feeAdvWithdraw: action.feeAdvWithdraw,
      };

    case actions.ADV_STATUS_REQUESTING:
    case actions.ADV_STATUS_REQUEST_ERROR:
      return {
        ...state,
        advStt: null,
      };
    case actions.ADV_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        advStt: action.advStt,
      };

    case actions.ADV_STATUS_HIS_REQUESTING:
    case actions.ADV_STATUS_HIS_REQUEST_ERROR:
      return {
        ...state,
        advSttHis: null,
      };
    case actions.ADV_STATUS_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        advSttHis: action.advSttHis,
      };

    case actions.LIST_SHARE_BALANCE_REQUESTING:
    case actions.LIST_SHARE_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        listShareBalance: null,
      };
    case actions.LIST_SHARE_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        listShareBalance: action.listShareBalance,
      };

    case actions.SHARE_TRANSFER_STATUS_REQUESTING:
    case actions.SHARE_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        listShareStt: null,
      };
    case actions.SHARE_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        listShareStt: action.listShareStt,
      };

    case actions.SHARE_TRANSFER_HIS_REQUESTING:
    case actions.SHARE_TRANSFER_HIS_REQUEST_ERROR:
      return {
        ...state,
        shareTransferHis: null,
      };
    case actions.SHARE_TRANSFER_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        shareTransferHis: action.shareTransferHis,
      };

    case actions.CASH_ACCOUNT_INFO_REQUESTING:
    case actions.CASH_ACCOUNT_INFO_REQUEST_ERROR:
      return {
        ...state,
        cashAccountInfo: null,
      };
    case actions.CASH_ACCOUNT_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        cashAccountInfo: action.cashAccountInfo,
      };

    case actions.ACCOUNT_BENEFICIARY_REQUESTING:
    case actions.ACCOUNT_BENEFICIARY_REQUEST_ERROR:
      return {
        ...state,
        beneficiaryAccount: null,
      };
    case actions.ACCOUNT_BENEFICIARY_REQUEST_SUCCESS:
      return {
        ...state,
        beneficiaryAccount: action.beneficiaryAccount,
      };

    case actions.CASH_TRANSFER_STATUS_REQUESTING:
    case actions.CASH_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        listCashStt: null,
      };
    case actions.CASH_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        listCashStt: action.listCashStt,
      };

    case actions.CASH_TRANSFER_HIS_REQUESTING:
    case actions.CASH_TRANSFER_HIS_REQUEST_ERROR:
      return {
        ...state,
        cashTransferHis: null,
      };
    case actions.CASH_TRANSFER_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        cashTransferHis: action.cashTransferHis,
      };

    default:
      return state;
  }
}
