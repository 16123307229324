import React from 'react';

interface Props {
  input: any;
  type: string | undefined;
  className: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
  autoFocus: boolean | undefined;
  showErr: boolean | undefined;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  meta: any;
}

const RenderInput: React.FunctionComponent<Props> = (props) => {
  const {
    input,
    type = 'text',
    placeholder,
    autoFocus = false,
    onKeyDown,
    disabled,
    className,
    showErr = true,
    meta: { touched, error },
  } = props;
  return (
    <div>
      <input
        {...input}
        type={type}
        className={
          'h-7 bg-transparent py-1 px-2 rounded-sm text-input-trading text-xs text-skin-base w-full border-[0.5px] placeholder:capitalize ' +
          (className || '')
        }
        placeholder={placeholder}
        autoComplete="off"
        autoFocus={autoFocus}
        disabled={disabled}
        onKeyDown={onKeyDown ? onKeyDown : () => {}}
        onFocus={(e) => e.target.select()}
        style={{
          borderColor: `${
            touched && error ? 'var(--color-bg-sell)' : 'var(--color-border-3)'
          }`,
        }}
      />
      {touched && error && showErr && (
        <small
          className="text-left text-[11px]"
          style={{
            color: 'var(--color-bg-sell)',
            margin: '0 0 10px',
          }}
        >
          {error}
        </small>
      )}
    </div>
  );
};

export default RenderInput;
