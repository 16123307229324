import ReactModal from 'react-modal';
import { numberFormat } from 'utils';
import { useTranslation } from 'react-i18next';
import { BtnSubmit, BtnClose } from 'utils/styledUtils';
import React, { Fragment, memo } from 'react';
import _ from 'lodash';

const styleLg = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: 'auto',
    left: 'calc( 50% - 213px )',
    backgroundColor: '#1C192C',
    height: 'auto',
    width: '426px',
    padding: '0',
    border: 'none',
    overflow: 'inherit',
    borderRadius: '12px',
  },
};

const styleXs = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: 'auto',
    height: 'auto',
    left: '12px',
    right: '12px',
    backgroundColor: '#1C192C',
    padding: '0',
    border: 'none',
    overflow: 'inherit',
    borderRadius: '12px',
  },
};

interface Props {
  handleCloseModal: any;
  order: any;
  handleSubmitOrder: Function;
}

function FormConfirmOrder(props: Props) {
  const { t } = useTranslation();
  const { order, handleCloseModal, handleSubmitOrder } = props;
  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleSubmit() {
    handleSubmitOrder(order);
  }

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      style={dimensions.width >= 820 ? styleLg : styleXs}
    >
      <div style={{ border: 'none', background: 'transparent' }}>
        <div
          className="text-center text-sm"
          style={{
            backgroundColor: 'var(--color-bg-modal)',
            borderBottom: '1px solid #4E4D62',
            padding: '10px',
          }}
        >
          {t('trading.order.title-new-confirm')}
        </div>
        <div
          style={{ backgroundColor: 'var(--color-bg-modal)', padding: '20px' }}
        >
          <table className="table table-no-bordered w-full">
            <tbody>
              {order &&
                _.isArray(order) &&
                !!order.length &&
                order.map((item: any, index: number) => {
                  const _orderType = item?.data?.orderType.split('=')[1];
                  let arr = Array.from(_orderType);
                  const orderType = _.takeRight(arr, 3).join('');
                  const expireDate = _.take(arr, arr.length - 3).join('');
                  return (
                    <Fragment key={index}>
                      {index === 0 && (
                        <>
                          <tr>
                            <td className="text-sm py-2 px-[2px]">
                              {t('trading.order.account')}
                            </td>
                            <td className="text-sm  font-bold text-right">
                              {item?.data?.account}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm py-2 px-[2px]">
                              {t('trading.order.symbol')}
                            </td>
                            <td className="text-sm font-bold text-right">
                              {item?.data?.symbol}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm py-2 px-[2px]">
                              Hiệu lực đến ngày
                            </td>
                            <td className="text-sm font-bold text-right">
                              {expireDate}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className="text-sm py-2 px-[2px]">
                          {t('trading.order.type')}
                        </td>
                        <td
                          className={
                            'uppercase font-bold text-right ' +
                            (item?.data?.side === 'B' ? 'green' : 'red')
                          }
                        >
                          {item &&
                            (item.data.side === 'B'
                              ? t('trading.order.buy')
                              : t('trading.order.sell'))}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm py-2 px-[2px]">
                          Kích hoạt khi giá
                        </td>
                        <td className="text-sm font-bold text-right">
                          {`${orderType === 'SOL' ? '≤' : '≥'} ${item?.data?.price}`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm py-2 px-[2px]">
                          {t('trading.order.vol')}
                        </td>
                        <td className="text-sm font-bold text-right">
                          {numberFormat(item?.data?.volume, 0, '0')}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              {order && !_.isArray(order) && (
                <>
                  <tr>
                    <td className="text-sm py-2 px-[2px]">
                      {t('trading.order.account')}
                    </td>
                    <td className="text-sm  font-bold text-right">
                      {order?.data?.account}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm py-2 px-[2px]">
                      {t('trading.order.symbol')}
                    </td>
                    <td className="text-sm font-bold text-right">
                      {order?.data?.symbol}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm py-2 px-[2px]">
                      {t('trading.order.type')}
                    </td>
                    <td
                      className={
                        'uppercase font-bold text-right ' +
                        (order?.data?.side === 'B' ? 'green' : 'red')
                      }
                    >
                      {order &&
                        (order.data.side === 'B'
                          ? t('trading.order.buy')
                          : t('trading.order.sell'))}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm py-2 px-[2px]">
                      {t('trading.order.price')}
                    </td>
                    <td className="text-sm font-bold text-right">
                      {order?.data?.price}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm py-2 px-[2px]">
                      {t('trading.order.vol')}
                    </td>
                    <td className="text-sm font-bold text-right">
                      {numberFormat(order?.data?.volume, 0, '0')}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <div className="flex justify-center mt-2 ">
            <BtnClose
              type="button"
              onClick={handleCloseModal}
              className="w-32 !h-8 !text-sm"
            >
              {t('trading.order.cancel')}
            </BtnClose>
            <BtnSubmit
              type="button"
              onClick={handleSubmit}
              className="ml-2 w-32 !h-8 !text-sm"
            >
              {t('trading.order.xac-nhan')}
            </BtnSubmit>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default memo(FormConfirmOrder);
