import * as actions from './actionType';

export interface StockState {
  stockList: any;
}

const initialState = {
  stockList: null,
};

export default function stockReducer(
  state: StockState = initialState,
  action: actions.StockAction,
): StockState {
  switch (action.type) {
    // case actions.STOCK_SEARCH_REQUESTING:
    case actions.STOCK_SEARCH_ERROR:
      return {
        ...state,
        stockList: null,
      };

    case actions.STOCK_SEARCH_SUCCESS:
      return {
        ...state,
        stockList: action.resData,
      };

    default:
      return state;
  }
}
