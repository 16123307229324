import * as _ from 'lodash';
import { StringToDouble } from 'utils';
import { processMapData } from 'utils/processMapData';
import * as actions from './actionType';

export interface PriceBoardState {
  secInfoAdv: any;
  allStock: any;
  allStockRequesting: boolean;
  allStockSuccessful: boolean;
  allStockErrors: any;

  listMessage: any;

  snapshot: any;
  snapshotRequesting: boolean;
  snapshotSuccessful: boolean;
  snapshotErrors: any;

  snapshotCW: any;
  psSnapshot: any;
  psIndex: any;
  ndataSnapshot: any;
  tradeHisPs: any;
  advStockData: any;

  part: any;
  partRequesting: boolean;
  partSuccessful: boolean;

  partId: any;
  partIdRequesting: boolean;
  partIdSuccessful: boolean;
  partIdErrors: any;

  allOrder: any;
  allOrdCond: any;
  marginList: any;
  ptList: any;
  psList: any;
  vcbsFilterList: any;
  vcbsFilterSnapshot: any;
  lole: any;
  cwList: any;
  list30: any;
  listNganh: any;
  listEtf: any;
  branchList: any;
  noicap: any;
  indSum: any;
  dmNganh: any;
  topInterest: any;

  sort: any;

  indexList: any;
  requesting: boolean;
  successful: boolean;
  errors: any;
}

const initialState = {
  secInfoAdv: [],
  allStock: [],
  allStockRequesting: false,
  allStockSuccessful: false,
  allStockErrors: [],

  listMessage: null,

  snapshot: [],
  snapshotRequesting: false,
  snapshotSuccessful: false,
  snapshotErrors: [],

  snapshotCW: [],
  psSnapshot: [],
  psIndex: [],
  ndataSnapshot: null,
  tradeHisPs: [],
  advStockData: [],

  part: [],
  partRequesting: false,
  partSuccessful: false,

  partId: [],
  partIdRequesting: false,
  partIdSuccessful: false,
  partIdErrors: [],

  allOrder: null,
  allOrdCond: null,
  marginList: [],
  ptList: [],
  psList: [],
  vcbsFilterList: [],
  vcbsFilterSnapshot: null,
  lole: [],
  cwList: null,
  list30: null,
  listNganh: null,
  listEtf: null,
  branchList: null,
  noicap: null,
  indSum: null,
  dmNganh: [],
  topInterest: [],

  sort: [],

  indexList: [],
  requesting: false,
  successful: false,
  errors: [],
};

export default function priceBoardActionReducer(
  state: PriceBoardState = initialState,
  action: actions.PriceBoardAction,
): PriceBoardState {
  switch (action.type) {
    case actions.ALL_STOCK_REQUESTING:
      return {
        ...state,
        allStockRequesting: false,
        allStockSuccessful: false,
        allStockErrors: [],
      };

    case actions.ALL_STOCK_REQUEST_SUCCESS:
      return {
        ...state,
        allStock: action.allStock,
        allStockRequesting: false,
        allStockSuccessful: true,
        allStockErrors: [],
      };

    case actions.ALL_STOCK_REQUEST_ERROR:
      return {
        ...state,
        allStockRequesting: false,
        allStockSuccessful: false,
        allStockErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.SNAPSHOT_REQUESTING:
      return {
        ...state,
        snapshotRequesting: true,
        snapshotSuccessful: false,
        snapshotErrors: [],
      };

    case actions.SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        snapshot: action.dataList, // replace with fresh list
        snapshotRequesting: false,
        snapshotSuccessful: true,
        snapshotErrors: [],
        lole: [],
        ptList: [],
        // psSnapshot: [],
        tradeHisPs: null,
        ndataSnapshot: null,
        // cwList: null,
        partId: [],
      };

    case actions.SNAPSHOT_REQUEST_ERROR:
      return {
        ...state,
        snapshotRequesting: false,
        snapshotSuccessful: false,
        snapshotErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.CLEAR_PART_REQUEST:
      return {
        ...state,
        part: [],
        partRequesting: false,
        partSuccessful: false,
      };

    case actions.CLEAR_SNAPSHOT_REQUEST:
      return {
        ...state,
        snapshot: [],
        snapshotCW: [],
        snapshotRequesting: false,
        snapshotSuccessful: false,
      };

    case actions.SNAPSHOT_PART_REQUESTING:
      return {
        ...state,
        partRequesting: true,
        partSuccessful: false,
      };

    case actions.SNAPSHOT_PART_REQUEST_SUCCESS:
      return {
        ...state,
        part: [...state.part, ...action.dataList], // replace with fresh list
        partRequesting: false,
        partSuccessful: true,
      };

    case actions.SNAPSHOT_PART_REQUEST_ERROR:
      return {
        ...state,
        partRequesting: false,
        partSuccessful: false,
      };

    case actions.STOCK_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        snapshot: [...state.snapshot, ...action.stockInfo],
      };

    case actions.CLEAR_ORD_LIST:
      return {
        ...state,
        allOrder: null,
        allOrdCond: null,
      };
    case actions.ALL_ORD_REQUEST_ERROR:
      return {
        ...state,
        allOrder: null,
      };

    case actions.ALL_ORD_REQUEST_SUCCESS:
      return {
        ...state,
        allOrder: action.allOrder,
      };

    case actions.ALL_ORD_COND_REQUEST_ERROR:
      return {
        ...state,
        allOrdCond: null,
      };

    case actions.ALL_ORD_COND_REQUEST_SUCCESS:
      return {
        ...state,
        allOrdCond: action.resData,
      };

    case actions.PT_LIST_REQUESTING:
    case actions.PT_LIST_REQUEST_ERROR:
      return {
        ...state,
        ptList: [],
      };

    case actions.PT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ptList: action.ptList,
      };

    case actions.PS_LIST_REQUESTING:
    case actions.PS_LIST_REQUEST_ERROR:
      return {
        ...state,
        psList: [],
      };

    case actions.PS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        psList: action.psList,
      };

    case actions.LOLE_SNAPSHOT_REQUESTING:
    case actions.LOLE_SNAPSHOT_REQUEST_ERROR:
    case actions.LOLE_HSX_SNAPSHOT_REQUESTING:
    case actions.LOLE_HSX_SNAPSHOT_REQUEST_ERROR:
      return {
        ...state,
        lole: [],
      };

    case actions.LOLE_SNAPSHOT_REQUEST_SUCCESS:
    case actions.LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        lole: action.dataList,
        ptList: [],
        // psSnapshot: [],
        tradeHisPs: null,
        ndataSnapshot: null,
        cwList: null,
        snapshotCW: [],
        snapshot: [],
        partId: [],
      };

    case actions.PS_SNAPSHOT_REQUESTING:
    case actions.PS_SNAPSHOT_REQUEST_ERROR:
      return {
        ...state,
        psSnapshot: [],
      };

    case actions.PS_SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        psSnapshot: action.dataList,
        // lole: [],
        // ptList: [],
        // cwList: null,
        // snapshotCW: [],
        // snapshot: [],
      };

    // case PS_INDEX_REQUESTING:
    // case PS_INDEX_REQUEST_ERROR:
    //   return {
    //     ...state,
    //     // psIndex: [],
    //   };

    case actions.PS_INDEX_REQUEST_SUCCESS:
      return {
        ...state,
        psIndex: action.dataList,
      };

    case actions.NDATA_SNAPSHOT_REQUESTING:
    case actions.NDATA_SNAPSHOT_REQUEST_ERROR:
      return {
        ...state,
        ndataSnapshot: null,
      };

    case actions.NDATA_SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        ndataSnapshot: action.ndata,
      };

    case actions.TRADE_HIS_PS_Add_ROW:
      const { data } = action;
      const { tradeHisPs } = state;
      const _tradeHis = _.isArray(tradeHisPs) ? [...tradeHisPs] : [];
      const __tradeHis = _.concat([data], _tradeHis);
      return {
        ...state,
        tradeHisPs: __tradeHis,
      };

    case actions.SORT_TYPE:
      const { _type } = action;
      const { snapshot, partId } = state;
      let _csData;

      console.log(_type, snapshot, partId)

      if (snapshot && !!snapshot.length) {
        if (_type && !!_type.length) {
          if (
            _type[0] === 'c' ||
            _type[0] === 'f' ||
            _type[0] === 'r' ||
            _type[0] === 'lot' ||
            _type[0] === 'totVal' ||
            _type[0] === 'fRoom' ||
            _type[0] === 'fBVol' ||
            _type[0] === 'fSVolume' ||
            _type[0] === 'highPrice' ||
            _type[0] === 'lowPrice' ||
            _type[0] === 'lastPrice' ||
            _type[0] === 'lastVolume' ||
            _type[0] === 'avePrice'
          ) {
            _csData = _.sortBy(snapshot, [
              function (obj) {
                return Number(obj[_type[0]]);
              },
            ]);
          } else if (_type[0] === 'changePc' || _type[0] === 'change') {
            _csData = _.sortBy(snapshot, [
              function (obj) {
                const _cl = Number(obj.lastPrice) < Number(obj.r) ? -1 : 1;
                return _cl * Math.abs(StringToDouble(obj[_type[0]]));
              },
            ]);
          } else {
            _csData = _.sortBy(snapshot, [_type[0]]);
          }
          if (_type[1] !== 'asc') _csData = _csData.reverse();
        }

        return {
          ...state,
          snapshot: _csData,
        };
      } else if (partId && !!partId.length) {
        if (_type && !!_type.length) {
          if (
            _type[0] === 'c' ||
            _type[0] === 'f' ||
            _type[0] === 'r' ||
            _type[0] === 'lot' ||
            _type[0] === 'totVal' ||
            _type[0] === 'fRoom' ||
            _type[0] === 'fBVol' ||
            _type[0] === 'fSVolume' ||
            _type[0] === 'highPrice' ||
            _type[0] === 'lowPrice' ||
            _type[0] === 'lastPrice' ||
            _type[0] === 'lastVolume' ||
            _type[0] === 'avePrice'
          ) {
            _csData = _.sortBy(partId, [
              function (obj) {
                return Number(obj[_type[0]]);
              },
            ]);
          } else if (_type[0] === 'changePc' || _type[0] === 'change') {
            _csData = _.sortBy(partId, [
              function (obj) {
                // console.log(obj)
                const _cl = Number(obj.lastPrice) < Number(obj.r) ? -1 : 1;
                return _cl * Math.abs(Number(obj[_type[0]] || '0'));
              },
            ]);
            // console.log(_csData)
          } else {
            _csData = _.sortBy(partId, [_type[0]]);
          }
          if (_type[1] !== 'asc') _csData = _csData.reverse();
        }

        return {
          ...state,
          partId: _csData,
        };
      } else
        return {
          ...state,
        };

    case actions.TRADE_HIS_PS_REQUESTING:
    case actions.TRADE_HIS_PS_REQUEST_ERROR:
      return {
        ...state,
        tradeHisPs: null,
      };

    case actions.TRADE_HIS_PS_REQUEST_SUCCESS:
      return {
        ...state,
        tradeHisPs: action.dataList,
      };

    case actions.CW_LIST_REQUESTING:
    case actions.CW_LIST_REQUEST_ERROR:
      return {
        ...state,
        cwList: null,
        snapshotCW: [],
        snapshot: [],
      };

    case actions.CW_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        cwList: action.cwList,
      };

    case actions.LIST_30_REQUESTING:
    case actions.LIST_30_REQUEST_ERROR:
      return {
        ...state,
        list30: null,
      };

    case actions.LIST_30_REQUEST_SUCCESS:
      return {
        ...state,
        list30: action.list30,
      };

    case actions.ADV_SEC_INFO_REQUESTING:
    case actions.ADV_SEC_INFO_ERROR:
      return {
        ...state,
        secInfoAdv: [],
      };

    case actions.ADV_SEC_INFO_SUCCESS:
      return {
        ...state,
        secInfoAdv: action.data,
      };

    case actions.VCBS_FILTER_LIST_REQUESTING:
    case actions.VCBS_FILTER_LIST_ERROR:
      return {
        ...state,
        vcbsFilterList: [],
      };

    case actions.VCBS_FILTER_LIST_SUCCESS:
      return {
        ...state,
        vcbsFilterList: action.data,
      };

    case actions.VCBS_FILTER_SNAPSHOT_REQUESTING:
    case actions.VCBS_FILTER_SNAPSHOT_ERROR:
      return {
        ...state,
        vcbsFilterSnapshot: '',
      };

    case actions.VCBS_FILTER_SNAPSHOT_SUCCESS:
      return {
        ...state,
        vcbsFilterSnapshot: action.data,
      };

    case actions.STOCK_BY_ID_CLEAR:
      return {
        ...state,
        partId: [],
        partIdRequesting: false,
        partIdSuccessful: true,
        partIdErrors: [],
        listEtf: [],
        lole: [],
        ptList: [],
        // psSnapshot: [],
        tradeHisPs: null,
        ndataSnapshot: null,
        cwList: null,
        snapshotCW: [],
        snapshot: [],
      };

    case actions.ADV_STOCK_DATA_BYID_REQUESTING:
    case actions.ADV_STOCK_LIST_DATA_REQUESTING:
    case actions.ADV_STOCK_LIST_DATA_ERROR:
    case actions.ADV_STOCK_LIST_DATA_CLEAR:
      return {
        ...state,
        advStockData: null,
      };

    case actions.ADV_STOCK_LIST_DATA_SUCCESS:
      return {
        ...state,
        advStockData: action.data,
      };

    case actions.ADV_STOCK_SINGLE_SUCCESS:
      return {
        ...state,
        advStockData: [
          ...state.advStockData,
          ...processMapData._processMapDataAdv(action.data, state.secInfoAdv),
        ],
      };

    case actions.ADV_STOCK_DATA_BYID_SUCCESS:
      const _advSMooth = processMapData._processMapDataAdv(
        action.data,
        state.secInfoAdv,
      );
      return {
        ...state,
        advStockData: state.advStockData
          ? [...state.advStockData, ..._advSMooth]
          : _advSMooth,
      };

    case actions.LIST_NGANH_REQUESTING:
    case actions.LIST_NGANH_REQUEST_ERROR:
      return {
        ...state,
        listNganh: null,
      };

    case actions.LIST_NGANH_REQUEST_SUCCESS:
      return {
        ...state,
        listNganh: action.listNganh,
      };

    case actions.LIST_ETF_REQUESTING:
    case actions.LIST_ETF_REQUEST_ERROR:
      return {
        ...state,
        listEtf: null,
      };

    case actions.LIST_ETF_REQUEST_SUCCESS:
      // log('LIST_ETF_REQUEST_SUCCESS')
      return {
        ...state,
        listEtf: action.listEtf,
        lole: [],
        ptList: [],
        // psSnapshot: [],
        tradeHisPs: null,
        ndataSnapshot: null,
        cwList: null,
        snapshotCW: [],
        snapshot: [],
        partId: [],
      };

    case actions.LIST_MESSAGE_REQUESTING:
    case actions.LIST_MESSAGE_REQUEST_ERROR:
      return {
        ...state,
        listMessage: null,
      };

    case actions.LIST_MESSAGE_REQUEST_SUCCESS:
      return {
        ...state,
        listMessage: action.listMessage,
      };

    case actions.BRANCH_CODE_REQUESTING:
    case actions.BRANCH_CODE_REQUEST_ERROR:
      return {
        ...state,
        branchList: null,
      };

    case actions.BRANCH_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        branchList: action.branchList,
      };

    case actions.NOI_CAP_REQUESTING:
    case actions.NOI_CAP_REQUEST_ERROR:
      return {
        ...state,
        noicap: null,
      };

    case actions.NOI_CAP_REQUEST_SUCCESS:
      return {
        ...state,
        noicap: action.noicap,
      };

    case actions.SUMMARY_REQUESTING:
    case actions.SUMMARY_REQUEST_ERROR:
      return {
        ...state,
        indSum: null,
      };

    case actions.SUMMARY_REQUEST_SUCCESS:
      return {
        ...state,
        indSum: action.indSum,
      };

    case actions.DM_NGANH_REQUESTING:
    case actions.DM_NGANH_REQUEST_ERROR:
      return {
        ...state,
        dmNganh: null,
      };

    case actions.DM_NGANH_REQUEST_SUCCESS:
      return {
        ...state,
        dmNganh: action.dataList,
      };

    case actions.TOP_INTEREST_REQUESTING:
    case actions.TOP_INTEREST_REQUEST_ERROR:
      return {
        ...state,
        topInterest: null,
      };

    case actions.TOP_INTEREST_REQUEST_SUCCESS:
      return {
        ...state,
        topInterest: action.dataList,
      };

    case actions.SNAPSHOT_UNDERLYING_REQUESTING:
    case actions.SNAPSHOT_UNDERLYING_REQUEST_ERROR:
      return {
        ...state,
        snapshotCW: [],
      };

    case actions.SNAPSHOT_UNDERLYING_REQUEST_SUCCESS:
      return {
        ...state,
        snapshotCW: action.dataList,
      };

    case actions.MARGIN_LIST_REQUESTING:
    case actions.MARGIN_LIST_REQUEST_ERROR:
      return {
        ...state,
        marginList: [],
      };

    case actions.MARGIN_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        marginList: action.marginList,
      };

    case actions.STOCK_BY_ID_VER2_SUCCESS:
      return {
        ...state,
        partId: state.partId ? [...state.partId, ...action.data] : action.data,
      };

    case actions.GET_STOCK_BY_ID_REQUESTING:
      return {
        ...state,
        partIdRequesting: true,
        partIdSuccessful: false,
        partIdErrors: [],
      };

    case actions.GET_STOCK_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        partId: action.dataList,
        partIdRequesting: false,
        partIdSuccessful: true,
        partIdErrors: [],
        listEtf: [],
        lole: [],
        ptList: [],
        // psSnapshot: [],
        tradeHisPs: null,
        ndataSnapshot: null,
        cwList: null,
        snapshotCW: [],
        snapshot: [],
      };

    case actions.GET_STOCK_BY_ID_REQUEST_ERROR:
      return {
        ...state,
        partId: [],
        partIdRequesting: false,
        partIdSuccessful: false,
        partIdErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.INDEX_REQUESTING:
      return {
        ...state, // ensure that we don't erase fetched ones
        requesting: true,
        successful: false,
        errors: [],
      };

    case actions.INDEX_REQUEST_SUCCESS:
      return {
        ...state,
        indexList: action.indexList, // replace with fresh list
        requesting: false,
        successful: true,
        errors: [],
      };

    case actions.INDEX_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    default:
      return state;
  }
}
