/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  makeGetCashBalance,
  makeGetDefaultAccount,
  makeGetPositions,
  makeGetToken,
} from 'lib/selector';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { checkSum, getRandom, numberFormat } from 'utils';

import { setToast } from 'containers/client/actions';
import {
  cashBalanceRequest,
  positionsRequest
} from 'containers/privData/actions';
import * as _ from 'lodash';

import RenderFieldCheckbox from 'helper/input/renderCheckbox';

import { ReactComponent as IconNext } from 'assets/img/icon/iconNext.svg';
import { ICashBalance, IPositions } from 'interface/response';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { AppState } from 'reducers';
import { useConfig } from 'shared/configContext';
import LoadingIndicator from 'shared/loadingIndicator';
import { BtnSubmit } from 'utils/styledUtils';

function usePrevious(value: any) {
  const ref = React.useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  allPos?: IPositions[] | undefined;
  cashBalance?: ICashBalance;
  token?: any;
  onClose: Function;
  defAccount?: string;
}

function AssetComponent(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [checkALL, setCheckAll] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderCheck, setOrderCheck] = useState<string[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [pageSize] = React.useState<number>(15);
  const [symbolDetail, setSymbolDetail] = useState<string>('');

  const { defAccount, allPos, cashBalance, token, onClose } = props;

  const preDefAccount = usePrevious(defAccount);
  const { config } = useConfig();

  useEffect(() => {
    return () => {
      // dispatch(positionsRequestSuccess(null));
      // dispatch(cashBalanceRequestSuccess(null));
    };
  }, []);

  useEffect(() => {
    if (defAccount && !_.isEqual(defAccount, preDefAccount)) {
      getAccountPortfolio();
      getAccountStatus();
    }
  }, [defAccount]);

  function getAccountPortfolio() {
    const params = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.Portfolio.PortfolioStatus',
        p1: defAccount,
        p2: (page || 1) + '',
        p3: pageSize + '',
        p4: '', // symbol
      },
    };

    dispatch(positionsRequest(params));
  }

  function getAccountStatus() {
    if (!defAccount || !token) return;

    const cmdPortFolio = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.Portfolio.AccountStatus',
        p1: defAccount,
      },
    };

    dispatch(cashBalanceRequest(cmdPortFolio));
  }

  function _handleChangeChk(e: any) {
    const { checked } = e.target;
    setCheckAll(checked);
    if (checked) {
      let xxx: string[] = [];
      allPos?.forEach((item: IPositions) => {
        if (item.symbol !== 'TOTAL' && +item.avaiable_vol > 0)
          xxx.push(item.symbol);
      });
      setOrderCheck(xxx);
    } else {
      setOrderCheck([]);
    }
  }

  function _handleChange(e: any) {
    const { checked, name } = e.target;
    let xxx = [...orderCheck];

    if (checked) {
      xxx.push(name);
    } else {
      _.remove(xxx, (o) => o === name);
    }
    setOrderCheck(xxx);
  }

  function _handleClickDelAll() {
    if (!orderCheck.length) {
      handleToast('Hãy chọn mã để bán!', 'warning');
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui-confirm">
              <h1>{t('notice.xac-nhan')}</h1>
              <p>Bán tất cả các mã đã chọn</p>
              <button onClick={onClose}>{t('button.cancel')}</button>
              <button
                onClick={() => {
                  handleActionApprove();
                  onClose();
                }}
              >
                {t('button.accept')}
              </button>
            </div>
          );
        },
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    }
  }

  function asyncIterator(_array: any[]) {
    return {
      next: function () {
        if (_array.length) {
          return {
            value: _array.shift(),
            done: false,
          };
        } else {
          return {
            done: true,
          };
        }
      },
    };
  }

  function handleActionApprove() {
    var _ite = asyncIterator(orderCheck);
    setIsLoading(true);
    handleSellItems(_ite);
  }

  async function handleSellItems(_ite: {
    next: () =>
      | { value: any; done: boolean }
      | { done: boolean; value?: undefined };
  }) {
    const _iterator = _ite.next();
    if (_iterator.done) {
      handleToast('Gửi lệnh bán các mã đã chọn thành công', 'success');
      setIsLoading(false);
      setOrderCheck([]);
    } else {
      handleCancelOrder(_iterator.value)?.then((response) => {
        if (response.code < 1) {
          handleToast('Gửi lệnh bán lỗi: ' + response.rs, 'error');
          setIsLoading(false);
          setOrderCheck([]);

          getAccountPortfolio();
        } else {
          handleSellItems(_ite);
        }
      });
    }
  }

  function handleCancelOrder(data: string) {
    console.log(data);
    const _position = _.find(allPos, (o: IPositions) => o.symbol === data);
    if (!_position) return;

    const pData: any = {
      type: 'string',
      cmd: 'Web.newOrder',
      account: defAccount,
      side: 'S',
      symbol: data.toUpperCase(),
      volume: +_position.avaiable_vol,
      price: _position.market_price,
      advance: '',
      refId: token.user + '.H.' + getRandom(),
      orderType: '1',
      pin: '',
    };

    const params = {
      group: 'O',
      user: token.user,
      session: token.sid,
      checksum: checkSum(token.sid, pData),
      data: pData,
    };

    const request = fetch(`${config?.AuthUrl || ''}/TraditionalService`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    });
    return request
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => {
        setIsLoading(false);
        throw error;
      });
  }

  function handleToast(msg: string, type = 'info') {
    // log(msg)
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: 'Thông báo',
      type,
    };
    dispatch(setToast(toastMsg));
  }

  function _handleNextPage(step: number): void {
    if (step < 0 && page === 1) return;
    if (step > 0 && (allPos?.length || 0) < 15) return;

    setPage(page + step);
  }

  function handleDetailPosition(symbol: string) {
    if (symbol === symbolDetail) {
      setSymbolDetail('');
    } else setSymbolDetail(symbol);
  }

  const _sumGainLoss = allPos
    ? _.find(allPos, (o) => o.symbol === 'TOTAL')
    : null;

  return (
    <div className="relative">
      <div className="hidden md:flex text-[13px] items-center">
        <IoClose className="ml-auto" size={24} onClick={() => onClose()} />
      </div>
      <div className="flex flex-col w-full mt-2">
        <div className="w-full bg-skin-natural-2 p-3 rounded grid grid-cols-2 gap-y-2">
          <div className="grid">
            <span className="text-xs font-normal uppercase leading-4 text-skin-text3">
              {t('account.modal.accStt.nav')}
            </span>
            <span className="text-2xl text-skin-text3 leading-10 font-medium">
              {numberFormat(cashBalance?.total_equity || 0, 0, '')}
            </span>
          </div>
          <div className="grid">
            <span className="text-xs font-normal uppercase leading-4 text-skin-text3 text-right">
              {t('account.modal.accStt.lai-lo')}
            </span>
            <span
              className={
                'text-xl leading-9 font-medium text-right ' +
                (+(_sumGainLoss?.gain_loss_value ?? 0) > 0
                  ? 'text-skin-up'
                  : +(_sumGainLoss?.gain_loss_value ?? 0) < 0
                    ? 'text-skin-down'
                    : '')
              }
            >
              {numberFormat(_sumGainLoss?.gain_loss_value ?? 0, 0, '0')}
              <span className="text-xs font-normal">
                ({_sumGainLoss?.gain_loss_per})
              </span>
            </span>
          </div>
          {/* <div className="grid col-span-2 gap-y-1">
            <span className="text-xs font-normal leading-4 text-skin-placeholder">
              Tài sản ròng
            </span>
            <span className="text-sm text-skin-ref leading-[18px] font-medium">
              {numberFormat(cashBalance?.total_equity || 0, 0, '')}
            </span>
          </div> */}
          <div className="grid col-span-2 grid-cols-3">
            <div className="grid text-center gap-y-1 border-r border-skin-bd3">
              <span className="text-xs font-normal leading-4 text-skin-placeholder">
                {t('account.modal.accStt.total-debt')}
              </span>
              <span className="text-sm text-skin-text-3 leading-[18px] font-medium">
                {numberFormat(cashBalance?.debt || 0, 0, '0')}
              </span>
            </div>
            <div className="grid text-center gap-y-1 border-r border-skin-bd3">
              <span className="text-xs font-normal leading-4 text-skin-placeholder">
                {t('account.modal.accStt.avai-colla')}
              </span>
              <span className="text-sm text-skin-text-3 leading-[18px] font-medium">
                {numberFormat(cashBalance?.ee || 0, 0, '0')}
              </span>
            </div>
            <div className="grid text-center gap-y-1">
              <span className="text-xs font-normal leading-4 text-skin-placeholder">
                {t('account.modal.accStt.securities-value')}
              </span>
              <span className="text-sm text-skin-text-3 leading-[18px] font-medium">
                {numberFormat(_sumGainLoss?.market_value || 0, 0, '0')}
              </span>
            </div>
          </div>
        </div>
        <table className="table table-bordered w-full mt-3">
          <thead>
            <tr>
              <th className="text-center p-0 w-8">
                <RenderFieldCheckbox
                  name="checkAll"
                  onChange={_handleChangeChk}
                />
              </th>
              <th className="text-left">{t('trading.table.symbol')}</th>
              <th className="text-right">{t('trading.table.price-ave')}</th>
              <th className="text-right">{t('trading.table.market-price')}</th>
              <th className="text-right">{t('trading.table.vol')}</th>
              <th className="text-left">% {t('trading.table.profit-loss')}</th>
            </tr>
          </thead>
          <tbody>
            {allPos &&
              !!allPos.length &&
              allPos.map((item: IPositions, index: number) => {
                if (item.symbol === 'TOTAL') return null;
                // return (
                //   <tr key={index}>
                //     <td className="text- p-0" colSpan={5}>
                //       {item.symbol}
                //     </td>

                //     <td
                //       className={
                //         'text-center ' +
                //         (+item.gain_loss_value > 0
                //           ? 'text-skin-up'
                //           : 'text-skin-down')
                //       }
                //     >
                //       {item.gain_loss_per}
                //     </td>
                //   </tr>
                // );

                return (
                  <Fragment key={index}>
                    <tr>
                      <td className="text-center p-0">
                        {+item.avaiable_vol > 0 && (
                          <RenderFieldCheckbox
                            name={item.symbol}
                            onChange={_handleChange}
                            checked={_.some(
                              orderCheck,
                              (o) => o === item.symbol,
                            )}
                          />
                        )}
                      </td>
                      <td
                        className="cursor-pointer"
                        onClick={() => handleDetailPosition(item.symbol)}
                      >
                        {item.symbol}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.avg_price, 4, '0')}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.market_price, 2, '0')}
                      </td>

                      <td className="text-right ">
                        {numberFormat(item.actual_vol, 0, '0')}
                      </td>
                      <td
                        className={
                          'text-center ' +
                          (+item.gain_loss_value > 0
                            ? 'text-skin-up'
                            : 'text-skin-down')
                        }
                      >
                        {item.gain_loss_per}
                      </td>
                    </tr>
                    {symbolDetail === item.symbol && (
                      <tr>
                        <td colSpan={6}>
                          <div className="w-full grid grid-cols-2 gap-1 my-1">
                            <div className="rounded bg-skin-natural-2 p-2 col-span-2 grid grid-cols-3 gap-1 text-center">
                              <div className="grid">
                                <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                  {t('trading.table.total-cap')}
                                </span>
                                <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                  {numberFormat(
                                    +item.actual_vol * +item.avg_price * 1000,
                                    0,
                                    '0',
                                  )}
                                </span>
                              </div>
                              <div className="grid">
                                <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                  {t('trading.table.cap-market')}
                                </span>
                                <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                  {numberFormat(item.market_value, 0, '0')}
                                </span>
                              </div>
                              <div className="grid">
                                <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                  {t('trading.table.gain-loss')}
                                </span>
                                <span
                                  className={
                                    'text-center text-xs leading-[18px] font-medium ' +
                                    (+item.gain_loss_value > 0
                                      ? 'text-skin-up'
                                      : 'text-skin-down')
                                  }
                                >
                                  {numberFormat(item.gain_loss_value, 0, '0')}
                                </span>
                              </div>
                            </div>
                            <div className="grid bg-skin-natural-2 p-2">
                              <ul>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('account.modal.accStt.total-vol')}
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.actual_vol, 0, '0')}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('account.modal.accStt.stock-dividend')}
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.right_vol, 0, '0')}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('account.modal.accStt.sell-unmatch')}
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(
                                      item.sell_unmatch_vol,
                                      0,
                                      '0',
                                    )}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('account.modal.accStt.buy-unmatch')}
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.buy_unmatch_vol, 0, '0')}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="grid bg-skin-natural-2 p-2">
                              <ul>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('trading.order.buy')} T0
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.buy_t0, 0, '0')}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('trading.order.sell')} T0
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.sell_t0, 0, '0')}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('trading.order.sell')} T1
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.sell_t1, 0, '0')}
                                  </span>
                                </li>
                                <li className="flex items-center justify-between py-1">
                                  <span className="text-xs font-normal leading-4 text-skin-placeholder">
                                    {t('trading.order.buy')} T2
                                  </span>
                                  <span className="text-xs text-skin-text-3 leading-[18px] font-medium">
                                    {numberFormat(item.sell_t2, 0, '0')}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
        {(!allPos || !allPos.length) && (
          <p className="text-center text-xs my-2">
            {t('trading.order-book.no-data-found')}
          </p>
        )}
        {allPos === null && (
          <p className="text-center text-xs content-read my-2">Loading...</p>
        )}
        <div className="w-full my-3 footer-component grid grid-cols-[auto_60px] gap-1">
          <BtnSubmit
            className={'!h-8 !text-sm !font-medium w-full danger '}
            onClick={_handleClickDelAll}
            disabled={!orderCheck?.length}
          >
            {t('trading.order.sell')}
          </BtnSubmit>
          <div className="grid grid-cols-2 paging-type2">
            <div
              className={
                'w-full h-full flex items-center justify-center ' +
                (page === 1 ? 'disabled' : '')
              }
              onClick={() => _handleNextPage(-1)}
            >
              <IconNext className="rotate-180" />
            </div>
            <div
              className={
                'w-full h-full flex items-center justify-center ' +
                (allPos && allPos.length < 15 ? 'disabled' : '')
              }
              onClick={() => _handleNextPage(1)}
            >
              <IconNext />
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getPositions = makeGetPositions();
  const getCashBalance = makeGetCashBalance();
  const getDefAccount = makeGetDefaultAccount();

  const mapStateToProps = (state: AppState) => {
    return {
      token: getToken(state),
      defAccount: getDefAccount(state),
      allPos: getPositions(state),
      cashBalance: getCashBalance(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(memo(AssetComponent));
