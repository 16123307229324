import * as actions from './actionType';
/** chỉ số chứng khoán realtime */
export function analStockRealRequest(
  params: any,
): actions.AnalStockRealRequestAction {
  return {
    type: actions.ANAL_STOCK_REAL_REQUESTING,
    params,
  };
}

export function analStockRealSuccess(
  resData: any,
): actions.AnalStockRealSuccessAction {
  return {
    type: actions.ANAL_STOCK_REAL_SUCCESS,
    resData,
  };
}

export function analStockRealError(
  error: any,
): actions.AnalStockRealErrorAction {
  return {
    type: actions.ANAL_STOCK_REAL_ERROR,
    error,
  };
}

/** chỉ số index realtime */
export function analSecListAllRequest(
  params: any,
): actions.AnalSecListAllRequestAction {
  return {
    type: actions.ANAL_SEC_LIST_ALL_REQUESTING,
    params,
  };
}

export function analSecListAllSuccess(
  resData: any,
): actions.AnalSecListAllSuccessAction {
  return {
    type: actions.ANAL_SEC_LIST_ALL_SUCCESS,
    resData,
  };
}

export function analSecListAllError(
  error: any,
): actions.AnalSecListAllErrorAction {
  return {
    type: actions.ANAL_SEC_LIST_ALL_ERROR,
    error,
  };
}

/** chỉ số index realtime */
export function analIndexRealRequest(
  params: any,
): actions.AnalIndexRealRequestAction {
  return {
    type: actions.ANAL_INDEX_REAL_REQUESTING,
    params,
  };
}

export function analIndexRealSuccess(
  resData: any,
): actions.AnalIndexRealSuccessAction {
  return {
    type: actions.ANAL_INDEX_REAL_SUCCESS,
    resData,
  };
}

export function analIndexRealError(
  error: any,
): actions.AnalIndexRealErrorAction {
  return {
    type: actions.ANAL_INDEX_REAL_ERROR,
    error,
  };
}

/** khớp lệnh theo bước giá */
export function analStockMatchPriceRequest(
  params: any,
): actions.AnalStockMatchPriceRequestAction {
  return {
    type: actions.ANAL_STOCK_MATCH_PRICE_REQUESTING,
    params,
  };
}

export function analStockMatchPriceSuccess(
  resData: any,
): actions.AnalStockMatchPriceSuccessAction {
  return {
    type: actions.ANAL_STOCK_MATCH_PRICE_SUCCESS,
    resData,
  };
}

export function analStockMatchPriceError(
  error: any,
): actions.AnalStockMatchPriceErrorAction {
  return {
    type: actions.ANAL_STOCK_MATCH_PRICE_ERROR,
    error,
  };
}

/** mua bán chủ động */
export function analStockMBActiveRequest(
  params: any,
): actions.AnalStockMBActiveRequestAction {
  return {
    type: actions.ANAL_STOCK_MB_ACTIVE_REQUESTING,
    params,
  };
}

export function analStockMBActiveSuccess(
  resData: any,
): actions.AnalStockMBActiveSuccessAction {
  return {
    type: actions.ANAL_STOCK_MB_ACTIVE_SUCCESS,
    resData,
  };
}

export function analStockMBActiveError(
  error: any,
): actions.AnalStockMBActiveErrorAction {
  return {
    type: actions.ANAL_STOCK_MB_ACTIVE_ERROR,
    error,
  };
}

/** mua bán nước ngoài trong ngày */
export function analNNIndayRequest(
  params: any,
): actions.AnalNNIndayRequesttAction {
  return {
    type: actions.ANAL_NN_INDAY_REQUESTING,
    params,
  };
}

export function analNNIndaySuccess(
  resData: any,
): actions.AnalNNIndaySuccessAction {
  return {
    type: actions.ANAL_NN_INDAY_SUCCESS,
    resData,
  };
}

export function analNNIndayError(error: any): actions.AnalNNIndayErrorAction {
  return {
    type: actions.ANAL_NN_INDAY_ERROR,
    error,
  };
}

/** mua bán nước ngoài 10 phiên */
export function analNN10dayRequest(
  params: any,
): actions.AanalNN10dayRequestAction {
  return {
    type: actions.ANAL_NN_10DAY_REQUESTING,
    params,
  };
}

export function analNN10daySuccess(
  resData: any,
): actions.AnalNN10daySuccessAction {
  return {
    type: actions.ANAL_NN_10DAY_SUCCESS,
    resData,
  };
}

export function analNN10dayError(error: any): actions.AnalNN10dayErrorAction {
  return {
    type: actions.ANAL_NN_10DAY_ERROR,
    error,
  };
}

/** phân bổ dòng tiền */
export function analPBDongTienRequest(
  params: any,
): actions.AnalPBDongTienRequestAction {
  return {
    type: actions.ANAL_PB_DONG_TIEN_REQUESTING,
    params,
  };
}

export function analPBDongTienSuccess(
  resData: any,
): actions.AnalPBDongTienSuccessAction {
  return {
    type: actions.ANAL_PB_DONG_TIEN_SUCCESS,
    resData,
  };
}

export function analPBDongTienError(
  error: any,
): actions.AnalPBDongTienErrorAction {
  return {
    type: actions.ANAL_PB_DONG_TIEN_ERROR,
    error,
  };
}

/** lịch sử giá */
export function analLichSuGiaRequest(
  params: any,
): actions.AnalLichSuGiaRequestAction {
  return {
    type: actions.ANAL_LICH_SU_GIA_REQUESTING,
    params,
  };
}

export function analLichSuGiaSuccess(
  resData: any,
): actions.AnalLichSuGiaSuccessAction {
  return {
    type: actions.ANAL_LICH_SU_GIA_SUCCESS,
    resData,
  };
}

export function analLichSuGiaError(
  error: any,
): actions.AnalLichSuGiaErrorAction {
  return {
    type: actions.ANAL_LICH_SU_GIA_ERROR,
    error,
  };
}

/** security basic info */
export function analBasicInfoRequest(
  params: any,
): actions.AnalBasicInfoRequestAction {
  return {
    type: actions.ANAL_BASIC_INFO_REQUESTING,
    params,
  };
}

export function analBasicInfoSuccess(
  resData: any,
): actions.AnalBasicInfoSuccessAction {
  return {
    type: actions.ANAL_BASIC_INFO_SUCCESS,
    resData,
  };
}

export function analBasicInfoError(
  error: any,
): actions.AnalBasicInfoErrorAction {
  return {
    type: actions.ANAL_BASIC_INFO_ERROR,
    error,
  };
}

/** fs ratios*/
export function analFSRatioRequest(
  params: any,
): actions.AnalFSRatioRequestAction {
  return {
    type: actions.ANAL_FS_RATIO_REQUESTING,
    params,
  };
}

export function analFSRatioSuccess(
  resData: any,
): actions.AnalFSRatioSuccessAction {
  return {
    type: actions.ANAL_FS_RATIO_SUCCESS,
    resData,
  };
}

export function analFSRatioError(error: any): actions.AnalFSRatioErrorAction {
  return {
    type: actions.ANAL_FS_RATIO_ERROR,
    error,
  };
}

/** ranking fs ratios*/
export function analRankFSRatioRequest(
  params: any,
): actions.AnalRankFSRatioRequestAction {
  return {
    type: actions.ANAL_RANK_FS_RATIO_REQUESTING,
    params,
  };
}

export function analRankFSRatioSuccess(
  resData: any,
): actions.AnalRankFSRatioSuccessAction {
  return {
    type: actions.ANAL_RANK_FS_RATIO_SUCCESS,
    resData,
  };
}

export function analRankFSRatioError(
  error: any,
): actions.AnalRankFSRatioErrorAction {
  return {
    type: actions.ANAL_RANK_FS_RATIO_ERROR,
    error,
  };
}

/** sec overview*/
export function analSecOverviewRequest(
  params: any,
): actions.AnalSecOverviewRequestAction {
  return {
    type: actions.ANAL_SEC_OVERVIEW_REQUESTING,
    params,
  };
}

export function analSecOverviewSuccess(
  resData: any,
): actions.AnalSecOverviewSuccessAction {
  return {
    type: actions.ANAL_SEC_OVERVIEW_SUCCESS,
    resData,
  };
}

export function analSecOverviewError(
  error: any,
): actions.AnalSecOverviewErrorAction {
  return {
    type: actions.ANAL_SEC_OVERVIEW_ERROR,
    error,
  };
}

/** company introduction */
export function analComIntroductionRequest(
  params: any,
): actions.AnalComIntroductionRequestAction {
  return {
    type: actions.ANAL_COMPANY_INTRODUCTION_REQUESTING,
    params,
  };
}

export function analComIntroductionSuccess(
  resData: any,
): actions.AnalComIntroductionSuccesssAction {
  return {
    type: actions.ANAL_COMPANY_INTRODUCTION_SUCCESS,
    resData,
  };
}

export function analComIntroductionError(
  error: any,
): actions.AnalComIntroductionErrorAction {
  return {
    type: actions.ANAL_COMPANY_INTRODUCTION_ERROR,
    error,
  };
}

/** company basic */
export function analComBasicRequest(
  params: any,
): actions.AnalComBasicRequestAction {
  return {
    type: actions.ANAL_COMPANY_BASIC_REQUESTING,
    params,
  };
}

export function analComBasicSuccess(
  resData: any,
): actions.AnalComBasicSuccessAction {
  return {
    type: actions.ANAL_COMPANY_BASIC_SUCCESS,
    resData,
  };
}

export function analComBasicError(error: any): actions.AnalComBasicErrorAction {
  return {
    type: actions.ANAL_COMPANY_BASIC_ERROR,
    error,
  };
}

/** company same industry */
export function analComSameIndustryRequest(
  params: any,
): actions.AnalComSameIndustryRequestAction {
  return {
    type: actions.ANAL_COMPANY_SAME_IND_REQUESTING,
    params,
  };
}

export function analComSameIndustrySuccess(
  resData: any,
): actions.AnalComSameIndustrySuccessAction {
  return {
    type: actions.ANAL_COMPANY_SAME_IND_SUCCESS,
    resData,
  };
}

export function analComSameIndustryError(
  error: any,
): actions.AnalComSameIndustryErrorAction {
  return {
    type: actions.ANAL_COMPANY_SAME_IND_ERROR,
    error,
  };
}

/** company leader */

export function analComLeaderRequest(
  params: any,
): actions.AnalComLeaderRequestAction {
  return {
    type: actions.ANAL_COMPANY_LEADER_REQUESTING,
    params,
  };
}

export function analComLeaderSuccess(
  resData: any,
): actions.AnalComLeaderSuccessAction {
  return {
    type: actions.ANAL_COMPANY_LEADER_SUCCESS,
    resData,
  };
}

export function analComLeaderError(
  error: any,
): actions.AnalComLeaderErrorAction {
  return {
    type: actions.ANAL_COMPANY_LEADER_ERROR,
    error,
  };
}

/** company holder */
export function analComHolderRequest(
  params: any,
): actions.AnalComHolderRequestAction {
  return {
    type: actions.ANAL_COMPANY_HOLDER_REQUESTING,
    params,
  };
}

export function analComHolderSuccess(
  resData: any,
): actions.AnalComHolderSuccessAction {
  return {
    type: actions.ANAL_COMPANY_HOLDER_SUCCESS,
    resData,
  };
}

export function analComHolderError(
  error: any,
): actions.AnalComHolderErrorAction {
  return {
    type: actions.ANAL_COMPANY_HOLDER_ERROR,
    error,
  };
}

/** company events */
export function analComEventsRequest(
  params: any,
): actions.AnalComEventsRequestAction {
  return {
    type: actions.ANAL_COMPANY_EVENTS_REQUESTING,
    params,
  };
}

export function analComEventsSuccess(
  resData: any,
): actions.AnalComEventsSuccessAction {
  return {
    type: actions.ANAL_COMPANY_EVENTS_SUCCESS,
    resData,
  };
}

export function analComEventsError(
  error: any,
): actions.AnalComEventsErrorAction {
  return {
    type: actions.ANAL_COMPANY_EVENTS_ERROR,
    error,
  };
}

/** company big trans */
export function analComBigTransRequest(
  params: any,
): actions.AnalComBigTransRequestAction {
  return {
    type: actions.ANAL_COMPANY_BIGTRANS_REQUESTING,
    params,
  };
}

export function analComBigTransSuccess(
  resData: any,
): actions.AnalComBigTransSuccessAction {
  return {
    type: actions.ANAL_COMPANY_BIGTRANS_SUCCESS,
    resData,
  };
}

export function analComBigTransError(
  error: any,
): actions.AnalComBigTransErrorAction {
  return {
    type: actions.ANAL_COMPANY_BIGTRANS_ERROR,
    error,
  };
}

/** công ty liên kết */
export function analComRelatedRequest(
  params: any,
): actions.AnalComRelatedRequestAction {
  return {
    type: actions.ANAL_COMPANY_RELATED_REQUESTING,
    params,
  };
}

export function analComRelatedSuccess(
  resData: any,
): actions.AnalComRelatedSuccessAction {
  return {
    type: actions.ANAL_COMPANY_RELATED_SUCCESS,
    resData,
  };
}

export function analComRelatedError(
  error: any,
): actions.AnalComRelatedErrorAction {
  return {
    type: actions.ANAL_COMPANY_RELATED_ERROR,
    error,
  };
}

/** filter range */
export function analFilterRangeRequest(): actions.AnalFilterRangeRequestAction {
  return {
    type: actions.ANAL_FILTER_RANGE_REQUESTING,
  };
}

export function analFilterRangeSuccess(
  resData: any,
): actions.AnalFilterRangeSuccessAction {
  return {
    type: actions.ANAL_FILTER_RANGE_SUCCESS,
    resData,
  };
}

export function analFilterRangeError(
  error: any,
): actions.AnalFilterRangeErrorAction {
  return {
    type: actions.ANAL_FILTER_RANGE_ERROR,
    error,
  };
}

/** filter action */
export function analFilterActionRequest(
  params: any,
): actions.AnalFilterActionRequestAction {
  return {
    type: actions.ANAL_FILTER_ACT_REQUESTING,
    params,
  };
}

export function analFilterActionSuccess(
  resData: any,
): actions.AnalFilterActionSuccessAction {
  return {
    type: actions.ANAL_FILTER_ACT_SUCCESS,
    resData,
  };
}

export function analFilterActionError(
  error: any,
): actions.AnalFilterActionErrorAction {
  return {
    type: actions.ANAL_FILTER_ACT_ERROR,
    error,
  };
}

/** user filter  */
export function analUserFilterRequest(
  params: any,
): actions.AnalUserFilterRequestAction {
  return {
    type: actions.ANAL_USER_FILTER_REQUESTING,
    params,
  };
}

export function analUserFilterSuccess(
  resData: any,
): actions.AnalUserFilterSuccessAction {
  return {
    type: actions.ANAL_USER_FILTER_SUCCESS,
    resData,
  };
}

export function analUserFilterError(
  error: any,
): actions.AnalUserFilterErrorAction {
  return {
    type: actions.ANAL_USER_FILTER_ERROR,
    error,
  };
}

/** danh sach tin hieu da dang kys */
export function analUserSignalRequest(
  params: any,
): actions.AnalUserSignalRequestAction {
  return {
    type: actions.ANAL_USER_SIGNAL_REQUESTING,
    params,
  };
}

export function analUserSignalSuccess(
  resData: any,
): actions.AnalUserSignalSuccessAction {
  return {
    type: actions.ANAL_USER_SIGNAL_SUCCESS,
    resData,
  };
}

export function analUserSignalError(
  error: any,
): actions.AnalUserSignalErrorAction {
  return {
    type: actions.ANAL_USER_SIGNAL_ERROR,
    error,
  };
}

/** Xóa bộ lọc đã đăng ký */
export function analDelFilterRequest(
  params: any,
): actions.AnalDelFilterRequestAction {
  return {
    type: actions.ANAL_DEL_FILTER_REQUESTING,
    params,
  };
}

export function analDelFilterSuccess(
  resData: any,
): actions.AnalDelFilterSuccessAction {
  return {
    type: actions.ANAL_DEL_FILTER_SUCCESS,
    resData,
  };
}

export function analDelFilterError(
  error: any,
): actions.AnalDelFilterErrorAction {
  return {
    type: actions.ANAL_DEL_FILTER_ERROR,
    error,
  };
}
