/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  makeGetAllOrderCond,
  makeGetDefaultAccount,
  makeGetToken,
} from 'lib/selector';
import React, { memo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getRandom, numberFormat } from 'utils';

import { setToast } from 'containers/client/actions';
import * as _ from 'lodash';

import RenderFieldCheckbox from 'helper/input/renderCheckbox';

import { ReactComponent as IconNext } from 'assets/img/icon/iconNext.svg';
import { allOrdCondRequest } from 'containers/banggia/actions';
import { IAllOrder } from 'interface/response';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { AppState } from 'reducers';
import { useConfig } from 'shared/configContext';
import LoadingIndicator from 'shared/loadingIndicator';
import { BtnSubmit } from 'utils/styledUtils';

interface Props {
  allOrder?: IAllOrder[] | undefined;
  token?: any;
  onClose: Function;
  defAccount?: string;
}

function usePrevious(value: any) {
  const ref = React.useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function CondOrderComponent(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [, setCheckAll] = useState<boolean>(false);
  const [orderCheck, setOrderCheck] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [page, setPage] = React.useState<number>(1);
  const [pageSize] = React.useState<number>(10);

  const { defAccount, allOrder, token, onClose } = props;

  const prePage = usePrevious(page);
  const { config } = useConfig();

  useEffect(() => {
    getCondOrder();

    return () => {
      dispatch({ type: 'CLEAR_ORD_LIST' });
    };
  }, []);

  useEffect(() => {
    if (page && prePage && !_.isEqual(page, prePage)) {
      getCondOrder();
    }
  }, [page]);

  function getCondOrder() {
    if (!defAccount || !token) return;

    const params = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.Order.ConditionOrder',
        // p1: defAccount,
        p1: (page || 1) + '',
        p2: '10',
      },
    };
    dispatch(allOrdCondRequest(params));
  }

  function _handleChangeChk(e: any) {
    const { checked } = e.target;
    setCheckAll(checked);
    if (checked) {
      // const xxx = _.map(allOrder, 'pk_orderNo');
      let xxx: string[] = [];
      allOrder?.forEach((item: any) => {
        if (item.status === '0') xxx.push(item.pk_orderNo);
      });
      setOrderCheck(xxx);
    } else {
      setOrderCheck([]);
    }
  }

  async function handleCancelOrder(data: string) {
    const params = {
      group: 'O',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.cancelActivatedOrder',
        orderNo: data,
        refId: token.user + '.H.' + getRandom(),
        fisID: '',
        orderType: '1',
        pin: '',
      },
    };
    const request = fetch(`${config?.AuthUrl || ''}/TraditionalService`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    });
    try {
      const response = await request;
      const json = await response.json();
      return json;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }

  function _handleChange(e: any) {
    const { checked, name } = e.target;
    let xxx = [...orderCheck];

    if (checked) {
      xxx.push(name);
    } else {
      _.remove(xxx, (o) => o === name);
    }
    setOrderCheck(xxx);
  }

  function _handleClickDelAll() {
    if (!orderCheck.length) {
      handleToast('Hãy chọn lệnh để huỷ!', 'warning');
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui-confirm">
              <h1>{t('notice.xac-nhan')}</h1>
              <p>Hủy tất cả các lệnh đã chọn</p>
              <button onClick={onClose}>{t('button.cancel')}</button>
              <button
                onClick={() => {
                  handleActionApprove();
                  onClose();
                }}
              >
                {t('button.accept')}
              </button>
            </div>
          );
        },
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    }
  }

  function asyncIterator(_array: any[]) {
    return {
      next: function () {
        if (_array.length) {
          return {
            value: _array.shift(),
            done: false,
          };
        } else {
          return {
            done: true,
          };
        }
      },
    };
  }

  function handleActionApprove() {
    var _ite = asyncIterator(orderCheck);
    setIsLoading(true);
    handleSellItems(_ite);
  }

  async function handleSellItems(_ite: {
    next: () =>
      | { value: any; done: boolean }
      | { done: boolean; value?: undefined };
  }) {
    const _iterator = _ite.next();
    if (_iterator.done) {
      handleToast('Gửi hủy lệnh chọn thành công', 'success');
      setIsLoading(false);
      getCondOrder();
      setOrderCheck([]);
    } else {
      handleCancelOrder(_iterator.value)?.then((response) => {
        if (response.code < 1) {
          handleToast('Hủy lệnh lỗi: ' + response.rs, 'error');
          setIsLoading(false);
          setOrderCheck([]);

          getCondOrder();
        } else {
          handleSellItems(_ite);
        }
      });
    }
  }

  function handleToast(msg: string, type = 'info') {
    // log(msg)
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: 'Thông báo',
      type,
    };
    dispatch(setToast(toastMsg));
  }

  function _handleNextPage(step: number): void {
    if (step < 0 && page === 1) return;
    if (step > 0 && (allOrder?.length || 0) < pageSize) return;

    setPage(page + step);
  }

  return (
    <div className="relative">
      <div className="hidden md:flex text-[13px] items-center">
        {t('trading.order-cond2')}
        <IoClose className="ml-auto" size={24} onClick={() => onClose()} />
      </div>
      <div className="flex flex-col w-full">
        <table className="table table-bordered w-full mt-1">
          <thead>
            <tr>
              <th className="text-center p-0 w-8">
                <RenderFieldCheckbox
                  name="checkAll"
                  onChange={_handleChangeChk}
                />
              </th>
              <th className="text-left">{t('trading.table.symbol')}</th>
              <th className="text-left">{t('trading.table.order-type')}</th>
              <th className="text-right">{t('trading.table.order-vol')}</th>
              <th className="text-right">{t('trading.table.activation-cond')}</th>
              <th className="text-left">{t('trading.table.status')}</th>
            </tr>
          </thead>
          <tbody>
            {allOrder &&
              !!allOrder.length &&
              allOrder.map((item: any) => (
                <tr key={item.pk_orderNo}>
                  <td className="text-center p-0">
                    {item.status === '0' && (
                      <RenderFieldCheckbox
                        name={item.pk_orderNo}
                        onChange={_handleChange}
                        checked={_.some(
                          orderCheck,
                          (o) => o === item.pk_orderNo,
                        )}
                      />
                    )}
                  </td>
                  <td className="position-relative">{item.symbol}</td>
                  <td className={item.side === 'B' ? 'green' : 'red'}>
                    {item.side === 'B'
                      ? t('trading.order-book.buy')
                      : t('trading.order-book.sell')}
                  </td>
                  <td className="text-right ">{numberFormat(item.volume)}</td>
                  <td className="text-right ">{`Giá TT ${item.orderType === 'SL' ? '≤' : '≥'
                    } ${item.showPrice}`}</td>
                  <td className={'text-center'}>
                    {item.cancel_time ||
                      (item.details < 0 && item.status === '1')
                      ? t('trading.order-book.canceled')
                      : item.status === '0'
                        ? t('trading.order-book.cond-pending')
                        : t('trading.order-book.cond-activated')}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {(!allOrder || !allOrder.length) && (
          <p className="text-center text-xs my-2">
            {t('trading.order-book.no-data-found')}
          </p>
        )}
        {allOrder === null && (
          <p className="text-center text-xs content-read my-2">Loading...</p>
        )}
        <div className="w-full my-3 footer-component grid grid-cols-[auto_60px] gap-1">
          <BtnSubmit
            className={
              '!h-8 !text-sm !font-medium w-full danger '
              // (!orderCheck || !orderCheck.length ? 'disabled' : '')
            }
            onClick={_handleClickDelAll}
            disabled={!orderCheck || !orderCheck.length}
          >
            {t('trading.order-book.cancel-order-selected')}
          </BtnSubmit>
          <div className="grid grid-cols-2 paging-type2">
            <div
              className={
                'w-full h-full flex items-center justify-center ' +
                (page === 1 ? 'disabled' : '')
              }
              onClick={() => _handleNextPage(-1)}
            >
              <IconNext className="rotate-180" />
            </div>
            <div
              className={
                'w-full h-full flex items-center justify-center ' +
                (allOrder && allOrder.length < pageSize ? 'disabled' : '')
              }
              onClick={() => _handleNextPage(1)}
            >
              <IconNext />
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAllOrder = makeGetAllOrderCond();
  const getDefAccount = makeGetDefaultAccount();

  const mapStateToProps = (state: AppState) => {
    return {
      token: getToken(state),
      defAccount: getDefAccount(state),
      allOrder: getAllOrder(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(memo(CondOrderComponent));
