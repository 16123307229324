import * as actions from './actionType';

export function summaryCashClear(): actions.SummaryCashClearAction {
  return {
    type: actions.SUMMARY_CASH_CLEAR,
  };
}

export function summaryCashRequest(
  params: any,
): actions.SummaryCashRequestAction {
  return {
    type: actions.SUMMARY_CASH_REQUESTING,
    params,
  };
}

export function summaryCashRequestSuccess(
  cashList: any,
): actions.SummaryCashRequestSuccessAction {
  return {
    type: actions.SUMMARY_CASH_REQUEST_SUCCESS,
    cashList,
  };
}

export function summaryCashRequestError(
  error: any,
): actions.SummaryCashRequestErrorAction {
  return {
    type: actions.SUMMARY_CASH_REQUEST_ERROR,
    error,
  };
}

export function shareEarnedRequest(
  params: any,
): actions.ShareEarnedRequestAction {
  return {
    type: actions.SUMMARY_SHARE_EARN_REQUESTING,
    params,
  };
}

export const shareEarnedRequestSuccess = function shareEarnedRequestSuccess(
  resData: any,
): actions.ShareEarnedRequestSuccessAction {
  return {
    type: actions.SUMMARY_SHARE_EARN_REQUEST_SUCCESS,
    resData,
  };
};

export function shareEarnedRequestError(
  error: any,
): actions.ShareEarnedRequestErrorAction {
  return {
    type: actions.SUMMARY_SHARE_EARN_REQUEST_ERROR,
    error,
  };
}

export function cashCanAdvRequest(
  params: any,
): actions.CashCanAdvRequestAction {
  return {
    type: actions.CASH_CAN_ADV_REQUESTING,
    params,
  };
}

export function cashCanAdvRequestSuccess(
  cashCanAdv: any,
): actions.CashCanAdvRequestSuccessAction {
  return {
    type: actions.CASH_CAN_ADV_REQUEST_SUCCESS,
    cashCanAdv,
  };
}

export function cashCanAdvRequestError(
  error: any,
): actions.CashCanAdvRequestErrorAction {
  return {
    type: actions.CASH_CAN_ADV_REQUEST_ERROR,
    error,
  };
}

export function feeAdvWithdrawRequest(
  params: any,
): actions.FeeAdvWithdrawRequestAction {
  return {
    type: actions.FEE_ADV_WITHDRAW_REQUESTING,
    params,
  };
}

export function feeAdvWithdrawRequestSuccess(
  feeAdvWithdraw: any,
): actions.FeeAdvWithdrawRequestSuccessAction {
  return {
    type: actions.FEE_ADV_WITHDRAW_REQUEST_SUCCESS,
    feeAdvWithdraw,
  };
}

export function feeAdvWithdrawRequestError(
  error: any,
): actions.FeeAdvWithdrawRequestErrorAction {
  return {
    type: actions.FEE_ADV_WITHDRAW_REQUEST_ERROR,
    error,
  };
}

export function advStatusRequest(params: any): actions.AdvStatusRequestAction {
  return {
    type: actions.ADV_STATUS_REQUESTING,
    params,
  };
}

export function advStatusRequestSuccess(
  advStt: any,
): actions.AdvStatusRequestSuccessAction {
  return {
    type: actions.ADV_STATUS_REQUEST_SUCCESS,
    advStt,
  };
}

export function advStatusRequestError(
  error: any,
): actions.AdvStatusRequestErrorAction {
  return {
    type: actions.ADV_STATUS_REQUEST_ERROR,
    error,
  };
}

export function advStatusHisRequest(
  params: any,
): actions.AdvStatusHisRequestAction {
  return {
    type: actions.ADV_STATUS_HIS_REQUESTING,
    params,
  };
}

export function advStatusHisRequestSuccess(
  advSttHis: any,
): actions.AdvStatusHisRequestSuccessAction {
  return {
    type: actions.ADV_STATUS_HIS_REQUEST_SUCCESS,
    advSttHis,
  };
}

export function advStatusHisRequestError(
  error: any,
): actions.AdvStatusHisRequestErrorAction {
  return {
    type: actions.ADV_STATUS_HIS_REQUEST_ERROR,
    error,
  };
}

export function listShareBalanceRequest(
  params: any,
): actions.ListShareBalanceRequestAction {
  return {
    type: actions.LIST_SHARE_BALANCE_REQUESTING,
    params,
  };
}

export function listShareBalanceRequestSuccess(
  listShareBalance: any,
): actions.ListShareBalanceRequestSuccessAction {
  return {
    type: actions.LIST_SHARE_BALANCE_REQUEST_SUCCESS,
    listShareBalance,
  };
}

export function listShareBalanceRequestError(
  error: any,
): actions.ListShareBalanceRequestErrorAction {
  return {
    type: actions.LIST_SHARE_BALANCE_REQUEST_ERROR,
    error,
  };
}

export function listShareStatusRequest(
  params: any,
): actions.ListShareStatusRequestAction {
  return {
    type: actions.SHARE_TRANSFER_STATUS_REQUESTING,
    params,
  };
}

export function listShareStatusRequestSuccess(
  listShareStt: any,
): actions.ListShareStatusRequestSuccessAction {
  return {
    type: actions.SHARE_TRANSFER_REQUEST_SUCCESS,
    listShareStt,
  };
}

export function listShareStatusRequestError(
  error: any,
): actions.ListShareStatusRequestErrorAction {
  return {
    type: actions.SHARE_TRANSFER_REQUEST_ERROR,
    error,
  };
}

export function shareTransferHisRequest(
  params: any,
): actions.ShareTransferHisRequestAction {
  return {
    type: actions.SHARE_TRANSFER_HIS_REQUESTING,
    params,
  };
}

export function shareTransferHisRequestSuccess(
  shareTransferHis: any,
): actions.ShareTransferHisRequestSuccessAction {
  return {
    type: actions.SHARE_TRANSFER_HIS_REQUEST_SUCCESS,
    shareTransferHis,
  };
}

export function shareTransferHisRequestError(
  error: any,
): actions.ShareTransferHisRequestErrorAction {
  return {
    type: actions.SHARE_TRANSFER_HIS_REQUEST_ERROR,
    error,
  };
}

export function cashAccountInfoRequest(
  params: any,
): actions.CashAccountInfoRequestAction {
  return {
    type: actions.CASH_ACCOUNT_INFO_REQUESTING,
    params,
  };
}

export function cashAccountInfoRequestSuccess(
  cashAccountInfo: any,
): actions.CashAccountInfoRequestSuccessAction {
  return {
    type: actions.CASH_ACCOUNT_INFO_REQUEST_SUCCESS,
    cashAccountInfo,
  };
}

export function cashAccountInfoRequestError(
  error: any,
): actions.CashAccountInfoRequestErrorAction {
  return {
    type: actions.CASH_ACCOUNT_INFO_REQUEST_ERROR,
    error,
  };
}

export function beneficiaryAccountRequest(
  params: any,
): actions.BeneficiaryAccountRequestAction {
  return {
    type: actions.ACCOUNT_BENEFICIARY_REQUESTING,
    params,
  };
}

export function beneficiaryAccountRequestSuccess(
  beneficiaryAccount: any,
): actions.BeneficiaryAccountRequestSuccessAction {
  return {
    type: actions.ACCOUNT_BENEFICIARY_REQUEST_SUCCESS,
    beneficiaryAccount,
  };
}

export function beneficiaryAccountRequestError(
  error: any,
): actions.BeneficiaryAccountRequestErrorAction {
  return {
    type: actions.ACCOUNT_BENEFICIARY_REQUEST_ERROR,
    error,
  };
}

export function listCashStatusRequest(
  params: any,
): actions.ListCashStatusRequestAction {
  return {
    type: actions.CASH_TRANSFER_STATUS_REQUESTING,
    params,
  };
}

export function listCashStatusRequestSuccess(
  listCashStt: any,
): actions.ListCashStatusRequestSuccessAction {
  return {
    type: actions.CASH_TRANSFER_REQUEST_SUCCESS,
    listCashStt,
  };
}

export function listCashStatusRequestError(
  error: any,
): actions.ListCashStatusRequestErrorAction {
  return {
    type: actions.CASH_TRANSFER_REQUEST_ERROR,
    error,
  };
}

export function cashTransferHisRequest(
  params: any,
): actions.CashTransferHisRequestAction {
  return {
    type: actions.CASH_TRANSFER_HIS_REQUESTING,
    params,
  };
}

export function cashTransferHisRequestSuccess(
  cashTransferHis: any,
): actions.CashTransferHisRequestSuccessAction {
  return {
    type: actions.CASH_TRANSFER_HIS_REQUEST_SUCCESS,
    cashTransferHis,
  };
}

export function cashTransferHisRequestError(
  error: any,
): actions.CashTransferHisRequestErrorAction {
  return {
    type: actions.CASH_TRANSFER_HIS_REQUEST_ERROR,
    error,
  };
}
