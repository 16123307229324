import { clientSettingSet, setCategory } from 'containers/client/actions'
const setting = {}

export const categoryDefault = [
  {
    id: 'mac-dinh',
    name: 'Mặc định',
    path: '/price/bang-gia/mac-dinh',
    value: [],
    pinnedRow: [],
    type: 'watchlist',
  },
  {
    name: 'HOSE',
    path: '/price/bang-gia/hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'VN30',
    path: '/price/bang-gia/vn30',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'GD thoả thuận',
    path: '/price/bang-gia/thoa-thuan-hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'HNX',
    path: '/price/bang-gia/hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'HNX30',
    path: '/price/bang-gia/hnx30',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'GD thoả thuận',
    path: '/price/bang-gia/thoa-thuan-hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'UPCOM',
    path: '/price/bang-gia/upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'GD thoả thuận',
    path: '/price/bang-gia/thoa-thuan-upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'Ngành',
    path: '/price/bang-gia/danh-muc-nganh',
    groupName: 'NGANH',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  // {
  //   name: 'Phái sinh',
  //   path: '/price/bang-gia/phai-sinh',
  //   groupName: 'PHAISINH',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   name: 'Chứng quyền',
  //   path: '/price/bang-gia/chung-quyen',
  //   groupName: 'CHUNGQUYEN',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   name: 'Lô lẻ',
  //   path: '/price/bang-gia/lo-le',
  //   groupName: 'LOLE',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   name: 'HOSE',
  //   path: '/price/bang-gia/etf-hose',
  //   groupName: 'ETF',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   name: 'HNX',
  //   path: '/price/bang-gia/etf-hnx',
  //   groupName: 'ETF',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
]

export const DMNGANH = [
  {
    id: '0500',
    sequence: 1,
    name: 'Dầu khí',
    path: '/price/bang-gia/nganh-0500',
  },
  {
    id: '1300',
    sequence: 2,
    name: 'Hóa chất',
    path: '/price/bang-gia/nganh-1300',
  },
  {
    id: '1700',
    sequence: 3,
    name: 'Tài nguyên cơ bản',
    path: '/price/bang-gia/nganh-1700',
  },
  {
    id: '2300',
    sequence: 4,
    name: 'Xây dựng và vật liệu',
    path: '/price/bang-gia/nganh-2300',
  },
  {
    id: '2700',
    sequence: 5,
    name: 'Hàng & Dịch vụ Công nghiệp',
    path: '/price/bang-gia/nganh-2700',
  },
  {
    id: '3300',
    sequence: 6,
    name: 'Ô tô và phụ tùng',
    path: '/price/bang-gia/nganh-3300',
  },
  {
    id: '3500',
    sequence: 7,
    name: 'Thực phẩm và đồ uống',
    path: '/price/bang-gia/nganh-3500',
  },
  {
    id: '3700',
    sequence: 8,
    name: 'Hàng cá nhân & Gia dụng',
    path: '/price/bang-gia/nganh-3700',
  },
  {
    id: '4500',
    sequence: 9,
    name: 'Y tế',
    path: '/price/bang-gia/nganh-4500',
  },
  {
    id: '5300',
    sequence: 10,
    name: 'Bán lẻ',
    path: '/price/bang-gia/nganh-5300',
  },
  {
    id: '5500',
    sequence: 11,
    name: 'Truyền thông',
    path: '/price/bang-gia/nganh-5500',
  },
  {
    id: '5700',
    sequence: 12,
    name: 'Du lịch và Giải trí',
    path: '/price/bang-gia/nganh-5700',
  },
  {
    id: '6500',
    sequence: 13,
    name: 'Viễn thông',
    path: '/price/bang-gia/nganh-6500',
  },
  {
    id: '7500',
    sequence: 14,
    name: 'Tiện ích',
    path: '/price/bang-gia/nganh-7500',
  },
  {
    id: '8300',
    sequence: 15,
    name: 'Ngân hàng',
    path: '/price/bang-gia/nganh-8300',
  },
  {
    id: '8500',
    sequence: 16,
    name: 'Bảo hiểm',
    path: '/price/bang-gia/nganh-8500',
  },
  {
    id: '8600',
    sequence: 17,
    name: 'Bất động sản',
    path: '/price/bang-gia/nganh-8600',
  },
  {
    id: '8700',
    sequence: 18,
    name: 'Dịch vụ tài chính',
    path: '/price/bang-gia/nganh-8700',
  },
  {
    id: '9500',
    sequence: 20,
    name: 'Công nghệ Thông tin',
    path: '/price/bang-gia/nganh-9500',
  },
]

export const storages = {
  loadState: (key: string) => {
    const _name = key + '_VST'
    try {
      const serializedState = localStorage.getItem(_name)
      if (serializedState === null) {
        return undefined
      }
      return JSON.parse(serializedState)
    } catch (err) {
      return undefined
    }
  },
  saveState: (key: string, state: any) => {
    const _name = key + '_VST'
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(_name, serializedState)
    } catch (err: any) {
      // ...
      console.log('save state error:', err.toString())
    }
  },
  getSetting: (dispatch: any) => {
    const storedSetting = storages.loadState('setting')

    if (storedSetting) {
      dispatch(clientSettingSet(storedSetting))
    } else {
      // chưa có category
      dispatch(clientSettingSet(setting))
      storages.saveState('setting', setting)
    }

    return true
  },
  removeState: (key: string): void => {
    const name = 'VST'
    localStorage.removeItem(key + '_' + name)
  },
}

export function getCategory({ dispatch }: { dispatch: any }) {
  const storedCategory = storages.loadState('category')

  if (storedCategory) {
    dispatch(setCategory(storedCategory))
  } else {
    // chưa có category
    dispatch(setCategory(categoryDefault))
    storages.saveState('category', categoryDefault)
  }
  return true
}
