import { handleApiErrors } from 'lib/api-error';
import { AppState } from 'reducers';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { mapIndexList } from 'utils/cfg';
import {
  analBasicInfoError,
  analBasicInfoSuccess,
  analComBasicError,
  analComBasicSuccess,
  analComBigTransError,
  analComBigTransSuccess,
  analComEventsError,
  analComEventsSuccess,
  analComHolderError,
  analComHolderSuccess,
  analComIntroductionError,
  analComIntroductionSuccess,
  analComLeaderError,
  analComLeaderSuccess,
  analComRelatedError,
  analComRelatedSuccess,
  analComSameIndustryError,
  analComSameIndustrySuccess,
  analDelFilterError,
  analDelFilterSuccess,
  analFilterActionError,
  analFilterActionSuccess,
  analFilterRangeError,
  analFilterRangeSuccess,
  analFSRatioError,
  analFSRatioSuccess,
  analIndexRealError,
  analIndexRealSuccess,
  analLichSuGiaError,
  analLichSuGiaSuccess,
  analNNIndayError,
  analNNIndaySuccess,
  analPBDongTienError,
  analPBDongTienSuccess,
  analRankFSRatioError,
  analRankFSRatioSuccess,
  analSecListAllError,
  analSecListAllSuccess,
  analSecOverviewError,
  analSecOverviewSuccess,
  analStockMatchPriceError,
  analStockMatchPriceSuccess,
  analStockMBActiveError,
  analStockMBActiveSuccess,
  analStockRealError,
  analStockRealSuccess,
  analUserFilterError,
  analUserFilterSuccess,
  analUserSignalError,
  analUserSignalSuccess,
} from './actions';
import * as actions from './actionType';

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function getIndexRealtime(data: any, appUrl: string) {
  const request = fetch(
    appUrl + '/pbapi/api/stockBoard/index/' + mapIndexList[data],
  );
  return handleRequest(request);
}

function getNNInday(data: any, appUrl: string) {
  const request = fetch(
    appUrl + '/pbapi/api/stockBoard/index/getFGDetails/' + mapIndexList[data],
  );
  return handleRequest(request);
}

function getBasicInfo(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/securityBasicInfo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getStockRealtime(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/stockBoard/stock/' + data);
  return handleRequest(request);
}

function getStockMatchPrice(data: any, appUrl: string) {
  const request = fetch(
    appUrl + '/pbapi/api/stockBoard/stock/getDetailsActively/' + data,
  );
  return handleRequest(request);
}

function getStockMBActive(data: any, appUrl: string) {
  const request = fetch(
    appUrl + '/pbapi/api/stockBoard/stock/getDetailsActivelyByPrice/' + data,
  );
  return handleRequest(request);
}

function getPBDongTien(data: any, appUrl: string) {
  let _param = '';
  switch (data) {
    case 'VNINDEX':
      _param = 'HOSE';
      break;
    case 'HNX':
    case 'HNXINDEX':
      _param = 'HNC';
      break;
    case 'UPCOMINDEX':
    case 'UPCOM':
      _param = 'UPCOM';
      break;

    default:
      _param = data;
      break;
  }

  const request = fetch(
    appUrl + '/pbapi/api/stockBoard/index/calculateCashFlowAllocation/' + _param,
  );
  return handleRequest(request);
}

function getLichSuGia(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secTradingHistory', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getFSRatio(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secFSRatios', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getRankFSRatio(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secRankingFSRatios', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getSecOverview(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secOverview', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComIntroduction(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/companies/introduction', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComSameInd(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/companies/sameIndustry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComLeader(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/companies/leaders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComHolder(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/companies/shareholders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComEvents(data: any, appUrl: string) {
  const request = fetch(
    appUrl +
      '/pbapi/api/news/sec_news?startDate=' +
      data.startDate +
      '&reqLanguage=' +
      data.lang.toUpperCase() +
      '&secCode=' +
      data.secCode,
  );
  return handleRequest(request);
}

function getComBigTrans(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secBigTrans', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComRelated(data: any, appUrl: string) {
  const request = fetch(
    appUrl + '/pbapi/api/companies/relatedCompanies?' + data,
  );
  return handleRequest(request);
}

function getUserFilter(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/getFilters', {
    method: 'GET',
    headers: {
      'X-USERNAME': data,
    },
  });
  return handleRequest(request);
}

function getUserSignal(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/usersignals/' + data, {
    method: 'GET',
    headers: {
      'X-USERNAME': data,
    },
  });
  return handleRequest(request);
}

function getFilterAct(data: any, appUrl: string) {
  const _user = data['user'];
  delete data['user'];

  const request = fetch(appUrl + '/pbapi/api/filter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-USERNAME': _user,
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function delFilterApi(data: any, appUrl: string) {
  const _user = data['user'];
  delete data['user'];

  const request = fetch(appUrl + '/pbapi/api/deleteFilter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-USERNAME': _user,
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getComBasic(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/companies/basic', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getFilterRange(appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/getFilterRange');
  return handleRequest(request);
}

function analSecListAllRequestApi(data: any, appUrl: string) {
  const request = fetch(appUrl + '/pbapi/api/secListAll', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* analStockRealRequestFlow(
  action: actions.AnalStockRealRequestAction,
): any {
  const { params } = action;
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(getStockRealtime, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analStockRealSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analStockRealError(error));
  }
}

function* analIndexRealRequestFlow(
  action: actions.AnalIndexRealRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getIndexRealtime, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analIndexRealSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analIndexRealError(error));
  }
}

function* analStockInfoRequestFlow(
  action: actions.AnalBasicInfoRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getBasicInfo, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analBasicInfoSuccess(resData.data[0]));
  } catch (error) {
    // log(error)
    yield put(analBasicInfoError(error));
  }
}

function* analStockMatchPriceRequestFlow(
  action: actions.AnalStockMatchPriceRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getStockMatchPrice, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analStockMatchPriceSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analStockMatchPriceError(error));
  }
}

function* analStockMBActiveRequestFlow(
  action: actions.AnalStockMBActiveRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getStockMBActive, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analStockMBActiveSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analStockMBActiveError(error));
  }
}

function* analPBDongTienRequestFlow(
  action: actions.AnalPBDongTienRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getPBDongTien, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analPBDongTienSuccess(resData.data[0]));
  } catch (error) {
    // log(error)
    yield put(analPBDongTienError(error));
  }
}

function* analNNIndayRequestFlow(
  action: actions.AnalNNIndayRequesttAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getNNInday, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analNNIndaySuccess(resData.data[0]));
  } catch (error) {
    // log(error)
    yield put(analNNIndayError(error));
  }
}

function* analLichSuGiaRequestFlow(
  action: actions.AnalLichSuGiaRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getLichSuGia, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analLichSuGiaSuccess(JSON.parse(resData.data)));
  } catch (error) {
    // log(error)
    yield put(analLichSuGiaError(error));
  }
}

function* analFSRatioRequestFlow(
  action: actions.AnalFSRatioRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getFSRatio, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analFSRatioSuccess(JSON.parse(resData.data)));
  } catch (error) {
    // log(error)
    yield put(analFSRatioError(error));
  }
}

function* analRankFSRatioRequestFlow(
  action: actions.AnalRankFSRatioRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getRankFSRatio, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analRankFSRatioSuccess(JSON.parse(resData.data)));
  } catch (error) {
    // log(error)
    yield put(analRankFSRatioError(error));
  }
}

function* analSecOverviewRequestFlow(
  action: actions.AnalSecOverviewRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getSecOverview, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analSecOverviewSuccess(JSON.parse(resData.data)));
  } catch (error) {
    // log(error)
    yield put(analSecOverviewError(error));
  }
}

function* analComIntroductionRequestFlow(
  action: actions.AnalComIntroductionRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComIntroduction, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComIntroductionSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComIntroductionError(error));
  }
}

function* analComBasicRequestFlow(
  action: actions.AnalComBasicRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComBasic, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComBasicSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComBasicError(error));
  }
}

function* analComSameIndRequestFlow(
  action: actions.AnalComSameIndustryRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComSameInd, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComSameIndustrySuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComSameIndustryError(error));
  }
}

function* analComLeaderRequestFlow(
  action: actions.AnalComLeaderRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComLeader, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComLeaderSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComLeaderError(error));
  }
}

function* analComHolderRequestFlow(
  action: actions.AnalComHolderRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComHolder, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComHolderSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComHolderError(error));
  }
}

function* analComEventsRequestFlow(
  action: actions.AnalComEventsRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComEvents, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComEventsSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComEventsError(error));
  }
}

function* analComBigTransRequestFlow(
  action: actions.AnalComBigTransRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComBigTrans, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComBigTransSuccess(JSON.parse(resData.data)));
  } catch (error) {
    // log(error)
    yield put(analComBigTransError(error));
  }
}

function* analComRelatedRequestFlow(
  action: actions.AnalComRelatedRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getComRelated, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analComRelatedSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analComRelatedError(error));
  }
}

function* analFilerRangeRequestFlow(): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(getFilterRange, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analFilterRangeSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analFilterRangeError(error));
  }
}

function* analFilerActRequestFlow(
  action: actions.AnalFilterActionRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getFilterAct, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message || resData?.dict?.errorMessage || 'Error',
      });
      throw Error(resData.message || resData?.dict?.errorMessage || 'Error');
    }

    yield put(analFilterActionSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(analFilterActionError(error));
  }
}

function* analUserFilerRequestFlow(
  action: actions.AnalUserFilterRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getUserFilter, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analUserFilterSuccess(resData.data));
  } catch (error) {
    yield put(analUserFilterError(error));
  }
}

function* analDelFilerRequestFlow(
  action: actions.AnalDelFilterRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(delFilterApi, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analDelFilterSuccess(Math.random()));
  } catch (error) {
    yield put(analDelFilterError(error));
  }
}

function* analUserSignalRequestFlow(
  action: actions.AnalUserSignalRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(getUserSignal, params, config.SmartAPIUrl);
    if (resData.status != 200) {
      yield put({
        type: 'REQUEST_PRIVATE_FALSE',
        msg: resData.message,
      });
      throw Error(resData.message);
    }

    yield put(analUserSignalSuccess(resData.data));
  } catch (error) {
    yield put(analUserSignalError(error));
  }
}

function* analSecListAllRequestFlow(
  action: actions.AnalSecListAllRequestAction,
): any {
  try {
    const config = yield select(selectConfig);
    if (!config.hasOwnProperty('SmartAPIUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(analSecListAllRequestApi, params, config.SmartAPIUrl);
    console.log(resData);
    yield put(analSecListAllSuccess(JSON.parse(resData.data)?.dict));
  } catch (error) {
    yield put(analSecListAllError(error));
  }
}

function* analWatcher() {
  yield all([
    takeLatest(actions.ANAL_STOCK_REAL_REQUESTING, analStockRealRequestFlow),
    takeLatest(actions.ANAL_SEC_LIST_ALL_REQUESTING, analSecListAllRequestFlow),
    takeLatest(actions.ANAL_INDEX_REAL_REQUESTING, analIndexRealRequestFlow),
    takeLatest(actions.ANAL_NN_INDAY_REQUESTING, analNNIndayRequestFlow),
    takeLatest(actions.ANAL_PB_DONG_TIEN_REQUESTING, analPBDongTienRequestFlow),
    takeLatest(actions.ANAL_LICH_SU_GIA_REQUESTING, analLichSuGiaRequestFlow),
    takeLatest(actions.ANAL_FS_RATIO_REQUESTING, analFSRatioRequestFlow),
    takeLatest(
      actions.ANAL_RANK_FS_RATIO_REQUESTING,
      analRankFSRatioRequestFlow,
    ),

    takeLatest(actions.ANAL_BASIC_INFO_REQUESTING, analStockInfoRequestFlow),
    takeLatest(
      actions.ANAL_STOCK_MATCH_PRICE_REQUESTING,
      analStockMatchPriceRequestFlow,
    ),
    takeLatest(
      actions.ANAL_STOCK_MB_ACTIVE_REQUESTING,
      analStockMBActiveRequestFlow,
    ),

    takeLatest(
      actions.ANAL_SEC_OVERVIEW_REQUESTING,
      analSecOverviewRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_INTRODUCTION_REQUESTING,
      analComIntroductionRequestFlow,
    ),
    takeLatest(actions.ANAL_COMPANY_BASIC_REQUESTING, analComBasicRequestFlow),
    takeLatest(
      actions.ANAL_COMPANY_SAME_IND_REQUESTING,
      analComSameIndRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_LEADER_REQUESTING,
      analComLeaderRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_HOLDER_REQUESTING,
      analComHolderRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_EVENTS_REQUESTING,
      analComEventsRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_BIGTRANS_REQUESTING,
      analComBigTransRequestFlow,
    ),
    takeLatest(
      actions.ANAL_COMPANY_RELATED_REQUESTING,
      analComRelatedRequestFlow,
    ),

    /** filter */
    takeLatest(actions.ANAL_FILTER_RANGE_REQUESTING, analFilerRangeRequestFlow),
    takeLatest(actions.ANAL_FILTER_ACT_REQUESTING, analFilerActRequestFlow),
    takeLatest(actions.ANAL_USER_FILTER_REQUESTING, analUserFilerRequestFlow),
    takeLatest(actions.ANAL_DEL_FILTER_REQUESTING, analDelFilerRequestFlow),

    /** signal */
    takeLatest(actions.ANAL_USER_SIGNAL_REQUESTING, analUserSignalRequestFlow),
  ]);
}

export default analWatcher;
