import { makeGetFooterActive, makeGetToken } from 'lib/selector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import TblCashTransferHis from './layout/tblCashTransferHis';
import FormSearchCashTransfer from './form/formSearchCashTransfer';
import PanelBankAccount from './layout/panelBankAccount';

interface IProps {
  token: any;
  footerActive: string;
}

function CashTransferComponent(props: IProps): JSX.Element {
  const { token, footerActive } = props;

  const { t } = useTranslation();

  return (
    <div className="grid gap-2 lg:grid-cols-[auto_468px] h-full">
      <div className="grid gap-2 h-full w-[-webkit-fill-available]">
        <div
          className={
            'w-full bg-skin-panel p-2 flex flex-col ' +
            (footerActive ? 'h-[calc(100vh-450px)]' : 'h-full')
          }
        >
          <FormSearchCashTransfer t={t} />
          <TblCashTransferHis />
        </div>
        {/* {footerActive === "FOOT_ORDER" && <PanelOrderInday />}
        {footerActive === "FOOT_ASSET" && <PanelAsset />}
        {footerActive === "FOOT_HISTORY" && <PanelHistory />} */}
      </div>
      <div
        className={'flex flex-col lg:w-[468px] ' + (footerActive ? 'mb-2' : '')}
      >
        <PanelBankAccount />
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getFooterActive = makeGetFooterActive();

  const mapStateToProps = (state: AppState) => {
    return {
      token: getToken(state),
      footerActive: getFooterActive(state),
      // isLoading: state.isLoading[actionTypes.REPORT_012],
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(CashTransferComponent);
