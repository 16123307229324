import * as actions from './actionType';

export function clientTokenSet(params: any): actions.ClientSetAction {
  return {
    type: actions.CLIENT_SET,
    data: params,
  };
}

export function clientTokenUnset(): actions.ClientUnsetAction {
  return {
    type: actions.CLIENT_UNSET,
  };
}

export function clientToastSet(params: any): actions.ClientToastSetAction {
  return {
    type: actions.CLIENT_TOAST_SET,
    data: params,
  };
}

export function clientToastClear(): actions.ClientToastClearAction {
  return {
    type: actions.CLIENT_TOAST_CLEAR,
  };
}

export function clientConfigSet(params: any): actions.ClientConfigSetAction {
  return {
    type: actions.CLIENT_CONFIG_SET,
    data: params,
  };
}

export function clientTypeNavSet(params: any): actions.ClientTypeNavSetAction {
  return {
    type: actions.CLIENT_TYPENAV_SET,
    data: params,
  };
}

export function dataSearchSet(params: any): actions.DataSearchSetAction {
  return {
    type: actions.DATA_SEARCH_SET,
    data: params,
  };
}

export function showNavSet(params: any): actions.ShowNavAction {
  return {
    type: actions.SHOW_NAV_SET,
    data: params,
  };
}

export function clientSettingSet(params: any): actions.ClientSettingSetAction {
  return {
    type: actions.CLIENT_SETTING_SET,
    data: params,
  };
}

export function footerActiveSet(params: any): actions.FooterActiveSetAction {
  return {
    type: actions.FOOTER_ACTIVE_SET,
    data: params,
  };
}

export function headerActiveSet(params: any): actions.HeaderActiveSetAction {
  return {
    type: actions.HEADER_ACTIVE_SET,
    data: params,
  };
}

export function setCategory(category: any): actions.SetCategoryAction {
  return {
    type: actions.CATEGORY_SET,
    category,
  };
}

export function setSetting(setting: any): actions.SetSettingAction {
  return {
    type: actions.SETTING_SET,
    setting,
  };
}

export function setLogin() {
  return {
    type: actions.LOGIN_SET,
  };
}

export function unsetLogin() {
  return {
    type: actions.LOGIN_UNSET,
  };
}

export function setChangePass(data: any): actions.SetChangePassAction {
  return {
    type: actions.CHANGE_PASS_SET,
    data,
  };
}

export function setChangePin(data: any): actions.SetChangePinAction {
  return {
    type: actions.CHANGE_PIN_SET,
    data,
  };
}

export function unsetLostSession(): actions.UnsetLostSessionAction {
  return {
    type: actions.CLEAR_LOST_SESSION,
  };
}

export function setSymbolDel(symbol: any): actions.SetSymbolDelAction {
  return {
    type: actions.SYMBOL_DEL,
    symbol,
  };
}

export function setObjTrading(data: any): actions.SetObjTradingAction {
  return {
    type: actions.OBJ_TRADING_SET,
    data,
  };
}

export function unsetObjTrading(): actions.UnsetObjTradingAction {
  return {
    type: actions.OBJ_TRADING_UNSET,
  };
}

export const setToast = function setToast(
  resData: any,
): actions.SetToastAction {
  return {
    type: actions.SHOW_TOAST,
    resData,
  };
};

export function setTypeIndex(data: any): actions.SetTypeIndexAction {
  return {
    type: actions.TYPE_INDEX_SET,
    data,
  };
}

export function setTypePrice(data: any): actions.SetTypePriceAction {
  return {
    type: actions.TYPE_PRICE_SET,
    data,
  };
}

export function setIndexHide(data: any): actions.SetIndexHideAction {
  return {
    type: actions.INDEX_HIDE_SET,
    data,
  };
}

export function setDblPrice(data: any): actions.SetDblPriceAction {
  return {
    type: actions.DBL_PRICE_SET,
    data,
  };
}

export function setSymbolActive(data: any): actions.SetSymbolActiveAction {
  return {
    type: actions.SYMBOL_ACTIVE_SET,
    data,
  };
}

export function setSymbolAdd(symbol: any): actions.SetSymbolAddAction {
  return {
    type: actions.SYMBOL_ADD,
    symbol,
  };
}

export function setSymbolScroll(symbol: any): actions.SetSymbolScrollAction {
  return {
    type: actions.SYMBOL_SCROLL,
    symbol,
  };
}

export function configRequest(): actions.ConfigRequestAction {
  return {
    type: actions.CONFIG_REQUESTING,
  };
}

export function configRequestSuccess(
  config: any,
): actions.ConfigRequestSuccessAction {
  return {
    type: actions.CONFIG_REQUEST_SUCCESS,
    config,
  };
}

export function configRequestError(
  error: any,
): actions.ConfigRequestErrorAction {
  return {
    type: actions.CONFIG_REQUEST_ERROR,
    error,
  };
}

export function categoryRequest(data: any): actions.CategoryRequestAction {
  return {
    type: actions.CATEGORY_REQUESTING,
    data,
  };
}

export function categoryRequestSuccess(
  category: any,
): actions.CategoryRequestSuccessAction {
  return {
    type: actions.CATEGORY_REQUEST_SUCCESS,
    category,
  };
}

export function categoryRequestError(
  error: any,
): actions.CategoryRequestErrorAction {
  return {
    type: actions.CATEGORY_REQUEST_ERROR,
    error,
  };
}

export function listAccountRequest(
  data: any,
): actions.ListAccountRequestAction {
  return {
    type: actions.LIST_ACCOUNT_REQUESTING,
    data,
  };
}

export function listAccountRequestSuccess(
  resData: any,
): actions.ListAccountRequestSuccessAction {
  return {
    type: actions.LIST_ACCOUNT_REQUEST_SUCCESS,
    resData,
  };
}

export function listAccountRequestError(
  error: any,
): actions.ListAccountRequestErrorAction {
  return {
    type: actions.LIST_ACCOUNT_REQUEST_ERROR,
    error,
  };
}

export function getOtpRequest(data: any): actions.GetOtpRequestAction {
  return {
    type: actions.GET_OTP_REQUESTING,
    data,
  };
}

export function getOtpRequestSuccess(
  resData: any,
): actions.GetOtpRequestSuccessAction {
  return {
    type: actions.GET_OTP_REQUEST_SUCCESS,
    resData,
  };
}

export function getOtpRequestError(
  error: any,
): actions.GetOtpRequestErrorAction {
  return {
    type: actions.GET_OTP_REQUEST_ERROR,
    error,
  };
}

export function checkPinRequest(data: any): actions.CheckPinRequestAction {
  return {
    type: actions.CHECK_PIN_REQUESTING,
    data,
  };
}

export function checkPinRequestSuccess(
  resData: any,
): actions.CheckPinRequestSuccessAction {
  return {
    type: actions.CHECK_PIN_REQUEST_SUCCESS,
    resData,
  };
}

export function checkPinRequestError(
  error: any,
): actions.CheckPinRequestErrorAction {
  return {
    type: actions.CHECK_PIN_REQUEST_ERROR,
    error,
  };
}

export function defaultAccountSet(
  resData: any,
): actions.DefaultAccountSetAction {
  return {
    type: actions.DEF_ACCOUNT_SET,
    resData,
  };
}

export function getBankOnlineRequest(
  data: any,
): actions.GetBankOnlineRequestAction {
  return {
    type: actions.GET_BANK_ONLINE_REQUESTING,
    data,
  };
}

export function getBankOnlineRequestSuccess(
  resData: any,
): actions.GetBankOnlineRequestSuccessAction {
  return {
    type: actions.GET_BANK_ONLINE_REQUEST_SUCCESS,
    resData,
  };
}

export function getBankOnlineRequestError(
  error: any,
): actions.GetBankOnlineRequestErrorAction {
  return {
    type: actions.GET_BANK_ONLINE_REQUEST_ERROR,
    error,
  };
}

export function getBankBranchRequest(
  data: any,
): actions.GetBankBranchRequestAction {
  return {
    type: actions.GET_BANK_BRANCH_REQUESTING,
    data,
  };
}

export function getBankBranchRequestSuccess(
  resData: any,
): actions.GetBankBranchRequestSuccessAction {
  return {
    type: actions.GET_BANK_BRANCH_REQUEST_SUCCESS,
    resData,
  };
}

export function getBankBranchRequestError(
  error: any,
): actions.GetBankBranchRequestErrorAction {
  return {
    type: actions.GET_BANK_BRANCH_REQUEST_ERROR,
    error,
  };
}

export function getProvinceRequest(
  data: any,
): actions.GetProvinceRequestAction {
  return {
    type: actions.GET_PROVINCE_REQUESTING,
    data,
  };
}

export function getProvinceRequestSuccess(
  resData: any,
): actions.GetProvinceRequestSuccessAction {
  return {
    type: actions.GET_PROVINCE_REQUEST_SUCCESS,
    resData,
  };
}

export function getProvinceRequestError(
  error: any,
): actions.GetProvinceRequestErrorAction {
  return {
    type: actions.GET_PROVINCE_REQUEST_ERROR,
    error,
  };
}

export function accountPortfolioRequest(
  data: any,
): actions.AccountPortfolioRequestAction {
  return {
    type: actions.ACC_PORTFOLIO_REQUESTING,
    data,
  };
}

export function accountPortfolioSuccess(
  resData: any,
): actions.AccountPortfolioSuccessAction {
  return {
    type: actions.ACC_PORTFOLIO_SUCCESS,
    resData,
  };
}

export function accountPortfolioError(
  error: any,
): actions.AccountPortfolioErrorAction {
  return {
    type: actions.ACC_PORTFOLIO_ERROR,
    error,
  };
}

export function updatePortfolioRequest(
  data: any,
): actions.UpdatePortfolioRequestAction {
  return {
    type: actions.UPD_PORTFOLIO_REQUESTING,
    data,
  };
}

export function updatePortfolioSuccess(
  resData: any,
): actions.UpdatePortfolioSuccessAction {
  return {
    type: actions.UPD_PORTFOLIO_SUCCESS,
    resData,
  };
}

export function updatePortfolioError(
  error: any,
): actions.UpdatePortfolioErrorAction {
  return {
    type: actions.UPD_PORTFOLIO_ERROR,
    error,
  };
}

export function delPortfolioRequest(
  data: any,
): actions.DelPortfolioRequestAction {
  return {
    type: actions.DEL_PORTFOLIO_REQUESTING,
    data,
  };
}

export function delPortfolioSuccess(
  resData: any,
): actions.DelPortfolioSuccessAction {
  return {
    type: actions.DEL_PORTFOLIO_SUCCESS,
    resData,
  };
}

export function delPortfolioError(error: any): actions.DelPortfolioErrorAction {
  return {
    type: actions.DEL_PORTFOLIO_ERROR,
    error,
  };
}
