/* eslint-disable jsx-a11y/anchor-is-valid */
import BgLogin from 'assets/img/bg/bg_login_small.png';
import { clientTokenUnset } from 'containers/client/actions';
import { loginRequest, loginSuccess } from 'containers/login/actions';
import * as actionTypes from 'containers/login/actionType';
import RenderLoginInput from 'helper/input/renderLoginInput';
import { IUser } from 'interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { AppState } from 'reducers';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { useConfig } from 'shared/configContext';
import { encrypt } from 'utils';
import { BtnSubmit } from 'utils/styledUtils';
import { required } from 'utils/validation';

interface Props {
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  clearChangePass: Function;
  onResetPass: Function;
}
const FormLogin: React.FunctionComponent<InjectedFormProps<IUser> & Props> = (
  props,
) => {
  const abortController = new AbortController();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, submitting, isLoading, errorMsg, clearChangePass, onResetPass } =
    props;

  const { config } = useConfig();

  React.useEffect(() => {
    return () => {
      dispatch(loginSuccess(null));
      dispatch({ type: 'login/LOGIN_CLEAR' });
    };
  }, []);

  function getKey() {
    const request = fetch((config?.AuthUrl || '') + '/GetKey', {
      signal: abortController.signal,
    });

    return request
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => {
        throw error;
      });
  }

  function submit(user: IUser) {
    const handleGetKey = new Promise((resolve, reject) => {
      getKey().then((response: any) => {
        if (response.valid < 30)
          getKey().then((res) => {
            resolve(res);
          });
        else resolve(response);
      });
    });

    handleGetKey.then((response: any) => {
      const _pass = encrypt(user.password, response.key);
      dispatch(clientTokenUnset());
      dispatch(loginRequest(user.username, _pass));
    });
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className="px-10 py-7 rounded-[10px] m-auto w-full md:w-[480px] flex flex-col justify-center"
        style={{
          border: 'none',
          background: 'transparent',
          backgroundImage: `url(${BgLogin})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <label className="text-2xl text-white uppercase font-semibold">
          Đăng nhập
        </label>

        <div className="auth-messages text-xs">
          {!isLoading && errorMsg ? (
            <div className="fz-13">{errorMsg.toString()}</div>
          ) : null}
          {isLoading ? <div className=" fz-13">Logging in...</div> : null}
        </div>
        <label className="w-full mt-4">
          <div className="text-sm font-medium text-white pb-2 mt-4">
            {t('login.txt-account')}{' '}
            <small className="text-red-600 font-normal">(*)</small>
          </div>
          <Field
            name="username"
            type="email"
            autoComplete="off"
            placeholder={t('login.txt-placeholder-account')}
            component={RenderLoginInput}
            // validate={[required, validateEmail]}
            validate={[required]}
          />
          <div className="text-sm font-medium text-white pb-2 mt-4">
            {t('login.txt-pass')}{' '}
            <small className="text-red-600 font-normal">(*)</small>
          </div>
          <Field
            name="password"
            type="password"
            autoComplete="off"
            placeholder={t('login.txt-placeholder-pass')}
            component={RenderLoginInput}
            validate={required}
          />
        </label>
        <div className="flex justify-end my-2 text-white text-sm font-normal tracking-[0.01em]">
          <a
            className="ml-2 underline cursor-pointer"
            onClick={() => onResetPass()}
          >
            Quên mật khẩu
          </a>
        </div>
        <div className="flex justify-center">
          <BtnSubmit
            type="submit"
            disabled={submitting}
            className="mx-8 w-full mt-2 !text-white"
          >
            {t('login.txt-login')}
          </BtnSubmit>
        </div>
        <div className="flex justify-center mt-4 text-white text-sm font-normal leading-5 tracking-[0.01em]">
          <span>{t('login.txt-no-account-yet')}</span>
          <a
            className="ml-2 underline cursor-pointer"
            onClick={() => clearChangePass()}
          >
            {t('login.txt-register')}
          </a>
        </div>
      </form>
    </>
  );
};

const _FormLogin = reduxForm<IUser, Props>({
  form: 'formLoginPawn',
  enableReinitialize: true,
})(FormLogin as any);

const selector = formValueSelector('formLoginPawn');

const makeMapStateToProps = () => {
  const mapStateToProps = (state: AppState) => {
    const { username, password } = selector(state, 'username', 'password');

    return {
      isLoading: state.isLoading[actionTypes.PAGE_LOGIN],
      errorMsg: state.error[actionTypes.PAGE_LOGIN],

      username,
      password,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormLogin);
