import { NavItems } from 'interface';

export const navItems: NavItems[] = [
  // {
  //   name: 'Dashboard',
  //   labelCode: 'nav.txt-dashboard',
  //   url: '/dashboard',
  //   icon: 'search',
  //   right: ['ALL'],
  // },
  {
    name: 'Bảng giá',
    labelCode: 'nav.txt-priceboard',
    url: '/price',
    icon: 'dashboard',
    right: ['ALL'],
  },
  {
    name: 'Đặt lệnh',
    labelCode: 'nav.txt-order',
    url: '/order',
    icon: 'order',
    right: ['ALL'],
  },
  // {
  //   name: 'Giao dịch chứng khoán',
  //   labelCode: 'nav.stock',
  //   url: '/stock',
  //   icon: 'stock',
  //   right: ['ALL'],
  // },
  // {
  //   name: 'Giao dịch tiền',
  //   labelCode: 'nav.cash',
  //   url: '/cash',
  //   icon: 'cash',
  //   right: ['ALL'],
  // },
  {
    name: 'Quản lý tài khoản',
    labelCode: 'nav.account',
    url: '/account',
    icon: 'account',
    right: ['ALL'],
  },
  // {
  //   name: 'Phân tích',
  //   labelCode: 'nav.analysis',
  //   url: '/market/analysis',
  //   icon: 'analysis',
  //   right: ['ALL'],
  // },
  // {
  //   name: 'Tra cứu',
  //   labelCode: 'nav.search',
  //   url: '/search',
  //   icon: 'search',
  //   right: ['ALL'],
  // },
  // {
  //   name: 'Hỗ trợ',
  //   labelCode: 'nav.support',
  //   url: '/support',
  //   icon: 'support',
  //   right: ['ALL'],
  // },
  // {
  //   name: 'Khảo sát',
  //   labelCode: 'nav.survey',
  //   url: '/survey',
  //   icon: 'survey',
  //   right: ['ALL'],
  // },
  {
    name: 'Cài đặt',
    labelCode: 'nav.setting',
    url: '/setting',
    icon: 'setting',
    right: ['ALL'],
  },
];

export const mobileItems: NavItems[] = [
  {
    name: 'Trang chủ',
    labelCode: 'nav.txt-trading',
    url: '/trading',
    icon: 'order',
    right: ['ALL'],
  },
  {
    name: 'Bảng giá',
    labelCode: 'nav.txt-priceboard',
    url: '/price/bang-gia',
    icon: 'dashboard',
    right: ['ALL'],
  },
  {
    name: 'Quản lý tài khoản',
    labelCode: 'nav.account',
    url: '/account',
    icon: 'account',
    right: ['ALL'],
  },
  {
    name: 'Cài đặt',
    labelCode: 'nav.setting',
    url: '/setting',
    icon: 'setting',
    right: ['ALL'],
  },
];
