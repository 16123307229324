/** tham số hệ thống */
export const SET_PARAM_SEARCH = 'set/PARAM_SEARCH';
export interface SetParamSearchAction {
  type: typeof SET_PARAM_SEARCH;
}

export const SET_PARAM_SEARCH_REQUESTING = 'set/PARAM_SEARCH_REQUESTING';
export interface SetParamSearchRequestingAction {
  type: typeof SET_PARAM_SEARCH_REQUESTING;
  data: any;
}

export const SET_PARAM_SEARCH_SUCCESS = 'set/PARAM_SEARCH_SUCCESS';
export interface SetParamSearchSuccessAction {
  type: typeof SET_PARAM_SEARCH_SUCCESS;
  resData: any;
}

export const SET_PARAM_SEARCH_ERROR = 'set/PARAM_SEARCH_ERROR';
export interface SetParamSearchErrorAction {
  type: typeof SET_PARAM_SEARCH_ERROR;
  error: any;
}

/** chi tiết tham số hệ thống */
export const SET_PARAM_SINGLE_REQUESTING = 'set/PARAM_SINGLE_REQUESTING';
export interface SetParamSingleRequestingAction {
  type: typeof SET_PARAM_SINGLE_REQUESTING;
  data: any;
}

export const SET_PARAM_SINGLE_SUCCESS = 'set/PARAM_SINGLE_SUCCESS';
export interface SetParamSingleSuccessAction {
  type: typeof SET_PARAM_SINGLE_SUCCESS;
  resData: any;
}

export const SET_PARAM_SINGLE_ERROR = 'set/PARAM_SINGLE_ERROR';
export interface SetParamSingleErrorAction {
  type: typeof SET_PARAM_SINGLE_ERROR;
  error: any;
}

/** update tham số hệ thống */
export const SET_PARAM_UPD_REQUESTING = 'set/PARAM_UPD_REQUESTING';
export interface SetParamUpdRequestingAction {
  type: typeof SET_PARAM_UPD_REQUESTING;
  data: any;
}

export const SET_PARAM_UPD_SUCCESS = 'set/PARAM_UPD_SUCCESS';
export interface SetParamUpdSuccessAction {
  type: typeof SET_PARAM_UPD_SUCCESS;
  resData: any;
}

export const SET_PARAM_UPD_ERROR = 'set/PARAM_UPD_ERROR';
export interface SetParamUpdErrorAction {
  type: typeof SET_PARAM_UPD_ERROR;
  error: any;
}

/** xóa tham số hệ thống */
export const SET_PARAM_DEL_REQUESTING = 'set/PARAM_DEL_REQUESTING';
export interface SetParamDelRequestingAction {
  type: typeof SET_PARAM_DEL_REQUESTING;
  data: any;
}

export const SET_PARAM_DEL_SUCCESS = 'set/PARAM_DEL_SUCCESS';
export interface SetParamDelSuccessAction {
  type: typeof SET_PARAM_DEL_SUCCESS;
  resData: any;
}

export const SET_PARAM_DEL_ERROR = 'set/PARAM_DEL_ERROR';
export interface SetParamDelErrorAction {
  type: typeof SET_PARAM_DEL_ERROR;
  error: any;
}

/** danh sách chức năng */
export const FUNC_SEARCH = 'set/FUNC_SEARCH';
export interface FuncSearchAction {
  type: typeof FUNC_SEARCH;
}

export const FUNC_SEARCH_REQUESTING = 'set/FUNC_SEARCH_REQUESTING';
export interface FuncSearchRequestingAction {
  type: typeof FUNC_SEARCH_REQUESTING;
  data: any;
}

export const FUNC_SEARCH_SUCCESS = 'set/FUNC_SEARCH_SUCCESS';
export interface FuncSearchSuccessAction {
  type: typeof FUNC_SEARCH_SUCCESS;
  resData: any;
}

export const FUNC_SEARCH_ERROR = 'set/FUNC_SEARCH_ERROR';
export interface FuncSearchErrorAction {
  type: typeof FUNC_SEARCH_ERROR;
  error: any;
}

/** chi tiết chức năng */
export const FUNC_DETAIL_REQUESTING = 'set/FUNC_DETAIL_REQUESTING';
export interface FuncDetailRequestingAction {
  type: typeof FUNC_DETAIL_REQUESTING;
  data: any;
}

export const FUNC_DETAIL_SUCCESS = 'set/FUNC_DETAIL_SUCCESS';
export interface FuncDetailSuccessAction {
  type: typeof FUNC_DETAIL_SUCCESS;
  resData: any;
}

export const FUNC_DETAIL_ERROR = 'set/FUNC_DETAIL_ERROR';
export interface FuncDetailErrorAction {
  type: typeof FUNC_DETAIL_ERROR;
  error: any;
}

/** update chức năng */
export const FUNC_UPD_REQUESTING = 'set/FUNC_UPD_REQUESTING';
export interface FuncUpdRequestingAction {
  type: typeof FUNC_UPD_REQUESTING;
  data: any;
}

export const FUNC_UPD_SUCCESS = 'set/FUNC_UPD_SUCCESS';
export interface FuncUpdSuccessAction {
  type: typeof FUNC_UPD_SUCCESS;
  resData: any;
}

export const FUNC_UPD_ERROR = 'set/FUNC_UPD_ERROR';
export interface FuncUpdErrorAction {
  type: typeof FUNC_UPD_ERROR;
  error: any;
}

/** xóa chức năng */
export const FUNC_DEL_REQUESTING = 'set/FUNC_DEL_REQUESTING';
export interface FuncDelRequestingAction {
  type: typeof FUNC_DEL_REQUESTING;
  data: any;
}

export const FUNC_DEL_SUCCESS = 'set/FUNC_DEL_SUCCESS';
export interface FuncDelSuccessAction {
  type: typeof FUNC_DEL_SUCCESS;
  resData: any;
}

export const FUNC_DEL_ERROR = 'set/FUNC_DEL_ERROR';
export interface FuncDelErrorAction {
  type: typeof FUNC_DEL_ERROR;
  error: any;
}

/** nhóm người dùng */
export const USER_GR_SEARCH = 'ugr/GR_SEARCH';
export interface UserGrSearchAction {
  type: typeof USER_GR_SEARCH;
}

export const USER_GR_SEARCH_REQUESTING = 'ugr/GR_SEARCH_REQUESTING';
export interface UserGrSearchRequestingAction {
  type: typeof USER_GR_SEARCH_REQUESTING;
  data: any;
}

export const USER_GR_SEARCH_SUCCESS = 'ugr/GR_SEARCH_SUCCESS';
export interface UserGrSearchSuccessAction {
  type: typeof USER_GR_SEARCH_SUCCESS;
  resData: any;
}

export const USER_GR_SEARCH_ERROR = 'ugr/GR_SEARCH_ERROR';
export interface UserGrSearchErrorAction {
  type: typeof USER_GR_SEARCH_ERROR;
  error: any;
}

/** chi tiết nhóm người dùng */
export const USER_GR_DETAIL_REQUESTING = 'ugr/GR_DETAIL_REQUESTING';
export interface UserGrDetailRequestingAction {
  type: typeof USER_GR_DETAIL_REQUESTING;
  data: any;
}

export const USER_GR_DETAIL_SUCCESS = 'ugr/GR_DETAIL_SUCCESS';
export interface UserGrDetailSuccessAction {
  type: typeof USER_GR_DETAIL_SUCCESS;
  resData: any;
}

export const USER_GR_DETAIL_ERROR = 'ugr/GR_DETAIL_ERROR';
export interface UserGrDetailErrorAction {
  type: typeof USER_GR_DETAIL_ERROR;
  error: any;
}

/** update nhóm người dùng */
export const USER_GR_UPD_REQUESTING = 'ugr/GR_UPD_REQUESTING';
export interface UserGrUpdRequestingAction {
  type: typeof USER_GR_UPD_REQUESTING;
  data: any;
}

export const USER_GR_UPD_SUCCESS = 'ugr/GR_UPD_SUCCESS';
export interface UserGrUpdSuccessAction {
  type: typeof USER_GR_UPD_SUCCESS;
  resData: any;
}

export const USER_GR_UPD_ERROR = 'ugr/GR_UPD_ERROR';
export interface UserGrUpdErrorAction {
  type: typeof USER_GR_UPD_ERROR;
  error: any;
}

/** xóa nhóm người dùng */
export const USER_GR_DEL_REQUESTING = 'ugr/GR_DEL_REQUESTING';
export interface UserGrDelRequestingAction {
  type: typeof USER_GR_DEL_REQUESTING;
  data: any;
}

export const USER_GR_DEL_SUCCESS = 'ugr/GR_DEL_SUCCESS';
export interface UserGrDelSuccessAction {
  type: typeof USER_GR_DEL_SUCCESS;
  resData: any;
}

export const USER_GR_DEL_ERROR = 'ugr/GR_DEL_ERROR';
export interface UserGrDelErrorAction {
  type: typeof USER_GR_DEL_ERROR;
  error: any;
}

/** quyền nhóm người dùng */
export const USER_GR_FUNC_REQUESTING = 'ugr/GR_FUNC_REQUESTING';
export interface UserGrFuncRequestingAction {
  type: typeof USER_GR_FUNC_REQUESTING;
  data: any;
}

export const USER_GR_FUNC_SUCCESS = 'ugr/GR_FUNC_SUCCESS';
export interface UserGrFuncSuccessAction {
  type: typeof USER_GR_FUNC_SUCCESS;
  resData: any;
}

export const USER_GR_FUNC_ERROR = 'ugr/GR_FUNC_ERROR';
export interface UserGrFuncErrorAction {
  type: typeof USER_GR_FUNC_ERROR;
  error: any;
}

export type SettingAction =
  | SetParamSearchAction
  | SetParamSearchRequestingAction
  | SetParamSearchSuccessAction
  | SetParamSearchErrorAction
  | SetParamSingleRequestingAction
  | SetParamSingleSuccessAction
  | SetParamSingleErrorAction
  | SetParamUpdRequestingAction
  | SetParamUpdSuccessAction
  | SetParamUpdErrorAction
  | SetParamDelRequestingAction
  | SetParamDelSuccessAction
  | SetParamDelErrorAction
  | FuncSearchAction
  | FuncSearchRequestingAction
  | FuncSearchSuccessAction
  | FuncSearchErrorAction
  | FuncDetailRequestingAction
  | FuncDetailSuccessAction
  | FuncDetailErrorAction
  | FuncUpdRequestingAction
  | FuncUpdSuccessAction
  | FuncUpdErrorAction
  | FuncDelRequestingAction
  | FuncDelSuccessAction
  | FuncDelErrorAction
  | UserGrSearchAction
  | UserGrSearchRequestingAction
  | UserGrSearchSuccessAction
  | UserGrSearchErrorAction
  | UserGrDetailRequestingAction
  | UserGrDetailSuccessAction
  | UserGrDetailErrorAction
  | UserGrUpdRequestingAction
  | UserGrUpdSuccessAction
  | UserGrUpdErrorAction
  | UserGrDelRequestingAction
  | UserGrDelSuccessAction
  | UserGrDelErrorAction
  | UserGrFuncRequestingAction
  | UserGrFuncSuccessAction
  | UserGrFuncErrorAction;
