import React from 'react';
import { connect, useDispatch } from 'react-redux';

import _ from 'lodash';
import { loginSuccess } from './actions';

import { clientTokenSet } from '../client/actions';
import { useHistory } from 'react-router';
import BgLogin from 'assets/img/bg/bg_login_full.png';
import { makeGetConfig } from 'lib/selector';
import { AppState } from 'reducers';
import FormLogin from 'components/login/formLogin';
import FormRegister from 'components/login/formRegister';
import FormForgotPass from 'components/login/formForgotPass';
import { storages } from 'lib/storages';

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  successful: any;
}

function LoginPage(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [showRegister, setShowRegister] = React.useState<boolean>(false);
  const [showResetPass, setShowResetPass] = React.useState<boolean>(false);

  const { successful } = props;

  const preSuccessful = usePrevious(successful);

  React.useEffect(() => {
    const storedToken = storages.loadState('token');
    if (storedToken) {
      const token = JSON.parse(storedToken);
      if (token.session) {
        dispatch(clientTokenSet(token));
        storages.removeState('ttl');
        navigate.push('/price/bang-gia/vn30');
      }
    }

    return () => {
      dispatch(loginSuccess(null));
    };
  }, []);

  React.useEffect(() => {
    if (successful && !_.isEqual(successful, preSuccessful)) {
      console.log(successful);

      // dispatch action to store
      dispatch(clientTokenSet(successful));
      storages.saveState('ttl', new Date().getTime())
      storages.saveState('token', JSON.stringify(successful));
      setTimeout(() => {
        navigate.push('/price/bang-gia/vn30');
      }, 200);
    }
  }, [successful]);

  return (
    <div className="app flex flex-col h-screen overflow-hidden relative">
      <div
        className="h-screen flex items-center justify-center overflow-auto"
        style={{
          backgroundImage: `url(${BgLogin})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {!showRegister && !showResetPass && (
          <FormLogin clearChangePass={() => setShowRegister(true)} onResetPass={() => setShowResetPass(true)} />
        )}
        {showRegister && (
          <FormRegister clearChangePass={() => setShowRegister(false)} />
        )}
        {showResetPass && (
          <FormForgotPass clearChangePass={() => setShowResetPass(false)} />
        )}
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getConfig = makeGetConfig();

  const mapStateToProps = (state: AppState) => {
    return {
      config: getConfig(state),

      successful: state.login.successful,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(LoginPage);
