import * as actions from './actionType';

export interface SocketState {
  receive1101: any;
  receive3211: any;
  receive3220: any;
  receive3210: any;

  serverStatus: string;
  socketReady: boolean;
  reRegister: boolean;
  receiveSignal: any;
  regSym: string | null;
  event: any;
  regTrade: any;

  algoSignal: boolean;
  algoIndex: any;
  algoStock: any;
  algoCache: any;
  algoIChart: any;
  algoSChart: any;
}

const initialState = {
  receive1101: {},
  receive3211: {},
  receive3220: {},
  receive3210: {},
  // channelStatus: 'off',
  serverStatus: 'unknown',
  socketReady: false,
  regPrivate: 'unknown',
  reRegister: false,

  algoSignal: false,
  receiveSignal: null,
  algoIndex: null,
  algoStock: null,
  algoCache: null,
  algoIChart: null,
  algoSChart: null,

  event: null,
  regSym: null,
  regTrade: null,
};

export default function socketReducer(
  state: SocketState = initialState,
  action: actions.SocketAction | any,
): SocketState {
  switch (action.type) {
    /***************************** LOG_OUT ***************************/
    case 'LOG_OUT':
    case 'CLIENT_UNSET':
    case 'INVALID_SESSION':
      return {
        ...state,
        event: null,
      };

    /***************************** PRIVATE ***************************/

    case Event:
      return { ...state, event: action.resData };

    /***************************** PUBLIC ***************************/
    case actions.SET_REG_SYMBOL:
      return {
        ...state,
        regSym: (state.regSym ? state.regSym + ',' : '') + action.symbol,
      };

    case actions.RE_REGISTER:
      return {
        ...state,
        reRegister: action.resData,
      };

    case actions.UNSET_REG_SYMBOL:
      return { ...state, regSym: '' };

    case 'REG_TRADE_SYMBOL':
      return { ...state, regTrade: action.data };

    case actions.SERVER_OFF:
      return { ...state, serverStatus: 'off' };

    case actions.SERVER_ON:
      return { ...state, serverStatus: 'on' };

    // case 'REGISTER_OK':
    //   return { ...state, regPrivate: 'ok', serverStatus: 'on' }

    // case 'REGISTER_NOT_OK':
    //   return { ...state, regPrivate: 'not', serverStatus: 'off' }

    case actions.SOCKET_READY:
      return { ...state, socketReady: true, serverStatus: 'on' };

    case actions.RECEIVE_1101:
      return { ...state, receive1101: action.resData };

    // case RECEIVE_3211:
    //   return { ...state, receive3211: action.resData }

    case actions.RECEIVE_3220:
      return { ...state, receive3220: action.resData };

    case actions.RECEIVE_3210:
      return { ...state, receive3210: action.resData };

    case 'ALGO_SIGNAL_READY':
      // console.log(action);
      return { ...state, algoSignal: action.resData };

    case 'ALGO_SIGNAL_RECEIVE':
      // console.log(action);
      return { ...state, receiveSignal: action.resData };

    case 'ALGO_INDEX_RECEIVE':
      // console.log(action);
      return { ...state, algoIndex: action.resData };

    case 'ALGO_STOCK_RECEIVE':
      // console.log(action);
      return { ...state, algoStock: action.resData };

    case 'ALGO_CACHE_RECEIVE':
      // console.log(action);
      return { ...state, algoCache: action.resData };

    case 'ALGO_INDEX_CHART':
      // console.log(action);
      return { ...state, algoIChart: action.resData };

    case 'ALGO_STOCK_CHART':
      // console.log(action);
      return { ...state, algoSChart: action.resData };

    default:
      return state;
  }
}
