import { BtnSubmit } from 'utils/styledUtils';
import BankAccountItem from './bankAccountItem';

interface IProps {}

function PanelBankAccount(props: IProps): JSX.Element {
  return (
    <div className={'w-full bg-skin-panel p-2 flex flex-col h-full'}>
      <div className="grid grid-cols-3 gap-2">
        <label className="col-start-1 col-end-3 text-sm md:text-lg text-white">
          Danh sách tài khoản ngân hàng
        </label>
        <BtnSubmit type="button" className="self-end !text-xs h-10 primary">
          Thêm mới tài khoản
        </BtnSubmit>
      </div>
      <ul className="w-full py-3">
        <BankAccountItem />
        <BankAccountItem className="mt-2" />
      </ul>
    </div>
  );
}

export default PanelBankAccount;
