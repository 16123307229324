/** pawn cash */
export const STOCK_SEARCH = 'stock/SEARCH';
export interface StockSearchAction {
  type: typeof STOCK_SEARCH;
}

export const STOCK_SEARCH_REQUESTING = 'stock/SEARCH_REQUESTING';
export interface StockSearchRequestingAction {
  type: typeof STOCK_SEARCH_REQUESTING;
  data: any;
}

export const STOCK_SEARCH_SUCCESS = 'stock/SEARCH_SUCCESS';
export interface StockSearchSuccessAction {
  type: typeof STOCK_SEARCH_SUCCESS;
  resData: any;
}

export const STOCK_SEARCH_ERROR = 'stock/SEARCH_ERROR';
export interface StockSearchErrorAction {
  type: typeof STOCK_SEARCH_ERROR;
  error: any;
}

export type StockAction =
  | StockSearchAction
  | StockSearchRequestingAction
  | StockSearchSuccessAction
  | StockSearchErrorAction;
