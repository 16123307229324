import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
// import { compose, createStore, applyMiddleware } from 'redux';

import 'index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './assets/css/nav.css';
import './assets/css/reactmodal.css';
import './assets/css/styles.css';

import WebSocketProvider from 'containers/socket/webSocket';
import { ConfigProvider } from 'shared/configContext';
import { WindowContextProvider } from 'shared/windowActive';
import App from './App';
import AlgoSignalProvider from './containers/socket/signalAlgo';
import './i18n/config';
import rootReducer from './reducers';
import rootSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewareProd = [sagaMiddleware];

/* prod */
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewareProd) as any,
});
// const store = createStore(
//   rootReducer,
//   compose(applyMiddleware(sagaMiddleware)),
// );

sagaMiddleware.run(rootSagas);

/* disable console log when production build */
let consoleHolder: Console = console;
function debug(bool: boolean) {
  if (!bool) {
    consoleHolder = console;
    console = {} as any;
    Object.keys(consoleHolder).forEach(function (key) {
      console[key as keyof Console] = function () { } as any;
    });
  } else {
    console = consoleHolder;
  }
}

debug(process.env.NODE_ENV !== 'production');

const container = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <WindowContextProvider>
      <ConfigProvider>
        <WebSocketProvider store={store}>
          <AlgoSignalProvider>
            <App />
          </AlgoSignalProvider>
        </WebSocketProvider>
      </ConfigProvider>
    </WindowContextProvider>
  </Provider>,
  container,
);
