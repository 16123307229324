import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en';

import vi from './locales/vi';

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'vi',
    fallbackLng: 'vi', // use en if detected lng is not available
    debug: false,
  });

export default i18n;
