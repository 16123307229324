/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { formatVolume10, StringToInt } from 'utils';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { numberFormat } from 'utils';
import { useDispatch } from 'react-redux';
import { analStockRealSuccess } from 'containers/market/analysis/actions';
import { colorFix } from 'utils';
import { useTranslation } from 'react-i18next';
import { AppState } from 'reducers';
import { IStockDetail } from 'interface/response';

function usePrevious(value: any) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  symbolInfo: any;
  analStReal?: IStockDetail;
}

function PanelBuocGia(props: Props): JSX.Element {
  const { symbolInfo } = props;

  const [stockDetail, setStockDetail] = React.useState<any>(null);
  const { t } = useTranslation();

  const preSymbolInfo = usePrevious(symbolInfo);

  React.useEffect(() => {
    if (symbolInfo && !_.isEqual(symbolInfo, preSymbolInfo)) {
      //   dispatch(analStockRealRequest(symbolInfo.sym));
      setStockDetail(symbolInfo);
    }
  }, [symbolInfo]);

  const totalBV =
    stockDetail &&
    StringToInt(stockDetail.bV1) +
      StringToInt(stockDetail.bV2) +
      StringToInt(stockDetail.bV3);

  const totalOV =
    stockDetail &&
    StringToInt(stockDetail.oV1) +
      StringToInt(stockDetail.oV2) +
      StringToInt(stockDetail.oV3);

  const perBV1 =
    stockDetail && ~~((StringToInt(stockDetail.bV1) * 100) / totalBV);
  const perBV2 =
    stockDetail && ~~((StringToInt(stockDetail.bV2) * 100) / totalBV);
  const perBV3 =
    stockDetail && ~~((StringToInt(stockDetail.bV3) * 100) / totalBV);

  const perOV1 =
    stockDetail && ~~((StringToInt(stockDetail.oV1) * 100) / totalOV);
  const perOV2 =
    stockDetail && ~~((StringToInt(stockDetail.oV2) * 100) / totalOV);
  const perOV3 =
    stockDetail && ~~((StringToInt(stockDetail.oV3) * 100) / totalOV);

  const perBV =
    totalBV + totalOV === 0
      ? 50
      : stockDetail && ~~((totalBV * 100) / (totalBV + totalOV));

  const _house = new Date().getHours();
  const _min = new Date().getMinutes();

  console.log(symbolInfo);

  return (
    <div className="w-full h-full bg-skin-natural-2">
      <div className="py-0 px-2">
        <div className="flex flex-col">
          <div className="flex justify-between py-2">
            <span className="text-skin-footer text-xs font-semibold pl-2 uppercase">
              {t('trading.history.buy')}
            </span>
            <span className="text-skin-footer text-xs font-semibold uppercase">
              {t('trading.history.price')}
            </span>
            <span className="text-skin-footer text-xs font-semibold uppercase pr-2">
              {t('trading.history.sell')}
            </span>
          </div>
          <div className="px-1 relative progress-total mb-1">
            <div className="progress">
              <div className="bg-success" style={{ width: `${perBV}%` }}></div>
            </div>
            <span className="per-vol-buy !text-skin-text3 text-xs">
              {symbolInfo && formatVolume10(totalBV)}
            </span>
            <span className="per-vol-sell !text-skin-text3 text-xs">
              {symbolInfo && formatVolume10(totalOV)}
            </span>
          </div>
        </div>
        {symbolInfo && stockDetail && (
          <div className="w-full grid grid-cols-2">
            <div className="pr-px">
              <div className="p-0">
                <table className="table table-borderless w-full">
                  <tbody>
                    {stockDetail?.bV1 !== 0 && (
                      <tr>
                        <td className="relative rtl p-[0.1rem]">
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.bP1,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perBV1}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs">
                            {formatVolume10(stockDetail?.bV1)}
                          </span>
                          <span
                            className={
                              'per-price text-xs ' +
                              colorFix(
                                stockDetail.bP1,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                          >
                            {stockDetail &&
                              ((!stockDetail.bP1 || stockDetail.bP1 == 0) &&
                              _house === 9 &&
                              _min <= 15
                                ? 'ATO'
                                : (!stockDetail.bP1 || stockDetail.bP1 == 0) &&
                                    _house === 14 &&
                                    _min >= 30 &&
                                    _min <= 45
                                  ? 'ATC'
                                  : numberFormat(stockDetail.bP1, 2))}
                          </span>
                        </td>
                      </tr>
                    )}
                    {stockDetail?.bV2 !== 0 && (
                      <tr>
                        <td className="relative rtl p-[0.1rem]">
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.bP2,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perBV2}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs">
                            {formatVolume10(stockDetail?.bV2)}
                          </span>
                          <span
                            className={
                              'per-price text-xs ' +
                              colorFix(
                                stockDetail.bP2,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                          >
                            {numberFormat(stockDetail?.bP2, 2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {stockDetail?.bV3 !== 0 && (
                      <tr>
                        <td className="relative rtl p-[0.1rem]">
                          <span
                            className={
                              'per-price text-xs ' +
                              colorFix(
                                stockDetail.bP3,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                          >
                            {numberFormat(stockDetail?.bP3, 2)}
                          </span>
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.bP3,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perBV3}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs">
                            {formatVolume10(stockDetail?.bV3)}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pr-px">
              <div className="p-0">
                <table className="table table-borderless w-full">
                  <tbody>
                    {stockDetail?.oV1 !== 0 && (
                      <tr>
                        <td className="relative p-[0.1rem]">
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.oP1,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perOV1}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs absolute right-3 top-1">
                            {formatVolume10(stockDetail?.oV1)}
                          </span>
                          <span
                            className={
                              'per-price text-xs absolute left-2 top-1 ' +
                              colorFix(
                                stockDetail.oP1,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                            style={{ left: '5px' }}
                          >
                            {stockDetail &&
                              ((!stockDetail.oP1 || stockDetail.oP1 == 0) &&
                              _house === 9 &&
                              _min <= 15
                                ? 'ATO'
                                : (!stockDetail.oP1 || stockDetail.oP1 == 0) &&
                                    _house === 14 &&
                                    _min >= 30 &&
                                    _min <= 45
                                  ? 'ATC'
                                  : numberFormat(stockDetail.oP1, 2))}
                          </span>
                        </td>
                      </tr>
                    )}
                    {stockDetail?.oV2 !== 0 && (
                      <tr>
                        <td className="relative p-[0.1rem]">
                          <span
                            className={
                              'per-price text-xs absolute left-2 top-1 ' +
                              colorFix(
                                stockDetail.oP2,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                            style={{ left: '5px' }}
                          >
                            {numberFormat(stockDetail?.oP2, 2)}
                          </span>
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.oP2,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perOV2}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs absolute right-3 top-1">
                            {formatVolume10(stockDetail?.oV2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {stockDetail?.oV3 !== 0 && (
                      <tr>
                        <td className="relative p-[0.1rem]">
                          <span
                            className={
                              'per-price text-xs absolute left-2 top-1 ' +
                              colorFix(
                                stockDetail.oP3,
                                stockDetail.r,
                                stockDetail.c,
                                stockDetail.f,
                                stockDetail.r,
                              )
                            }
                            style={{ left: '5px' }}
                          >
                            {numberFormat(stockDetail?.oP3, 2)}
                          </span>
                          <div className="progress ">
                            <div
                              className={
                                'progress-bar bg-' +
                                colorFix(
                                  stockDetail.oP3,
                                  stockDetail.r,
                                  stockDetail.c,
                                  stockDetail.f,
                                  stockDetail.r,
                                )
                              }
                              style={{ width: `${perOV3}%` }}
                            ></div>
                          </div>
                          <span className="per-vol text-xs absolute right-3 top-1">
                            {formatVolume10(stockDetail?.oV3)}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  //   const getAnalStockReal = makeGetAnalStockReal();

  const mapStateToProps = () => {
    return {
      //   analStReal: getAnalStockReal(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(PanelBuocGia);
