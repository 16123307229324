import React, { FunctionComponent } from 'react';

interface Props {
  input: any;
  type: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
  meta: any;
}

const _regexp = /^[0-9\b]+$/;

const RenderOtp: FunctionComponent<Props> = (props) => {
  const inputEl1 = React.useRef<string>('');
  const inputEl2 = React.useRef<string>('');
  const inputEl3 = React.useRef<string>('');
  const inputEl4 = React.useRef<string>('');
  const inputEl5 = React.useRef<string>('');
  const inputEl6 = React.useRef<string>('');

  const refVal1 = React.useRef<HTMLInputElement>(null);
  const refVal2 = React.useRef<HTMLInputElement>(null);
  const refVal3 = React.useRef<HTMLInputElement>(null);
  const refVal4 = React.useRef<HTMLInputElement>(null);
  const refVal5 = React.useRef<HTMLInputElement>(null);
  const refVal6 = React.useRef<HTMLInputElement>(null);

  const {
    input,
    meta: { touched, error },
  } = props;

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    if (_regexp.test(value)) {
      switch (name) {
        case 'inputEl1':
          inputEl1.current = value;
          refVal2?.current?.focus();
          break;
        case 'inputEl2':
          inputEl2.current = value;
          refVal3?.current?.focus();
          break;
        case 'inputEl3':
          inputEl3.current = value;
          refVal4?.current?.focus();
          break;
        case 'inputEl4':
          inputEl4.current = value;
          refVal5?.current?.focus();
          break;
        case 'inputEl5':
          inputEl5.current = value;
          refVal6?.current?.focus();
          break;
        case 'inputEl6':
          inputEl6.current = value;
          break;

        default:
          break;
      }

      setTimeout(() => {
        changeInput();
      }, 100);
    }
  }

  function _handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    if (e.key === 'Delete' || e.key === 'Backspace') {
      switch (name) {
        case 'inputEl1':
          inputEl1.current = '';
          break;
        case 'inputEl2':
          inputEl2.current = '';
          refVal1?.current?.focus();
          break;
        case 'inputEl3':
          inputEl3.current = '';
          refVal2?.current?.focus();
          break;
        case 'inputEl4':
          inputEl4.current = '';
          refVal3?.current?.focus();
          break;
        case 'inputEl5':
          inputEl5.current = '';
          refVal4?.current?.focus();
          break;
        case 'inputEl6':
          inputEl6.current = '';
          refVal5?.current?.focus();
          break;

        default:
          break;
      }

      setTimeout(() => {
        changeInput();
      }, 100);
    }
  }

  function changeInput() {
    const _val = [
      inputEl1.current,
      inputEl2.current,
      inputEl3.current,
      inputEl4.current,
      inputEl5.current,
      inputEl6.current,
    ];
    console.log(_val.join(''));
    input?.onChange(_val.join(''));
  }

  console.log(error, input?.value);

  return (
    <div className="flex flex-col w-full">
      <div className="w-full grid grid-cols-6 gap-2 md:gap-3">
        <input
          value={inputEl1.current}
          maxLength={1}
          minLength={0}
          ref={refVal1}
          name="inputEl1"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl1?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
        <input
          value={inputEl2.current}
          maxLength={1}
          minLength={0}
          ref={refVal2}
          name="inputEl2"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl2?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
        <input
          value={inputEl3.current}
          maxLength={1}
          minLength={0}
          ref={refVal3}
          name="inputEl3"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl3?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
        <input
          value={inputEl4.current}
          maxLength={1}
          minLength={0}
          ref={refVal4}
          name="inputEl4"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl4?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
        <input
          value={inputEl5.current}
          maxLength={1}
          minLength={0}
          ref={refVal5}
          name="inputEl5"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl5?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
        <input
          value={inputEl6.current}
          maxLength={1}
          minLength={0}
          ref={refVal6}
          name="inputEl6"
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          placeholder="-"
          className="bg-skin-input border-x border-y  rounded-md text-2xl  text-center text-dark"
          style={{
            borderColor: `${touched && error && !inputEl6?.current
              ? '#FF0000'
              : 'var(--color-border)'
              }`,
          }}
        />
      </div>
    </div>
  );
};

export default RenderOtp;
