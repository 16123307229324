import { Config } from 'interface';
import { handleApiErrors } from 'lib/api-error';
import { AppState } from 'reducers';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { checkInvalidSession, getMsgByErrorCode } from 'utils';
import * as actions from './actionType';
import { SummaryCashRequestAction } from './actionType';
import {
  advStatusHisRequestError,
  advStatusHisRequestSuccess,
  advStatusRequestError,
  advStatusRequestSuccess,
  beneficiaryAccountRequestError,
  beneficiaryAccountRequestSuccess,
  cashAccountInfoRequestError,
  cashAccountInfoRequestSuccess,
  cashCanAdvRequestError,
  cashCanAdvRequestSuccess,
  cashTransferHisRequestError,
  cashTransferHisRequestSuccess,
  feeAdvWithdrawRequestError,
  feeAdvWithdrawRequestSuccess,
  listCashStatusRequestError,
  listCashStatusRequestSuccess,
  listShareBalanceRequestError,
  listShareBalanceRequestSuccess,
  listShareStatusRequestError,
  listShareStatusRequestSuccess,
  shareEarnedRequestError,
  shareEarnedRequestSuccess,
  shareTransferHisRequestError,
  shareTransferHisRequestSuccess,
  summaryCashRequestError,
  summaryCashRequestSuccess,
} from './actions';

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error;
    });
}

function summaryApi(data: any, appUrl: string) {
  const request = fetch(appUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* summaryCashRequestFlow(action: SummaryCashRequestAction): any {
  const { params } = action;
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(summaryCashRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(summaryCashRequestError(error));
  }
}

function* shareEarnRequestFlow(action: actions.ShareEarnedRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: getMsgByErrorCode(resData.rc),
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: getMsgByErrorCode(resData.rc),
        });
      }
      throw Error(resData.rs);
    }

    yield put(shareEarnedRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(shareEarnedRequestError(error));
  }
}

function* cashCanAdvRequestFlow(action: actions.CashCanAdvRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(cashCanAdvRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(cashCanAdvRequestError(error));
  }
}

function* cashFeeAdvRequestFlow(
  action: actions.FeeAdvWithdrawRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(feeAdvWithdrawRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(feeAdvWithdrawRequestError(error));
  }
}

function* advStatusRequestFlow(action: actions.AdvStatusRequestAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(advStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(advStatusRequestError(error));
  }
}

function* advStatusHisRequestFlow(
  action: actions.AdvStatusHisRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(advStatusHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(advStatusHisRequestError(error));
  }
}

function* listShareBalanceRequestFlow(
  action: actions.ListShareBalanceRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listShareBalanceRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listShareBalanceRequestError(error));
  }
}

function* listShareStatusRequestFlow(
  action: actions.ListShareStatusRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listShareStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listShareStatusRequestError(error));
  }
}

function* shareTransferHisRequestFlow(
  action: actions.ShareTransferHisRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(shareTransferHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(shareTransferHisRequestError(error));
  }
}

function* cashAccountInfoRequestFlow(
  action: actions.CashAccountInfoRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(cashAccountInfoRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(cashAccountInfoRequestError(error));
  }
}

function* accountBeneficiaryRequestFlow(
  action: actions.BeneficiaryAccountRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(beneficiaryAccountRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(beneficiaryAccountRequestError(error));
  }
}

function* listCashStatusRequestFlow(
  action: actions.ListCashStatusRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(listCashStatusRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(listCashStatusRequestError(error));
  }
}

function* cashTransferHisRequestFlow(
  action: actions.CashTransferHisRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action;
    const resData = yield call(summaryApi, params, config.AuthUrl);
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        });
      }
      throw Error(resData.rs);
    }

    yield put(cashTransferHisRequestSuccess(resData.data));
  } catch (error) {
    // log(error)
    yield put(cashTransferHisRequestError(error));
  }
}

function* sumaryWatcher() {
  yield all([
    takeLatest(actions.SUMMARY_CASH_REQUESTING, summaryCashRequestFlow),
    takeLatest(actions.SUMMARY_SHARE_EARN_REQUESTING, shareEarnRequestFlow),

    takeLatest(actions.CASH_CAN_ADV_REQUESTING, cashCanAdvRequestFlow),
    takeLatest(actions.FEE_ADV_WITHDRAW_REQUESTING, cashFeeAdvRequestFlow),
    takeLatest(actions.ADV_STATUS_REQUESTING, advStatusRequestFlow),
    takeLatest(actions.ADV_STATUS_HIS_REQUESTING, advStatusHisRequestFlow),
    takeLatest(
      actions.LIST_SHARE_BALANCE_REQUESTING,
      listShareBalanceRequestFlow,
    ),
    takeLatest(
      actions.SHARE_TRANSFER_STATUS_REQUESTING,
      listShareStatusRequestFlow,
    ),
    takeLatest(
      actions.SHARE_TRANSFER_HIS_REQUESTING,
      shareTransferHisRequestFlow,
    ),

    takeLatest(
      actions.CASH_ACCOUNT_INFO_REQUESTING,
      cashAccountInfoRequestFlow,
    ),
    takeLatest(
      actions.ACCOUNT_BENEFICIARY_REQUESTING,
      accountBeneficiaryRequestFlow,
    ),

    takeLatest(
      actions.CASH_TRANSFER_STATUS_REQUESTING,
      listCashStatusRequestFlow,
    ),
    takeLatest(
      actions.CASH_TRANSFER_HIS_REQUESTING,
      cashTransferHisRequestFlow,
    ),
  ]);
}

export default sumaryWatcher;
