/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import styled from 'styled-components';

import _ from 'lodash';

import { clientTokenSet } from '../client/actions';
import { useHistory } from 'react-router';
import LogoDtnd from 'assets/img/logo/dtnd.png';
import BgLogin from 'assets/img/bg/login.jpg';
import { makeGetConfig } from 'lib/selector';
import { AppState } from 'reducers';
import FormRegister from 'components/login/formRegister';
import { storages } from 'lib/storages';

const StyledLogo = styled.img`
  height: 35px;
  width: 35px;
  position: absolute;
  top: 5px;
  left: 5px;
`;

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  successful: any;
}

function RegisterPage(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const { successful } = props;

  const preSuccessful = usePrevious(successful);

  React.useEffect(() => {
    return () => {
      //   dispatch(loginSuccess(null));
    };
  }, []);

  React.useEffect(() => {
    if (successful && !_.isEqual(successful, preSuccessful)) {
      const token = {
        user: successful.USER_NAME,
        session: successful.SESSION_ID,
        info: successful.CMDS,
        serverType: 'Prod',
      };

      // dispatch action to store
      dispatch(clientTokenSet(token));

      storages.saveState('token', JSON.stringify(token));
      navigate.push('/home');
    }
  }, [successful]);

  return (
    <div
      className="app flex flex-row justify-center items-center h-screen overflow-hidden"
      style={{
        backgroundImage: `url(${BgLogin})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <StyledLogo src={LogoDtnd} alt="homepage" />
      <div className="flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
        <div className="flex flex-col">
          <FormRegister clearChangePass={() => {}} />
        </div>
      </div>
      <div
        className="absolute fz-14 w-full flex py-1 px-2"
        style={{ bottom: '0', background: '#e0e2e3', opacity: '0.7' }}
      >
        <span className="mr-auto">
          status:&nbsp;
          <span className="i">Connected</span>
        </span>
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getConfig = makeGetConfig();

  const mapStateToProps = (state: AppState) => {
    return {
      config: getConfig(state),

      successful: state.login.successful,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(RegisterPage);
