import * as actions from './actionType';

export interface PrivDataState {
  orderList: any;
  listOrder: any;
  getMatchOrder: any;

  newOrder: any;
  newOrderSuccess: boolean;
  newOrderErrors: any;

  cancelOrder: any;
  cancelOrderSuccess: boolean;
  cancelOrderErrors: any;

  editOrder: any;
  editOrderSuccess: boolean;
  editOrderErrors: any;

  availStockList: any;
  symbolInfo: any;
  matchOrder: any;

  cashBalance: any;
  shareBalance: any;
  stockBalance: any;
  positions: any;
  advOrder: any;
  sStockInfo: any;
  condOrders: any;
  listOrderConfirm: any;
}

const initialState = {
  orderList: null,
  listOrder: null,
  getMatchOrder: null,

  newOrder: null,
  newOrderSuccess: false,
  newOrderErrors: [],

  cancelOrder: null,
  cancelOrderSuccess: false,
  cancelOrderErrors: [],

  editOrder: null,
  editOrderSuccess: false,
  editOrderErrors: [],

  availStockList: null,
  symbolInfo: null,
  matchOrder: null,

  cashBalance: null,
  shareBalance: null,
  stockBalance: null,
  positions: null,
  advOrder: null,
  sStockInfo: null,
  condOrders: null,
  listOrderConfirm: null,
};

export default function tradingReducer(
  state: PrivDataState = initialState,
  action: actions.PrivDataAction,
): PrivDataState {
  switch (action.type) {
    // case "CASH_BALANCE_CLEAR":
    // case actions.CASH_BALANCE_REQUESTING:
    case actions.CASH_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        cashBalance: null,
      };

    case actions.CASH_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        cashBalance: action.cashBalance,
      };

    case actions.SHARE_BALANCE_REQUESTING:
    case actions.SHARE_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        shareBalance: null,
      };

    case actions.SHARE_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        shareBalance: action.shareBalance,
      };

    // case actions.STOCK_BALANCE_REQUESTING:
    case actions.STOCK_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        stockBalance: null,
      };

    case actions.STOCK_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        stockBalance: action.stockBalance,
      };

    // case "POSITION_CLEAR":
    // case actions.POSITION_REQUESTING:
    case actions.POSITION_REQUEST_ERROR:
      return {
        ...state,
        positions: null,
      };

    case actions.POSITION_REQUEST_SUCCESS:
      return {
        ...state,
        positions: action.positions,
      };

    // case "STOCK_INFO_CLEAR":
    case actions.SSTOCK_INFO_REQUESTING:
    case actions.SSTOCK_INFO_REQUEST_ERROR:
      return {
        ...state,
        sStockInfo: null,
      };

    case actions.SSTOCK_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        sStockInfo: action.resData,
      };

    // case actions.ADVANCE_ORDER_REQUESTING:
    case actions.ADVANCE_ORDER_REQUEST_ERROR:
      return {
        ...state,
        advOrder: null,
      };

    case actions.ADVANCE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        advOrder: action.advOrder,
      };

    // case actions.COND_ORDER_REQUESTING:
    case actions.COND_ORDER_REQUEST_ERROR:
      return {
        ...state,
        condOrders: null,
      };

    case actions.COND_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        condOrders: action.resData,
      };

    case actions.SYMBOL_INFO_REQUESTING:
    case actions.SYMBOL_INFO_REQUEST_ERROR:
      return {
        ...state,
        symbolInfo: null,
      };

    case actions.SYMBOL_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        symbolInfo: action.symbolInfo,
      };

    case actions.MATCH_ORDER_REQUESTING:
    case actions.MATCH_ORDER_REQUEST_ERROR:
      return {
        ...state,
        matchOrder: null,
      };

    case actions.MATCH_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        matchOrder: action.matchOrder,
      };

    case actions.LIST_ORDER_CONFIRM_REQUESTING:
    case actions.LIST_ORDER_CONFIRM_ERROR:
      return {
        ...state,
        listOrderConfirm: null,
      };

    case actions.LIST_ORDER_CONFIRM_SUCCESS:
      return {
        ...state,
        listOrderConfirm: action.resData,
      };

    case actions.NEW_ORDER_CLEAR:
    case actions.NEW_ORDER_REQUESTING:
      return {
        ...state,
        newOrder: null,
        newOrderSuccess: false,
        newOrderErrors: [],
      };

    case actions.NEW_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        newOrder: action.newOrder,
        newOrderSuccess: true,
        newOrderErrors: [],
      };

    case actions.NEW_ORDER_REQUEST_ERROR:
      return {
        ...state,
        newOrder: null,
        newOrderSuccess: false,
        newOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.EDIT_ORDER_CLEAR:
    case actions.EDIT_ORDER_REQUESTING:
      return {
        ...state,
        editOrder: null,
        editOrderSuccess: false,
        editOrderErrors: [],
      };

    case actions.EDIT_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        editOrder: action.editOrder,
        editOrderSuccess: true,
        editOrderErrors: [],
      };

    case actions.EDIT_ORDER_REQUEST_ERROR:
      return {
        ...state,
        editOrder: null,
        editOrderSuccess: false,
        editOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case actions.CANCEL_ORDER_CLEAR:
    case actions.CANCEL_ORDER_REQUESTING:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [],
      };

    case actions.CANCEL_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        cancelOrder: action.cancelOrder,
        cancelOrderSuccess: true,
        cancelOrderErrors: [],
      };

    case actions.CANCEL_ORDER_REQUEST_ERROR:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    default:
      return state;
  }
}
