import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config } from 'interface';

const initialState: Config = {
  HomeUrl: '',
  SmartAPIUrl: '',
  AuthUrl: '',
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setUrlConfig: (state, action: PayloadAction<Config>) => {
      state.HomeUrl = action.payload.HomeUrl ?? '';
      state.SmartAPIUrl = action.payload.SmartAPIUrl ?? '';
      state.AuthUrl = action.payload.AuthUrl ?? '';
    },
  },
});

export const { setUrlConfig } = configSlice.actions;
export default configSlice.reducer;
