import CashTransferComponent from 'components/cash/cash-transfer';

interface Props {}

function CashTransfer(props: Props): JSX.Element {
  return (
    <div className="w-full h-full p-2">
      <CashTransferComponent />
    </div>
  );
}

export default CashTransfer;
