import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PanelOrderTrade = React.lazy(() => import('./formTradeNew'));
const PanelOrderInday = React.lazy(() => import('./panelOrderInday'));
const PanelAsset = React.lazy(() => import('./panelAsset'));
const PanelOrderCond = React.lazy(() => import('./panelOrderCond'));

interface Props {
  onClose: Function;
}

function PanelTrade(props: Props): JSX.Element {
  const { onClose } = props;
  const [key, setKey] = useState('order');
  const { t } = useTranslation();

  return (
    <div className="panel-trade">
      <div className="h-full w-full">
        <div className="h-[calc(100vh-110px)]">
          {key === 'order' && <PanelOrderTrade onClose={onClose} />}
          {key === 'orderCond' && <PanelOrderCond onClose={onClose} />}
          {key === 'book' && <PanelOrderInday onClose={onClose} />}
          {key === 'assets' && <PanelAsset onClose={onClose} />}
        </div>
        <div className="nav-trade">
          <ul className="flex">
            <li
              className={
                'cursor-pointer ' + (key === 'order' ? 'text-skin-footer' : '')
              }
              onClick={() => setKey('order')}
            >
              {t('trading.order.index')}
            </li>
            <li
              className={
                'cursor-pointer ml-5 ' +
                (key === 'book' ? 'text-skin-footer' : '')
              }
              onClick={() => setKey('book')}
            >
              {t('trading.order-book.index')}
            </li>
            <li
              className={
                'cursor-pointer ml-5 ' +
                (key === 'orderCond' ? 'text-skin-footer' : '')
              }
              onClick={() => setKey('orderCond')}
            >
              {t('trading.order-cond2')}
            </li>
            <li
              className={
                'cursor-pointer ml-5 ' +
                (key === 'assets' ? 'text-skin-footer' : '')
              }
              onClick={() => setKey('assets')}
            >
              {t('trading.assets.index')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(PanelTrade);
