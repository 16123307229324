import * as actions from './actionType';
/** get list branch */
export function glListBranchRequest(
  data: any,
): actions.GlListBranchRequestingAction {
  return {
    type: actions.GL_LIST_BRANCH_REQUESTING,
    data,
  };
}

export function glListBranchSuccess(
  resData: any,
): actions.GlListBranchSuccessAction {
  return {
    type: actions.GL_LIST_BRANCH_SUCCESS,
    resData,
  };
}

export function glListBranchError(
  error: Error | string,
): actions.GlListBranchErrorAction {
  return {
    type: actions.GL_LIST_BRANCH_ERROR,
    error,
  };
}

/** get list subbranch */
export function glListSubBranchRequest(
  data: any,
): actions.GlListSubBranchRequestingAction {
  return {
    type: actions.GL_LIST_SUBBRANCH_REQUESTING,
    data,
  };
}

export function glListSubBranchSuccess(
  resData: any,
): actions.GlListSubBranchSuccessAction {
  return {
    type: actions.GL_LIST_SUBBRANCH_SUCCESS,
    resData,
  };
}

export function glListSubBranchError(
  error: Error | string,
): actions.GlListSubBranchErrorAction {
  return {
    type: actions.GL_LIST_SUBBRANCH_ERROR,
    error,
  };
}

/** get list subbranch */
export function glImportTypeRequest(
  data: any,
): actions.GlImportTypeRequestingAction {
  return {
    type: actions.GL_IMPORT_TYPE_REQUESTING,
    data,
  };
}

export function glImportTypeSuccess(
  resData: any,
): actions.GlImportTypeSuccessAction {
  return {
    type: actions.GL_IMPORT_TYPE_SUCCESS,
    resData,
  };
}

export function glImportTypeError(
  error: Error | string,
): actions.GlImportTypeErrorAction {
  return {
    type: actions.GL_IMPORT_TYPE_ERROR,
    error,
  };
}
