import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { makeGetCancelOrder, makeGetNewOrder } from 'lib/selector';

import { allOrdRequest } from 'containers/banggia/actions';
import { defaultAccountSet } from 'containers/client/actions';
import * as _ from 'lodash';

import { cancelOrderClear } from 'containers/privData/actions';
import RenderInput from 'helper/input/renderInput';
import RenderNormalSelect from 'helper/select/renderNormalSelect';
import { IAllOrder } from 'interface/response';
import { IIndayOrderSearch } from 'interface/search';
import { useTranslation } from 'react-i18next';
import { AppState } from 'reducers';
import { ArrStatusOrderBook } from 'utils/cfg';

function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  defAccount: string | undefined;
  token: any;
  page: number;
  setPage: Function;
  handleToast: Function;
  handleQuery: boolean;
  setHandleQuery: any;
  setClearOrder: any;

  newOrder?: any;
  cancelOrder?: any;
  allOrder?: IAllOrder[];

  orderAccount?: string;
  orderStatus?: string;
  formSymbol?: string;
}

const FormSearchIndayOrder: React.FunctionComponent<
  InjectedFormProps<IIndayOrderSearch> & Props
> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defAccount,
    orderAccount,
    token,
    page,
    setPage,

    orderStatus,
    formSymbol,

    newOrder,
    cancelOrder,

    handleToast,
    handleQuery,
    setHandleQuery,

    setClearOrder,
    handleSubmit,
  } = props;

  const [pageSize] = useState<number>(15);

  const preDefAccount = usePrevious(defAccount);
  const preOrderStatus = usePrevious(orderStatus);
  const preOrderSymbol = usePrevious(formSymbol);

  const preNewOrder = usePrevious(newOrder);
  const preCancelOrder = usePrevious(cancelOrder);
  const preHandleQuery = usePrevious(handleQuery);
  const preOrderAccount = usePrevious(orderAccount);
  const prePage = usePrevious(page);

  useEffect(() => {
    initialValues();

    return () => { };
  }, []);

  useEffect(() => {
    if (defAccount && !_.isEqual(defAccount, preDefAccount)) {
      dispatch(change('formSearchIndayOrder', 'orderStatus', ''));
      dispatch(change('formSearchIndayOrder', 'preOrderSymbol', ''));

      setTimeout(() => {
        getIndayOrder(0);
      }, 150);
    }
  }, [defAccount]);

  useEffect(() => {
    if (prePage && page && !_.isEqual(page, prePage)) {
      getIndayOrder(page);
    }
  }, [page]);

  useEffect(() => {
    if (orderAccount && !_.isEqual(orderAccount, preOrderAccount)) {
      dispatch(defaultAccountSet(orderAccount));
    }
  }, [orderAccount]);

  useEffect(() => {
    if (handleQuery && !_.isEqual(handleQuery, preHandleQuery)) {
      setHandleQuery(false);
      getIndayOrder(0);
    }
  }, [handleQuery]);

  useEffect(() => {
    if (
      (formSymbol && (formSymbol.length === 3 || formSymbol.length === 8)) ||
      (!preOrderSymbol && formSymbol) ||
      (orderStatus && preOrderStatus && !_.isEqual(orderStatus, preOrderStatus))
    ) {
      getIndayOrder(0);
    }
  }, [formSymbol, orderStatus]);

  useEffect(() => {
    if (
      newOrder &&
      newOrder.newOrderSuccess &&
      !_.isEqual(newOrder, preNewOrder)
    ) {
      getIndayOrder(0);
    }
  }, [newOrder]);

  useEffect(() => {
    if (
      cancelOrder &&
      cancelOrder.cancelOrderSuccess &&
      !_.isEqual(cancelOrder, preCancelOrder)
    ) {
      getIndayOrder(0);
      handleToast('Huỷ lệnh thành công.', 'success');
      dispatch(cancelOrderClear());
    }
  }, [cancelOrder]);

  function initialValues() {
    dispatch(change('formSearchIndayOrder', 'orderStatus', ''));
  }

  function getIndayOrder(_page: number) {
    if (_page === 0) {
      setPage(1);
      setClearOrder(true);
    }
    if (!defAccount || !token) return;
    // orderStatus,
    // orderSymbol,
    // orderSide,
    const cond =
      (formSymbol ? formSymbol.toUpperCase() : 'ALL') +
      ',' +
      (orderStatus || '') +
      ',ALL';

    const params = {
      group: 'Q',
      user: token.user,
      session: token.sid,
      data: {
        type: 'string',
        cmd: 'Web.Order.IndayOrder2',
        p1: (_page || 1) + '',
        p2: pageSize + '',
        p3: defAccount,
        p4: cond,
      },
    };
    dispatch(allOrdRequest(params));
  }

  function submit() {
    return;
  }

  function _handleBlurSymbol(_e: any) {
    getIndayOrder(0);
  }

  return (
    <form
      className="w-full grid grid-cols-2 gap-2 my-2"
      onSubmit={handleSubmit(submit)}
    >
      <Field
        name="orderStatus"
        component={RenderNormalSelect}
        opts={ArrStatusOrderBook}
        required
      />
      <Field
        name="formSymbol"
        type="text"
        placeholder={t('trading.order.search-stock')}
        className="uppercase"
        component={RenderInput}
        onBlur={_handleBlurSymbol}
        required
      />
    </form>
  );
};

const _FormSearchIndayOrder = reduxForm<IIndayOrderSearch, Props>({
  form: 'formSearchIndayOrder',
})(FormSearchIndayOrder as any);

const selector = formValueSelector('formSearchIndayOrder');

const makeMapStateToProps = () => {
  const getNewOrder = makeGetNewOrder();
  const getCancelOrder = makeGetCancelOrder();

  const mapStateToProps = (state: AppState) => {
    const { orderStatus, formSymbol } = selector(
      state,
      'orderStatus',
      'formSymbol',
    );

    return {
      newOrder: getNewOrder(state),
      cancelOrder: getCancelOrder(state),

      orderStatus,
      formSymbol,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormSearchIndayOrder);
