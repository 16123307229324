/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { useRef } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IAllStock } from 'interface/response';
import PerfectScrollBar from 'react-perfect-scrollbar';
import _ from 'lodash';

interface Props {
  input: any;
  tabindex: number | undefined;
  placeholder: string | undefined;
  meta: any;
  classParent?: string;
  dataSuggest: IAllStock[];
}

function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const SymbolAdvanceOrder: React.FunctionComponent<Props> = (props) => {
  const { input, tabindex, placeholder, meta, classParent, dataSuggest } =
    props;

  const [suggestions, setSuggestions] = React.useState<IAllStock[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [indexActive, setIndexActive] = React.useState<number>(1);
  const [selected, setSelected] = React.useState<boolean>(false);
  const [textSearch, setTextSearch] = React.useState<string>('');

  const isFocus = React.useRef<any>(null);
  const wrapperRef = useRef<any>();
  const preIndexActive = usePrevious(indexActive);
  const preInputValue = usePrevious(input?.value);

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    // See note below
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, []);

  React.useEffect(() => {
    if (!_.isEqual(input?.value, preInputValue)) {
      console.log(input);
      setTextSearch(input?.value ?? '');
    }
  }, [input?.value]);

  React.useEffect(() => {
    if (selected) return;
    // remove old data
    if (textSearch === input?.value) return;

    if (textSearch) {
      setShowModal(true);
      getSuggestions(textSearch);
    } else {
      setSuggestions([]);
      setShowModal(false);
      setIndexActive(-1);
    }
  }, [textSearch]);

  function handleClickOutside(event: any) {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      // handleCheckInput()
      // console.log(input?.value)
      setShowModal(false);
      setSelected(false);
    }
  }

  function getSuggestions(value: string) {
    const escapedValue = escapeRegexCharacters(value.trim()).toLowerCase();
    if (escapedValue === '') {
      setIndexActive(0);
      return setSuggestions(dataSuggest);
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    const _abx = dataSuggest?.filter((item) => regex.test(item.stock_code?.toLowerCase()))
    setSuggestions(_abx);
    setIndexActive(!!_abx?.length ? 0 : -1);
  }

  function onClearSymbol() {
    input.onChange('');
    setTextSearch('');
    setShowModal(false);
  }

  function _handleClick() {
    console.log('click');
    isFocus.current = true;
    wrapperRef && wrapperRef.current.focus();
  }

  function handleSelect(record: IAllStock) {
    setSelected(true);
    setTextSearch(record?.stock_code);
    input.onChange(record?.stock_code);
    setShowModal(false);
  }

  const handleKeyDown = (event: any) => {
    console.log(event.key, event.keyCode);
    const { value } = input;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (indexActive < 0 || indexActive > suggestions.length) return;
      setSelected(true);
      const _record = suggestions[indexActive];
      setTextSearch(_record?.stock_code);
      input.onChange(_record?.stock_code);
      setShowModal(false);
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (indexActive < suggestions.length - 1) {
        setIndexActive(indexActive + 1);
      } else setIndexActive(-1);
      return;
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (indexActive > 0) setIndexActive(indexActive - 1);
      if (indexActive === 0) setIndexActive(-1);
      if (indexActive < 0) setIndexActive(suggestions.length - 1);
      return;
    }

    // const _input = String.fromCharCode(event.keyCode);
    // if (!/[a-zA-Z0-9-_ ]/.test(_input)) {
    //   event.preventDefault();
    //   // const _val = value + _input.toUpperCase();
    //   // console.log(_val);
    //   // input.onChange(_val);
    // }
  };

  return (
    <div
      className={
        'relative h-[36px] border-[0.5px] rounded bg-[var(--color-button-trade)] w-full ' +
        (classParent ?? '')
      }
      onClick={_handleClick}
      style={{
        borderColor: `${(isFocus.current || meta?.touched) && meta?.error
          ? '#FF0000'
          : 'var(--color-border-3)'
          }`,
      }}
      ref={wrapperRef}
    >
      <input
        // ref={wrapperRef}
        tabIndex={tabindex}
        value={textSearch}
        type={'text'}
        className="form-control text-[13px] h-[36px] bg-transparent uppercase w-full pr-8 pl-2 border-0"
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
        onFocus={(e) => e.currentTarget.select()}
        onClick={() => setShowModal(true)}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setSelected(false);
          setTextSearch(e.target.value);
        }}
      />

      <BsSearch className="absolute top-1/2 right-2 h-5 w-5 -translate-y-1/2" />
      {showModal && (
        <div
          className="w-full z-50 animate-[fadeIn_1s] bg-skin-header absolute top-[calc(100%+3px)] overflow-hidden"
          style={{ boxShadow: 'rgba(50, 50, 71, 0.5) 5px 5px 5px 0px' }}
        >
          <PerfectScrollBar className="max-h-80 p-0">
            <ul className="list-vendor">
              {suggestions &&
                !!suggestions.length &&
                suggestions.map((item, index) => (
                  <li
                    key={index}
                    className={
                      'py-2 px-5 ' + (indexActive === index ? 'active' : '')
                    }
                    onClick={() => handleSelect(item)}
                  >
                    <span className="text-sm font-semibold text-skin-white">
                      {item?.stock_code}
                    </span>
                    <span className="text-sm text-skin-white">{` - ${item?.name_vn}`}</span>
                  </li>
                ))}
            </ul>
            {(!suggestions || !suggestions.length) && (
              <p className="text-center text-sm text-skin-white pt-2">
                Không có dữ liệu
              </p>
            )}
          </PerfectScrollBar>
        </div>
      )}
    </div>
  );
};

export default memo(SymbolAdvanceOrder);
