/** Giải ngân/ thu nợ */
export const REPORT_001 = 'rp/001';
export interface Report001Action {
  type: typeof REPORT_001;
}

export const REPORT_001_REQUESTING = 'rp/001_REQUESTING';
export interface Report001RequestingAction {
  type: typeof REPORT_001_REQUESTING;
  data: any;
}

export const REPORT_001_SUCCESS = 'rp/001_SUCCESS';
export interface Report001SuccessAction {
  type: typeof REPORT_001_SUCCESS;
  resData: any;
}

export const REPORT_001_ERROR = 'rp/001_ERROR';
export interface Report001ErrorAction {
  type: typeof REPORT_001_ERROR;
  error: any;
}

/** Dư nợ theo tài khoản */
export const REPORT_002 = 'rp/002';
export interface Report002Action {
  type: typeof REPORT_002;
}

export const REPORT_002_REQUESTING = 'rp/002_REQUESTING';
export interface Report002RequestingAction {
  type: typeof REPORT_002_REQUESTING;
  data: any;
}

export const REPORT_002_SUCCESS = 'rp/002_SUCCESS';
export interface Report002SuccessAction {
  type: typeof REPORT_002_SUCCESS;
  resData: any;
}

export const REPORT_002_ERROR = 'rp/002_ERROR';
export interface Report002ErrorAction {
  type: typeof REPORT_002_ERROR;
  error: any;
}

/** Chi tiết dư nợ */
export const REPORT_003 = 'rp/003';
export interface Report003Action {
  type: typeof REPORT_003;
}

export const REPORT_003_REQUESTING = 'rp/003_REQUESTING';
export interface Report003RequestingAction {
  type: typeof REPORT_003_REQUESTING;
  data: any;
}

export const REPORT_003_SUCCESS = 'rp/003_SUCCESS';
export interface Report003SuccessAction {
  type: typeof REPORT_003_SUCCESS;
  resData: any;
}

export const REPORT_003_ERROR = 'rp/003_ERROR';
export interface Report003ErrorAction {
  type: typeof REPORT_003_ERROR;
  error: any;
}

/** Dư nợ đến hạn */
export const REPORT_004 = 'rp/004';
export interface Report004Action {
  type: typeof REPORT_004;
}

export const REPORT_004_REQUESTING = 'rp/004_REQUESTING';
export interface Report004RequestingAction {
  type: typeof REPORT_004_REQUESTING;
  data: any;
}

export const REPORT_004_SUCCESS = 'rp/004_SUCCESS';
export interface Report004SuccessAction {
  type: typeof REPORT_004_SUCCESS;
  resData: any;
}

export const REPORT_004_ERROR = 'rp/004_ERROR';
export interface Report004ErrorAction {
  type: typeof REPORT_004_ERROR;
  error: any;
}

/** Sao kê nợ */
export const REPORT_005 = 'rp/005';
export interface Report005Action {
  type: typeof REPORT_005;
}

export const REPORT_005_REQUESTING = 'rp/005_REQUESTING';
export interface Report005RequestingAction {
  type: typeof REPORT_005_REQUESTING;
  data: any;
}

export const REPORT_005_SUCCESS = 'rp/005_SUCCESS';
export interface Report005SuccessAction {
  type: typeof REPORT_005_SUCCESS;
  resData: any;
}

export const REPORT_005_ERROR = 'rp/005_ERROR';
export interface Report005ErrorAction {
  type: typeof REPORT_005_ERROR;
  error: any;
}

/** Lịch sử đăng ký gói */
export const REPORT_006 = 'rp/006';
export interface Report006Action {
  type: typeof REPORT_006;
}

export const REPORT_006_REQUESTING = 'rp/006_REQUESTING';
export interface Report006RequestingAction {
  type: typeof REPORT_006_REQUESTING;
  data: any;
}

export const REPORT_006_SUCCESS = 'rp/006_SUCCESS';
export interface Report006SuccessAction {
  type: typeof REPORT_006_SUCCESS;
  resData: any;
}

export const REPORT_006_ERROR = 'rp/006_ERROR';
export interface Report006ErrorAction {
  type: typeof REPORT_006_ERROR;
  error: any;
}

/** lịch sử khớp lệnh */
export const REPORT_007 = 'rp/007';
export interface Report007Action {
  type: typeof REPORT_007;
}

export const REPORT_007_REQUESTING = 'rp/007_REQUESTING';
export interface Report007RequestingAction {
  type: typeof REPORT_007_REQUESTING;
  data: any;
}

export const REPORT_007_SUCCESS = 'rp/007_SUCCESS';
export interface Report007SuccessAction {
  type: typeof REPORT_007_SUCCESS;
  resData: any;
}

export const REPORT_007_ERROR = 'rp/007_ERROR';
export interface Report007ErrorAction {
  type: typeof REPORT_007_ERROR;
  error: any;
}

/** báo cáo phí hợp tác */
export const REPORT_008 = 'rp/008';
export interface Report008Action {
  type: typeof REPORT_008;
}

export const REPORT_008_REQUESTING = 'rp/008_REQUESTING';
export interface Report008RequestingAction {
  type: typeof REPORT_008_REQUESTING;
  data: any;
}

export const REPORT_008_SUCCESS = 'rp/008_SUCCESS';
export interface Report008SuccessAction {
  type: typeof REPORT_008_SUCCESS;
  resData: any;
}

export const REPORT_008_ERROR = 'rp/008_ERROR';
export interface Report008ErrorAction {
  type: typeof REPORT_008_ERROR;
  error: any;
}

/** báo cáo phí điện tương tác */
export const REPORT_009 = 'rp/009';
export interface Report009Action {
  type: typeof REPORT_009;
}

export const REPORT_009_REQUESTING = 'rp/009_REQUESTING';
export interface Report009RequestingAction {
  type: typeof REPORT_009_REQUESTING;
  data: any;
}

export const REPORT_009_SUCCESS = 'rp/009_SUCCESS';
export interface Report009SuccessAction {
  type: typeof REPORT_009_SUCCESS;
  resData: any;
}

export const REPORT_009_ERROR = 'rp/009_ERROR';
export interface Report009ErrorAction {
  type: typeof REPORT_009_ERROR;
  error: any;
}

/** báo cáo phí phụ thu */
export const REPORT_010 = 'rp/010';
export interface Report010Action {
  type: typeof REPORT_010;
}

export const REPORT_010_REQUESTING = 'rp/010_REQUESTING';
export interface Report010RequestingAction {
  type: typeof REPORT_010_REQUESTING;
  data: any;
}

export const REPORT_010_SUCCESS = 'rp/010_SUCCESS';
export interface Report010SuccessAction {
  type: typeof REPORT_010_SUCCESS;
  resData: any;
}

export const REPORT_010_ERROR = 'rp/010_ERROR';
export interface Report010ErrorAction {
  type: typeof REPORT_010_ERROR;
  error: any;
}

/** báo cáo thống kê tài sản - dashboard */
export const REPORT_011 = 'rp/011';
export interface Report011Action {
  type: typeof REPORT_011;
}

export const REPORT_011_REQUESTING = 'rp/011_REQUESTING';
export interface Report011RequestingAction {
  type: typeof REPORT_011_REQUESTING;
  data: any;
}

export const REPORT_011_SUCCESS = 'rp/011_SUCCESS';
export interface Report011SuccessAction {
  type: typeof REPORT_011_SUCCESS;
  resData: any;
}

export const REPORT_011_ERROR = 'rp/011_ERROR';
export interface Report011ErrorAction {
  type: typeof REPORT_011_ERROR;
  error: any;
}

/** báo cáo thống kê doanh số - dashboard */
export const REPORT_012 = 'rp/012';
export interface Report012Action {
  type: typeof REPORT_012;
}

export const REPORT_012_REQUESTING = 'rp/012_REQUESTING';
export interface Report012RequestingAction {
  type: typeof REPORT_012_REQUESTING;
  data: any;
}

export const REPORT_012_SUCCESS = 'rp/012_SUCCESS';
export interface Report012SuccessAction {
  type: typeof REPORT_012_SUCCESS;
  resData: any;
}

export const REPORT_012_ERROR = 'rp/012_ERROR';
export interface Report012ErrorAction {
  type: typeof REPORT_012_ERROR;
  error: any;
}

export type ReportAction =
  | Report001Action
  | Report001RequestingAction
  | Report001SuccessAction
  | Report001ErrorAction
  | Report002Action
  | Report002RequestingAction
  | Report002SuccessAction
  | Report002ErrorAction
  | Report003Action
  | Report003RequestingAction
  | Report003SuccessAction
  | Report003ErrorAction
  | Report004Action
  | Report004RequestingAction
  | Report004SuccessAction
  | Report004ErrorAction
  | Report005Action
  | Report005RequestingAction
  | Report005SuccessAction
  | Report005ErrorAction
  | Report006Action
  | Report006RequestingAction
  | Report006SuccessAction
  | Report006ErrorAction
  | Report007Action
  | Report007RequestingAction
  | Report007SuccessAction
  | Report007ErrorAction
  | Report008Action
  | Report008RequestingAction
  | Report008SuccessAction
  | Report008ErrorAction
  | Report009Action
  | Report009RequestingAction
  | Report009SuccessAction
  | Report009ErrorAction
  | Report010Action
  | Report010RequestingAction
  | Report010SuccessAction
  | Report010ErrorAction
  | Report011Action
  | Report011RequestingAction
  | Report011SuccessAction
  | Report011ErrorAction
  | Report012Action
  | Report012RequestingAction
  | Report012SuccessAction
  | Report012ErrorAction;
