import * as actions from './actionType';

export interface ReportState {
  rp001: any;
  rp002: any;
  rp003: any;
  rp004: any;
  rp005: any;
  rp006: any;
  rp007: any;
  rp008: any;
  rp009: any;
  rp010: any;
  rp011: any;
  rp012: any;
}

const initialState = {
  rp001: null,
  rp002: null,
  rp003: null,
  rp004: null,
  rp005: null,
  rp006: null,
  rp007: null,
  rp008: null,
  rp009: null,
  rp010: null,
  rp011: null,
  rp012: null,
};

export default function reportReducer(
  state: ReportState = initialState,
  action: actions.ReportAction,
): ReportState {
  switch (action.type) {
    case actions.REPORT_001_REQUESTING:
    case actions.REPORT_001_ERROR:
      return {
        ...state,
        rp001: null,
      };

    case actions.REPORT_001_SUCCESS:
      return {
        ...state,
        rp001: action.resData,
      };

    case actions.REPORT_002_REQUESTING:
    case actions.REPORT_002_ERROR:
      return {
        ...state,
        rp002: null,
      };

    case actions.REPORT_002_SUCCESS:
      return {
        ...state,
        rp002: action.resData,
      };

    case actions.REPORT_003_REQUESTING:
    case actions.REPORT_003_ERROR:
      return {
        ...state,
        rp003: null,
      };

    case actions.REPORT_003_SUCCESS:
      return {
        ...state,
        rp003: action.resData,
      };

    case actions.REPORT_004_REQUESTING:
    case actions.REPORT_004_ERROR:
      return {
        ...state,
        rp004: null,
      };

    case actions.REPORT_004_SUCCESS:
      return {
        ...state,
        rp004: action.resData,
      };

    case actions.REPORT_005_REQUESTING:
    case actions.REPORT_005_ERROR:
      return {
        ...state,
        rp005: null,
      };

    case actions.REPORT_005_SUCCESS:
      return {
        ...state,
        rp005: action.resData,
      };

    case actions.REPORT_006_REQUESTING:
    case actions.REPORT_006_ERROR:
      return {
        ...state,
        rp006: null,
      };

    case actions.REPORT_006_SUCCESS:
      return {
        ...state,
        rp006: action.resData,
      };

    case actions.REPORT_007_REQUESTING:
    case actions.REPORT_007_ERROR:
      return {
        ...state,
        rp007: null,
      };

    case actions.REPORT_007_SUCCESS:
      return {
        ...state,
        rp007: action.resData,
      };

    case actions.REPORT_008_REQUESTING:
    case actions.REPORT_008_ERROR:
      return {
        ...state,
        rp008: null,
      };

    case actions.REPORT_008_SUCCESS:
      return {
        ...state,
        rp008: action.resData,
      };

    case actions.REPORT_009_REQUESTING:
    case actions.REPORT_009_ERROR:
      return {
        ...state,
        rp009: null,
      };

    case actions.REPORT_009_SUCCESS:
      return {
        ...state,
        rp009: action.resData,
      };

    case actions.REPORT_010_REQUESTING:
    case actions.REPORT_010_ERROR:
      return {
        ...state,
        rp010: null,
      };

    case actions.REPORT_010_SUCCESS:
      return {
        ...state,
        rp010: action.resData,
      };

    case actions.REPORT_011_REQUESTING:
    case actions.REPORT_011_ERROR:
      return {
        ...state,
        rp011: null,
      };

    case actions.REPORT_011_SUCCESS:
      return {
        ...state,
        rp011: action.resData,
      };

    case actions.REPORT_012_REQUESTING:
    case actions.REPORT_012_ERROR:
      return {
        ...state,
        rp012: null,
      };

    case actions.REPORT_012_SUCCESS:
      return {
        ...state,
        rp012: action.resData,
      };

    default:
      return state;
  }
}
