import React, { useEffect, useRef, useState } from 'react';

import { FaMinus, FaPlus } from 'react-icons/fa';
import { numberFormat, StringToDouble } from 'utils';

import * as _ from 'lodash';

import { IIndexList, IStockDetailOrder } from 'interface/response';

interface Props {
  className?: string;
  placeholder?: string;
  fnCallback: Function;
  record: IStockDetailOrder;
  isEdit?: boolean;
  tabindex?: number;
  meta: { touched: string; error: string };
  txtError?: string;
  typeTrade?: string;
  input: any;
  indexList: IIndexList[];
}

function priceCheckMethod(e: any) {
  const re = /[0-9a-zA-Z.]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
}

const RenderInputAdvanceTradePrice: React.FunctionComponent<Props> = (
  props,
) => {
  const [isFocus, setIsFocus] = useState(false);

  const wrapperRef = useRef<any>();
  const {
    input,
    className,
    placeholder,
    fnCallback,
    record,
    isEdit,
    tabindex,
    meta: { touched, error },
    txtError,
    indexList,
    typeTrade,
  } = props;

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    // window.addEventListener('focusin', documentFocus);
    window.addEventListener('touchstart', handleClickOutside);
    // See note below
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      // window.removeEventListener('focusin', documentFocus);
    };
  });

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setIsFocus(false);
    }
  };

  const documentFocus = (event: any) => {
    // log(event);
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setIsFocus(false);
    }
  };

  function _handleMinus() {
    const { value } = input;
    let unit = 0.1;
    if (record?.mc === 'HO' || record?.mc === '10') {
      if (record?.r < 10) {
        unit = 0.01;
      } else if (record?.r < 50) {
        unit = 0.05;
      } else {
        unit = 0.1;
      }
    } else if (record?.mc === 'HA' || record?.mc === '02') {
      unit = 0.1;
    } else {
      unit = 0.1;
    }

    const _newPrice = StringToDouble(value) - unit;
    const _f = record?.f || 0;

    if (_newPrice < _f) {
      fnCallback(_f);
      return;
    }

    fnCallback(numberFormat(_newPrice, 2));
  }

  function _handlePlus() {
    const { value } = input;
    let unit = 0.1;
    if (record?.mc === 'HO' || record?.mc === '10') {
      if (record?.r < 10) {
        unit = 0.01;
      } else if (record?.r < 50) {
        unit = 0.05;
      } else {
        unit = 0.1;
      }
    } else if (record?.mc === 'HA' || record?.mc === '02') {
      unit = 0.1;
    } else {
      unit = 0.1;
    }

    const _newPrice = StringToDouble(value) + unit;
    const _f = record?.f || 0;
    const _c = record?.c || 0;

    if (_newPrice < _f) {
      fnCallback(_f);
      return;
    }
    if (_newPrice > _c) {
      fnCallback(_c);
      return;
    }

    fnCallback(numberFormat(_newPrice, 2));
  }

  const marketStatus = record
    ? record?.mc === 'HO' || record?.mc === '10'
      ? _.find(indexList, (o: IIndexList) => o.mc === '10')?.status
      : record?.mc === 'HA' || record?.mc === '02'
        ? _.find(indexList, (o: IIndexList) => o.mc === '02')?.status
        : _.find(indexList, (o: IIndexList) => o.mc === '03')?.status
    : null;

  // log(isFocus, record);
  const hours = new Date().getHours();

  // log(marketStatus, indexList)

  return (
    <div className="flex flex-col w-full price-advance" ref={wrapperRef}>
      <div
        className="w-full grid grid-cols-[20px_auto_20px] form-gr-trade"
        style={{
          borderColor: `${(touched && error) || txtError ? '#FF0000' : '#343142'
            }`,
        }}
      >
        <div className="btn-minus" onClick={_handleMinus}>
          <FaMinus />
        </div>

        <input
          {...input}
          style={{ width: 'inherit' }}
          onKeyPress={(e) => priceCheckMethod(e)}
          type="text"
          className={className}
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          tabIndex={tabindex}
          onFocus={(e) => {
            e.currentTarget.select();
            setIsFocus(true);
          }}
        />
        <div className="btn-plus" onClick={_handlePlus}>
          <FaPlus />
        </div>
      </div>
      {((touched && error) || txtError) && (
        <div
          style={{
            color: '#FF0000',
            margin: '0',
            fontSize: '0.75rem',
          }}
        >
          {error || txtError}
        </div>
      )}
      {isFocus &&
        typeTrade === '1' &&
        !isEdit &&
        record &&
        record?.mc !== '03' &&
        record?.mc !== 'UP' && (
          <div className="w-full">
            {record && (record?.mc === '02' || record?.mc === 'HA') && (
              <>
                {(!marketStatus ||
                  ['O', 'I', 'A'].indexOf(marketStatus) > -1) && (
                    <>
                      <button
                        className="mr-1 my-1 w-1/4"
                        onClick={() => fnCallback('ATC')}
                        type="button"
                      >
                        ATC
                      </button>
                      <button
                        className="m-1 w-1/4"
                        onClick={() => fnCallback('MTL')}
                        type="button"
                      >
                        MTL
                      </button>
                      <button
                        className="m-1 w-1/4"
                        onClick={() => fnCallback('MOK')}
                        type="button"
                      >
                        MOK
                      </button>
                      <button
                        className="ml-1 my-1 w-1/4"
                        onClick={() => fnCallback('MAK')}
                        type="button"
                      >
                        MAK
                      </button>
                    </>
                  )}
                {(marketStatus === 'C' || marketStatus === 'K') &&
                  hours < 15 &&
                  hours > 12 && (
                    <button
                      className="mr-1 my-1 w-1/4"
                      onClick={() => fnCallback('PLO')}
                      type="button"
                    >
                      PLO
                    </button>
                  )}
              </>
            )}
            {record && (record?.mc === '10' || record?.mc === 'HO') && (
              <>
                {(!marketStatus || marketStatus === 'P') && (
                  <button
                    className="mr-1 my-1 w-1/4"
                    onClick={() => fnCallback('ATO')}
                    type="button"
                  >
                    ATO
                  </button>
                )}
                {(!marketStatus ||
                  ['P', 'O', 'I', 'A'].indexOf(marketStatus) > -1) && (
                    <>
                      <button
                        className="m-1 w-1/4"
                        onClick={() => fnCallback('ATC')}
                        type="button"
                      >
                        ATC
                      </button>
                    </>
                  )}
                {(!marketStatus ||
                  ['P', 'O', 'I'].indexOf(marketStatus) > -1) && (
                    <>
                      <button
                        className="mr-1 my-1 w-1/4"
                        onClick={() => fnCallback('MP')}
                        type="button"
                      >
                        MP
                      </button>
                    </>
                  )}
              </>
            )}
          </div>
        )}
    </div>
  );
};

export default RenderInputAdvanceTradePrice;
