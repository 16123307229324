import { handleApiErrors } from 'lib/api-error'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import axios from 'axios'
import { Config } from 'interface'
import { AppState } from 'reducers'
import { checkInvalidSession } from 'utils'
import { stockSearchError, stockSearchSuccess } from './actions'
import * as actions from './actionType'

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.timeout = 3000

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

async function postRequestApi(data: Object, appUrl: string) {
  const url = `${appUrl}/traditional-service`

  try {
    const response = axios.post(url, JSON.stringify(data))
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
    // if (!json.code || json.code < 1) {
    //   throw Error(json.re);
    // }
    // return json.data;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

async function getRequestApi(data: string, appUrl: string) {
  const url = `${appUrl}/getlistindexdetail/${data}`

  try {
    const response = axios.get(url)
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

function* stockSearchRequestFlow(
  action: actions.StockSearchRequestingAction
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }
    yield put(stockSearchSuccess(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(stockSearchError(error))
    }
  }
}

function* pawnWatcher() {
  yield all([
    takeLatest(actions.STOCK_SEARCH_REQUESTING, stockSearchRequestFlow),
  ])
}

export default pawnWatcher
