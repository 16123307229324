import { FunctionComponent, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import { ReactComponent as IconAccFill } from 'assets/img/icon/icAccountFill.svg';
import { ReactComponent as IconLockFill } from 'assets/img/icon/icLockFill.svg';
import { ReactComponent as IconSmsFill } from 'assets/img/icon/icSmsFill.svg';

interface Props {
  input: any;
  type: string | undefined;
  placeholder: string | undefined;
  disabled: boolean | undefined;
  meta: any;
}

const RenderLoginInput: FunctionComponent<Props> = (props) => {
  const {
    input,
    type,
    disabled,
    placeholder,
    meta: { touched, error },
  } = props;
  const [typeInput, setTypeInput] = useState(
    type === 'password' ? 'password' : 'text',
  );

  return (
    <div className="flex flex-col relative">
      <div
        className="w-full flex bg-skin-input rounded-md h-10 border-x border-y"
        style={{
          borderColor: `${
            touched && error ? '#ff5555' : 'var(--color-border)'
          }`,
        }}
      >
        <div className="w-10 h-full flex items-center justify-center">
          {type === 'password' || type === 'otp' ? (
            <IconLockFill className="w-5 h-auto p-0.5" />
          ) : type === 'email' ? (
            <IconSmsFill className="w-5 h-auto p-0.5" />
          ) : (
            <IconAccFill className="w-5 h-auto p-0.5" />
          )}
        </div>
        <input
          {...input}
          type={typeInput}
          className="bg-transparent w-[calc(100%-40px)] text-sm text-skin-dark placeholder:text-skin-placeholder text-input-trading border-0"
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="false"
          autoCorrect="false"
          aria-autocomplete="none"
          style={{
            paddingRight: type === 'password' ? '2rem' : '.75rem',
          }}
        />
      </div>
      {type === 'password' && (
        <span
          className="absolute"
          style={{ top: '12px', right: '12px' }}
          onClick={() =>
            setTypeInput(typeInput === 'text' ? 'password' : 'text')
          }
        >
          {typeInput === 'text' ? (
            <EyeIcon className="h-4 text-skin-placeholder" />
          ) : (
            <EyeSlashIcon className="h-4 text-skin-placeholder" />
          )}
        </span>
      )}
      {touched && error && (
        <div
          style={{
            color: '#ff5555',
            margin: '0 0 10px',
            fontSize: '0.75rem',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default RenderLoginInput;
