import * as actions from './actionType';
export function clearCache(): actions.ClearCacheAction {
  return {
    type: actions.CLEAR_CACHE_STOCK_DETAIL,
  };
}

export function stockDetailRequest(
  data: any,
): actions.StockDetailRequestAction {
  return {
    type: actions.STOCK_DETAIL_REQUESTING,
    data,
  };
}

export function stockDetailRequestSuccess(
  resData: any,
): actions.StockDetailRequestSuccessAction {
  return {
    type: actions.STOCK_DETAIL_REQUEST_SUCCESS,
    resData,
  };
}

export function stockDetailRequestError(
  error: any,
): actions.StockDetailRequestErrorAction {
  return {
    type: actions.STOCK_DETAIL_REQUEST_ERROR,
    error,
  };
}

export function stockTradeRequest(data: any): actions.StockTradeRequestAction {
  return {
    type: actions.STOCK_TRADE_REQUESTING,
    data,
  };
}

export function stockTradeRequestSuccess(
  resData: any,
): actions.StockTradeRequestSuccessAction {
  return {
    type: actions.STOCK_TRADE_REQUEST_SUCCESS,
    resData,
  };
}

export function stockTradeRequestError(
  error: any,
): actions.StockTradeRequestErrorAction {
  return {
    type: actions.STOCK_TRADE_REQUEST_ERROR,
    error,
  };
}

export function cstRequest(data: any): actions.CstRequestAction {
  return {
    type: actions.CST_REQUESTING,
    data,
  };
}

export function cstRequestSuccess(
  resData: any,
): actions.CstRequestSuccessAction {
  return {
    type: actions.CST_REQUEST_SUCCESS,
    resData,
  };
}

export function cstRequestError(error: any): actions.CstRequestErrorAction {
  return {
    type: actions.CST_REQUEST_ERROR,
    error,
  };
}

export function kqkdRequest(data: any): actions.KqkdRequestAction {
  return {
    type: actions.KQKD_REQUESTING,
    data,
  };
}

export function kqkdRequestSuccess(
  resData: any,
): actions.KqkdRequestSuccessAction {
  return {
    type: actions.KQKD_REQUEST_SUCCESS,
    resData,
  };
}

export function kqkdRequestError(error: any): actions.KqkdRequestErrorAction {
  return {
    type: actions.KQKD_REQUEST_ERROR,
    error,
  };
}

export function cdktRequest(data: any): actions.CdktRequestAction {
  return {
    type: actions.CDKT_REQUESTING,
    data,
  };
}

export function cdktRequestSuccess(
  resData: any,
): actions.CdktRequestSuccessAction {
  return {
    type: actions.CDKT_REQUEST_SUCCESS,
    resData,
  };
}

export function cdktRequestError(error: any): actions.CdktRequestErrorAction {
  return {
    type: actions.CDKT_REQUEST_ERROR,
    error,
  };
}

export function stockHisRequest(data: any): actions.StockHisRequestAction {
  return {
    type: actions.STOCK_HIS_REQUESTING,
    data,
  };
}

export function stockHisRequestSuccess(
  resData: any,
): actions.StockHisRequestSuccessAction {
  return {
    type: actions.STOCK_HIS_REQUEST_SUCCESS,
    resData,
  };
}

export function stockHisRequestError(
  error: any,
): actions.StockHisRequestErrorAction {
  return {
    type: actions.STOCK_HIS_REQUEST_ERROR,
    error,
  };
}

export function transferDataRequest(
  data: any,
): actions.TransferDataRequestAction {
  return {
    type: actions.TRANSFER_DATA_REQUESTING,
    data,
  };
}

export function transferDataRequestSuccess(
  resData: any,
): actions.TransferDataRequestSuccessAction {
  return {
    type: actions.TRANSFER_DATA_REQUEST_SUCCESS,
    resData,
  };
}

export function transferDataRequestError(
  error: any,
): actions.TransferDataRequestErrorAction {
  return {
    type: actions.TRANSFER_DATA_REQUEST_ERROR,
    error,
  };
}

export function stockNewsRequest(data: any): actions.StockNewsRequestAction {
  return {
    type: actions.STOCK_NEWS_REQUESTING,
    data,
  };
}

export const stockNewsRequestSuccess = function stockNewsRequestSuccess(
  resData: any,
): actions.StockNewsRequestSuccessAction {
  return {
    type: actions.STOCK_NEWS_REQUEST_SUCCESS,
    resData,
  };
};

export function stockNewsRequestError(
  error: any,
): actions.StockNewsRequestErrorAction {
  return {
    type: actions.STOCK_NEWS_REQUEST_ERROR,
    error,
  };
}

export function stockEventsRequest(
  data: any,
): actions.StockEventsRequestAction {
  return {
    type: actions.STOCK_EVENTS_REQUESTING,
    data,
  };
}

export function stockEventsRequestSuccess(
  resData: any,
): actions.StockEventsRequestSuccessAction {
  return {
    type: actions.STOCK_EVENTS_REQUEST_SUCCESS,
    resData,
  };
}

export function stockEventsRequestError(
  error: any,
): actions.StockEventsRequestErrorAction {
  return {
    type: actions.STOCK_EVENTS_REQUEST_ERROR,
    error,
  };
}

export function newsDetailRequest(data: any): actions.NewsDetailRequestAction {
  return {
    type: actions.NEWS_DETAIL_REQUESTING,
    data,
  };
}

export function newsDetailRequestSuccess(
  resData: any,
): actions.NewsDetailRequestSuccessAction {
  return {
    type: actions.NEWS_DETAIL_REQUEST_SUCCESS,
    resData,
  };
}

export function newsDetailRequestError(
  error: any,
): actions.NewsDetailRequestErrorAction {
  return {
    type: actions.NEWS_DETAIL_REQUEST_ERROR,
    error,
  };
}

export function stockManagementRequest(
  data: any,
): actions.StockManagementRequestAction {
  return {
    type: actions.STOCK_MANAGEMENT_REQUESTING,
    data,
  };
}

export function stockManagementRequestSuccess(
  resData: any,
): actions.StockManagementRequestSuccessAction {
  return {
    type: actions.STOCK_MANAGEMENT_REQUEST_SUCCESS,
    resData,
  };
}

export function stockManagementRequestError(
  error: any,
): actions.StockManagementRequestErrorAction {
  return {
    type: actions.STOCK_MANAGEMENT_REQUEST_ERROR,
    error,
  };
}

export function companyInfoRequest(
  data: any,
): actions.CompanyInfoRequestAction {
  return {
    type: actions.COMPANY_INFO_REQUESTING,
    data,
  };
}

export function companyInfoRequestSuccess(
  resData: any,
): actions.CompanyInfoRequestSuccessAction {
  return {
    type: actions.COMPANY_INFO_REQUEST_SUCCESS,
    resData,
  };
}

export function companyInfoRequestError(
  error: any,
): actions.CompanyInfoRequestErrorAction {
  return {
    type: actions.COMPANY_INFO_REQUEST_ERROR,
    error,
  };
}

export function associateRequest(data: any): actions.AssociateRequestAction {
  return {
    type: actions.ASSOCIATE_REQUESTING,
    data,
  };
}

export function associateRequestSuccess(
  resData: any,
): actions.AssociateRequestSuccessAction {
  return {
    type: actions.ASSOCIATE_REQUEST_SUCCESS,
    resData,
  };
}

export function associateRequestError(
  error: any,
): actions.AssociateRequestErrorAction {
  return {
    type: actions.ASSOCIATE_REQUEST_ERROR,
    error,
  };
}

export function collectionPriceRequest(
  data: any,
): actions.CollectionPriceRequestAction {
  return {
    type: actions.COLLECTION_PRICE_REQUESTING,
    data,
  };
}

export function collectionPriceRequestSuccess(
  resData: any,
): actions.CollectionPriceRequestSuccessAction {
  return {
    type: actions.COLLECTION_PRICE_REQUEST_SUCCESS,
    resData,
  };
}

export function collectionPriceRequestError(
  error: any,
): actions.CollectionPriceRequestErrorAction {
  return {
    type: actions.COLLECTION_PRICE_REQUEST_ERROR,
    error,
  };
}

export function topMatchRequest(data: any): actions.TopMatchRequestAction {
  return {
    type: actions.TOP_MATCH_REQUESTING,
    data,
  };
}

export function topMatchRequestSuccess(
  resData: any,
): actions.TopMatchRequestSuccessAction {
  return {
    type: actions.TOP_MATCH_REQUEST_SUCCESS,
    resData,
  };
}

export function topMatchRequestError(
  error: any,
): actions.TopMatchRequestErrorAction {
  return {
    type: actions.TOP_MATCH_REQUEST_ERROR,
    error,
  };
}
