import { BtnSubmit } from 'utils/styledUtils';

interface IProps {
  className?: string;
}

function BankAccountItem(props: IProps): JSX.Element {
  const { className } = props;
  return (
    <li
      className={
        'flex flex-col w-full p-2 rounded-sm border-x border-y cl-border-var ' +
        (className || '')
      }
    >
      <div className="grid grid-cols-4 gap-1">
        <label className="text-[10px] text-skin-vendor col-start-1 col-end-3">
          Chủ hở hữu
        </label>
        <label className="text-[10px] text-skin-vendor">Số tài khoản</label>
        <label className="text-[10px] text-skin-vendor">Ngân hàng</label>
        <label className="text-xs text-skin-vendor col-start-1 col-end-3 uppercase">
          Nguyễn Tấn Dũng
        </label>
        <label className="text-xs text-skin-text7">1234567891011</label>
        <label className="text-xs text-skin-text7">Techcombank</label>
      </div>
      <div className="w-full my-2 h-[1px] bg-skin-border" />
      <div className="flex items-end justify-between">
        <div className="grid">
          <label className="text-[10px] text-skin-vendor">Trạng thái</label>
          <label className="text-xs ">Chờ duyệt</label>
        </div>
        <BtnSubmit type="button" className="info h-7 !text-[10px] px-3">
          Xóa tài khoản
        </BtnSubmit>
      </div>
    </li>
  );
}

export default BankAccountItem;
