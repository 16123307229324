/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, connect } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import {
  clientTokenUnset,
  headerActiveSet,
  setLogin,
  showNavSet,
} from 'containers/client/actions';

import { mobileItems, navItems } from '../_nav';

import { ReactComponent as ComponentDashboard } from 'assets/img/nav/price-tb.svg';
import { ReactComponent as ComponentOrder } from 'assets/img/nav/order.svg';
import { ReactComponent as ComponentStock } from 'assets/img/nav/stock.svg';
import { ReactComponent as ComponentCash } from 'assets/img/nav/cash.svg';
import { ReactComponent as ComponentAccount } from 'assets/img/nav/account.svg';
import { ReactComponent as ComponentAnalysis } from 'assets/img/nav/analysis.svg';
import { ReactComponent as ComponentSearch } from 'assets/img/nav/search.svg';
import { ReactComponent as ComponentSupport } from 'assets/img/nav/support.svg';
import { ReactComponent as ComponentSurvey } from 'assets/img/nav/survey.svg';
import { ReactComponent as ComponentSetting } from 'assets/img/nav/setting.svg';
import { ReactComponent as ComponentMenu } from 'assets/img/nav/menu.svg';
import { ReactComponent as ComponentCancel } from 'assets/img/nav/cancel.svg';

import { AppState } from 'reducers';
import { makeGetToken } from 'lib/selector';
import { storages } from 'lib/storages';
import { checkHasActByFunc } from 'utils';
import { useTranslation } from 'react-i18next';
import { NavItems } from 'interface';

function usePrevious(value: any) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  typeNav: string | undefined;
  showNav: boolean;
  token: any;
}

function NavLeft(props: Props): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = location;

  const [linkActive, setLinkActive] = React.useState<string>('');
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const { token } = props;
  // console.log("token nav", token);
  const prePathname: string | undefined | null = usePrevious(pathname);

  React.useEffect(() => {
    if (pathname && pathname !== prePathname) {
      setTimeout(() => {
        setLinkActive(pathname);
      }, 200);
    }
  }, [pathname]);

  function getImageByKey(key: string) {
    switch (key) {
      case 'order':
        return <ComponentOrder />;
      case 'stock':
        return <ComponentStock />;
      case 'cash':
        return <ComponentCash />;
      case 'account':
        return <ComponentAccount />;
      case 'analysis':
        return <ComponentAnalysis />;
      case 'search':
        return <ComponentSearch />;
      case 'support':
        return <ComponentSupport />;
      case 'survey':
        return <ComponentSurvey />;
      case 'setting':
        return <ComponentSetting />;
      default:
        return <ComponentDashboard />;
    }
  }

  function handleClickToOrder() {
    if (!token) {
      dispatch(setLogin());
      return;
    }

    dispatch({ type: 'REQ_TRADING', data: true });
  }

  function handleClickToOrderOver() {
    if (!token) {
      dispatch(setLogin());
      return;
    }

    dispatch(headerActiveSet('HEAD_TRADING'));

    // dispatch({ type: 'REQ_TRADING', data: true });
  }

  function renderMiniHasAct(record: NavItems) {
    if (record.url === '/order')
      return (
        <a
          onClick={() => handleClickToOrder()}
          className={
            'w-full flex flex-col justify-center ' +
            (linkActive.startsWith(record.url + '/') ||
            linkActive === record.url
              ? 'active'
              : '')
          }
        >
          {getImageByKey(record.icon)}
          <span>{t(record.labelCode)}</span>
        </a>
      );
    if (record.url === '/trading')
      return (
        <a
          onClick={() => handleClickToOrderOver()}
          className={
            'w-full flex flex-col justify-center ' +
            (linkActive.startsWith(record.url + '/') ||
            linkActive === record.url
              ? 'active'
              : '')
          }
        >
          {getImageByKey(record.icon)}
          <span>{t(record.labelCode)}</span>
        </a>
      );

    return (
      <Link
        to={record.url}
        className={
          'w-full flex flex-col justify-center ' +
          (linkActive.startsWith(record.url + '/') || linkActive === record.url
            ? 'active'
            : '')
        }
      >
        {getImageByKey(record.icon)}
        <span>{t(record.labelCode)}</span>
      </Link>
    );
  }

  return (
    <>
      <div className="hidden md:block nav-account nav-md w-[72px]">
        <PerfectScrollBar
          style={{
            minHeight: 'calc(100vh - 48px)',
            height: 'calc(100vh - 48px)',
          }}
        >
          <ul className="mt-3">
            {navItems &&
              navItems.map(
                (item, index) =>
                  checkHasActByFunc(item?.right, token?.rightList) && (
                    <li key={index} className="flex flex-col justify-center ">
                      {renderMiniHasAct(item)}
                    </li>
                  ),
              )}
          </ul>
        </PerfectScrollBar>
      </div>
      <div className="md:hidden nav-account nav-sm w-full absolute bottom-0 z-10">
        <ul className="grid grid-cols-4">
          {mobileItems &&
            mobileItems.map(
              (item, index) =>
                checkHasActByFunc(item?.right, token?.rightList) && (
                  <li key={index} className="flex flex-col justify-center">
                    {renderMiniHasAct(item)}
                  </li>
                ),
            )}
          {/* <li className="flex flex-col justify-center">
            <a
              className='w-full flex flex-col justify-center'
              onClick={() => setShowMenu(!showMenu)}
            >
              {
                showMenu && (<>
                  <ComponentCancel />
                  <span>Thu gọn</span>
                </>)
              }
              {
                !showMenu && (<>
                  <ComponentMenu />
                  <span>Menu</span>
                </>)
              }
            </a>
          </li> */}
        </ul>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  const getToken = makeGetToken();
  return {
    showNav: state.client.showNav,
    token: getToken(state),
  };
};

export default connect(mapStateToProps)(React.memo(NavLeft));
