import * as actions from './actionType';

/** get danh sách tham số hệ thống */
export function setParamSearchRequest(
  data: any,
): actions.SetParamSearchRequestingAction {
  return {
    type: actions.SET_PARAM_SEARCH_REQUESTING,
    data,
  };
}

export function setParamSearchSuccess(
  resData: any,
): actions.SetParamSearchSuccessAction {
  return {
    type: actions.SET_PARAM_SEARCH_SUCCESS,
    resData,
  };
}

export function setParamSearchError(
  error: Error | string,
): actions.SetParamSearchErrorAction {
  return {
    type: actions.SET_PARAM_SEARCH_ERROR,
    error,
  };
}

/** chi tiết tham số hệ thống */
export function setParamDetailRequest(
  data: any,
): actions.SetParamSingleRequestingAction {
  return {
    type: actions.SET_PARAM_SINGLE_REQUESTING,
    data,
  };
}

export function setParamDetailSuccess(
  resData: any,
): actions.SetParamSingleSuccessAction {
  return {
    type: actions.SET_PARAM_SINGLE_SUCCESS,
    resData,
  };
}

export function setParamDetailError(
  error: Error | string,
): actions.SetParamSingleErrorAction {
  return {
    type: actions.SET_PARAM_SINGLE_ERROR,
    error,
  };
}

/** cập nhât tham số hệ thống */
export function setParamUpdRequest(
  data: any,
): actions.SetParamUpdRequestingAction {
  return {
    type: actions.SET_PARAM_UPD_REQUESTING,
    data,
  };
}

export function setParamUpdSuccess(
  resData: any,
): actions.SetParamUpdSuccessAction {
  return {
    type: actions.SET_PARAM_UPD_SUCCESS,
    resData,
  };
}

export function setParamUpdError(
  error: Error | string,
): actions.SetParamUpdErrorAction {
  return {
    type: actions.SET_PARAM_UPD_ERROR,
    error,
  };
}

/** xóa tham số hệ thống */
export function setParamDelRequest(
  data: any,
): actions.SetParamDelRequestingAction {
  return {
    type: actions.SET_PARAM_DEL_REQUESTING,
    data,
  };
}

export function setParamDelSuccess(
  resData: any,
): actions.SetParamDelSuccessAction {
  return {
    type: actions.SET_PARAM_DEL_SUCCESS,
    resData,
  };
}

export function setParamDelError(
  error: Error | string,
): actions.SetParamDelErrorAction {
  return {
    type: actions.SET_PARAM_DEL_ERROR,
    error,
  };
}

/** danh sách chức năng */
export function funcSearchRequest(
  data: any,
): actions.FuncSearchRequestingAction {
  return {
    type: actions.FUNC_SEARCH_REQUESTING,
    data,
  };
}

export function funcSearchSuccess(
  resData: any,
): actions.FuncSearchSuccessAction {
  return {
    type: actions.FUNC_SEARCH_SUCCESS,
    resData,
  };
}

export function funcSearchError(
  error: Error | string,
): actions.FuncSearchErrorAction {
  return {
    type: actions.FUNC_SEARCH_ERROR,
    error,
  };
}

/** chi tiết chức năng */
export function funcDetailRequest(
  data: any,
): actions.FuncDetailRequestingAction {
  return {
    type: actions.FUNC_DETAIL_REQUESTING,
    data,
  };
}

export function funcDetailSuccess(
  resData: any,
): actions.FuncDetailSuccessAction {
  return {
    type: actions.FUNC_DETAIL_SUCCESS,
    resData,
  };
}

export function funcDetailError(
  error: Error | string,
): actions.FuncDetailErrorAction {
  return {
    type: actions.FUNC_DETAIL_ERROR,
    error,
  };
}

/** update chức năng */
export function funcUpdRequest(data: any): actions.FuncUpdRequestingAction {
  return {
    type: actions.FUNC_UPD_REQUESTING,
    data,
  };
}

export function funcUpdSuccess(resData: any): actions.FuncUpdSuccessAction {
  return {
    type: actions.FUNC_UPD_SUCCESS,
    resData,
  };
}

export function funcUpdError(
  error: Error | string,
): actions.FuncUpdErrorAction {
  return {
    type: actions.FUNC_UPD_ERROR,
    error,
  };
}

/** xóa chức năng */
export function funcDelRequest(data: any): actions.FuncDelRequestingAction {
  return {
    type: actions.FUNC_DEL_REQUESTING,
    data,
  };
}

export function funcDelSuccess(resData: any): actions.FuncDelSuccessAction {
  return {
    type: actions.FUNC_DEL_SUCCESS,
    resData,
  };
}

export function funcDelError(
  error: Error | string,
): actions.FuncDelErrorAction {
  return {
    type: actions.FUNC_DEL_ERROR,
    error,
  };
}

/** nhóm quyền */
export function userGrSearchRequest(
  data: any,
): actions.UserGrSearchRequestingAction {
  return {
    type: actions.USER_GR_SEARCH_REQUESTING,
    data,
  };
}

export function userGrSearchSuccess(
  resData: any,
): actions.UserGrSearchSuccessAction {
  return {
    type: actions.USER_GR_SEARCH_SUCCESS,
    resData,
  };
}

export function userGrSearchError(
  error: Error | string,
): actions.UserGrSearchErrorAction {
  return {
    type: actions.USER_GR_SEARCH_ERROR,
    error,
  };
}

/** chi tiết nhóm quyền */
export function userGrDetailRequest(
  data: any,
): actions.UserGrDetailRequestingAction {
  return {
    type: actions.USER_GR_DETAIL_REQUESTING,
    data,
  };
}

export function userGrDetailSuccess(
  resData: any,
): actions.UserGrDetailSuccessAction {
  return {
    type: actions.USER_GR_DETAIL_SUCCESS,
    resData,
  };
}

export function userGrDetailError(
  error: Error | string,
): actions.UserGrDetailErrorAction {
  return {
    type: actions.USER_GR_DETAIL_ERROR,
    error,
  };
}

/** Update nhóm quyền */
export function userGrUpdRequest(data: any): actions.UserGrUpdRequestingAction {
  return {
    type: actions.USER_GR_UPD_REQUESTING,
    data,
  };
}

export function userGrUpdSuccess(resData: any): actions.UserGrUpdSuccessAction {
  return {
    type: actions.USER_GR_UPD_SUCCESS,
    resData,
  };
}

export function userGrUpdError(
  error: Error | string,
): actions.UserGrUpdErrorAction {
  return {
    type: actions.USER_GR_UPD_ERROR,
    error,
  };
}

/** xóa nhóm quyền */
export function userGrDelRequest(data: any): actions.UserGrDelRequestingAction {
  return {
    type: actions.USER_GR_DEL_REQUESTING,
    data,
  };
}

export function userGrDelSuccess(resData: any): actions.UserGrDelSuccessAction {
  return {
    type: actions.USER_GR_DEL_SUCCESS,
    resData,
  };
}

export function userGrDelError(
  error: Error | string,
): actions.UserGrDelErrorAction {
  return {
    type: actions.USER_GR_DEL_ERROR,
    error,
  };
}

/** function nhóm quyền */
export function userGrFuncRequest(
  data: any,
): actions.UserGrFuncRequestingAction {
  return {
    type: actions.USER_GR_FUNC_REQUESTING,
    data,
  };
}

export function userGrFuncSuccess(
  resData: any,
): actions.UserGrFuncSuccessAction {
  return {
    type: actions.USER_GR_FUNC_SUCCESS,
    resData,
  };
}

export function userGrFuncError(
  error: Error | string,
): actions.UserGrFuncErrorAction {
  return {
    type: actions.USER_GR_FUNC_ERROR,
    error,
  };
}
