import { PlacesType, Tooltip } from 'react-tooltip';

interface Props {
  id: string;
  placement?: PlacesType;
}

function Tooltiped(props: Props): JSX.Element {
  const { placement = 'top', id } = props;
  return (
    <Tooltip
      id={id}
      place={placement}
      style={{ backgroundColor: '#000711', color: '#E7EEFA' }}
    />
  );
}

export default Tooltiped;
