import { ParamModel } from 'interface';
import * as actions from './actionType';

export function stockSearchRequest(
  data: ParamModel,
): actions.StockSearchRequestingAction {
  return {
    type: actions.STOCK_SEARCH_REQUESTING,
    data,
  };
}

export function stockSearchSuccess(
  resData: any,
): actions.StockSearchSuccessAction {
  return {
    type: actions.STOCK_SEARCH_SUCCESS,
    resData,
  };
}

export function stockSearchError(
  error: Error | string,
): actions.StockSearchErrorAction {
  return {
    type: actions.STOCK_SEARCH_ERROR,
    error,
  };
}
