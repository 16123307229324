import { handleApiErrors } from 'lib/api-error'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import axios from 'axios'
import { Config } from 'interface'
import { AppState } from 'reducers'
import { checkInvalidSession } from 'utils'
import {
  report001Error,
  report001Success,
  report002Error,
  report002Success,
  report003Error,
  report003Success,
  report004Error,
  report004Success,
  report005Error,
  report005Success,
  report006Error,
  report006Success,
  report007Error,
  report007Success,
  report008Error,
  report008Success,
  report009Error,
  report009Success,
  report010Error,
  report010Success,
  report011Error,
  report011Success,
  report012Error,
  report012Success,
} from './actions'
import * as actions from './actionType'

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.timeout = 3000

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

async function postRequestApi(data: Object, appUrl: string) {
  const url = `${appUrl}/TraditionalService`

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    // check success
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

function* report001RequestFlow(action: actions.Report001RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report001Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report001Error(error))
    }
  }
}

function* report002RequestFlow(action: actions.Report002RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report002Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report002Error(error))
    }
  }
}

function* report003RequestFlow(action: actions.Report003RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report003Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report003Error(error))
    }
  }
}

function* report004RequestFlow(action: actions.Report004RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report004Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report004Error(error))
    }
  }
}

function* report005RequestFlow(action: actions.Report005RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report005Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report005Error(error))
    }
  }
}

function* report006RequestFlow(action: actions.Report006RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report006Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report006Error(error))
    }
  }
}

function* report007RequestFlow(action: actions.Report007RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report007Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report007Error(error))
    }
  }
}

function* report008RequestFlow(action: actions.Report008RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report008Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report008Error(error))
    }
  }
}

function* report009RequestFlow(action: actions.Report009RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report009Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report009Error(error))
    }
  }
}

function* report010RequestFlow(action: actions.Report010RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report010Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report010Error(error))
    }
  }
}

function* report011RequestFlow(action: actions.Report011RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report011Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report011Error(error))
    }
  }
}

function* report012RequestFlow(action: actions.Report012RequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action
    const _res: any = yield call(postRequestApi, data, config.AuthUrl)

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        })
      }
      throw Error(_res.rs)
    }

    yield put(report012Success(_res.data))
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' })
    if (error instanceof Error) {
      yield put(report012Error(error))
    }
  }
}

function* reportWatcher() {
  yield all([
    takeLatest(actions.REPORT_001_REQUESTING, report001RequestFlow),
    takeLatest(actions.REPORT_002_REQUESTING, report002RequestFlow),
    takeLatest(actions.REPORT_003_REQUESTING, report003RequestFlow),
    takeLatest(actions.REPORT_004_REQUESTING, report004RequestFlow),
    takeLatest(actions.REPORT_005_REQUESTING, report005RequestFlow),
    takeLatest(actions.REPORT_006_REQUESTING, report006RequestFlow),
    takeLatest(actions.REPORT_007_REQUESTING, report007RequestFlow),
    takeLatest(actions.REPORT_008_REQUESTING, report008RequestFlow),
    takeLatest(actions.REPORT_009_REQUESTING, report009RequestFlow),
    takeLatest(actions.REPORT_010_REQUESTING, report010RequestFlow),
    takeLatest(actions.REPORT_011_REQUESTING, report011RequestFlow),
    takeLatest(actions.REPORT_012_REQUESTING, report012RequestFlow),
  ])
}

export default reportWatcher
