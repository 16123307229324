import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import clientReducer from './../containers/client/reducer';
// import socket from './../containers/socket/reducer';
import globalReducer from '../containers/global/reducer';
import loginReducer from '../containers/login/reducer';

import settingReducer from 'containers/setting/reducer';
import socketReducer from 'containers/socket/reducer';
import userReducer from 'containers/user/reducer';
import reportReducer from '../containers/baocao/reducer';
import stockReducer from '../containers/cash/chung-khoan/reducer';

import errorReducer from './errorReducer';
import isLoadingReducer from './isLoadingReducer';

import priceBoardReducer from 'containers/banggia/reducer';
import stockDetailReducer from 'containers/banggia/stockDetail/reducer';
import analysisReducer from 'containers/market/analysis/reducer';
import accInfoReducer from '../containers/account-info/reducer';
import privDataReducer from '../containers/privData/reducer';
import registerReducer from '../containers/register/reducer';
import RightReducer from '../containers/right/reducer';
import SummaryReducer from '../containers/summary/reducer';
import tradingReducer from '../containers/trading/reducer';
import configReducer from '../shared/configContext/configSlice';

const rootReducer = combineReducers({
  form,
  config: configReducer,
  login: loginReducer,
  client: clientReducer,
  trading: tradingReducer,
  global: globalReducer,
  report: reportReducer,
  stock: stockReducer,
  setting: settingReducer,
  user: userReducer,
  socket: socketReducer,

  error: errorReducer,
  isLoading: isLoadingReducer,

  priceBoard: priceBoardReducer,
  stockDetail: stockDetailReducer,
  analysis: analysisReducer,
  privData: privDataReducer,
  summary: SummaryReducer,
  right: RightReducer,
  regis: registerReducer,
  accInfo: accInfoReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
