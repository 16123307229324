import _ from 'lodash';
import React from 'react';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IOrderInday } from 'interface/search';
import { AppState } from 'reducers';
import { connect } from 'react-redux';
import { makeGetToken } from 'lib/selector';
import { useDispatch } from 'react-redux';

import { dataSearchSet, footerActiveSet } from 'containers/client/actions';
import { TFunction } from 'i18next';

import IconRotate from 'assets/img/icon/icRotateFill.svg';
import { BtnSubmit } from 'utils/styledUtils';
import RenderSelectDate from 'helper/input/renderDatePicker';
import RenderNormalSelect from 'helper/select/renderNormalSelect';

interface Props {
  isLoading?: boolean;
  token?: any;
  t: TFunction;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const FormSearch: React.FunctionComponent<
  InjectedFormProps<IOrderInday> & Props
> = (props) => {
  const refSubmit = React.useRef<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const { handleSubmit, isLoading, submitting, token, t } = props;

  React.useEffect(() => {
    initForm();

    setTimeout(() => {
      if (refSubmit.current) refSubmit.current?.click();
    }, 200);

    return () => {
      // clear
      dispatch(dataSearchSet(null));
    };
  }, []);

  function initForm(): void {
    dispatch(change('formSearchCashTransfer', 'formTradingType', 'NORMAL'));
    dispatch(change('formSearchCashTransfer', 'formOrderStatus', ''));
    dispatch(change('formSearchCashTransfer', 'formOrderPrice', ''));
  }

  function handleClear() {
    initForm();
  }

  function handleReload() {
    setTimeout(() => {
      if (refSubmit.current) refSubmit.current?.click();
    }, 200);
  }

  function submit(formSearch: IOrderInday) {
    // const _data = {
    //     cmd: "BACK.REPORT_012",
    //     FROM_DATE: fromDate,
    //     TO_DATE: toDate,
    //     PAGE: 1,
    //     RECORD_PER_PAGE: 10,
    //   };
    //   const _param = renderParams(token, _data);
    //   dispatch(report011Request(_param));
  }

  function handleClosePanel() {
    dispatch(footerActiveSet(''));
  }

  return (
    <form className="w-full py-2 flex flex-col" onSubmit={handleSubmit(submit)}>
      <div className="w-full flex items-center">
        <label className="text-sm whitespace-nowrap">Nạp rút tiền</label>
        <a className="ml-2 mr-auto" onClick={() => handleReload()}>
          <img src={IconRotate} alt="rotate" className="w-6 h-auto p-0.5" />
        </a>

        <BtnSubmit
          type="button"
          className="danger uppercase self-end text-sm !px-10 h-8 xl:w-48"
          disabled={submitting || isLoading}
          // onClick={() => handleSetSide("S")}
        >
          RÚT TIỀN
        </BtnSubmit>
        <BtnSubmit
          type="button"
          className="success uppercase self-end text-sm h-8 !px-10 ml-3 xl:w-48"
          // onClick={() => handleSetSide("B")}
          disabled={submitting || isLoading}
        >
          NẠP TIỀN
        </BtnSubmit>
      </div>
      <div className="w-full mt-3 grid grid-cols-5 gap-3">
        <div>
          <label>Loại lệnh</label>
          <Field
            name="formTransferType"
            placeholderText={'Loại lệnh'}
            component={RenderNormalSelect}
            opts={[]}
          />
        </div>
        <div>
          <label>Trạng thái</label>
          <Field
            name="formTransferStatus"
            placeholderText={'Trạng thái'}
            component={RenderNormalSelect}
            opts={[]}
          />
        </div>
        <div>
          <label>Từ ngày</label>
          <Field name="formFromDate" component={RenderSelectDate} />
        </div>
        <div>
          <label>Đến ngày</label>
          <Field name="formToDate" component={RenderSelectDate} />
        </div>

        <BtnSubmit
          ref={refSubmit}
          type="submit"
          className="self-end text-sm h-8 primary uppercase"
          disabled={submitting || isLoading}
        >
          {t('dashboard.search')}
        </BtnSubmit>
      </div>
    </form>
  );
};

const _FormSearch = reduxForm<IOrderInday, Props>({
  form: 'formSearchCashTransfer',
  // enableReinitialize: true,
})(FormSearch as any);

const selector = formValueSelector('formSearchCashTransfer');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state: AppState) => {
    const { formTradingType, formOrderStatus, formOrderPrice } = selector(
      state,
      'formTradingType',
      'formOrderStatus',
      'formOrderPrice',
    );

    return {
      token: getToken(state),
      //   isLoading: state.isLoading[actionTypes.REPORT_012],

      formTradingType,
      formOrderStatus,
      formOrderPrice,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(_FormSearch);
