import * as actions from './actionType';

// TODO: Giải ngân thu nợ
export function report001Request(data: any): actions.Report001RequestingAction {
  return {
    type: actions.REPORT_001_REQUESTING,
    data,
  };
}

export function report001Success(resData: any): actions.Report001SuccessAction {
  return {
    type: actions.REPORT_001_SUCCESS,
    resData,
  };
}

export function report001Error(
  error: Error | string,
): actions.Report001ErrorAction {
  return {
    type: actions.REPORT_001_ERROR,
    error,
  };
}

// TODO: Dư nợ theo tài khoản
export function report002Request(data: any): actions.Report002RequestingAction {
  return {
    type: actions.REPORT_002_REQUESTING,
    data,
  };
}

export function report002Success(resData: any): actions.Report002SuccessAction {
  return {
    type: actions.REPORT_002_SUCCESS,
    resData,
  };
}

export function report002Error(
  error: Error | string,
): actions.Report002ErrorAction {
  return {
    type: actions.REPORT_002_ERROR,
    error,
  };
}

// TODO: Chi tiết dư nợ
export function report003Request(data: any): actions.Report003RequestingAction {
  return {
    type: actions.REPORT_003_REQUESTING,
    data,
  };
}

export function report003Success(resData: any): actions.Report003SuccessAction {
  return {
    type: actions.REPORT_003_SUCCESS,
    resData,
  };
}

export function report003Error(
  error: Error | string,
): actions.Report003ErrorAction {
  return {
    type: actions.REPORT_003_ERROR,
    error,
  };
}

// TODO: Dư nợ đến hạn
export function report004Request(data: any): actions.Report004RequestingAction {
  return {
    type: actions.REPORT_004_REQUESTING,
    data,
  };
}

export function report004Success(resData: any): actions.Report004SuccessAction {
  return {
    type: actions.REPORT_004_SUCCESS,
    resData,
  };
}

export function report004Error(
  error: Error | string,
): actions.Report004ErrorAction {
  return {
    type: actions.REPORT_004_ERROR,
    error,
  };
}

// TODO: Sao kê nợ
export function report005Request(data: any): actions.Report005RequestingAction {
  return {
    type: actions.REPORT_005_REQUESTING,
    data,
  };
}

export function report005Success(resData: any): actions.Report005SuccessAction {
  return {
    type: actions.REPORT_005_SUCCESS,
    resData,
  };
}

export function report005Error(
  error: Error | string,
): actions.Report005ErrorAction {
  return {
    type: actions.REPORT_005_ERROR,
    error,
  };
}

// TODO: Lịch sử đăng ký gói
export function report006Request(data: any): actions.Report006RequestingAction {
  return {
    type: actions.REPORT_006_REQUESTING,
    data,
  };
}

export function report006Success(resData: any): actions.Report006SuccessAction {
  return {
    type: actions.REPORT_006_SUCCESS,
    resData,
  };
}

export function report006Error(
  error: Error | string,
): actions.Report006ErrorAction {
  return {
    type: actions.REPORT_006_ERROR,
    error,
  };
}

// TODO: lịch sử khớp lệnh
export function report007Request(data: any): actions.Report007RequestingAction {
  return {
    type: actions.REPORT_007_REQUESTING,
    data,
  };
}

export function report007Success(resData: any): actions.Report007SuccessAction {
  return {
    type: actions.REPORT_007_SUCCESS,
    resData,
  };
}

export function report007Error(
  error: Error | string,
): actions.Report007ErrorAction {
  return {
    type: actions.REPORT_007_ERROR,
    error,
  };
}

// TODO: báo cáo phí hợp tác
export function report008Request(data: any): actions.Report008RequestingAction {
  return {
    type: actions.REPORT_008_REQUESTING,
    data,
  };
}

export function report008Success(resData: any): actions.Report008SuccessAction {
  return {
    type: actions.REPORT_008_SUCCESS,
    resData,
  };
}

export function report008Error(
  error: Error | string,
): actions.Report008ErrorAction {
  return {
    type: actions.REPORT_008_ERROR,
    error,
  };
}

// TODO: báo cáo điện tương tác
export function report009Request(data: any): actions.Report009RequestingAction {
  return {
    type: actions.REPORT_009_REQUESTING,
    data,
  };
}

export function report009Success(resData: any): actions.Report009SuccessAction {
  return {
    type: actions.REPORT_009_SUCCESS,
    resData,
  };
}

export function report009Error(
  error: Error | string,
): actions.Report009ErrorAction {
  return {
    type: actions.REPORT_009_ERROR,
    error,
  };
}

// TODO: báo cáo phí phụ thu
export function report010Request(data: any): actions.Report010RequestingAction {
  return {
    type: actions.REPORT_010_REQUESTING,
    data,
  };
}

export function report010Success(resData: any): actions.Report010SuccessAction {
  return {
    type: actions.REPORT_010_SUCCESS,
    resData,
  };
}

export function report010Error(
  error: Error | string,
): actions.Report010ErrorAction {
  return {
    type: actions.REPORT_010_ERROR,
    error,
  };
}

// TODO: báo cáo thống kê tài sản
export function report011Request(data: any): actions.Report011RequestingAction {
  return {
    type: actions.REPORT_011_REQUESTING,
    data,
  };
}

export function report011Success(resData: any): actions.Report011SuccessAction {
  return {
    type: actions.REPORT_011_SUCCESS,
    resData,
  };
}

export function report011Error(
  error: Error | string,
): actions.Report011ErrorAction {
  return {
    type: actions.REPORT_011_ERROR,
    error,
  };
}

// TODO: báo cáo thống kê doanh số
export function report012Request(data: any): actions.Report012RequestingAction {
  return {
    type: actions.REPORT_012_REQUESTING,
    data,
  };
}

export function report012Success(resData: any): actions.Report012SuccessAction {
  return {
    type: actions.REPORT_012_SUCCESS,
    resData,
  };
}

export function report012Error(
  error: Error | string,
): actions.Report012ErrorAction {
  return {
    type: actions.REPORT_012_ERROR,
    error,
  };
}
