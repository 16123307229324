/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import BgLogin from 'assets/img/bg/bg_login_small.png';
import { loginSuccess } from 'containers/login/actions';
import { clientTokenSet } from 'containers/client/actions';
import { AppState } from 'reducers';
import { storages } from 'lib/storages';
import _ from 'lodash';
import FormLoginModal from './layout/formLogin';

const styleLg = {
  content: {
    top: '50%',
    transform: 'translateY(-50%)',
    bottom: 'auto',
    left: 'calc( 50% - 240px )',
    height: 'auto',
    width: '480px',
    padding: '0',
    borderWidth: '0',
    overflow: 'inherit',
    borderRadius: '10px',
    background: 'transparent',
  },
};

const styleXs = {
  content: {
    inset: '12px',
    padding: '0',
    borderWidth: '0',
    overflow: 'inherit',
    background: 'transparent',
    display: 'flex',
  },
};

interface Props {
  onClose: any;
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  successful: any;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function LoginRegisModal(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onClose, successful } = props;

  const preSuccessful = usePrevious(successful);

  const [mode, setMode] = React.useState<string>('LOGIN');
  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(loginSuccess(null));
    };
  }, []);

  React.useEffect(() => {
    if (successful && !_.isEqual(successful, preSuccessful)) {
      // dispatch action to store
      dispatch(clientTokenSet(successful));
      storages.saveState('token', JSON.stringify(successful));
      onClose();
    }
  }, [successful]);

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={dimensions.width >= 820 ? styleLg : styleXs}
    >
      <div
        className="px-10 py-5 rounded-[10px] m-auto w-full md:w-auto"
        style={{
          border: 'none',
          background: 'transparent',
          backgroundImage: `url(${BgLogin})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {mode === 'LOGIN' && <FormLoginModal t={t} setMode={setMode} />}
      </div>
    </ReactModal>
  );
}

const makeMapStateToProps = (state: AppState) => {
  const mapStateToProps = (state: AppState) => {
    return {
      successful: state.login.successful,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(LoginRegisModal);
