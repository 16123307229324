import axios from 'axios'
import { Config } from 'interface'
import { handleApiErrors } from 'lib/api-error'
import { AppState } from 'reducers'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { checkInvalidSession } from 'utils'
import * as actions from './actionType'
import {
  listRightBuyRequestError,
  listRightBuyRequestSuccess,
  rightAllStatusError,
  rightAllStatusSuccess,
  rightBuyHistoryRequestError,
  rightBuyHistoryRequestSuccess,
  rightUnExecRequestError,
  rightUnExecRequestSuccess,
  updateUnExecRequestError,
  updateUnExecRequestSuccess,
} from './actions'

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.timeout = 3000

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

function handleRequest(request: any) {
  return request
    .then(handleApiErrors)
    .then((response: any) => response.json())
    .then((json: any) => json)
    .catch((error: any) => {
      throw error
    })
}

async function summaryApi(data: any, appUrl: string) {
  const url = `${appUrl}/TraditionalService`

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    // check success
    const response_1 = handleApiErrors(await response)
    const json = await response_1.data
    return json
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!'
      )
    } else throw error
  }
}

function* rightInfoUnExecRequestFlow(
  action: actions.RightUnExecRequestAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action
    const resData = yield call(summaryApi, params, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        })
      }
      throw Error(resData.rs)
    }

    yield put(rightUnExecRequestSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(rightUnExecRequestError(error))
  }
}

function* listRightBuyRequestFlow(
  action: actions.ListRightBuyRequestAction
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action
    const resData = yield call(summaryApi, params, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        })
      }
      throw Error(resData.rs)
    }

    yield put(listRightBuyRequestSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(listRightBuyRequestError(error))
  }
}

function* rightBuyHisRequestFlow(
  action: actions.RightBuyHistoryRequestAction
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action
    const resData = yield call(summaryApi, params, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        })
      }
      throw Error(resData.rs)
    }

    yield put(rightBuyHistoryRequestSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(rightBuyHistoryRequestError(error))
  }
}

function* rightAllStatusRequestFlow(
  action: actions.RightAllStatusRequestAction
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action
    const resData = yield call(summaryApi, params, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        })
      }
      throw Error(resData.rs)
    }

    yield put(rightAllStatusSuccess(resData.data))
  } catch (error) {
    // log(error)
    yield put(rightAllStatusError(error))
  }
}

function* updateInfoUnExecRequestFlow(
  action: actions.UpdateUnExecRequestAction
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { params } = action
    const resData = yield call(summaryApi, params, config.AuthUrl)
    if (resData.rc < 1) {
      if (checkInvalidSession(resData.rs)) {
        localStorage.removeItem('token')
        yield put({
          type: 'INVALID_SESSION',
          msg: resData.rs,
        })
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: resData.rs,
        })
      }
      throw Error(resData.rs)
    }

    yield put(updateUnExecRequestSuccess(Math.random()))
  } catch (error) {
    // log(error)
    yield put(updateUnExecRequestError(error))
  }
}

function* rightWatcher() {
  yield all([
    takeLatest(actions.RIGHT_UN_EXEC_REQUESTING, rightInfoUnExecRequestFlow),
    takeLatest(actions.UPDATE_UN_EXEC_REQUESTING, updateInfoUnExecRequestFlow),
    takeLatest(actions.RIGHT_BUY_STT_REQUESTING, listRightBuyRequestFlow),
    takeLatest(actions.RIGHT_BUY_HIS_REQUESTING, rightBuyHisRequestFlow),
    takeLatest(actions.RIGHT_ALL_STATUS_REQUESTING, rightAllStatusRequestFlow),
  ])
}

export default rightWatcher
