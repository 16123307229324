import DefaultLayout from 'containers/defaultLayout';
import DefaultCash from 'containers/defaultLayout/DefaultCash';
import Login from 'containers/login';
import Register from 'containers/register';
import React from 'react';
import { Redirect, Route, HashRouter as Router } from 'react-router-dom';
import { useConfig } from 'shared/configContext';
import Loading from 'shared/loading';
import LoadingIndicator from 'shared/loadingIndicator';
import { getParameterByName, replaceAll } from 'utils';

interface Props {
  store?: any;
}

const App: React.FunctionComponent<Props> = (props) => {
  const { store } = props;
  const path = replaceAll(window.location.hash, '#', '').replace('/.', '');
  const _mode = getParameterByName('mode') || 'FULL';
  const _adv = getParameterByName('adv') || 'ALL';
  const _theme = getParameterByName('theme') || 'oled';
  const _lang = getParameterByName('lang') || 'vi';
  const _token = getParameterByName('token') || '';
  const _user = getParameterByName('user') || '';

  const _newPath =
    !path || path === '/'
      ? _mode === 'FRAME' || (_user && _token)
        ? '/price'
        : '/login'
      : path;

  console.log(_user, _token);

  const { config } = useConfig();
  if (!config) {
    return (
      <div className="bg-skin-body w-screen h-screen">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingIndicator />

          <div className="animated fadeIn pt-1 text-center text-skin-white text-xs">
            Loading...
          </div>
        </div>
      </div>
    );
  }

  return (
    // <Router basename={process.env.PUBLIC_URL}>
    <Router>
      <React.Suspense fallback={<Loading />}>
        <Route exact path="/login" component={() => <Login />} />
        <Route exact path="/register" component={() => <Register />} />
        <Route
          path={['/dashboard', '/account', '/price', '/setting']}
          component={() => (
            <DefaultLayout
              store={store}
              mode={_mode}
              adv={_adv}
              theme={_theme}
              lang={_lang}
              userStr={_user}
              tokenStr={_token}
            />
          )}
        />
        {/* <Route path="/market" component={() => <DefaultMarket store={store} />} /> */}
        <Route path="/cash" component={() => <DefaultCash store={store} />} />
        <Redirect from="/" to={_newPath} />
      </React.Suspense>
    </Router>
  );
};

export default App;
