import { AppState } from 'reducers';
import { createSelector } from 'reselect';

const getAccountInfo = (state: AppState) => state.accInfo.accInfo;
const getAccountCashApprove = (state: AppState) => state.accInfo.accCashAppr;
const getAccountBenUpd = (state: AppState) => state.accInfo.accBenUpd;
const getAccountBenList = (state: AppState) => state.accInfo.accBenList;
const getBranchInfo = (state: AppState) => state.accInfo.branchInfo;

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetAccountInfo = () =>
  createSelector(getAccountInfo, (accInfo) => accInfo);
export const makeGetAccountCashApprove = () =>
  createSelector(getAccountCashApprove, (accCashAppr) => accCashAppr);
export const makeGetAccountBenUpd = () =>
  createSelector(getAccountBenUpd, (accBenUpd) => accBenUpd);
export const makeGetAccountBenList = () =>
  createSelector(getAccountBenList, (accBenList) => accBenList);
export const makeGetBranchInfo = () =>
  createSelector(getBranchInfo, (branchInfo) => branchInfo);
