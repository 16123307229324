export const ALL_STOCK_REQUESTING = 'priceBoard/STOCK_REQUESTING';
export interface AllStockRequestAction {
  type: typeof ALL_STOCK_REQUESTING;
}

export const ALL_STOCK_REQUEST_SUCCESS = 'priceBoard/STOCK_SUCCESS';
export interface AllStockRequestSuccessAction {
  type: typeof ALL_STOCK_REQUEST_SUCCESS;
  allStock: any;
}

export const ALL_STOCK_REQUEST_ERROR = 'priceBoard/STOCK_ERROR';
export interface AllStockRequestErrorAction {
  type: typeof ALL_STOCK_REQUEST_ERROR;
  error: any;
}

export const MARGIN_LIST_REQUESTING = 'priceBoard/MARGIN_REQUESTING';
export interface MarginListRequestAction {
  type: typeof MARGIN_LIST_REQUESTING;
}

export const MARGIN_LIST_REQUEST_SUCCESS = 'priceBoard/MARGIN_SUCCESS';
export interface MarginListRequestSuccessAction {
  type: typeof MARGIN_LIST_REQUEST_SUCCESS;
  marginList: any;
}

export const MARGIN_LIST_REQUEST_ERROR = 'priceBoard/MARGIN_ERROR';
export interface MarginListRequestErrorAction {
  type: typeof MARGIN_LIST_REQUEST_ERROR;
  error: any;
}

export const LIST_MESSAGE_REQUESTING = 'priceBoard/MESSAGE_REQUESTING';
export interface ListMessageRequestAction {
  type: typeof LIST_MESSAGE_REQUESTING;
}

export const LIST_MESSAGE_REQUEST_SUCCESS = 'priceBoard/MESSAGE_SUCCESS';
export interface ListMessageRequestSuccessAction {
  type: typeof LIST_MESSAGE_REQUEST_SUCCESS;
  listMessage: any;
}

export const LIST_MESSAGE_REQUEST_ERROR = 'priceBoard/MESSAGE_ERROR';
export interface ListMessageRequestErrorAction {
  type: typeof LIST_MESSAGE_REQUEST_ERROR;
  error: any;
}

export const SNAPSHOT_REQUESTING = 'priceBoard/SNAPSHOT_REQUESTING';
export interface SnapshotRequestAction {
  type: typeof SNAPSHOT_REQUESTING;
  data: any;
}

export const SNAPSHOT_REQUEST_SUCCESS = 'priceBoard/SNAPSHOT_SUCCESS';
export interface SnapshotRequestSuccessAction {
  type: typeof SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_REQUEST_ERROR = 'priceBoard/SNAPSHOT_ERROR';
export interface SnapshotRequestErrorAction {
  type: typeof SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const SNAPSHOT_UNDERLYING_REQUESTING =
  'priceBoard/SNAPSHOT_UNDERLYING_REQUESTING';
export interface SnapshotUnderlyingCWRequestAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUESTING;
  data: any;
}

export const SNAPSHOT_UNDERLYING_REQUEST_SUCCESS =
  'priceBoard/SNAPSHOT_UNDERLYING_SUCCESS';
export interface SnapshotUnderlyingCWSuccessAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_UNDERLYING_REQUEST_ERROR =
  'priceBoard/SNAPSHOT_UNDERLYING_ERROR';
export interface SnapshotUnderlyingCWErrorAction {
  type: typeof SNAPSHOT_UNDERLYING_REQUEST_ERROR;
  error: any;
}

export const PS_SNAPSHOT_REQUESTING = 'priceBoard/PS_SNAPSHOT_REQUESTING';
export interface PsSnapshotRequestAction {
  type: typeof PS_SNAPSHOT_REQUESTING;
  data: any;
}

export const PS_SNAPSHOT_REQUEST_SUCCESS = 'priceBoard/PS_SNAPSHOT_SUCCESS';
export interface PsSnapshotRequestSuccessAction {
  type: typeof PS_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const PS_SNAPSHOT_REQUEST_ERROR = 'priceBoard/PS_SNAPSHOT_ERROR';
export interface PsSnapshotRequestErrorAction {
  type: typeof PS_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const PS_INDEX_REQUESTING = 'priceBoard/PS_INDEX_REQUESTING';
export interface PsIndexRequestAction {
  type: typeof PS_INDEX_REQUESTING;
  data: any;
}

export const PS_INDEX_REQUEST_SUCCESS = 'priceBoard/PS_INDEX_SUCCESS';
export interface PsIndexRequestSuccessAction {
  type: typeof PS_INDEX_REQUEST_SUCCESS;
  dataList: any;
}

export const PS_INDEX_REQUEST_ERROR = 'priceBoard/PS_INDEX_ERROR';
export interface PsIndexRequestErrorAction {
  type: typeof PS_INDEX_REQUEST_ERROR;
  error: any;
}

export const LOLE_SNAPSHOT_REQUESTING = 'priceBoard/LOLE_REQUESTING';
export interface LoleSnapshotRequestAction {
  type: typeof LOLE_SNAPSHOT_REQUESTING;
  data: any;
}

export const LOLE_SNAPSHOT_REQUEST_SUCCESS = 'priceBoard/LOLE_SUCCESS';
export interface LoleSnapshotRequestSuccessAction {
  type: typeof LOLE_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const LOLE_SNAPSHOT_REQUEST_ERROR = 'priceBoard/LOLE_ERROR';
export interface LoleSnapshotRequestErrorAction {
  type: typeof LOLE_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const LOLE_HSX_SNAPSHOT_REQUESTING = 'priceBoard/LOLE_HSX_REQUESTING';
export interface LoleHSXSnapshotRequestAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUESTING;
  data: any;
}

export const LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS = 'priceBoard/LOLE_HSX_SUCCESS';
export interface LoleHSXSnapshotRequestSuccessAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUEST_SUCCESS;
  dataList: any;
}

export const LOLE_HSX_SNAPSHOT_REQUEST_ERROR = 'priceBoard/LOLE_HSX_ERROR';
export interface LoleHSXSnapshotRequestErrorAction {
  type: typeof LOLE_HSX_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const CLEAR_PART_REQUEST = 'priceBoard/CLEAR_PART_REQUEST';
export interface ClearPartRequestAction {
  type: typeof CLEAR_PART_REQUEST;
}

export const CLEAR_SNAPSHOT_REQUEST = 'priceBoard/CLEAR_SNAPSHOT_REQUEST';
export interface ClearSnapshotRequestAction {
  type: typeof CLEAR_SNAPSHOT_REQUEST;
}

export const SNAPSHOT_PART_REQUESTING = 'priceBoard/SNAPSHOT_PART_REQUESTING';
export interface SnapshotPartRequestAction {
  type: typeof SNAPSHOT_PART_REQUESTING;
  data: any;
}

export const SNAPSHOT_PART_REQUEST_SUCCESS = 'priceBoard/SNAPSHOT_PART_SUCCESS';
export interface SnapshotPartRequestSuccessAction {
  type: typeof SNAPSHOT_PART_REQUEST_SUCCESS;
  dataList: any;
}

export const SNAPSHOT_PART_REQUEST_ERROR = 'priceBoard/SNAPSHOT_PART_ERROR';
export interface SnapshotPartRequestErrorAction {
  type: typeof SNAPSHOT_PART_REQUEST_ERROR;
  error: any;
}

export const STOCK_INFO_REQUESTING = 'priceBoard/STOCK_INFO_REQUESTING';
export interface StockInfoRequestAction {
  type: typeof STOCK_INFO_REQUESTING;
  data: any;
}

export const STOCK_INFO_REQUEST_SUCCESS = 'priceBoard/STOCK_INFO_SUCCESS';
export interface StockInfoRequestSuccessAction {
  type: typeof STOCK_INFO_REQUEST_SUCCESS;
  stockInfo: any;
}

export const STOCK_INFO_REQUEST_ERROR = 'priceBoard/STOCK_INFO_ERROR';
export interface StockInfoRequestErrorAction {
  type: typeof STOCK_INFO_REQUEST_ERROR;
  error: any;
}

export const ALL_ORD_REQUESTING = 'priceBoard/ALL_ORD_REQUESTING';
export interface AllOrdRequestAction {
  type: typeof ALL_ORD_REQUESTING;
  data: any;
}

export const ALL_ORD_REQUEST_SUCCESS = 'priceBoard/ALL_ORD_SUCCESS';
export interface AllOrdRequestSuccessAction {
  type: typeof ALL_ORD_REQUEST_SUCCESS;
  allOrder: any;
}

export const ALL_ORD_REQUEST_ERROR = 'priceBoard/ALL_ORD_ERROR';
export interface AllOrdRequestErrorAction {
  type: typeof ALL_ORD_REQUEST_ERROR;
  error: any;
}

export const ALL_ORD_COND_REQUESTING = 'priB/ALL_ORD_COND_REQUESTING';
export interface AllOrdCondRequestAction {
  type: typeof ALL_ORD_COND_REQUESTING;
  data: any;
}

export const ALL_ORD_COND_REQUEST_SUCCESS = 'priB/ALL_ORD_COND_SUCCESS';
export interface AllOrdCondSuccessAction {
  type: typeof ALL_ORD_COND_REQUEST_SUCCESS;
  resData: any;
}

export const ALL_ORD_COND_REQUEST_ERROR = 'priB/ALL_ORD_COND_ERROR';
export interface AllOrdCondErrorAction {
  type: typeof ALL_ORD_COND_REQUEST_ERROR;
  error: any;
}

export const CLEAR_ORD_LIST = 'priceBoard/CLEAR_ORD_LIST';
export interface ClearOrdListAction {
  type: typeof CLEAR_ORD_LIST;
}

export const PT_LIST_REQUESTING = 'priceBoard/PT_LIST_REQUESTING';
export interface PtListRequestAction {
  type: typeof PT_LIST_REQUESTING;
}

export const PT_LIST_REQUEST_SUCCESS = 'priceBoard/PT_LIST_SUCCESS';
export interface PtListRequestSuccessAction {
  type: typeof PT_LIST_REQUEST_SUCCESS;
  ptList: any;
}

export const PT_LIST_REQUEST_ERROR = 'priceBoard/PT_LIST_ERROR';
export interface PtListRequestErrorAction {
  type: typeof PT_LIST_REQUEST_ERROR;
  error: any;
}

export const PS_LIST_REQUESTING = 'priceBoard/PS_LIST_REQUESTING';
export interface PsListRequestAction {
  type: typeof PS_LIST_REQUESTING;
}

export const PS_LIST_REQUEST_SUCCESS = 'priceBoard/PS_LIST_SUCCESS';
export interface PsListRequestSuccessAction {
  type: typeof PS_LIST_REQUEST_SUCCESS;
  psList: any;
}

export const PS_LIST_REQUEST_ERROR = 'priceBoard/PS_LIST_ERROR';
export interface PsListRequestErrorAction {
  type: typeof PS_LIST_REQUEST_ERROR;
  error: any;
}

export const CW_LIST_REQUESTING = 'priceBoard/CW_LIST_REQUESTING';
export interface CwListRequestAction {
  type: typeof CW_LIST_REQUESTING;
}

export const CW_LIST_REQUEST_SUCCESS = 'priceBoard/CW_LIST_SUCCESS';
export interface CwListRequestSuccessAction {
  type: typeof CW_LIST_REQUEST_SUCCESS;
  cwList: any;
}

export const CW_LIST_REQUEST_ERROR = 'priceBoard/CW_LIST_ERROR';
export interface CwListRequestErrorAction {
  type: typeof CW_LIST_REQUEST_ERROR;
  error: any;
}

export const LIST_30_REQUESTING = 'priceBoard/LIST_30_REQUESTING';
export interface List30RequestAction {
  type: typeof LIST_30_REQUESTING;
  data: any;
}

export const LIST_30_REQUEST_SUCCESS = 'priceBoard/LIST_30_SUCCESS';
export interface List30RequestSuccessAction {
  type: typeof LIST_30_REQUEST_SUCCESS;
  list30: any;
}

export const LIST_30_REQUEST_ERROR = 'priceBoard/LIST_30_ERROR';
export interface List30RequestErrorAction {
  type: typeof LIST_30_REQUEST_ERROR;
  error: any;
}

export const LIST_NGANH_REQUESTING = 'priceBoard/LIST_NGANH_REQUESTING';
export interface ListNganhRequestAction {
  type: typeof LIST_NGANH_REQUESTING;
  data: any;
}

export const LIST_NGANH_REQUEST_SUCCESS = 'priceBoard/LIST_NGANH_SUCCESS';
export interface ListNganhRequestSuccessAction {
  type: typeof LIST_NGANH_REQUEST_SUCCESS;
  listNganh: any;
}

export const LIST_NGANH_REQUEST_ERROR = 'priceBoard/LIST_NGANH_ERROR';
export interface ListNganhRequestErrorAction {
  type: typeof LIST_NGANH_REQUEST_ERROR;
  error: any;
}

export const LIST_ETF_REQUESTING = 'priceBoard/LIST_ETF_REQUESTING';
export interface ListEtfRequestAction {
  type: typeof LIST_ETF_REQUESTING;
  data: any;
}

export const LIST_ETF_REQUEST_SUCCESS = 'priceBoard/LIST_ETF_REQUEST_SUCCESS';
export interface ListEtfRequestSuccessAction {
  type: typeof LIST_ETF_REQUEST_SUCCESS;
  listEtf: any;
}

export const LIST_ETF_REQUEST_ERROR = 'priceBoard/LIST_ETF_REQUEST_ERROR';
export interface ListEtfRequestErrorAction {
  type: typeof LIST_ETF_REQUEST_ERROR;
  error: any;
}

export const BRANCH_CODE_REQUESTING = 'priceBoard/BRANCH_CODE_REQUESTING';
export interface BranchCodeRequestAction {
  type: typeof BRANCH_CODE_REQUESTING;
}

export const BRANCH_CODE_REQUEST_SUCCESS = 'priceBoard/BRANCH_CODE_SUCCESS';
export interface BranchCodeRequestSuccessAction {
  type: typeof BRANCH_CODE_REQUEST_SUCCESS;
  branchList: any;
}

export const BRANCH_CODE_REQUEST_ERROR = 'priceBoard/BRANCH_CODE_ERROR';
export interface BranchCodeRequestErrorAction {
  type: typeof BRANCH_CODE_REQUEST_ERROR;
  error: any;
}

export const NOI_CAP_REQUESTING = 'priceBoard/NOI_CAP_REQUESTING';
export interface NoicapRequestAction {
  type: typeof NOI_CAP_REQUESTING;
}

export const NOI_CAP_REQUEST_SUCCESS = 'priceBoard/NOI_CAP_SUCCESS';
export interface NoicapRequestSuccessAction {
  type: typeof NOI_CAP_REQUEST_SUCCESS;
  noicap: any;
}

export const NOI_CAP_REQUEST_ERROR = 'priceBoard/NOI_CAP_ERROR';
export interface NoicapRequestErrorAction {
  type: typeof NOI_CAP_REQUEST_ERROR;
  error: any;
}

export const SUMMARY_REQUESTING = 'priceBoard/SUMMARY_REQUESTING';
export interface IndSumRequestAction {
  type: typeof SUMMARY_REQUESTING;
}

export const SUMMARY_REQUEST_SUCCESS = 'priceBoard/SUMMARY_SUCCESS';
export interface IndSumRequestSuccessAction {
  type: typeof SUMMARY_REQUEST_SUCCESS;
  indSum: any;
}

export const SUMMARY_REQUEST_ERROR = 'priceBoard/SUMMARY_ERROR';
export interface IndSumRequestErrorAction {
  type: typeof SUMMARY_REQUEST_ERROR;
  error: any;
}

export const GET_STOCK_BY_ID_REQUESTING = 'priceBoard/STOCK_BY_ID_REQUESTING';
export interface GetStockByIdRequestAction {
  type: typeof GET_STOCK_BY_ID_REQUESTING;
  data: any;
}

export const GET_STOCK_BY_ID_REQUEST_SUCCESS = 'priceBoard/STOCK_BY_ID_SUCCESS';
export interface GetStockByIdRequestSuccessAction {
  type: typeof GET_STOCK_BY_ID_REQUEST_SUCCESS;
  dataList: any;
}

export const GET_STOCK_BY_ID_REQUEST_ERROR = 'priceBoard/STOCK_BY_ID_ERROR';
export interface GetStockByIdRequestErrorAction {
  type: typeof GET_STOCK_BY_ID_REQUEST_ERROR;
  error: any;
}

export const INDEX_REQUESTING = 'priceBoard/INDEX_REQUESTING';
export interface IndexRequestAction {
  type: typeof INDEX_REQUESTING;
  data: any;
}

export const INDEX_REQUEST_SUCCESS = 'priceBoard/INDEX_SUCCESS';
export interface IndexRequestSuccessAction {
  type: typeof INDEX_REQUEST_SUCCESS;
  indexList: any;
}

export const INDEX_REQUEST_ERROR = 'priceBoard/INDEX_ERROR';
export interface IndexRequestErrorAction {
  type: typeof INDEX_REQUEST_ERROR;
  error: any;
}

export const NDATA_SNAPSHOT_REQUESTING = 'priceBoard/NDATA_REQUESTING';
export interface NdataSnapshotRequestAction {
  type: typeof NDATA_SNAPSHOT_REQUESTING;
  data: any;
}

export const NDATA_SNAPSHOT_REQUEST_SUCCESS = 'priceBoard/NDATA_SUCCESS';
export interface NdataSnapshotRequestSuccessAction {
  type: typeof NDATA_SNAPSHOT_REQUEST_SUCCESS;
  ndata: any;
}

export const NDATA_SNAPSHOT_REQUEST_ERROR = 'priceBoard/NDATA_ERROR';
export interface NdataSnapshotRequestErrorAction {
  type: typeof NDATA_SNAPSHOT_REQUEST_ERROR;
  error: any;
}

export const TRADE_HIS_PS_REQUESTING = 'priceBoard/TRADE_HIS_PS_REQUESTING';
export interface TradeHisPsRequestAction {
  type: typeof TRADE_HIS_PS_REQUESTING;
  data: any;
}

export const TRADE_HIS_PS_REQUEST_SUCCESS = 'priceBoard/TRADE_HIS_PS_SUCCESS';
export interface TradeHisPsRequestSuccessAction {
  type: typeof TRADE_HIS_PS_REQUEST_SUCCESS;
  dataList: any;
}

export const TRADE_HIS_PS_REQUEST_ERROR = 'priceBoard/TRADE_HIS_PS_ERROR';
export interface TradeHisPsRequestErrorAction {
  type: typeof TRADE_HIS_PS_REQUEST_ERROR;
  error: any;
}

export const TRADE_HIS_PS_Add_ROW = 'priceBoard/TRADE_HIS_PS_Add_ROW';
export interface TradeHisPsAddRowAction {
  type: typeof TRADE_HIS_PS_Add_ROW;
  data: any;
}

export const SORT_TYPE = 'priceBoard/SORT_TYPE';
export interface SortTypeTblAction {
  type: typeof SORT_TYPE;
  _type: any;
}

export const DM_NGANH_REQUESTING = 'priceBoard/DM_NGANH_REQUESTING';
export interface DmNganhRequestAction {
  type: typeof DM_NGANH_REQUESTING;
}

export const DM_NGANH_REQUEST_SUCCESS = 'priceBoard/DM_NGANH_SUCCESS';
export interface DmNganhRequestSuccessAction {
  type: typeof DM_NGANH_REQUEST_SUCCESS;
  dataList: any;
}

export const DM_NGANH_REQUEST_ERROR = 'priceBoard/DM_NGANH_ERROR';
export interface DmNganhRequestErrorAction {
  type: typeof DM_NGANH_REQUEST_ERROR;
  error: any;
}

export const TOP_INTEREST_REQUESTING = 'priceBoard/TOP_INTEREST_REQUESTING';
export interface TopInterestRequestAction {
  type: typeof TOP_INTEREST_REQUESTING;
}

export const TOP_INTEREST_REQUEST_SUCCESS =
  'priceBoard/TOP_INTEREST_REQUEST_SUCCESS';
export interface TopInterestRequestSuccessAction {
  type: typeof TOP_INTEREST_REQUEST_SUCCESS;
  dataList: any;
}

export const TOP_INTEREST_REQUEST_ERROR =
  'priceBoard/TOP_INTEREST_REQUEST_ERROR';
export interface TopInterestRequestErrorAction {
  type: typeof TOP_INTEREST_REQUEST_ERROR;
  error: any;
}

/** danh sách nâng cao */
export const ADV_STOCK_DATA_BYID = 'priB/ADV_STOCK_DATA_BYID';

export const ADV_STOCK_DATA_BYID_REQUESTING =
  'priB/ADV_STOCK_DATA_BYID_REQUESTING';
export interface AdvStockDataByIdRequestAction {
  type: typeof ADV_STOCK_DATA_BYID_REQUESTING;
  data: any;
}

export const ADV_STOCK_DATA_BYID_SUCCESS = 'priB/ADV_STOCK_DATA_BYID_SUCCESS';
export interface AdvStockDataByIdSuccessAction {
  type: typeof ADV_STOCK_DATA_BYID_SUCCESS;
  data: any;
}

export const ADV_STOCK_DATA_BYID_ERROR = 'priB/ADV_STOCK_DATA_BYID_ERROR';
export interface AdvStockDataByIdErrorAction {
  type: typeof ADV_STOCK_DATA_BYID_ERROR;
  error: any;
}

/** vcbs filter list */
export const VCBS_FILTER_LIST = 'priB/VCBS_FILTER_LIST';

export const VCBS_FILTER_LIST_REQUESTING = 'priB/VCBS_FILTER_LIST_REQUESTING';
export interface VcbsFilterListRequestAction {
  type: typeof VCBS_FILTER_LIST_REQUESTING;
}

export const VCBS_FILTER_LIST_SUCCESS = 'priB/VCBS_FILTER_LIST_SUCCESS';
export interface VcbsFilterListSuccessAction {
  type: typeof VCBS_FILTER_LIST_SUCCESS;
  data: any;
}

export const VCBS_FILTER_LIST_ERROR = 'priB/VCBS_FILTER_LIST_ERROR';
export interface VcbsFilterListErrorAction {
  type: typeof VCBS_FILTER_LIST_ERROR;
  error: any;
}

/** vcbs filter snapshot */
export const VCBS_FILTER_SNAPSHOT = 'priB/VCBS_FILTER_SNAPSHOT';

export const VCBS_FILTER_SNAPSHOT_REQUESTING =
  'priB/VCBS_FILTER_SNAPSHOT_REQUESTING';
export interface VcbsFilterSnapshotRequestAction {
  type: typeof VCBS_FILTER_SNAPSHOT_REQUESTING;
  data: any;
}

export const VCBS_FILTER_SNAPSHOT_SUCCESS = 'priB/VCBS_FILTER_SNAPSHOT_SUCCESS';
export interface VcbsFilterSnapshotSuccessAction {
  type: typeof VCBS_FILTER_SNAPSHOT_SUCCESS;
  data: any;
}

export const VCBS_FILTER_SNAPSHOT_ERROR = 'priB/VCBS_FILTER_SNAPSHOT_ERROR';
export interface VcbsFilterSnapshotErrorAction {
  type: typeof VCBS_FILTER_SNAPSHOT_ERROR;
  error: any;
}

/** danh sách sec info priceboard */
export const ADV_SEC_INFO_REQUESTING = 'priB/ADV_SEC_INFO_REQUESTING';
export interface AdvSecInfoRequestAction {
  type: typeof ADV_SEC_INFO_REQUESTING;
}

export const ADV_SEC_INFO_SUCCESS = 'priB/ADV_SEC_INFO_SUCCESS';
export interface AdvSecInfoSuccessAction {
  type: typeof ADV_SEC_INFO_SUCCESS;
  data: any;
}

export const ADV_SEC_INFO_ERROR = 'priB/ADV_SEC_INFO_ERROR';
export interface AdvSecInfoErrorAction {
  type: typeof ADV_SEC_INFO_ERROR;
  error: any;
}

export const STOCK_BY_ID_CLEAR = 'priB/STOCK_BY_ID_CLEAR';
export interface StockByIdClearAction {
  type: typeof STOCK_BY_ID_CLEAR;
}

export const STOCK_BY_ID_VER2_SUCCESS = 'priB/STOCK_BY_ID_VER2_SUCCESS';
export interface StockByIdVer2SuccessAction {
  type: typeof STOCK_BY_ID_VER2_SUCCESS;
  data: any;
}

/** danh sách nâng cao */
export const ADV_STOCK_LIST_DATA = 'priB/ADV_STOCK_LIST_DATA';
export const ADV_STOCK_LIST_DATA_CLEAR = 'priB/ADV_STOCK_LIST_DATA_CLEAR';
export interface AdvStockListDataClearAction {
  type: typeof ADV_STOCK_LIST_DATA_CLEAR;
}

export const ADV_STOCK_LIST_DATA_REQUESTING =
  'priB/ADV_STOCK_LIST_DATA_REQUESTING';
export interface AdvStockListDataRequestAction {
  type: typeof ADV_STOCK_LIST_DATA_REQUESTING;
  data: any;
}

export const ADV_STOCK_LIST_DATA_SUCCESS = 'priB/ADV_STOCK_LIST_DATA_SUCCESS';
export interface AdvStockListDataSuccessAction {
  type: typeof ADV_STOCK_LIST_DATA_SUCCESS;
  data: any;
}

export const ADV_STOCK_LIST_DATA_ERROR = 'priB/ADV_STOCK_LIST_DATA_ERROR';
export interface AdvStockListDataErrorAction {
  type: typeof ADV_STOCK_LIST_DATA_ERROR;
  error: any;
}

// lấy chi tiết mã advance single  => dùng khi thêm
export const ADV_STOCK_SINGLE_REQUESTING = 'priB/ADV_STOCK_SINGLE_REQUESTING';
export interface AdvStockSingleRequestAction {
  type: typeof ADV_STOCK_SINGLE_REQUESTING;
  data: any;
}

export const ADV_STOCK_SINGLE_SUCCESS = 'priB/ADV_STOCK_SINGLE_SUCCESS';
export interface AdvStockSingleSuccessAction {
  type: typeof ADV_STOCK_SINGLE_SUCCESS;
  data: any;
}

export const ADV_STOCK_SINGLE_ERROR = 'priB/ADV_STOCK_SINGLE_ERROR';
export interface AdvStockSingleErrorAction {
  type: typeof ADV_STOCK_SINGLE_ERROR;
  error: any;
}

export type PriceBoardAction =
  | AllStockRequestAction
  | AllStockRequestSuccessAction
  | AllStockRequestErrorAction
  | MarginListRequestAction
  | MarginListRequestSuccessAction
  | MarginListRequestErrorAction
  | ListMessageRequestAction
  | ListMessageRequestSuccessAction
  | ListMessageRequestErrorAction
  | SnapshotRequestAction
  | SnapshotRequestSuccessAction
  | SnapshotRequestErrorAction
  | SnapshotUnderlyingCWRequestAction
  | SnapshotUnderlyingCWSuccessAction
  | SnapshotUnderlyingCWErrorAction
  | PsSnapshotRequestAction
  | PsSnapshotRequestSuccessAction
  | PsSnapshotRequestErrorAction
  | PsIndexRequestAction
  | PsIndexRequestSuccessAction
  | PsIndexRequestErrorAction
  | LoleSnapshotRequestAction
  | LoleSnapshotRequestSuccessAction
  | LoleSnapshotRequestErrorAction
  | LoleHSXSnapshotRequestAction
  | LoleHSXSnapshotRequestSuccessAction
  | LoleHSXSnapshotRequestErrorAction
  | ClearPartRequestAction
  | ClearSnapshotRequestAction
  | SnapshotPartRequestAction
  | SnapshotPartRequestSuccessAction
  | SnapshotPartRequestErrorAction
  | StockInfoRequestAction
  | StockInfoRequestSuccessAction
  | StockInfoRequestErrorAction
  | AllOrdRequestAction
  | AllOrdRequestSuccessAction
  | AllOrdRequestErrorAction
  | PtListRequestAction
  | PtListRequestSuccessAction
  | PtListRequestErrorAction
  | PsListRequestAction
  | PsListRequestSuccessAction
  | PsListRequestErrorAction
  | CwListRequestAction
  | CwListRequestSuccessAction
  | CwListRequestErrorAction
  | List30RequestAction
  | List30RequestSuccessAction
  | List30RequestErrorAction
  | ListNganhRequestAction
  | ListNganhRequestSuccessAction
  | ListNganhRequestErrorAction
  | ListEtfRequestAction
  | ListEtfRequestSuccessAction
  | ListEtfRequestErrorAction
  | BranchCodeRequestAction
  | BranchCodeRequestSuccessAction
  | BranchCodeRequestErrorAction
  | NoicapRequestAction
  | NoicapRequestSuccessAction
  | NoicapRequestErrorAction
  | IndSumRequestAction
  | IndSumRequestSuccessAction
  | IndSumRequestErrorAction
  | GetStockByIdRequestAction
  | GetStockByIdRequestSuccessAction
  | GetStockByIdRequestErrorAction
  | IndexRequestAction
  | IndexRequestSuccessAction
  | IndexRequestErrorAction
  | NdataSnapshotRequestAction
  | NdataSnapshotRequestSuccessAction
  | NdataSnapshotRequestErrorAction
  | TradeHisPsRequestAction
  | TradeHisPsRequestSuccessAction
  | TradeHisPsRequestErrorAction
  | TradeHisPsAddRowAction
  | SortTypeTblAction
  | DmNganhRequestAction
  | DmNganhRequestSuccessAction
  | DmNganhRequestErrorAction
  | TopInterestRequestAction
  | TopInterestRequestSuccessAction
  | TopInterestRequestErrorAction
  | AllOrdCondRequestAction
  | AllOrdCondSuccessAction
  | AllOrdCondErrorAction
  | ClearOrdListAction
  | AdvSecInfoRequestAction
  | AdvSecInfoSuccessAction
  | AdvSecInfoErrorAction
  | AdvStockListDataRequestAction
  | AdvStockListDataSuccessAction
  | AdvStockListDataErrorAction
  | StockByIdClearAction
  | StockByIdVer2SuccessAction
  | AdvStockListDataClearAction
  | AdvStockSingleRequestAction
  | AdvStockSingleSuccessAction
  | AdvStockSingleErrorAction
  | AdvStockDataByIdRequestAction
  | AdvStockDataByIdSuccessAction
  | AdvStockDataByIdErrorAction
  | VcbsFilterListRequestAction
  | VcbsFilterListSuccessAction
  | VcbsFilterListErrorAction
  | VcbsFilterSnapshotRequestAction
  | VcbsFilterSnapshotSuccessAction
  | VcbsFilterSnapshotErrorAction;
