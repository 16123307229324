import { AppState } from 'reducers';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { DMNGANH, categoryDefault } from '../storages';
import { INormalSelect } from 'interface';
import { getVcbsFilterList } from './priceBoard';
import { IVcbsFilter } from 'interface/response';

const getToken = (state: AppState) => state.client.token;
const getToast = (state: AppState) => state.client.toast;
const getTypeNav = (state: AppState) => state.client.typeNav;
export const getConfig = (state: AppState) => state.client.config;
const getDataSearch = (state: AppState) => state.client.dataSearch;
const getClientSetting = (state: AppState) => state.client.setting;
const getFooterActive = (state: AppState) => state.client.footActive;
const getHeaderActive = (state: AppState) => state.client.headActive;
const getTypeIndex = (state: AppState) => {
  const { setting } = state.client;
  return (setting && setting.typeIndex) || 1;
};
const getTypePrice = (state: AppState) => {
  const { setting } = state.client;
  return (setting && setting.priceType) || 1;
};
export const makeGetCategory = () =>
  createSelector([getCategory], (category) => category);
const getCatalogSelected = (state: AppState, props: any) => {
  const { categoryId } = props;
  // log(categoryId)
  if (categoryId?.startsWith('vst-')) {
    const vcbsFilter = getVcbsFilterList(state);
    const _filter = _.find(
      vcbsFilter,
      (o: IVcbsFilter) => 'vst' + o.FilterId === categoryId,
    );
    if (!_filter) return null;

    return {
      groupName: 'VCBS',
      name: _filter.Name,
      path: '/price/bang-gia/vst' + _filter.FilterId,
      type: 'group',
      id: _filter.FilterId,
      sequence: _filter.FilterId,
      value: _filter.Criterias,
      exchange: _filter.ExchangeCode,
      industry: _filter.IndustryCode,
    };
  } else if (categoryId?.startsWith('nganh-')) {
    // danh muc nganh
    const _filter = _.find(
      DMNGANH,
      (o) => o && o.path && o.path.endsWith(categoryId),
    );
    return _filter;
  } else {
    const _category = getCategory(state);
    const _accPortfolio = getAccountPortfolio(state);

    const _filter =
      _.find(_category, (o) => o && o.path && o.path.endsWith(categoryId)) ||
      _.find(_accPortfolio, (o) => o.id === categoryId);

    return _filter;
  }
};
const getAccountPortfolio = (state: AppState) => {
  const { accPortfolio } = state.client;
  const _arr: any = [];

  if (accPortfolio) {
    accPortfolio.forEach((element: any) => {
      _arr.push({
        id: element.PK_ACCOUNT_PORFOLIO,
        name: element.C_PORFOLIO_NAME,
        path: '/price/bang-gia/' + element.PK_ACCOUNT_PORFOLIO,
        value:
          element.C_LIST_SHARE_CODE && element.C_LIST_SHARE_CODE !== 'null'
            ? element.C_LIST_SHARE_CODE.split(',')
            : [],
        pinnedRow: [],
        type: 'watchlist',
        isDefault: element.C_DEFAULT,
      });
    });
  }

  return _arr;
};

const getCategory = (state: AppState) => {
  // console.log(state.client.category)
  const { category } = state?.client;
  if (!category || !_.isArray(category)) return categoryDefault;

  return category;
};

const getUpdatePortfolio = (state: AppState) => state.client.updPortfolio;
const getDeletePortfolio = (state: AppState) => state.client.delPortfolio;
const getDefaultAccount = (state: AppState) => state.client.defAccount;
const getListAccount = (state: AppState) => state.client.listAccount;

const getListAccountSelect = (state: AppState) => {
  const listAcc: INormalSelect[] = [];

  if (!state.client.listAccount) return [];

  state.client.listAccount.forEach((element: any) => {
    listAcc.push({
      value: element.accCode,
      label: element.accCode,
    });
  });

  return listAcc;
};

/************************************ MAKE GET MESSAGE ***********************************/
export const makeGetToken = () => createSelector(getToken, (token) => token);
export const makeGetToast = () => createSelector(getToast, (toast) => toast);
export const makeGetTypeNav = () =>
  createSelector(getTypeNav, (typeNav) => typeNav);
export const makeGetConfig = () =>
  createSelector(getConfig, (config) => config);
export const makeGetDataSearch = () =>
  createSelector(getDataSearch, (dataSearch) => dataSearch);
export const makeGetClientSetting = () =>
  createSelector(getClientSetting, (setting) => setting);
export const makeGetFooterActive = () =>
  createSelector(getFooterActive, (footActive) => footActive);
export const makeGetHeaderActive = () =>
  createSelector(getHeaderActive, (headActive) => headActive);
export const makeGetTypeIndex = () =>
  createSelector([getTypeIndex], (typeIndex) => typeIndex);
export const makeGetTypePrice = () =>
  createSelector([getTypePrice], (typePrice) => typePrice);
export const makeGetCategorySelected = () =>
  createSelector([getCatalogSelected], (symbol) => symbol);
export const makeGetAccountPortfolio = () =>
  createSelector([getAccountPortfolio], (accPortfolio) => accPortfolio);
export const makeGetUpdatePortfolio = () =>
  createSelector([getUpdatePortfolio], (updPortfolio) => updPortfolio);

export const makeGetDeletePortfolio = () =>
  createSelector([getDeletePortfolio], (delPortfolio) => delPortfolio);
export const makeGetDefaultAccount = () =>
  createSelector([getDefaultAccount], (defAccount) => defAccount);
export const makeGetListAccount = () =>
  createSelector([getListAccount], (listAccount) => listAccount);

export const makeGetListAccountSelect = () =>
  createSelector([getListAccountSelect], (listAccount) => listAccount);
