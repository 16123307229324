import { createSelector } from 'reselect';

import * as _ from 'lodash';
import { AppState } from 'reducers';

const getSummaryCash = (state: AppState) => {
  let { cashList } = state.summary;
  // if (!cashList || !cashList.list) return null;

  // let _cashList = _.sortBy(cashList.list, ['tranDate']);

  // let bgAmt = cashList.beginAmt || 0;

  // _cashList.map((item) => {
  //   item.beginAmt = bgAmt;
  //   item.afterAmt = bgAmt + item.inAmt - item.outAmt;
  //   bgAmt = item.afterAmt;
  // });
  // cashList.list = [..._cashList];
  if (!!cashList.length) return cashList[0];
  return null;
};

const getCashCanAdv = (state: AppState) => state.summary.cashCanAdv;
const getAdvStatus = (state: AppState) => state.summary.advStt;
const getAdvStatusHis = (state: AppState) => state.summary.advSttHis;
const getListShareBalance = (state: AppState) => state.summary.listShareBalance;
const getListShareStatus = (state: AppState) => state.summary.listShareStt;
const getListShareTransferHis = (state: AppState) =>
  state.summary.shareTransferHis;

const getCashAccountInfo = (state: AppState) => {
  if (!state.summary.cashAccountInfo || !state.summary.cashAccountInfo.length)
    return state.summary.cashAccountInfo;

  return state.summary.cashAccountInfo[0];
};
const getBeneficiaryAccount = (state: AppState) =>
  state.summary.beneficiaryAccount;

const getListCashStatus = (state: AppState) => state.summary.listCashStt;
const getListCashTransferHis = (state: AppState) =>
  state.summary.cashTransferHis;

/************************************** MAKE GET ****************************************/

export const makeGetSummaryCash = () =>
  createSelector([getSummaryCash], (cashList) => cashList);

export const makeGetCashCanAdv = () =>
  createSelector([getCashCanAdv], (cashCanAdv) => cashCanAdv);

export const makeGetAdvStatus = () =>
  createSelector([getAdvStatus], (advStt) => advStt);

export const makeGetAdvStatusHis = () =>
  createSelector([getAdvStatusHis], (advSttHis) => advSttHis);

export const makeGetListShareBalance = () =>
  createSelector([getListShareBalance], (listShareBalance) => listShareBalance);

export const makeGetListShareStatus = () =>
  createSelector([getListShareStatus], (listShareStt) => listShareStt);

export const makeListShareTransferHis = () =>
  createSelector(
    [getListShareTransferHis],
    (shareTransferHis) => shareTransferHis,
  );

export const makeGetCashAccountInfo = () =>
  createSelector([getCashAccountInfo], (cashAccountInfo) => cashAccountInfo);

export const makeGetBeneficiaryAccount = () =>
  createSelector(
    [getBeneficiaryAccount],
    (beneficiaryAccount) => beneficiaryAccount,
  );

export const makeGetListCashStatus = () =>
  createSelector([getListCashStatus], (listCashStt) => listCashStt);

export const makeListCashTransferHis = () =>
  createSelector(
    [getListCashTransferHis],
    (cashTransferHis) => cashTransferHis,
  );
