/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppState } from 'reducers';
import { connect } from 'react-redux';
import _ from 'lodash';

import { makeGetToken } from 'lib/selector';
import { useDispatch } from 'react-redux';
import { headerActiveSet } from 'containers/client/actions';
import { IoClose } from 'react-icons/io5';
import PanelOrderTrade from './formTradeNew';
import PanelOrderInday from './panelOrderInday';
import PanelOrderCond from './panelOrderCond';
import PanelAsset from './panelAsset';
import React from 'react';

interface IProps {
  token: any;
  headActive?: string;
}

function PanelHeaderActiveM(props: IProps): JSX.Element {
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = React.useState<string>('ORDER_NOMAL');
  const { headActive } = props;

  function handleClosePanel() {
    dispatch(headerActiveSet(''));
  }

  return (
    <div className="w-full h-[calc(100vh-40px)] bg-skin-panel absolute bottom-0 left-0 z-10 flex flex-col">
      <div className="bg-skin-nav h-full">
        <div className="flex items-center p-4 w-full">
          <label className="text-skin-white text-lg font-semibold leading-7 mr-auto flex items-center">
            {headActive === 'HEAD_TRADING' ? (
              'Đặt lệnh'
            ) : headActive === 'HEAD_ORDER' ? (
              <div className="grid grid-cols-2">
                <div
                  className={
                    tabActive === 'ORDER_NOMAL' ? 'text-skin-vendorAct' : ''
                  }
                  onClick={() => setTabActive('ORDER_NOMAL')}
                >
                  Lệnh thường
                </div>
                <div
                  className={
                    tabActive === 'ORDER_COND' ? 'text-skin-vendorAct' : ''
                  }
                  onClick={() => setTabActive('ORDER_COND')}
                >
                  Lệnh điều kiện
                </div>
              </div>
            ) : (
              'Tài sản'
            )}
          </label>

          <a
            className="ml-3 text-skin-vendor"
            onClick={() => handleClosePanel()}
          >
            <IoClose className="w-6 h-auto p-0.5" />
          </a>
        </div>
        <div className="p-4 pt-0">
          {headActive === 'HEAD_TRADING' && (
            <PanelOrderTrade onClose={handleClosePanel} />
          )}
          {headActive === 'HEAD_ORDER' && tabActive === 'ORDER_NOMAL' && (
            <PanelOrderInday onClose={handleClosePanel} />
          )}
          {headActive === 'HEAD_ORDER' && tabActive === 'ORDER_COND' && (
            <PanelOrderCond onClose={handleClosePanel} />
          )}
          {headActive === 'HEAD_ASSET' && (
            <PanelAsset onClose={handleClosePanel} />
          )}
        </div>
      </div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state: AppState) => {
    return {
      token: getToken(state),
      headActive: state.client.headActive,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(PanelHeaderActiveM);
