import { handleApiErrors } from 'lib/api-error';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { Config } from 'interface';
import _ from 'lodash';
import { AppState } from 'reducers';
import { checkInvalidSession } from 'utils';
import {
  userAssetDetailError,
  userAssetDetailSuccess,
  userDelError,
  userDelSuccess,
  userDetailError,
  userDetailSuccess,
  userProfitCloseError,
  userProfitCloseSuccess,
  userRightByAccError,
  userRightByAccSuccess,
  userSearchError,
  userSearchSuccess,
  userUpdError,
  userUpdSuccess,
} from './action';
import * as actions from './actionType';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.timeout = 3000;

// Helper function to get config from context
const selectConfig = (state: AppState) => state.config;

async function postRequestApi(data: Object, appUrl: string) {
  const url = `${appUrl}/TraditionalService`;

  try {
    const response = axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    const response_1 = handleApiErrors(await response);
    const json = await response_1.data;
    return json;
  } catch (error) {
    if (error?.toString().includes('Failed to fetch')) {
      throw Error(
        'Không kết nối được server, Vui lòng kiểm tra đường truyền mạng!',
      );
    } else throw error;
  }
}

function* userSearchRequestFlow(
  action: actions.UserSearchRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userSearchSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userSearchError(error));
    }
  }
}

function* userDetailRequestFlow(
  action: actions.UserDetailRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(
      userDetailSuccess(
        _res.data && _.isArray(_res.data) && !!_res.data.length
          ? _res.data[0]
          : _res.data,
      ),
    );
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userDetailError(error));
    }
  }
}

function* userUpdRequestFlow(action: actions.UserUpdRequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userUpdSuccess(Math.random()));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userUpdError(error));
    }
  }
}

function* userDelRequestFlow(action: actions.UserDelRequestingAction): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userDelSuccess(Math.random()));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userDelError(error));
    }
  }
}

function* userRightByAccRequestFlow(
  action: actions.UserSearchRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userRightByAccSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userRightByAccError(error));
    }
  }
}

function* userAssetDetailRequestFlow(
  action: actions.UserAssetDetailRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);

    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userAssetDetailSuccess(_res.data));
  } catch (error: any) {
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userAssetDetailError(error));
    }
  }
}

function* userProfitCloseRequestFlow(
  action: actions.UserProfitCloseRequestingAction,
): any {
  try {
    const config: Config = yield select(selectConfig);
    if (!config.hasOwnProperty('AuthUrl')) {
      throw new Error('Config URLs are not available');
    }
    const { data } = action;
    const _res: any = yield call(postRequestApi, data, config.AuthUrl);
    if (_res.rc < 1) {
      if (checkInvalidSession(_res.rs)) {
        localStorage.removeItem('token');
        yield put({
          type: 'INVALID_SESSION',
          msg: _res.rs,
        });
      } else {
        yield put({
          type: 'REQUEST_PRIVATE_FALSE',
          msg: _res.rs,
        });
      }
      throw Error(_res.rs);
    }
    yield put(userProfitCloseSuccess(_res.data));
  } catch (error: any) {
    console.log(error);
    if (error?.response?.statusText === 'Unauthorized')
      yield put({ type: 'INVALID_SESSION' });
    if (error instanceof Error) {
      yield put(userProfitCloseError(error));
    }
  }
}

function* userWatcher() {
  yield all([
    takeLatest(actions.USER_SEARCH_REQUESTING, userSearchRequestFlow),
    takeLatest(actions.USER_DETAIL_REQUESTING, userDetailRequestFlow),
    takeLatest(actions.USER_UPD_REQUESTING, userUpdRequestFlow),
    takeLatest(actions.USER_DEL_REQUESTING, userDelRequestFlow),
    takeLatest(actions.USER_RIGHT_BY_ACC_REQUESTING, userRightByAccRequestFlow),
    takeLatest(
      actions.USER_ASSET_DETAIL_REQUESTING,
      userAssetDetailRequestFlow,
    ),
    takeLatest(
      actions.USER_PROFIT_CLOSE_REQUESTING,
      userProfitCloseRequestFlow,
    ),
  ]);
}

export default userWatcher;
