/* eslint-disable import/no-anonymous-default-export */
import layout from './layout.json';
import common from './common.json';
import nav from './nav.json';
import dashboard from './dashboard.json';
import account from './account.json';
import risk from './risk.json';
import policy from './policy.json';
import report from './report.json';
import system from './system.json';
import category from './category.json';
import admin from './admin.json';
import notice from './notice.json';
import trading from './trading.json';
import priceBroad from './priceBroad.json';
import stockDetail from './stockDetail.json';
import orderBook from './orderbook.json';
import summary from './summary.json';
import right from './right.json';
import analysis from './analysis.json';
import algo from './algo.json';

export default {
  ...layout,
  ...nav,
  ...dashboard,
  ...common,
  ...account,
  ...risk,
  ...policy,
  ...report,
  ...system,
  ...category,
  ...admin,
  ...notice,
  ...trading,
  ...priceBroad,
  ...stockDetail,
  ...orderBook,
  ...summary,
  ...right,
  ...analysis,
  ...algo,
};
