import { useTranslation } from 'react-i18next';
import NoData from 'shared/nodata';
import { numberFormat } from 'utils';

interface IProps {}

function TblCashTransferHis(props: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="w-full mt-3">
      <table className="table table-bordered w-full">
        <thead>
          <tr>
            <th colSpan={3}>Net</th>
            <th colSpan={2}>{numberFormat(1400000000, 0, '0')}</th>
            <th colSpan={2}></th>
          </tr>
          <tr>
            <th colSpan={3}>Tổng đã duyệt</th>
            <th>{numberFormat(700000000, 0, '0')}</th>
            <th>{numberFormat(700000000, 0, '0')}</th>
            <th colSpan={2}></th>
          </tr>
          <tr>
            <th>Thời gian</th>
            <th>Nghiệp vụ</th>
            <th>Trạng thái</th>
            <th>Tiền nạp</th>
            <th>Tiền rút</th>
            <th>Bank</th>
            <th>Bút toán</th>
          </tr>
        </thead>
      </table>
      <div className="d-flex justify-center mt-7">
        <NoData content="Hiện không có giao dịch nào" />
      </div>
    </div>
  );
}

export default TblCashTransferHis;
