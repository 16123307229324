import ListEmpty from 'assets/img/icon/list-empty.png';

interface IProps {
  content: string;
}

function NoData(props: IProps): JSX.Element {
  const { content } = props;
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={ListEmpty} alt="empty" />
      <span className="text-skin-vendor text-sm mt-3">{content}</span>
    </div>
  );
}

export default NoData;
